import { Injectable, isDevMode } from '@angular/core';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor, SpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { ATTR_SERVICE_NAME } from '@opentelemetry/semantic-conventions';

@Injectable({
  providedIn: 'root',
})
export class OpenTelemetryService {
  constructor() {
    this.init();
  }

  private init() {
    const provider = new WebTracerProvider({
      spanProcessors: this.getSpanProcessors(),
      resource: new Resource({
        [ATTR_SERVICE_NAME]: 'lage-frontend',
      }),
    });

    provider.register({
      contextManager: new ZoneContextManager(),
    });

    registerInstrumentations({
      instrumentations: [
        getWebAutoInstrumentations({
          '@opentelemetry/instrumentation-document-load': {},
          '@opentelemetry/instrumentation-user-interaction': {},
          '@opentelemetry/instrumentation-fetch': {},
          '@opentelemetry/instrumentation-xml-http-request': {},
        }),
      ],
    });
  }

  private getSpanProcessors(): SpanProcessor[] {
      // Produktiv: Senden an Grafana/Jaeger
      let endpoint;
      if (isDevMode()) {
        endpoint = 'http://localhost:4318/v1/traces';
      } else {
        endpoint = window.location.origin + '/api/otel/v1/traces';
      }
      const exporter = new OTLPTraceExporter({
        url: endpoint,
      });
      const processor = new BatchSpanProcessor(exporter);
      return [processor];
  }
}
