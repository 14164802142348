import { NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { BehaviorSubject, Observable } from 'rxjs';
import { TaktischeFormationDTO, TaktischeFormationNestedDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';

/**
 * Baum-Struktur taktischer Formationen.
 * Zeigt alle Taktischen Formationen unterhalb einer übergebenen Taktischen Formation an
 */
@Component({
  selector: 'app-taktische-formation-tree',
  standalone: true,
  imports: [CommonModule, MatTreeModule, MatIconModule, MatButtonModule, MatToolbarModule, MatTooltipModule],
  templateUrl: './taktische-formation-tree.component.html',
  styleUrls: ['./taktische-formation-tree.component.scss'],
})
export class TaktischeFormationTreeComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  @Input()
  rootTaktischeFormationen$!: Observable<TaktischeFormationNestedDTO[]>;

  @Input()
  selectedTaktischeFormationNested$!: BehaviorSubject<TaktischeFormationNestedDTO>;

  treeControl = new NestedTreeControl<TaktischeFormationNestedDTO>((node) => node.taktischeFormationen);
  dataSource = new MatTreeNestedDataSource<TaktischeFormationNestedDTO>();

  private taktischeZeichenService = inject(TaktischeZeichenService);

  readonly taktischeZeichenInfoMapping = this.taktischeZeichenService.taktischeZeichenInfoMapping;
  TaktischesZeichenTyp = TaktischesZeichenTyp;

  selectTaktischeFormation(taktischeFormation: TaktischeFormationDTO) {
    this.selectedTaktischeFormationNested$.next(taktischeFormation);
  }

  ngOnInit(): void {
    this.rootTaktischeFormationen$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((taktischeFormationen) => {
      this.dataSource.data = taktischeFormationen;
      this.dataSource.data.forEach((data) => this.treeControl.expandDescendants(data));
    });
  }

  hasChild = (_: number, node: TaktischeFormationNestedDTO) =>
    !!node.taktischeFormationen && node.taktischeFormationen.length > 0;
}
