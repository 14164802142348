import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorService } from '@product/ise-error-lib';
import { FotoDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

/**
 * Form zum Erstellen/Bearbeiten von Fotos als Taktische Zeichen.
 * Erlaubt das Hochladen eines Fotos als Anhang.
 */
@Component({
  selector: 'app-foto-form',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    CleanableFormFieldComponent,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './foto-form.component.html',
  styleUrls: ['./foto-form.component.scss'],
})
export class FotoFormComponent implements TaktischesZeichenForm {
  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  iconDataUrlChanged = new EventEmitter<string>();

  private errorService = inject(ErrorService);
  private taktischeZeichenService = inject(TaktischeZeichenService);

  readonly DEFAULT_ICON_DATA_URL =
    this.taktischeZeichenService.taktischeZeichenInfoMapping.get(TaktischesZeichenTyp.Foto)?.dataUrl || '';
  private isCustomIcon = false;
  private iconDataUrl?: string;

  dtoToEdit?: FotoDTO;

  fotoBase64 = '';
  uploadURL = '';

  private formBuilder = inject(NonNullableFormBuilder);
  fcAnzeigename = this.formBuilder.control('', [Validators.maxLength(30), Validators.required]);
  fcBeschreibung = this.formBuilder.control('', [Validators.maxLength(255)]);
  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    beschreibung: this.fcBeschreibung,
  });

  // Maximale Upload-Größe 5MB
  protected readonly MAX_FILE_SIZE = 5_000_000;
  showFileSizeError = false;

  // Nur Bilddaten sind als Upload erlaubt
  showFileTypeError = false;

  // FileUpload ist verpflichtend
  showFileRequiredError = false;

  constructor() {
    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v?.trim());
    });
  }

  setDTO(dto: FotoDTO) {
    if (!dto) {
      this.errorService.showErrorMessage('Kein Model zum Bearbeiten vorhanden.');
      return;
    }

    this.dtoToEdit = dto;
    this.dtoToFormGroup(this.dtoToEdit);
    this.fotoBase64 = this.dtoToEdit.fotoBase64 || '';
    this.handleIconDataChanged();
  }

  /**
   * Aktualisiertes DTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): FotoDTO | null {
    this.showFileRequiredError = !this.fotoBase64.length;

    if (!this.formGroup.valid || this.showFileSizeError || this.showFileTypeError || this.showFileRequiredError) {
      this.formGroup.markAllAsTouched();
      return null;
    }

    return {
      ...this.dtoToEdit,
      customZeichen: this.isCustomIcon,
      dataUrl: this.iconDataUrl,
      anzeigename: this.fcAnzeigename.value.trim(),
      beschreibung: this.fcBeschreibung.value.trim(),
      typ: TaktischesZeichenTyp.Foto,
      fotoBase64: this.fotoBase64,
    };
  }

  /**
   * FormGroup mit Werten aus DTO füllen
   */
  dtoToFormGroup(dto: FotoDTO): void {
    this.isCustomIcon = dto.customZeichen || false;
    this.iconDataUrl = dto.dataUrl;
    this.fcAnzeigename.setValue(dto.anzeigename);
    this.fcBeschreibung.setValue(dto.beschreibung || '');
    this.uploadURL = dto.fotoBase64 || '';
  }

  /**
   * Hochgeladenes File verarbeiten
   *
   * Kein Event-Typ unterstützt event.target.files, daher any
   */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  async setFile(event: any) {
    const file: File = event.target.files[0];

    this.showFileSizeError = file.size > this.MAX_FILE_SIZE;
    this.showFileTypeError = !file.type.includes('image/');
    this.showFileRequiredError = false;

    if (this.showFileSizeError || this.showFileTypeError) {
      this.fotoBase64 = '';
      this.uploadURL = '';
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result && typeof reader.result === 'string') {
        this.fotoBase64 = reader.result;
        this.uploadURL = URL.createObjectURL(file);
      }
    };
  }

  private handleIconDataChanged() {
    if (!this.isCustomIcon) {
      this.iconDataUrl = this.DEFAULT_ICON_DATA_URL;
      this.iconDataUrlChanged.emit(this.iconDataUrl);
    }
  }

  public setCustomIcon(iconDataUrl: string) {
    this.isCustomIcon = true;
    this.iconDataUrl = iconDataUrl;
  }

  public removeCustomIcon() {
    this.isCustomIcon = false;
    this.handleIconDataChanged();
  }
}
