<mat-toolbar>
  <h2>{{ data.headerText }}</h2>
</mat-toolbar>
<div class="content">
  <mat-card class="left-container">
    <mat-toolbar>
      <h3>{{ data.calledFromBib ? "Verwaltungsebenen" : "Führungsebenen" }}</h3>
    </mat-toolbar>
    <div class="scrollable">
      <mat-card-content>
        <app-verwaltungsebenen-tree
          *ngIf="data.calledFromBib"
          [expandTree]="true"
          [enableEdit]="false"
          [verwaltungsebenen]="verwaltungsebenenTree"
          (treeNodeClicked)="onClickVerwaltungsebeneTreeNode($event)"
        />
        <app-fuehrungsebenen-tree
          *ngIf="!data.calledFromBib"
          [expandTree]="true"
          [enableEdit]="false"
          [fuehrungsebenen]="fuehrungsebenenTree?.fuehrungsebeneDTOs"
          (treeNodeClicked)="onClickFuehrungsebeneTreeNode($event)"
        />
      </mat-card-content>
      <h3 class="keine-daten-text" *ngIf="!verwaltungsebenenTree.length && this.data.calledFromBib">
        Keine Verwaltungsebenen vorhanden
      </h3>
      <h3 class="keine-daten-text" *ngIf="!fuehrungsebenenTree?.fuehrungsebeneDTOs && !this.data.calledFromBib">
        Keine Führungsebenen vorhanden
      </h3>
    </div>
  </mat-card>

  <div class="right-container">
    <mat-card>
      <mat-card-content class="filter">
        <h3>Filter:</h3>
        <mat-form-field>
          <mat-label>Suche...</mat-label>
          <input matInput type="text" matTooltip="Suche nach Namen" [formControl]="fcFilterText" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Organisationen</mat-label>
          <mat-select matInput multiple [formControl]="fcFilterOrganisationen">
            <mat-option *ngFor="let option of organisationOptions | keyvalue" [value]="option.value">{{
              option.key
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Grundzeichen</mat-label>
          <mat-select matInput multiple [formControl]="fcFilterGrundzeichen">
            <mat-option *ngFor="let option of grundzeichenOptions | keyvalue" [value]="option.value">{{
              option.key
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-checkbox [formControl]="fcFilterVorlagen">Nur Vorlagen</mat-checkbox>

        <button mat-icon-button matTooltip="Filter entfernen" (click)="onClickClearFilter()">
          <mat-icon>delete</mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="onClickToggleAccordion()"
          [matTooltip]="'Hierarchie ' + (accordionExpanded ? 'zusammenklappen' : 'ausklappen')"
        >
          <mat-icon>{{ accordionExpanded ? "unfold_less" : "unfold_more" }}</mat-icon>
        </button></mat-card-content
      >
    </mat-card>

    <div class="accordion-container" *ngIf="this.data.calledFromBib">
      <mat-accordion class="zeichen-container" multi *ngIf="verwaltungsebenen$ | async as verwaltungsebenen">
        <app-verwaltungsebene-details
          *ngFor="let verwaltungsebene of verwaltungsebenen"
          [verwaltungsebene]="verwaltungsebene"
          [expand]="verwaltungsebenen.length === 1"
          [expandChildren]="accordionExpanded"
          [selectionMode]="true"
          [bibliothekFilter$]="bibliothekFilter$"
          [taktischeFormationId]="taktischeFormationId"
          [singleSelect]="data.singleSelect || false"
          [exludeTaktischesZeichenTypen]="data.excludeTaktischeZeichenTypen || []"
        />
      </mat-accordion>
    </div>
    <div class="accordion-container" *ngIf="!this.data.calledFromBib">
      <mat-accordion class="zeichen-container" multi *ngIf="fuehrungebenen$ | async as fuehrungsebenen">
        <app-fuehrungsebene-detail-akkordeon
          *ngFor="let node of fuehrungsebenen"
          [fuehrungsebene]="node"
          [expand]="true"
          [expandChildren]="true"
          [selectionMode]="true"
          [bibliothekFilter$]="bibliothekFilter$"
          [taktischeFormationId]="taktischeFormationId"
          [singleSelect]="data.singleSelect || false"
          [exludeTaktischesZeichenTypen]="data.excludeTaktischeZeichenTypen || []"
          [selectedFahrzeuge]="data.selectedFahrzeuge || []"
          [selectedPersonen]="data.selectedPersonen || []"
          [selectedTaktischeFormationen]="data.selectedTaktischeFormationen || []"
        />
      </mat-accordion>
    </div>
    <mat-card class="selected">
      <mat-toolbar>
        <h3>Ausgewählt</h3>
      </mat-toolbar>
      <div class="items">
        <app-taktische-zeichen-item
          *ngFor="let tz of selectedTz$ | async; let index = index"
          [item]="tz"
          (tzClick)="onClickSelecectedTz(tz, index)"
        />
      </div>
    </mat-card>
  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="onClickSave()" [disabled]="isSaving">Speichern</button>
</mat-dialog-actions>
