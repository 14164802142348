import { Component, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router';
import { ApplicationType } from 'src/app/api/build/openapi';
import { AppService } from 'src/app/app.service';
import { APPLICATION_LIGHTMODE, ApplicationLogos, IconService } from '../../shared/services/icon.service';
import { AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-program-select',
  standalone: true,
  imports: [MatCardModule, AsyncPipe],
  templateUrl: './program-select.component.html',
  styleUrl: './program-select.component.scss',
})
export class ProgramSelectComponent {
  ApplicationType = ApplicationType;

  private router: Router = inject(Router);
  private appService: AppService = inject(AppService);

  logos: ApplicationLogos = APPLICATION_LIGHTMODE;

  constructor() {
    inject(IconService)
      .getApplicationLogos()
      .pipe(takeUntilDestroyed())
      .subscribe((logos) => (this.logos = logos));
  }

  onClick(app: ApplicationType) {
    this.appService.selectApplication(app);
    this.router.navigate(['lagen']);
  }
}
