import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { Observable, debounceTime } from 'rxjs';
import { LoadableComponentComponent } from 'src/app/shared/components/loadable-component/loadable-component.component';
import { DateTimePipe } from 'src/app/shared/pipes/date-time.pipe';
import { wetterActions } from '../+state/wetter.actions';
import { wetterLoadingSelector, wetterSelector } from '../+state/wetter.selectors';
import { FuehrungsebeneService } from '../../fuehrungsebene/fuehrungsebene.service';
import { WetterDialogComponent } from '../wetter-dialog/wetter-dialog.component';
import { IseKompassComponent } from '@product/ise-kompass-lib';
import { WetterDTO, WettermessungDTO } from 'src/app/api/build/openapi';
import { WetterService } from '../wetter.service';

@Component({
  selector: 'app-lagedarstellung-wetter-anzeige',
  templateUrl: './lagedarstellung-wetter-anzeige.component.html',
  styleUrls: ['./lagedarstellung-wetter-anzeige.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    AsyncPipe,
    MatTooltipModule,
    DateTimePipe,
    LoadableComponentComponent,
    IseKompassComponent,
  ],
})
export class LagedarstellungWetterAnzeigeComponent {
  private store = inject(Store);

  wetter?: WetterDTO;
  latestWettermessung?: WettermessungDTO;
  isLoading$: Observable<boolean> = this.store
    .select(wetterLoadingSelector)
    .pipe(takeUntilDestroyed(), debounceTime(500));

  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private wetterService = inject(WetterService);

  constructor(private dialog: MatDialog) {
    this.store
      .select(wetterSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((wetter: WetterDTO | undefined) => {
        this.wetter = wetter;
        this.latestWettermessung = this.wetterService.getLatestWettermessung(this.wetter);
      });
  }

  createWetter() {
    const currentLageId = this.fuehrungsebeneService.getCurrentLage()?.id;
    if (!currentLageId) {
      return;
    }

    this.dialog
      .open(WetterDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.store.dispatch(wetterActions.createWetter({ lageId: currentLageId, wetter: result }));
        }
      });
  }

  editWetter() {
    if (this.wetter) {
      this.dialog
        .open(WetterDialogComponent, {
          data: { wetter: this.wetter },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.store.dispatch(wetterActions.patchWetter({ wetter: result }));
          }
        });
    }
  }

  public onClickErfassen() {
    this.dialog.open(WetterDialogComponent);
  }
}
