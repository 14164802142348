import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DialogAction, DialogService } from '@product/ise-dialog-lib';
import { ErrorService } from '@product/ise-error-lib';
import { BehaviorSubject, EMPTY, Observable, catchError, of, switchMap, take, throwError } from 'rxjs';
import {
  ApplicationType,
  EinsatzfilterDTO,
  EinsatzfilterMoveDTO,
  EinsatzfilterResourceService,
  ErrorResponse,
  FuehrungsebeneResourceService,
  PersonenuebersichtDTO,
  SchadenkontoColor,
} from 'src/app/api/build/openapi';
import { FuehrungsebeneDTO } from 'src/app/api/build/openapi/model/fuehrungsebeneDTO';
import { FuehrungsebeneNestedDTO } from 'src/app/api/build/openapi/model/fuehrungsebeneNestedDTO';
import { Fuehrungsebenentyp } from 'src/app/api/build/openapi/model/fuehrungsebenentyp';
import { LageDTO } from 'src/app/api/build/openapi/model/lageDTO';
import { AppService } from 'src/app/app.service';
import { IconService } from 'src/app/shared/services/icon.service';
import { LogService } from 'src/app/shared/services/log.service';
import { TaktischesZeichen, erzeugeTaktischesZeichen } from 'taktische-zeichen-core';
import { currentLageSelector } from '../lagen/+state/lage.selectors';
import { fuehrungsebeneActions } from './+state/fuehrungsebene.actions';
import { allFuehrungsebenenSelector, currentFuehrungsebeneSelector } from './+state/fuehrungsebene.selectors';
import { FuehrungsebeneDetailsDialogComponent } from './fuehrungsebene-details-dialog/fuehrungsebene-details-dialog.component';
import {
  FuehrungsebeneTreeNestedDialogInputData,
  FuehrungsebeneTreeNestedDialogOutputData,
  FuehrungsebeneTreeSelectNestedDialogComponent,
} from './fuehrungsebene-tree-select-nested-dialog/fuehrungsebene-tree-select-nested-dialog.component';

export interface FuehrungsebenentypSettings {
  // Kurzform des Typs. Wird im Führungsebene-Header ganz oben angezeigt
  shortname: string;
  // Langform des Typs. Wird in Texten und Überschriften, z.B. Führungsebene-Dialog angezeigt
  longname: string;
  // Untertitel der Kurzform im Führungsebene-Header. Wird sehr klein unter der Kurzform angezeigt
  subtitle?: string;
  // Mittlerer Text im Führungsebene-Header
  middle?: string;
  icon: string;
}

@Injectable({
  providedIn: 'root',
})
export class FuehrungsebeneService {
  /**
   * Mapping von Fuehrungsebenentyp zu verschiedenen Texten und Styles.
   * Wird benötigt, damit Führungsebene-Header einheitlich mit Überschriften und Hintergrundfarben versehen werden können.
   */
  public readonly fuehrungsebenentypMapping = new Map<Fuehrungsebenentyp, FuehrungsebenentypSettings>([
    [
      Fuehrungsebenentyp.Bereitstellungsraum,
      {
        shortname: 'BR',
        longname: 'Bereitstellungsraum',
        subtitle: 'Bereitstellungsraum',
        middle: 'Einsatzleitung',
        icon: this.erzeugeFuehrungsebeneIcon('BR'),
      },
    ],
    [
      Fuehrungsebenentyp.Einsatzabschnitt,
      { shortname: 'EA', longname: 'Einsatzabschnitt', icon: this.erzeugeFuehrungsebeneIcon('EA') },
    ],
    [
      Fuehrungsebenentyp.Einsatzraum,
      {
        shortname: 'ER',
        longname: 'Einsatzraum',
        middle: 'Einsatzraum',
        icon: this.erzeugeFuehrungsebeneIcon('ER'),
      },
    ],
    [
      Fuehrungsebenentyp.Einsatzstelle,
      {
        shortname: 'ESt',
        longname: 'Einsatzstelle',
        middle: 'Einsatzstelle',
        icon: this.erzeugeFuehrungsebeneIcon('ESt'),
      },
    ],
    [
      Fuehrungsebenentyp.Grundschutz,
      {
        shortname: 'GS',
        longname: 'Grundschutz',
        middle: 'Grundschutz',
        icon: this.erzeugeFuehrungsebeneIcon('GS'),
      },
    ],
    [
      Fuehrungsebenentyp.Lage,
      { shortname: 'L', longname: 'Lage', middle: 'Lage', icon: this.erzeugeFuehrungsebeneIcon('L') },
    ],
    [
      Fuehrungsebenentyp.Schadengebiet,
      {
        shortname: 'SG',
        longname: 'Schadengebiet',
        middle: 'Schadengebiet',
        icon: this.erzeugeFuehrungsebeneIcon('SG'),
      },
    ],
    [
      Fuehrungsebenentyp.Unterabschnitt,
      { shortname: 'UA', longname: 'Unterabschnitt', icon: this.erzeugeFuehrungsebeneIcon('UA') },
    ],
    [
      Fuehrungsebenentyp.AdministrativOrganisatorisch,
      {
        shortname: 'AO',
        longname: 'Administrativ-Organisatorische Komponente',
        icon: this.erzeugeFuehrungsebeneIcon('AO'),
      },
    ],
    [
      Fuehrungsebenentyp.OperativTaktisch,
      { shortname: 'OPT', longname: 'Operativ-Taktische Komponente', icon: this.erzeugeFuehrungsebeneIcon('OPT') },
    ],
    [
      Fuehrungsebenentyp.PolitischGesamtverantwortlicher,
      { shortname: 'PG', longname: 'Politisch-Gesamtverantwortlicher', icon: this.erzeugeFuehrungsebeneIcon('PG') },
    ],
    [
      Fuehrungsebenentyp.Leitstelle,
      { shortname: 'LsT', longname: 'Leitstelle', icon: this.erzeugeFuehrungsebeneIcon('LsT') },
    ],
    [
      Fuehrungsebenentyp.Oertlich,
      { shortname: 'ÖE', longname: 'Örtliche Einheiten', icon: this.erzeugeFuehrungsebeneIcon('ÖE') },
    ],
    [
      Fuehrungsebenentyp.Krisenstab,
      {
        shortname: 'KS',
        longname: 'Krisenstab',
        icon: this.erzeugeFuehrungsebeneIcon('KS'),
      },
    ],
    [
      Fuehrungsebenentyp.Sae,
      {
        shortname: 'SAE',
        longname: 'SAE',
        icon: this.erzeugeFuehrungsebeneIcon('SAE'),
      },
    ],
    [
      Fuehrungsebenentyp.Einsatz,
      {
        shortname: 'E',
        longname: 'Einsatz',
        icon: this.erzeugeFuehrungsebeneIcon('E'),
      },
    ],
  ]);

  public static readonly SCHADENKONTO_COLOR_MAPPING = new Map<SchadenkontoColor, string>([
    [SchadenkontoColor.Blue, '#3D85C6'],
    [SchadenkontoColor.Brown, '#783F04'],
    [SchadenkontoColor.Green, '#6AA84F'],
    [SchadenkontoColor.Gray, '#E2E2E3'],
    [SchadenkontoColor.Orange, '#E69138'],
    [SchadenkontoColor.Red, '#CC0000'],
    [SchadenkontoColor.White, '#FFFFFF'],
    [SchadenkontoColor.Yellow, '#F1C232'],
  ]);

  static readonly MAIN_FUEHRUNGSEBENENTYPEN = [
    Fuehrungsebenentyp.Lage,
    Fuehrungsebenentyp.Einsatz,
    Fuehrungsebenentyp.Schadengebiet,
    Fuehrungsebenentyp.Einsatzraum,
    Fuehrungsebenentyp.Einsatzstelle,
    Fuehrungsebenentyp.Einsatzabschnitt,
    Fuehrungsebenentyp.Unterabschnitt,
    Fuehrungsebenentyp.Krisenstab,
    Fuehrungsebenentyp.Sae,
  ];

  static readonly SPECIAL_FUEHRUNGSEBENETYPEN = [
    Fuehrungsebenentyp.Grundschutz,
    Fuehrungsebenentyp.Bereitstellungsraum,
    Fuehrungsebenentyp.Oertlich,
  ];

  static readonly MOVABLE_FUEHRUNGSEBENETYPEN = [
    Fuehrungsebenentyp.Einsatz,
    Fuehrungsebenentyp.Schadengebiet,
    Fuehrungsebenentyp.Einsatzraum,
    Fuehrungsebenentyp.Einsatzstelle,
    Fuehrungsebenentyp.Einsatzabschnitt,
    Fuehrungsebenentyp.Unterabschnitt,
    Fuehrungsebenentyp.Grundschutz,
    Fuehrungsebenentyp.Bereitstellungsraum,
    Fuehrungsebenentyp.Oertlich,
    Fuehrungsebenentyp.Krisenstab,
    Fuehrungsebenentyp.Sae,
  ];

  static readonly MED_RETTUNG_FUEHRUNGSEBENETYPEN = [
    Fuehrungsebenentyp.Einsatzraum,
    Fuehrungsebenentyp.Einsatzstelle,
    Fuehrungsebenentyp.Einsatzabschnitt,
    Fuehrungsebenentyp.Unterabschnitt,
  ];

  static readonly EINSATZLEITUNG_FUEHRUNGSEBENENTYPEN = [
    Fuehrungsebenentyp.Einsatz,
    Fuehrungsebenentyp.OperativTaktisch,
  ];

  static readonly LAGE_K_FUEHRUNGSEBENETYPEN = [Fuehrungsebenentyp.Krisenstab, Fuehrungsebenentyp.Sae];

  childFuehrungsebeneHeader$ = new BehaviorSubject<string>('');
  fuehrungsebeneBreadcrumbs$ = new BehaviorSubject<FuehrungsebeneDTO[]>([]);

  private currentLage: LageDTO | null = null;
  private currentFuehrungsebene: FuehrungsebeneDTO | null = null;
  private allFuehrungsebenen: FuehrungsebeneDTO[] = [];

  // BroadcastChannel, über den die aktuell selektierte Führungsebene an andere Fenster verteilt wird.
  private bc = new BroadcastChannel('MultiWindow-CurrentFuehrungsebene');

  private appService = inject(AppService);
  private actions$ = inject(Actions);
  private dialogService = inject(DialogService);
  private dialog = inject(MatDialog);
  private logService = inject(LogService);
  private errorService = inject(ErrorService);
  private fuehrungsebeneResourceService = inject(FuehrungsebeneResourceService);
  private einsatzfilterResourceService = inject(EinsatzfilterResourceService);
  private iconService = inject(IconService);
  private store = inject(Store);

  constructor() {
    // Für Multi-Fenster
    // Immer auf das Setzen der aktuellen Führungsebene hören; aktuelle Führungsebene aus Liste raussuchen und im Anschluss setzen.
    // Der BroadcastChannel sendet nie an Auslöser zurück; keine Filterun notwenidg.
    this.bc.onmessage = (event) => {
      const selectedFuehrungsebene = this.allFuehrungsebenen.find((a) => a.id === event.data);
      if (selectedFuehrungsebene) {
        this.store.dispatch(
          fuehrungsebeneActions.setCurrentFuehrungsebene({ currentFuehrungsebene: selectedFuehrungsebene })
        );
      }
    };

    this.store
      .select(currentLageSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((currentLage: LageDTO | null) => {
        this.currentLage = currentLage;
      });

    this.store
      .select(allFuehrungsebenenSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((fuehrungsebenen) => {
        this.allFuehrungsebenen = fuehrungsebenen;
        this.generateFuehrungsebeneBreadcrumbs();
      });

    this.store
      .select(currentFuehrungsebeneSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((fuehrungsebene) => {
        this.currentFuehrungsebene = fuehrungsebene;
        this.fuehrungsebeneBreadcrumbs$.next(this.generateFuehrungsebeneBreadcrumbs());

        // selektierte Führungsebene an andere Fenster senden
        if (fuehrungsebene?.id) {
          this.bc.postMessage(fuehrungsebene.id);
        }
      });
  }

  /**
   * Liefert, wenn vorhanden, das aktuell gesetzte Icon (TZ) für die übergebene Führungsebene.
   */
  getFuehrungsebeneIcon(fuehrungsebeneDTO: FuehrungsebeneDTO): string {
    if (fuehrungsebeneDTO.symbol?.length) {
      return fuehrungsebeneDTO.symbol;
    }
    return this.fuehrungsebenentypMapping.get(fuehrungsebeneDTO.typ)?.icon || '';
  }

  /**
   * Erzeugt ein Führungsebene-Icon basierend auf dessen Kurzbezeichnung
   */
  private erzeugeFuehrungsebeneIcon(shortname: string) {
    return erzeugeTaktischesZeichen({
      grundzeichen: 'stelle',
      organisation: 'fuehrung',
      text: shortname,
    }).dataUrl;
  }

  /**
   * Generiert eine Liste von Parent-Führungsebenen, ausgehend von der Current-Führungsebene.
   */
  generateFuehrungsebeneBreadcrumbs(): FuehrungsebeneDTO[] {
    if (!this.currentFuehrungsebene) {
      return [];
    }

    let breadcrumbFuehrungsebene: FuehrungsebeneDTO | undefined = this.currentFuehrungsebene;
    const result = [this.currentFuehrungsebene];
    while (breadcrumbFuehrungsebene && breadcrumbFuehrungsebene.parentFuehrungsebeneId) {
      const parentFuehrungsebene: FuehrungsebeneDTO | undefined = this.allFuehrungsebenen.find(
        (fuehrungsebene) => fuehrungsebene.id == breadcrumbFuehrungsebene?.parentFuehrungsebeneId
      );
      if (parentFuehrungsebene) {
        result.push(parentFuehrungsebene);
      }
      breadcrumbFuehrungsebene = parentFuehrungsebene;
    }

    return result.reverse();
  }

  /**
   * Erzeugt eine neue Führungsebene für die aktuelle Lage und einer aktuellen Führungsebene als Parent.
   * Der Typ der neuen Führungsebene wird passend zum Parent gewählt.
   */
  prepareNewChildFuehrungsebene(
    parentFuehrungsebene: FuehrungsebeneDTO,
    fuehrungsebenentyp?: Fuehrungsebenentyp
  ): FuehrungsebeneDTO | null {
    if (!this.currentLage) {
      this.logService.error('No current lage');
      return null;
    }

    const childType = fuehrungsebenentyp || this.getSuggestedChildType(parentFuehrungsebene.typ);
    if (!childType) {
      console.warn('Kein Childtyp gefunden');
      return null;
    }

    const fuehrungsebeneDto: FuehrungsebeneDTO = {
      customSymbol: false,
      lageId: this.currentLage.id,
      name: this.fuehrungsebenentypMapping.get(childType)?.longname || '',
      parentFuehrungsebeneId: parentFuehrungsebene.id,
      typ: childType,
      schadenkontoColor: SchadenkontoColor.Gray,
      startedOn: new Date().toISOString(),
    };

    if (
      fuehrungsebenentyp === Fuehrungsebenentyp.PolitischGesamtverantwortlicher ||
      fuehrungsebenentyp === Fuehrungsebenentyp.AdministrativOrganisatorisch ||
      fuehrungsebenentyp === Fuehrungsebenentyp.OperativTaktisch
    ) {
      switch (fuehrungsebenentyp) {
        case Fuehrungsebenentyp.PolitischGesamtverantwortlicher:
          fuehrungsebeneDto.name = 'Regierungspräsident / Landrat / Oberbürgermeister';
          break;
        case Fuehrungsebenentyp.AdministrativOrganisatorisch:
          fuehrungsebeneDto.name = 'Krisenstab';
          break;
        case Fuehrungsebenentyp.OperativTaktisch:
          fuehrungsebeneDto.name = 'Einsatzleitung';
          break;
      }
    } else if (fuehrungsebenentyp === Fuehrungsebenentyp.Grundschutz) {
      fuehrungsebeneDto.schadenkontoColor = SchadenkontoColor.Blue;
    }
    return fuehrungsebeneDto;
  }

  /**
   * Erzeugt eine neue Logistik-Führungsebene mit vordefinierten Child-Führungsebenen und Auftägen
   */
  prepareNewLogistikFuehrungsebene(): Observable<FuehrungsebeneDTO | null> {
    const logistikTz: TaktischesZeichen = {
      fachaufgabe: 'fuehrung',
      grundzeichen: 'stelle',
      organisation: 'fuehrung',
      text: 'LOG',
    };
    return this.iconService.generateCompressedDataUrl(logistikTz).pipe(
      switchMap((dataUrl) => {
        if (!this.currentFuehrungsebene) {
          return of(null);
        }
        const logistik = this.prepareNewChildFuehrungsebene(
          this.currentFuehrungsebene,
          Fuehrungsebenentyp.Einsatzstelle
        );
        if (logistik) {
          logistik.customSymbol = true;
          logistik.schadenkontoColor = SchadenkontoColor.Yellow;
          logistik.symbol = dataUrl;
          return of(logistik);
        }
        return of(null);
      })
    );
  }

  createLogistikWithDefaults(logistikDto: FuehrungsebeneDTO) {
    if (this.currentLage?.id) {
      this.fuehrungsebeneResourceService.createLogistikWithDefaults(this.currentLage.id, logistikDto).subscribe();
    }
  }

  public getCurrentFuehrungsebene(): FuehrungsebeneDTO | null {
    return this.currentFuehrungsebene;
  }

  public getAllFuehrungsebenen(): FuehrungsebeneDTO[] | [] {
    return this.allFuehrungsebenen;
  }

  public getCurrentLage(): LageDTO | null {
    return this.currentLage;
  }

  /**
   * Lädt die Personenübersicht zur übergebenen Führungsebene.
   */
  getPersonenuebersicht(fuehrungsebene: FuehrungsebeneDTO): Observable<PersonenuebersichtDTO> {
    if (!fuehrungsebene.id || !fuehrungsebene.lageId) {
      return EMPTY;
    }
    return this.fuehrungsebeneResourceService.getPersonenuebersichtByFuehrungsebene(
      fuehrungsebene.id,
      fuehrungsebene.lageId
    );
  }

  /**
   * Öffnet einen Dialog mit dem eine Führungsebene in eine andere Führungsebene verschoben werden kann.
   * Wählt der Nutzer eine Führungsebene aus, wird das Verschieben im Backend angestoßen.
   */
  openMoveFuehrungsebeneDialog(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    if (!fuehrungsebeneDTO.id || !fuehrungsebeneDTO.lageId) {
      this.logService.warn(
        'Führungsebene kann nicht verschoben werden, da sie keiner Lage zugeordnet ist oder nicht persistent ist.'
      );
      return;
    }

    this.fuehrungsebeneResourceService
      .getMoveOptions(fuehrungsebeneDTO.id, fuehrungsebeneDTO.lageId)
      .pipe(
        // MoveOptions im Backend anfordern und Dialog öffnen
        switchMap((rootFuehrungsebene: FuehrungsebeneNestedDTO) => {
          if (!rootFuehrungsebene) {
            return throwError(() => 'Auswahlmöglichkeiten zum Verschieben konnten nicht bestimmt werden.');
          }
          return this.openFuehrungsebeneTreeDialog(
            rootFuehrungsebene,
            fuehrungsebeneDTO.parentFuehrungsebeneId
          ).afterClosed();
        }),
        // Auf Auswahl warten und Führungsebene im Backend verschieben
        switchMap((result: FuehrungsebeneTreeNestedDialogOutputData) => {
          if (!result?.selectedFuehrungsebeneId) {
            return EMPTY;
          }

          if (!fuehrungsebeneDTO.id || !fuehrungsebeneDTO.lageId || !result.selectedFuehrungsebeneId) {
            return throwError(() => 'Führungsebene ist nicht vollständig. Verschieben kann nicht durchgeführt werden.');
          }

          return this.fuehrungsebeneResourceService.moveFuehrungsebene(
            fuehrungsebeneDTO.id,
            fuehrungsebeneDTO.lageId,
            result.selectedFuehrungsebeneId
          );
        }),
        catchError((error: HttpErrorResponse) => {
          const errorResponse: ErrorResponse = error.error;
          this.errorService.showError(errorResponse);
          return EMPTY;
        })
      )
      .subscribe(() => {
        const currentLageId = this.currentLage?.id;
        if (currentLageId) {
          this.store.dispatch(fuehrungsebeneActions.getFuehrungsebenen({ lageId: currentLageId }));
        }
      });
  }

  openDeleteFuehrungsebeneDialog(
    fuehrungsebeneDTO: FuehrungsebeneDTO,
    dialogRef: MatDialogRef<FuehrungsebeneDetailsDialogComponent>
  ) {
    if (!fuehrungsebeneDTO.id || !fuehrungsebeneDTO.lageId) {
      this.logService.warn(
        'Führungsebene kann nicht gelöscht werden, da sie keiner Lage zugeordnet ist oder nicht persistent ist.'
      );
      return;
    }

    this.fuehrungsebeneResourceService
      .canFuehrungsebeneBeDeleted(fuehrungsebeneDTO.lageId, fuehrungsebeneDTO.id)
      .pipe(
        switchMap((canBeDeleted: boolean) => {
          if (!canBeDeleted) {
            this.errorService.showErrorMessage(
              'Führungsebene kann nicht gelöscht werden, da sie oder darunterliegende Führungsebenen noch Taktische Zeichen besitzen.'
            );
            return EMPTY;
          }

          return this.dialogService
            .openConfirmDialog(
              'Führungsebene löschen?',
              'Mit dem Löschen der Führungsebene werden auch alle darunterliegenden Führungsebenen gelöscht. Der Vorgang kann nicht rückgängig gemacht werden.'
            )
            .afterClosed();
        })
      )
      .subscribe((result: DialogAction) => {
        if (result === DialogAction.OK) {
          this.actions$.pipe(ofType(fuehrungsebeneActions.deleteFuehrungsebeneSuccess), take(1)).subscribe(() => {
            dialogRef.close();
          });
          this.store.dispatch(fuehrungsebeneActions.deleteFuehrungsebene({ fuehrungsebene: fuehrungsebeneDTO }));
        }
      });
  }

  /**
   * Öffnet einen Dialog mit dem ein Einsatzfilter aus einer Führungsebene in eine andere Führungsebene verschoben werden kann.
   * Wählt der Nutzer eine Führungsebene aus, wird das Verschieben im Backend angestoßen.
   */
  openMoveEinsatzfilterDialog(einsatzFilterDTO: EinsatzfilterDTO, currentFuehrungsebeneDTO: FuehrungsebeneDTO) {
    if (!einsatzFilterDTO.lageId) {
      this.logService.warn(
        'Einsatzfilter kann nicht verschoben werden, da er keiner Lage zugeordnet ist oder nicht persistent ist.'
      );
      return EMPTY;
    }

    return this.fuehrungsebeneResourceService.getEinsatzfilterMoveTree(einsatzFilterDTO.lageId).pipe(
      switchMap((rootFuehrungsebene) => {
        if (!rootFuehrungsebene) {
          return throwError(() => 'Auswahlmöglichkeiten zum Verschieben konnten nicht bestimmt werden.');
        }

        return this.openFuehrungsebeneTreeDialog(rootFuehrungsebene, currentFuehrungsebeneDTO.id).afterClosed();
      }),
      switchMap((dialogResult: FuehrungsebeneTreeNestedDialogOutputData) => {
        if (!dialogResult) {
          return EMPTY;
        }

        if (!einsatzFilterDTO.id || !einsatzFilterDTO.lageId) {
          return throwError(() => 'Einsatzfilter ist nicht vollständig. Verschieben kann nicht durchgeführt werden.');
        }

        const moveDTO: EinsatzfilterMoveDTO = {
          oldFuehrungsebeneId: currentFuehrungsebeneDTO.id,
          newFuehrungsebeneId: dialogResult.selectedFuehrungsebeneId,
        };
        return this.einsatzfilterResourceService.moveEinsatzfilter(
          einsatzFilterDTO.id,
          einsatzFilterDTO.lageId,
          moveDTO
        );
      })
    );
  }

  /**
   * Öffnet einen Navigationsbaum aus der übergebenen Root-Führungsebene.
   * Die optionale selectedFührungsebene wird optisch hervorgehoben.
   */
  private openFuehrungsebeneTreeDialog(rootFuehrungsebene: FuehrungsebeneNestedDTO, selectedFuehrungsebeneId?: string) {
    const inputData: FuehrungsebeneTreeNestedDialogInputData = {
      treeData: rootFuehrungsebene,
      headerText: 'Verschieben in...',
      selectedFuehrungsebeneId: selectedFuehrungsebeneId,
    };

    return this.dialog.open(FuehrungsebeneTreeSelectNestedDialogComponent, { data: inputData });
  }

  /**
   * Liefert alle Führungsebenetypen, die unter der aktuellen Führungsebene angelegt werden dürfen.
   */
  public getAllowedChildTypes(): Observable<Fuehrungsebenentyp[]> {
    const currentApplicationType = this.appService.getCurrentApplicationType();
    if (!this.currentLage?.id || !currentApplicationType || !this.currentFuehrungsebene?.id) {
      return of([]);
    }

    return this.fuehrungsebeneResourceService.getAllowedChildTypes(
      this.currentLage.id,
      currentApplicationType,
      this.currentFuehrungsebene.id
    );
  }

  /**
   * Je nach aktuellem ApplicationType wird für den übergebenen Parent-Führungsebenentyp der optimale Childtype vorgeschlagen.
   */
  public getSuggestedChildType(parentType: Fuehrungsebenentyp) {
    const applicationType = this.appService.getCurrentApplicationType();
    if (!applicationType) {
      console.warn('Kein ApplicationType verfügbar');
      return undefined;
    }

    if (applicationType === ApplicationType.LageK) {
      switch (parentType) {
        case Fuehrungsebenentyp.Lage:
          return Fuehrungsebenentyp.Krisenstab;
        case Fuehrungsebenentyp.Krisenstab:
          return Fuehrungsebenentyp.Sae;
        case Fuehrungsebenentyp.Sae:
          // Wenn ganz unten angekommen, weiter den untersten Typen vorschlagen
          return Fuehrungsebenentyp.Sae;
      }
    }

    if (applicationType === ApplicationType.Lage) {
      switch (parentType) {
        case Fuehrungsebenentyp.Lage:
          return Fuehrungsebenentyp.Einsatz;
        case Fuehrungsebenentyp.Einsatz:
          return Fuehrungsebenentyp.Schadengebiet;
        case Fuehrungsebenentyp.Schadengebiet:
          return Fuehrungsebenentyp.Einsatzraum;
        case Fuehrungsebenentyp.Einsatzraum:
          return Fuehrungsebenentyp.Einsatzstelle;
        case Fuehrungsebenentyp.Einsatzstelle:
          return Fuehrungsebenentyp.Einsatzabschnitt;
        case Fuehrungsebenentyp.Einsatzabschnitt:
          return Fuehrungsebenentyp.Unterabschnitt;
        case Fuehrungsebenentyp.Unterabschnitt:
          // Wenn ganz unten angekommen, weiter den untersten Typen vorschlagen
          return Fuehrungsebenentyp.Unterabschnitt;
      }
    }
    return undefined;
  }

  /**
   * Prüft, dass der übergebene Führungsebenentyp nur in der passenden Application editiert werden kann.
   */
  public editingFuehrungsebenentypAllowed(typeToEdit: Fuehrungsebenentyp) {
    // Lage darf aus LAGE und LAGE-K editiert werden
    if (typeToEdit === Fuehrungsebenentyp.Lage) {
      return true;
    }

    const applicationType = this.appService.getCurrentApplicationType();
    if (!applicationType) {
      return false;
    }

    if (applicationType === ApplicationType.LageK) {
      return FuehrungsebeneService.LAGE_K_FUEHRUNGSEBENETYPEN.includes(typeToEdit);
    }

    if (applicationType === ApplicationType.Lage) {
      return !FuehrungsebeneService.LAGE_K_FUEHRUNGSEBENETYPEN.includes(typeToEdit);
    }

    return false;
  }
}
