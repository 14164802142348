<div class="container">
  <h1>Anwendung auswählen</h1>
  <div class="card-container">
    <mat-card class="select-card" (click)="onClick(ApplicationType.Lage)">
      <img [src]="logos.lage" />
      <p>für Einsatzleitungen mit Führungsstab und unterstützende, rückwärtige Führungsstäbe</p>
    </mat-card>
    <mat-card class="select-card" (click)="onClick(ApplicationType.LageK)">
      <img [src]="logos.lageK" />
      <p>für Krisenstäbe, Verwaltungsstäbe und Stäbe für außergewöhnliche Ereignisse</p>
    </mat-card>
  </div>
  <div class="flex-col flex-center">
    <p>Powered by</p>
    <img class="flex-self-end" [src]="logos.ise" height="30" />
  </div>
</div>
