import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { AppService } from 'src/app/app.service';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';

@Component({
  selector: 'app-taktische-zeichen-item-grouped',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatBadgeModule, MatTooltipModule],
  templateUrl: './taktische-zeichen-item-grouped.component.html',
  styleUrls: ['./taktische-zeichen-item-grouped.component.scss'],
})
export class TaktischeZeichenItemGroupedComponent {
  @Input({ required: true })
  tzDTOs: TaktischesZeichenDTO[] = [];

  @Input({ required: true })
  tzTyp!: TaktischesZeichenTyp;

  protected hovering = false;

  private tzService = inject(TaktischeZeichenService);
  protected tzInfoMapping = this.tzService.taktischeZeichenInfoMapping;
  protected darkmode$ = inject(AppService).isDarkMode();
}
