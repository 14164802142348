import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';

/**
 * Wenn die Bibliothek im "Selektionsmodus" ist, sammelt dieser Service die selektierten Zeichen aus der Bibliothek.
 */
@Injectable({
  providedIn: 'root',
})
export class TaktischeZeichenSelectionService {
  selectedTaktischeZeichen$ = new BehaviorSubject<TaktischesZeichenDTO[]>([]);

  toggleSelection(zeichen: TaktischesZeichenDTO) {
    if (!zeichen.vorlage && this.isSelected(zeichen)) {
      this.remove(zeichen);
    } else {
      this.add(zeichen);
    }
  }

  isSelected(zeichen: TaktischesZeichenDTO): boolean {
    return this.selectedTaktischeZeichen$.value.some((v) => zeichen.typ === v.typ && zeichen.id === v.id);
  }

  hasSelection() {
    return this.selectedTaktischeZeichen$.getValue().length > 0;
  }

  add(zeichen: TaktischesZeichenDTO) {
    const current = this.selectedTaktischeZeichen$.value;
    this.selectedTaktischeZeichen$.next([...current, zeichen]);
  }

  removeAt(index: number) {
    this.selectedTaktischeZeichen$.value.splice(index, 1);
    this.selectedTaktischeZeichen$.next(this.selectedTaktischeZeichen$.value);
  }

  remove(zeichen: TaktischesZeichenDTO) {
    this.selectedTaktischeZeichen$.next(this.selectedTaktischeZeichen$.value.filter((z) => !(zeichen === z)));
  }

  clear() {
    this.selectedTaktischeZeichen$.next([]);
  }
}
