import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LogService } from 'src/app/shared/services/log.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { AuthService } from '../auth.service';
import { IconService } from '../../shared/services/icon.service';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule, AsyncPipe, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingPageComponent implements OnInit {
  iconService = inject(IconService);
  isLoggedIn = false;

  logos$ = this.iconService.getApplicationLogos();

  constructor(private authService: AuthService, private logger: LogService, private snackbar: SnackbarService) {}

  ngOnInit() {
    this.authService.isAuthenticated$.subscribe({
      error: (e) => {
        this.logger.error(e);
        this.snackbar.showLong('Authentifizierung fehlgeschlagen');
      },
    });
  }

  toggleLogin(): void {
    if (this.isLoggedIn) {
      this.logout();
    } else {
      this.login();
    }
  }

  private login() {
    this.authService.login();
  }

  private logout() {
    this.authService.logout();
  }
}
