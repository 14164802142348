import { inject, Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { catchError, Observable, of, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VidalGuard {
  private appService = inject(AppService);

  canActivate(): Observable<boolean> {
    if (!this.appService) {
      return of(false);
    }

    return this.appService.vidalAcivated$;
  }
}
