import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-vidal-navigation',
  standalone: true,
  imports: [RouterModule, CommonModule, MatButtonModule],
  templateUrl: './vidal-navigation.component.html',
  styleUrl: './vidal-navigation.component.scss',
})
export class VidalNavigationComponent {
  protected router = inject(Router);
}
