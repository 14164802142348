import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { FuehrungsebeneDTO, Fuehrungsebenentyp } from 'src/app/api/build/openapi';
import { childFuehrungsebenenSelector, currentFuehrungsebeneSelector } from './+state/fuehrungsebene.selectors';

@Injectable({
  providedIn: 'root',
})
export class FuehrungsebeneDisplayService {
  readonly LOCAL_STORAGE_KEY = 'displayedFuehrungsebenen';
  readonly MAX_DISPLAYED_FUEHRUNGSEBENEN_COUNT = 6;
  private selectedFuehrungsebenen$ = new BehaviorSubject<Map<string, string[]>>(new Map<string, string[]>());

  /**
   * Angezeigte Führungsebenen ergeben sich aus dem currentFuehrungsebene und ob der Benutzer aktiv Child-Führungsebenen zur Anzeige selektiert hat.
   * Hat er keine Führungsebenen selektiert, werden bis zu 6 Child-Führungsebenen angezeigt. Andernfalls wird seine Auswahl angezeigt.
   */
  displayedFuehrungsebenen$ = new BehaviorSubject<FuehrungsebeneDTO[]>([]);
  headers$ = new BehaviorSubject<string[]>([]);
  shortenedHeader$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(store: Store<AppStateInterface>) {
    combineLatest([
      store.select(currentFuehrungsebeneSelector),
      this.selectedFuehrungsebenen$,
      store.select(childFuehrungsebenenSelector),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(([currentFuehrungsebene, selectedFuehrungsebenenMap, childFuehrungsebenen]) => {
        // Wenn keine Führungsebenen vorhanden, kann nichts angezeigt werden
        if (!currentFuehrungsebene?.id) {
          this.displayedFuehrungsebenen$.next([]);
          return;
        }

        // Skippen, wenn Children nicht zum Parent passen
        if (
          childFuehrungsebenen.length &&
          childFuehrungsebenen[0].parentFuehrungsebeneId !== currentFuehrungsebene.id
        ) {
          this.displayedFuehrungsebenen$.next([]);
          return;
        }

        const selectedFuehrungsebeneIds = this.getSelectedFuehrungsebeneIds(currentFuehrungsebene.id);
        if (!selectedFuehrungsebeneIds) {
          this.prepareDisplayedChildrenWithoutSelection(childFuehrungsebenen);
        } else {
          this.prepareDisplayedChildrenWithSelection(childFuehrungsebenen, selectedFuehrungsebeneIds);
        }
        this.generateHeaders(childFuehrungsebenen, this.displayedFuehrungsebenen$.value);
      });

    const displayedFuehrungsebenen = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (!displayedFuehrungsebenen) {
      return;
    }

    this.selectedFuehrungsebenen$.next(new Map(JSON.parse(displayedFuehrungsebenen)));
  }

  /**
   * Wenn Benutzer explizit Führungsebenen für die Anzeige ausgewählt hat, diese anzeigen.
   *
   */
  prepareDisplayedChildrenWithSelection(
    childFuehrungsebenen: FuehrungsebeneDTO[],
    selectedFuehrungsebeneIds: string[]
  ) {
    let displayedFuehrungsebenen = childFuehrungsebenen;
    if (selectedFuehrungsebeneIds) {
      displayedFuehrungsebenen = displayedFuehrungsebenen.filter(
        (childFuehrungsebene) => childFuehrungsebene.id && selectedFuehrungsebeneIds.includes(childFuehrungsebene.id)
      );
    }
    displayedFuehrungsebenen = displayedFuehrungsebenen.slice(
      0,
      Math.min(childFuehrungsebenen.length, this.MAX_DISPLAYED_FUEHRUNGSEBENEN_COUNT)
    );
    this.displayedFuehrungsebenen$.next(displayedFuehrungsebenen);
  }

  /**
   * Wenn Benutzer keine Führungsebenen ausgewählt hat, die ersten (maximal 6) Führungsebenen anzeigen.
   */
  prepareDisplayedChildrenWithoutSelection(childFuehrungsebenen: FuehrungsebeneDTO[]) {
    this.displayedFuehrungsebenen$.next(
      childFuehrungsebenen.slice(0, Math.min(childFuehrungsebenen.length, this.MAX_DISPLAYED_FUEHRUNGSEBENEN_COUNT))
    );
  }

  /**
   * Je nach CurrentFuehrungsebene wird angezeigt, welche und wie viele Child-Führungsebenen links und rechts neben der Karte dargestellt werden.
   */
  private generateHeaders(childFuehrungsebenen: FuehrungsebeneDTO[], displayedFuehrungsebenen: FuehrungsebeneDTO[]) {
    const headers: string[] = [];

    // Schadengebiete zählen
    const schadengebietChildren = childFuehrungsebenen.filter(
      (child) => child.typ === Fuehrungsebenentyp.Schadengebiet
    );
    if (schadengebietChildren.length) {
      const displayedCount = displayedFuehrungsebenen.filter(
        (child) => child.typ === Fuehrungsebenentyp.Schadengebiet
      ).length;
      headers.push(`Schadengebiete: (${displayedCount}/${schadengebietChildren.length})`);
    }

    // Einsätze zählen
    const einsatzChildren = childFuehrungsebenen.filter((child) => child.typ === Fuehrungsebenentyp.Einsatz);
    if (einsatzChildren.length) {
      const displayedCount = displayedFuehrungsebenen.filter(
        (child) => child.typ === Fuehrungsebenentyp.Einsatz
      ).length;
      headers.push(`Einsätze: (${displayedCount}/${einsatzChildren.length})`);
    }

    // Einsatzräume zählen
    const einsatzraumChildren = childFuehrungsebenen.filter((child) => child.typ === Fuehrungsebenentyp.Einsatzraum);
    if (einsatzraumChildren.length) {
      const displayedCount = displayedFuehrungsebenen.filter(
        (child) => child.typ === Fuehrungsebenentyp.Einsatzraum
      ).length;
      headers.push(`Einsatzräume: (${displayedCount}/${einsatzraumChildren.length})`);
    }

    // Einsatzstellen zählen
    const einsatzstelleChildren = childFuehrungsebenen.filter(
      (child) => child.typ === Fuehrungsebenentyp.Einsatzstelle
    );
    if (einsatzstelleChildren.length) {
      const displayedCount = displayedFuehrungsebenen.filter(
        (child) => child.typ === Fuehrungsebenentyp.Einsatzstelle
      ).length;
      headers.push(`Einsatzstellen: (${displayedCount}/${einsatzstelleChildren.length})`);
    }

    // Einsatzabschnitte zählen
    const einsatzabschnittChildren = childFuehrungsebenen.filter(
      (child) => child.typ === Fuehrungsebenentyp.Einsatzabschnitt
    );
    if (einsatzabschnittChildren.length) {
      const displayedCount = displayedFuehrungsebenen.filter(
        (child) => child.typ === Fuehrungsebenentyp.Einsatzabschnitt
      ).length;
      headers.push(`Einsatzabschnitte: (${displayedCount}/${einsatzabschnittChildren.length})`);
    }

    // Unterabschnitte zählen
    const unterabschnittChildren = childFuehrungsebenen.filter(
      (child) => child.typ === Fuehrungsebenentyp.Unterabschnitt
    );
    if (unterabschnittChildren.length) {
      const displayedCount = displayedFuehrungsebenen.filter(
        (child) => child.typ === Fuehrungsebenentyp.Unterabschnitt
      ).length;
      headers.push(`Unterabschnitte: (${displayedCount}/${unterabschnittChildren.length})`);
    }

    // Krisenstäbe zählen
    const krisenstabChildren = childFuehrungsebenen.filter((child) => child.typ === Fuehrungsebenentyp.Krisenstab);
    if (krisenstabChildren.length) {
      const displayedCount = displayedFuehrungsebenen.filter(
        (child) => child.typ === Fuehrungsebenentyp.Krisenstab
      ).length;
      headers.push(`Krisenstäbe: (${displayedCount}/${krisenstabChildren.length})`);
    }

    // SAEs zählen
    const saeChildren = childFuehrungsebenen.filter((child) => child.typ === Fuehrungsebenentyp.Sae);
    if (saeChildren.length) {
      const displayedCount = displayedFuehrungsebenen.filter((child) => child.typ === Fuehrungsebenentyp.Sae).length;
      headers.push(`SAEs: (${displayedCount}/${saeChildren.length})`);
    }

    if (!headers.length) {
      headers.push(`Führungsebenen: (${displayedFuehrungsebenen.length}/${childFuehrungsebenen.length})`);
    }

    this.headers$.next(headers);

    if (headers.length <= 2) {
      this.shortenedHeader$.next(undefined);
    } else {
      // Wenn zu viele Header existieren, besser einen zusammenfassenden Header generieren
      this.shortenedHeader$.next(`Führungsebenen: (${displayedFuehrungsebenen.length}/${childFuehrungsebenen.length})`);
    }
  }

  /**
   * Liefert eine Liste anzuzeigender Führungsebenen für eine Parent-Führungsebene
   */
  getSelectedFuehrungsebeneIds(parentFuehrungsebeneId: string): string[] | undefined {
    return this.selectedFuehrungsebenen$.value.get(parentFuehrungsebeneId);
  }

  hasSelectedFuehrungsebenen(): boolean {
    return !!localStorage.getItem(this.LOCAL_STORAGE_KEY);
  }

  clearSelectedFuehrungsebenen(): void {
    localStorage.removeItem(this.LOCAL_STORAGE_KEY);
    this.selectedFuehrungsebenen$.next(new Map());
  }

  /**
   * Liest alle anzuzeigenden Führungsebenen aus dem LocalStorage
   */
  readSelectedFuehrungsebenen(): void {
    const displayedFuehrungsebenen = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (displayedFuehrungsebenen) {
      this.selectedFuehrungsebenen$.next(new Map(JSON.parse(displayedFuehrungsebenen)));
    } else {
      this.selectedFuehrungsebenen$.next(new Map());
    }
  }

  /**
   * Speichert die übergebene Liste anzuzeigender Child-Führungsebenen für eine Parent-Führungsebene im LocalStorage
   */
  saveSelectedFuehrungsebenen(parentFuehrungsebeneId: string, selectedFuehrungsebeneIds: string[]): void {
    const mapCopy = new Map(this.selectedFuehrungsebenen$.value);
    mapCopy.set(parentFuehrungsebeneId, selectedFuehrungsebeneIds);

    this.selectedFuehrungsebenen$.next(mapCopy);
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(Array.from(mapCopy.entries())));
  }
}
