<mat-expansion-panel class="flex-grow" [expanded]="expand">
  <mat-expansion-panel-header class="expansion-panel-header">
    <div class="header">
      <h2>{{ fuehrungsebene.name }}</h2>
      &nbsp;&nbsp;|&nbsp;&nbsp;
      <h3 *ngIf="fuehrungsebene.typ; let v">
        {{ fuehrungsebeneService.fuehrungsebenentypMapping.get(fuehrungsebene.typ)?.longname }}
      </h3>
    </div>
  </mat-expansion-panel-header>

  <mat-card-content class="scrollable content flex-col zeichen-container">
    <ng-container *ngFor="let fahrzeug of fahrzeuge">
      <ng-container *ngTemplateOutlet="zeichen; context: { zeichen: fahrzeug }" />
    </ng-container>

    <ng-container *ngFor="let person of personen">
      <ng-container *ngTemplateOutlet="zeichen; context: { zeichen: person }" />
    </ng-container>

    <ng-container *ngFor="let taktischeFormation of taktischeFormationen">
      <ng-container *ngTemplateOutlet="zeichen; context: { zeichen: taktischeFormation }" />
    </ng-container>

    <ng-container *ngFor="let befehlsstelle of befehlsstellen">
      <ng-container *ngTemplateOutlet="zeichen; context: { zeichen: befehlsstelle }" />
    </ng-container>

    <h3 class="keine-daten-text" *ngIf="!fahrzeuge.length && !personen.length && !taktischeFormationen.length">
      Noch keine Taktischen Zeichen vorhanden
    </h3>
  </mat-card-content>

  <ng-container *ngIf="fuehrungsebene.fuehrungsebeneDTOs.length">
    <mat-accordion class="example-headers-align" multi>
      <app-fuehrungsebene-detail-akkordeon
        *ngFor="let fuehrungsebene of fuehrungsebene.fuehrungsebeneDTOs"
        [fuehrungsebene]="fuehrungsebene"
        [expand]="expand"
        [expandChildren]="expandChildren"
        [selectionMode]="selectionMode"
        [bibliothekFilter$]="bibliothekFilter$"
        [exludeTaktischesZeichenTypen]="exludeTaktischesZeichenTypen"
        [taktischeFormationId]="taktischeFormationId"
        [singleSelect]="singleSelect"
        [selectedFahrzeuge]="selectedFahrzeuge"
        [selectedPersonen]="selectedPersonen"
        [selectedTaktischeFormationen]="selectedTaktischeFormationen"
      />
    </mat-accordion>
  </ng-container>
</mat-expansion-panel>

<!-- Template für einzelne Taktisch Zeichen -->
<ng-template #zeichen let-zeichen="zeichen">
  <app-taktische-zeichen-item
    class="zeichen"
    [item]="zeichen"
    (click)="toggleSelection(zeichen, $event)"
    [selected]="isSelected(zeichen)"
    [useDefaultTooltip]="false"
    (tzClick)="selectTz($event)"
  />
</ng-template>
