<mat-toolbar>
  <app-fuehrungsebene-icon-and-text class="icon-and-name" [fuehrungsebeneDTO]="fuehrungsebeneDTO" />
  <span>
    <mat-icon
      (click)="navigateToFuehrungsebene()"
      *ngIf="allowNavigation"
      [matTooltip]="'In ' + fuehrungsebenentypSettings?.longname + ' springen'"
    >
      south_east
    </mat-icon>
    <mat-icon (click)="focusFuehrungsebene()" [matTooltip]="fuehrungsebenentypSettings?.longname + ' fokussieren'">
      center_focus_strong
    </mat-icon>
    @if ((selectedApp$ | async) === ApplicationType.Lage) {
    <mat-icon (click)="editFuehrungsebene()" [matTooltip]="fuehrungsebenentypSettings?.longname + ' bearbeiten'">
      edit
    </mat-icon>
    }
  </span>
</mat-toolbar>

<div class="content">
  <app-fuehrungsebene-icon-and-text
    class="icon-and-name"
    *ngIf="parentFuehrungsebene"
    [fuehrungsebeneDTO]="parentFuehrungsebene"
  />

  @if (fuehrungsebeneDTO.typ !== Fuehrungsebenentyp.Lage) {
  <hr />

  <!-- Kontakte -->
  <app-kontakt-icons
    *ngIf="fuehrungsebeneDTO.kommunikationOptionen"
    [kontaktmoeglichkeiten]="fuehrungsebeneDTO.kommunikationOptionen"
  />

  <!-- Aufträge -->
  <app-auftrag-header-element *ngIf="fuehrungsebeneDTO.auftraege" [auftraege]="fuehrungsebeneDTO.auftraege" />
  }
</div>
