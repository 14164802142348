import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogAction, DialogService } from '@product/ise-dialog-lib';
import { BehaviorSubject } from 'rxjs';
import { Kommunikation, KontaktTyp } from 'src/app/api/build/openapi';
import {
  KontaktDetailsDialogComponent,
  KontaktDetailsDialogInputData,
} from '../kontakt-details-dialog/kontakt-details-dialog.component';
import { KontaktService } from '../kontakt.service';

@Component({
  selector: 'app-kontakt-list',
  templateUrl: './kontakt-list.component.html',
  styleUrls: ['./kontakt-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatListModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KontaktListComponent implements OnChanges {
  @Input()
  kommunikationOptionen: Kommunikation[] = [];

  @Input()
  readonly = false;

  protected kommunikationOptionen$ = new BehaviorSubject<Kommunikation[]>([]);

  private dialog = inject(MatDialog);
  private dialogService = inject(DialogService);
  protected kontaktService = inject(KontaktService);

  /**
   * True, wenn Buttons zum Anlegen, Bearbeiten und Löschen angezeigt werden sollen
   */
  @Input()
  showButtons = true;

  /**
   * Angezeigter Text im Header
   */
  @Input()
  headerText = 'Kontaktmöglichkeiten';

  selectedIndex = -1;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['kommunikationOptionen']) {
      this.kommunikationOptionen$.next(this.kommunikationOptionen);
    }
  }

  /**
   * Öffnet den KontaktDetailsDialog, um eine neue Kommunkationsmöglichkeit anzulegen
   */
  addContact() {
    const dialogData: KontaktDetailsDialogInputData = {
      kommunikation: { kontaktTyp: KontaktTyp.Mobiltelefon },
      newContact: true,
    };
    this.dialog
      .open(KontaktDetailsDialogComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.kommunikationOptionen$.next([...this.kommunikationOptionen$.value, dialogResult]);
          this.selectedIndex = this.kommunikationOptionen$.value.length - 1;
        }
      });
  }

  /**
   * Öffnet den KontaktDetailsDialog, um die ausgewählte Kommunkationsmöglichkeit zu bearbeiten
   */
  editContact() {
    const dialogData: KontaktDetailsDialogInputData = {
      kommunikation: { ...this.kommunikationOptionen$.value[this.selectedIndex] },
      newContact: false,
    };
    this.dialog
      .open(KontaktDetailsDialogComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          this.kommunikationOptionen$.next(
            this.kommunikationOptionen$.value.map((kommunikation, index) =>
              index === this.selectedIndex ? dialogResult : kommunikation
            )
          );
        }
      });
  }

  /**
   * Nachfragen, ob die ausgewählte Kommunkationsmöglichkeit gelöscht werden soll
   */
  askDeleteContact() {
    this.dialogService
      .openConfirmDialog('Kontaktmöglichkeit löschen?', 'Kontaktmöglichkeit sicher löschen?')
      .afterClosed()
      .subscribe((result: DialogAction) => {
        if (result === DialogAction.OK) {
          this.kommunikationOptionen$.next([
            ...this.kommunikationOptionen$.value.filter((_, index) => index !== this.selectedIndex),
          ]);
          this.selectedIndex = -1;
        }
      });
  }

  getKommunikationOptionen(): Kommunikation[] {
    return this.kommunikationOptionen$.getValue();
  }
}
