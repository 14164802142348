import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Observable } from 'rxjs';
import { PersonenuebersichtDTO } from 'src/app/api/build/openapi';
import { TaktischesZeichenPopup } from '../../taktische-zeichen/taktische-zeichen.interface';
import { IconService, PERSONEN_LIGHTMODE } from 'src/app/shared/services/icon.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Kleine Komponente zur Übersicht von Personen verschiedener Zustände.
 * Personenanzahl wird nur angezeigt und kann nicht vom Benutzer editiert werden.
 */
@Component({
  selector: 'app-personenuebersicht-subset-small',
  templateUrl: './personenuebersicht-subset-small.component.html',
  styleUrls: ['./personenuebersicht-subset-small.component.scss'],
  standalone: true,
  imports: [NgIf, MatToolbarModule, MatCardModule, MatTooltipModule, AsyncPipe],
})
export class PersonenuebersichtSubsetSmallComponent implements TaktischesZeichenPopup {
  @Input()
  header = 'Personenübersicht - Gesamtzahl';

  @Input({ required: true })
  personenuebersicht$!: Observable<PersonenuebersichtDTO | undefined>;

  icons = PERSONEN_LIGHTMODE;

  constructor() {
    inject(IconService)
      .getPersonenIcons()
      .pipe(takeUntilDestroyed())
      .subscribe((icons) => (this.icons = icons));
  }
}
