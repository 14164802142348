import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnChanges, OnDestroy, ViewContainerRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import {
  AnlassDTO,
  BefehlsstelleDTO,
  EinsatzDTO,
  EinsatzfilterDTO,
  FahrzeugDTO,
  FotoDTO,
  FuehrungsebeneDTO,
  FuehrungsebeneMassnahmeDTO,
  Fuehrungsebenentyp,
  GebaeudeDTO,
  GebietDTO,
  GefahrDTO,
  PersonDTO,
  PersonenschadenDTO,
  StelleDTO,
  TaktischeFormationDTO,
  TaktischesZeichenDTO,
  TaktischesZeichenStatus,
  TaktischesZeichenTyp,
  TierschadenDTO,
} from 'src/app/api/build/openapi';
import { einsatzfilterOfFuehrungsebeneSelector } from 'src/app/einsatz/+state/einsatzfilter.selectors';
import {
  EinsatzDialogComponent,
  EinsatzDialogInputData,
} from 'src/app/einsatz/einsatz-dialog/einsatz-dialog.component';
import { EinsatzItemGroupedComponent } from 'src/app/einsatz/einsatz-item-grouped/einsatz-item-grouped.component';
import { EinsatzItemComponent } from 'src/app/einsatz/einsatz-item/einsatz-item.component';
import { EinsatzService } from 'src/app/einsatz/einsatz.service';
import { ComponentQualificationService } from 'src/app/misc/component-qualification.service';
import { BubbleMenueConfig, BubblemenueService } from 'src/app/shared/services/bubblemenue/bubblemenue.service';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';
import { allFuehrungsebenenSelector } from '../../fuehrungsebene/+state/fuehrungsebene.selectors';
import { anlaesseOfFuehrungsebeneSelector } from '../anlass-ereignis/+state/anlass.selectors';
import { befehlsstellenOfFuehrungsebeneSelector } from '../befehlsstelle/+state/befehlsstelle.selectors';
import { fahrzeugeOfFuehrungsebeneSelector } from '../fahrzeuge/+state/fahrzeug.selectors';
import { fotosOfFuehrungsebeneSelector } from '../foto/+state/foto.selectors';
import { gebaeudeOfFuehrungsebeneSelector } from '../gebaeude/+state/gebaeude.selectors';
import { gebieteOfFuehrungsebeneSelector } from '../gebiete/+state/gebiet.selectors';
import { gefahrenOfFuehrungsebeneSelector } from '../gefahren/+state/gefahr.selectors';
import { fuehrungsebeneMassnahmenOfFuehrungsebeneSelector } from '../massnahmen/+state/fuehrungsebene-massnahme.selectors';
import { personenOfFuehrungsebeneSelector } from '../personen/+state/person.selectors';
import { personenschaedenOfFuehrungsebeneSelector } from '../personenschaden/+state/personenschaden.selectors';
import { stellenOfFuehrungsebeneSelector } from '../stelle-einrichtung/+state/stelle.selectors';
import { taktischeFormationenOfFuehrungsebeneSelector } from '../taktische-formation/+state/taktische-formation.selectors';
import { tierschaedenOfFuehrungsebeneSelector } from '../tierschaden/+state/tierschaden.selectors';
import { TzBubbleMenuService } from '../tz-bubble-menu.service';
import { TzDialogService } from '../tz-dialog.service';
import { TzMultiSelectService } from '../tz-multi-select-service';
import { TaktischeZeichenContainerDialogComponent } from './taktische-zeichen-container-dialog/taktische-zeichen-container-dialog.component';
import { TaktischeZeichenItemGroupedComponent } from './taktische-zeichen-item-grouped/taktische-zeichen-item-grouped.component';
import { TaktischeZeichenItemComponent, TzItemEvent } from './taktische-zeichen-item/taktische-zeichen-item.component';

@Component({
  selector: 'app-taktische-zeichen-container',
  templateUrl: './taktische-zeichen-container.component.html',
  styleUrls: ['./taktische-zeichen-container.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TaktischeZeichenItemComponent,
    MatIconModule,
    MatCardModule,
    DragDropModule,
    MatBadgeModule,
    MatButtonModule,
    MatTooltipModule,
    TaktischeZeichenItemGroupedComponent,
    EinsatzItemGroupedComponent,
    EinsatzItemComponent,
  ],
})
export class TaktischeZeichenContainerComponent implements OnChanges, OnDestroy {
  destroyRef = inject(DestroyRef);

  @Input()
  allowedTzTypes: TaktischesZeichenTyp[] = [
    TaktischesZeichenTyp.Anlass,
    TaktischesZeichenTyp.Befehlsstelle,
    TaktischesZeichenTyp.Fahrzeug,
    TaktischesZeichenTyp.Foto,
    TaktischesZeichenTyp.Gebaeude,
    TaktischesZeichenTyp.Gebiet,
    TaktischesZeichenTyp.Gefahr,
    TaktischesZeichenTyp.Massnahme,
    TaktischesZeichenTyp.Person,
    TaktischesZeichenTyp.Personenschaden,
    TaktischesZeichenTyp.Stelle,
    TaktischesZeichenTyp.TaktischeFormation,
    TaktischesZeichenTyp.Tierschaden,
  ];

  Fuehrungsebenentyp = Fuehrungsebenentyp;
  TaktischesZeichenTyp = TaktischesZeichenTyp;

  droplistId = '';

  // ------------------------
  // Inputs

  @Input({ required: true })
  fuehrungsebeneDTO!: FuehrungsebeneDTO;

  @Input()
  taktischesZeichenStatus = TaktischesZeichenStatus.InFuehrungsebene;

  /**
   * Legt fest, ob TZs einzeln oder gruppiert dargestellt werden sollen.
   * - True: Pro TzTyp gibt es ein Item mit einer Info, wie viele TZs dieses Typs vorhanden sind
   * - False: Alle TZs werden hintereinander angezeigt (default)
   */
  @Input()
  groupItems = false;

  @Input()
  taktischeZeichenTypen: TaktischesZeichenTyp[] = [];

  @Input()
  showAll = true;

  @Input()
  showEinsaetze = true;

  // ------------------------
  // Darstellung
  fahrzeugDTOs: FahrzeugDTO[] = [];
  personenDTOs: PersonDTO[] = [];
  massnahmeDTOs: FuehrungsebeneMassnahmeDTO[] = [];
  gefahrDTOs: GefahrDTO[] = [];
  befehlsstelleDTOs: BefehlsstelleDTO[] = [];
  anlassDTOs: AnlassDTO[] = [];
  gebaeudeDTOs: GebaeudeDTO[] = [];
  stelleDTOs: StelleDTO[] = [];
  taktischeFormationDTOs: TaktischeFormationDTO[] = [];
  personenschadenDTOs: PersonenschadenDTO[] = [];
  gebietDTOs: GebietDTO[] = [];
  tierschadenDTOs: TierschadenDTO[] = [];
  fotoDTOs: FotoDTO[] = [];
  einsatzfilterDtos: EinsatzfilterDTO[] = [];
  einsatzDtos: EinsatzDTO[] = [];
  tzTotalCount = 0;

  // Alle Führungsebenen der Lage
  allFuehrungsebeneDTOs: FuehrungsebeneDTO[] = [];
  // Alle Führungsebenen ohne die aktuelle Führungsebene
  otherFuehrungsebeneDTOs: FuehrungsebeneDTO[] = [];

  private storeSubscription?: Subscription;

  private dialog = inject(MatDialog);
  private bubbleMenueService = inject(BubblemenueService);
  private einsatzService = inject(EinsatzService);
  private store = inject(Store<AppStateInterface>);
  protected taktischeZeichenService = inject(TaktischeZeichenService);
  protected componentService = inject(ComponentQualificationService);
  protected multiSelectService = inject(TzMultiSelectService);
  protected tzBubbleMenuService = inject(TzBubbleMenuService);
  private tzDialogService = inject(TzDialogService);

  readonly tzInfoMapping = this.taktischeZeichenService.taktischeZeichenInfoMapping;

  /**
   * Wenn sich der Input-Führungsebene ändert, alle TZs und andere Abhängigkeiten zu neuer Führungsebene laden
   */
  ngOnChanges() {
    this.droplistId = `${this.fuehrungsebeneDTO?.id}-${this.taktischesZeichenStatus}`;
    // this.einsatzDtos = this.einsatzService.getDistinctEinsatzDtos(this.fuehrungsebeneDTO.einsatzfilter);

    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }

    this.storeSubscription = combineLatest([
      this.store.select(fuehrungsebeneMassnahmenOfFuehrungsebeneSelector(this.fuehrungsebeneDTO.id)),
      this.store.select(anlaesseOfFuehrungsebeneSelector(this.fuehrungsebeneDTO.id)),
      this.store.select(befehlsstellenOfFuehrungsebeneSelector(this.fuehrungsebeneDTO.id)),
      this.store.select(fahrzeugeOfFuehrungsebeneSelector(this.fuehrungsebeneDTO.id)),
      this.store.select(gebaeudeOfFuehrungsebeneSelector(this.fuehrungsebeneDTO.id)),
      this.store.select(gebieteOfFuehrungsebeneSelector(this.fuehrungsebeneDTO.id)),
      this.store.select(gefahrenOfFuehrungsebeneSelector(this.fuehrungsebeneDTO.id)),
      this.store.select(personenOfFuehrungsebeneSelector(this.fuehrungsebeneDTO.id)),
      this.store.select(personenschaedenOfFuehrungsebeneSelector(this.fuehrungsebeneDTO.id)),
      this.store.select(stellenOfFuehrungsebeneSelector(this.fuehrungsebeneDTO.id)),
      this.store.select(taktischeFormationenOfFuehrungsebeneSelector(this.fuehrungsebeneDTO.id)),
      this.store.select(tierschaedenOfFuehrungsebeneSelector(this.fuehrungsebeneDTO.id)),
      this.store.select(fotosOfFuehrungsebeneSelector(this.fuehrungsebeneDTO.id)),
      this.store.select(allFuehrungsebenenSelector),
      this.store.select(einsatzfilterOfFuehrungsebeneSelector(this.fuehrungsebeneDTO.id)),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([
          massnahmeDTOs,
          anlassDTOs,
          befehlsstelleDTOs,
          fahrzeugDTOs,
          gebaeudeDTOs,
          gebietDTOs,
          gefahrDTOs,
          personDTOs,
          personenschadenDTOs,
          stelleDTOs,
          taktischeFormationDTOs,
          tierschadenDTOs,
          fotoDTOs,
          allFuehrungsebeneDTOs,
          einsatzfilterDtos,
        ]) => {
          this.massnahmeDTOs = massnahmeDTOs;
          this.anlassDTOs = anlassDTOs;
          this.befehlsstelleDTOs = befehlsstelleDTOs;
          this.fahrzeugDTOs = fahrzeugDTOs.filter(
            (fahrzeug) => fahrzeug.status?.status === this.taktischesZeichenStatus
          );
          this.gebaeudeDTOs = gebaeudeDTOs;
          this.gebietDTOs = gebietDTOs;
          this.gefahrDTOs = gefahrDTOs;
          this.personenDTOs = personDTOs.filter((person) => person.status?.status === this.taktischesZeichenStatus);
          this.personenschadenDTOs = personenschadenDTOs;
          this.stelleDTOs = stelleDTOs;
          this.taktischeFormationDTOs = taktischeFormationDTOs.filter(
            (taktischeFormation) => taktischeFormation.status?.status === this.taktischesZeichenStatus
          );
          this.tierschadenDTOs = tierschadenDTOs;
          this.fotoDTOs = fotoDTOs;
          this.einsatzfilterDtos = einsatzfilterDtos;
          this.einsatzDtos = this.einsatzService.getDistinctEinsatzDtos(einsatzfilterDtos);

          this.tzTotalCount =
            this.anlassDTOs.length +
            this.befehlsstelleDTOs.length +
            this.fahrzeugDTOs.length +
            this.fotoDTOs.length +
            this.gebaeudeDTOs.length +
            this.gebietDTOs.length +
            this.gefahrDTOs.length +
            this.massnahmeDTOs.length +
            this.personenDTOs.length +
            this.personenschadenDTOs.length +
            this.stelleDTOs.length +
            this.taktischeFormationDTOs.length +
            this.tierschadenDTOs.length;

          this.allFuehrungsebeneDTOs = allFuehrungsebeneDTOs;
          this.otherFuehrungsebeneDTOs = allFuehrungsebeneDTOs.filter(
            (a: FuehrungsebeneDTO) => a.id !== this.fuehrungsebeneDTO.id
          );
        }
      );
  }

  ngOnDestroy(): void {
    this.multiSelectService.clearSelection();
  }

  openEinsatzBubbleMenu(einsatzDto: EinsatzDTO, containerRef: ViewContainerRef) {
    const menuConfig: BubbleMenueConfig[] = [
      {
        matIcon: 'info',
        action: () => {
          const inputData: EinsatzDialogInputData = {
            einsatzDTO: einsatzDto,
          };
          this.dialog.open(EinsatzDialogComponent, { data: inputData });
        },
        tooltip: 'Anzeigen',
      },
    ];
    this.bubbleMenueService.openMenue(containerRef, menuConfig);
  }

  openTzDetails(typ: TaktischesZeichenTyp) {
    this.dialog.open(TaktischeZeichenContainerDialogComponent, {
      data: {
        fuehrungsebeneDTO: this.fuehrungsebeneDTO,
        taktischesZeichenStatus: this.taktischesZeichenStatus,
        taktischesZeichenTypen: [typ],
      },
    });
  }

  openEinsatzDetails() {
    this.dialog.open(TaktischeZeichenContainerDialogComponent, {
      data: {
        fuehrungsebeneDTO: this.fuehrungsebeneDTO,
        taktischesZeichenStatus: this.taktischesZeichenStatus,
        taktischesZeichenTypen: [],
        showEinsaetze: true,
      },
    });
  }

  getSelection(): TaktischesZeichenDTO[] {
    return this.multiSelectService.getSelection();
  }

  /**
   * Methode, die beim "fallenlassen" eines TZs in diesen Container aufgerufen wird
   */
  dropped(event: CdkDragDrop<TaktischesZeichenDTO[]>) {
    // nichts machen, wenn nicht verschoben
    if (event.container === event.previousContainer) {
      return;
    }

    if (!this.fuehrungsebeneDTO.id) {
      console.warn('Zeichen kann nicht verschoben werden, da keine FührungsebeneId hinterlegt ist.');
      return;
    }

    const tzDtos: TaktischesZeichenDTO[] = event.item.data;
    if (!tzDtos.length) {
      console.warn('Keine Taktischen Zeichen zum Draggen ausgewählt');
      return;
    }

    this.tzDialogService.openStatuswechselInfoDialog(tzDtos, this.fuehrungsebeneDTO.id, this.taktischesZeichenStatus);
  }

  /**
   * Öffnet einen TZ-Dialog zum Anlegen eines neuen TZs in übergebener Führungsebene.
   */
  openTaktischeZeichenDialog(fuehrungsebeneDTO: FuehrungsebeneDTO): void {
    this.tzDialogService.openCreateTzDialogLage(fuehrungsebeneDTO, this.taktischesZeichenStatus, this.allowedTzTypes);
  }

  /**
   * Öffnet einen Bibliothek-Dialog zum Importieren von TZs aus der Bibliothek in diesen Container.
   */
  openBibliothekSelectionDialog(fuehrungsebeneDTO: FuehrungsebeneDTO): void {
    this.tzDialogService.openBibliothekSelectionDialog(fuehrungsebeneDTO, this.taktischesZeichenStatus);
  }

  protected isTzTypVisible(tzTyp: TaktischesZeichenTyp): boolean {
    return this.showAll || this.taktischeZeichenTypen.includes(tzTyp);
  }

  protected areEinsaetzeVisible() {
    return this.showAll || this.showEinsaetze;
  }

  startDraggingTz(tzDto: TaktischesZeichenDTO) {
    if (!this.multiSelectService.isSelected(tzDto)) {
      this.multiSelectService.clearSelection();
      this.multiSelectService.add(tzDto);
    }
  }

  /**
   * Beim Click auf ein Item soll es ausgewählt werden.
   * Das Event darf nicht propagated werden, da sonst das clearSelection vom Hintergrund aufgerufen wird.
   */
  clickedTz(tzClickEvent: TzItemEvent) {
    tzClickEvent.mouseEvent.stopPropagation();
    if (!tzClickEvent.mouseEvent.ctrlKey) {
      this.multiSelectService.clearSelection();
      this.multiSelectService.add(tzClickEvent.dto);
    } else {
      this.multiSelectService.toggleSelection(tzClickEvent.dto);
    }
  }

  openTzBubbleMenu(tzItemEvent: TzItemEvent) {
    if (!tzItemEvent.mouseEvent.ctrlKey && !this.multiSelectService.isSelected(tzItemEvent.dto)) {
      this.multiSelectService.clearSelection();
      this.multiSelectService.add(tzItemEvent.dto);
    }
    this.tzBubbleMenuService.openTzBubbleMenu(tzItemEvent);
  }

  // Selection auslösen, wenn in den Hintergrund des Containers geklickt wird
  clearSelection(event: MouseEvent) {
    if (!event.ctrlKey) {
      this.multiSelectService.clearSelection();
    }
  }
}
