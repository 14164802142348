<div class="header">
  <h5>{{ header() }}</h5>
  @if(showInfo()) {
  <mat-icon fontSet="material-icons-outlined" [matTooltip]="showInfo()">info</mat-icon>
  }
</div>

<div class="inputs">
  <mat-form-field class="less-margin">
    <input matInput type="number" min="0" max="9999" [formControl]="formGroup.controls.staerke1" />
  </mat-form-field>

  <span class="less-margin">/</span>

  <mat-form-field class="less-margin">
    <input matInput type="number" min="0" max="9999" [formControl]="formGroup.controls.staerke2" />
  </mat-form-field>

  <span>/</span>

  <mat-form-field class="less-margin">
    <input matInput type="number" min="0" max="9999" [formControl]="formGroup.controls.staerke3" />
  </mat-form-field>

  <span class="result"
    >=
    {{
      formGroup.controls.staerke1.value + formGroup.controls.staerke2.value + formGroup.controls.staerke3.value
    }}</span
  >
</div>

@if (showEinheitenCount()) {
<div class="inputs">
  <mat-form-field class="less-margin">
    <mat-label>Anzahl Fahrzeuge</mat-label>
    <input matInput type="number" min="0" max="9999" [formControl]="formGroup.controls.fahrzeugCount" />
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Anzahl Formationen</mat-label>
    <input matInput type="number" min="0" max="9999" [formControl]="formGroup.controls.formationCount" />
  </mat-form-field>
</div>
}
