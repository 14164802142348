import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { IconService } from 'src/app/shared/services/icon.service';
import { TaktischesZeichen } from 'taktische-zeichen-core';
import { TaktischeZeichenFormComponent } from '../taktische-zeichen-form/taktische-zeichen-form.component';

export interface TzEditorDialogInputData {
  taktischesZeichen?: TaktischesZeichen;
}

/**
 * Dialog, der die Zusammenstellung eines Taktischen Zeichens nach den Regeln von https://taktische-zeichen.dev/ erlaubt.
 * Liefert mit dem Schließen des Dialogs die DataUrl des generierten Zeichens zurück.
 */
@Component({
  selector: 'app-taktische-zeichen-editor-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatToolbarModule, TaktischeZeichenFormComponent],
  templateUrl: './taktische-zeichen-editor-dialog.component.html',
  styleUrls: ['./taktische-zeichen-editor-dialog.component.scss'],
})
export class TaktischeZeichenEditorDialogComponent implements AfterViewInit {
  @ViewChild(TaktischeZeichenFormComponent)
  taktischeZeichenForm!: TaktischeZeichenFormComponent;

  protected isSaving = false;

  private dialogRef = inject(MatDialogRef);
  private dialogData: TzEditorDialogInputData = inject(MAT_DIALOG_DATA);
  private iconService = inject(IconService);

  ngAfterViewInit(): void {
    if (!this.dialogData) {
      return;
    }

    const inputTz = this.dialogData.taktischesZeichen;
    if (inputTz) {
      setTimeout(() => this.taktischeZeichenForm.init(inputTz));
    }
  }

  submit() {
    if (!this.isSaving) {
      this.isSaving = true;
      this.iconService.generateCompressedDataUrl(this.taktischeZeichenForm.taktischesZeichen).subscribe((dataUrl) => {
        this.dialogRef.close({ dataUrl: dataUrl });
      });
    }
  }
}
