import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder } from '@angular/forms';
import { combineLatest, BehaviorSubject, Observable } from 'rxjs';
import { EinsatztagebuchSelectDTO, SortingDirection } from '../api/build/openapi';

export const defaultEinsatztagebuchFilter = {
  applications: ['LAGE', 'LAGE-System', 'LAGE-K', 'LAGE-K-System'],
  entriesPerPage: 10,
  page: 0,
  sortingDirection: SortingDirection.Desc,
};

@Injectable({
  providedIn: 'root',
})
export class EinsatztagebuchService {
  private formBuilder = inject(NonNullableFormBuilder);

  readonly filterGroup = this.formBuilder.group({
    fcApplication: this.formBuilder.control<string[] | undefined>(undefined),
    fcFuehrungsebene: this.formBuilder.control<string | undefined>(undefined),
    fcDatumVon: this.formBuilder.control<Date | undefined>(undefined),
    fcDatumBis: this.formBuilder.control<Date | undefined>(undefined),
  });

  private itemsPerPage = defaultEinsatztagebuchFilter.entriesPerPage;
  private currentPage = defaultEinsatztagebuchFilter.page;
  private sortingDirection: SortingDirection = defaultEinsatztagebuchFilter.sortingDirection;
  private filterChanged$: BehaviorSubject<EinsatztagebuchSelectDTO> = new BehaviorSubject<EinsatztagebuchSelectDTO>(
    defaultEinsatztagebuchFilter
  );

  constructor() {
    this.loadFilterFromLocalStorage();
    combineLatest([
      this.filterGroup.controls.fcDatumVon.valueChanges,
      this.filterGroup.controls.fcDatumBis.valueChanges,
    ])
      .pipe(takeUntilDestroyed())
      .subscribe((change) => {});
    this.filterGroup.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((change) => this.filterChanged$.next(this.getFilter()));
  }

  resetFilter() {
    this.filterGroup.reset();
    this.filterGroup.patchValue({
      fcApplication: ['LAGE', 'LAGE-System', 'LAGE-K', 'LAGE-K-System'],
    });
    this.saveFilterToLocalStorage();
  }

  saveFilterToLocalStorage() {
    const filter = this.filterGroup.getRawValue();
    filter.fcFuehrungsebene = undefined;
    localStorage.setItem('einsatztagebuch_filter', JSON.stringify(filter));
  }

  loadFilterFromLocalStorage() {
    const value: string | null = localStorage.getItem('einsatztagebuch_filter');
    if (value) {
      this.filterGroup.patchValue(JSON.parse(value));
    } else {
      this.filterGroup.patchValue({
        fcApplication: ['LAGE', 'LAGE-System', 'LAGE-K', 'LAGE-K-System'],
      });
    }
  }

  updatePageination(itemsPerPage: number, currentPage: number) {
    this.itemsPerPage = itemsPerPage;
    this.currentPage = currentPage;
    this.filterChanged$.next(this.getFilter());
  }

  toggleSorting() {
    this.sortingDirection =
      this.sortingDirection === SortingDirection.Desc ? SortingDirection.Asc : SortingDirection.Desc;
    this.filterChanged$.next(this.getFilter());
  }

  filterChanged(): Observable<EinsatztagebuchSelectDTO> {
    return this.filterChanged$.asObservable();
  }

  getFilter(): EinsatztagebuchSelectDTO {
    const dto: EinsatztagebuchSelectDTO = {
      applications: this.filterGroup.controls.fcApplication.value,
      hierarchy_context_id: this.filterGroup.controls.fcFuehrungsebene.value,
      itemsPerPage: this.itemsPerPage,
      currentPage: this.currentPage,
      sortingDirection: this.sortingDirection,
    };

    if (this.filterGroup.controls.fcDatumVon.value) {
      dto.zeitraum_von = new Date(this.filterGroup.controls.fcDatumVon.value).toISOString();
    }
    if (this.filterGroup.controls.fcDatumBis.value) {
      dto.zeitraum_bis = new Date(this.filterGroup.controls.fcDatumBis.value).toISOString();
    }
    return dto;
  }
}
