<mat-tab-group
  class="full-height"
  selectedIndex="1"
  (selectedTabChange)="selectedTabChange($event)"
  *ngIf="(monitorManager.useMultiWindowSetup$ | async) === false; else lagedarstellung"
>
  <!-- Tab 1 -->
  <mat-tab label="Dashboard">
    <app-kennzahlen-dashboard class="full-height" />
  </mat-tab>

  <!-- Tab 2 -->
  <mat-tab label="Lagekarte">
    <app-lage-map class="full-height" />
  </mat-tab>

  <!-- Tab 3 -->
  <mat-tab label="Maßnahmen">
    <app-massnahmen-dashboard class="full-height" />
  </mat-tab>
</mat-tab-group>

<ng-template #lagedarstellung>
  <app-lage-map class="full-height" />
</ng-template>
