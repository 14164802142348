<mat-card class="container">
  <mat-toolbar>
    <h2>Führungsebenen</h2>
    <button mat-icon-button (click)="onClickReload()"><mat-icon>refresh</mat-icon></button>
  </mat-toolbar>

  <mat-card-content class="dashboard-container">
    @for (dataset of fuehrungsebeneDatasets; track $index) {
    <app-dashboard-fuehrungsebene [dataset]="dataset" />

    <!-- Abstand zwischen erster und restlichen Führungsebenen -->
    @if ($index === 0 && fuehrungsebeneDatasets.length > 1) {
    <mat-icon class="hierarchy-icon">chevron_right</mat-icon>
    } }
  </mat-card-content>
</mat-card>
