import { NgIf } from '@angular/common';
import { Component, EventEmitter, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ErrorService } from '@product/ise-error-lib';
import { GebietDTO, Gebietsauspraegung, Gebietstyp, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

@Component({
  selector: 'app-gebiet-form',
  templateUrl: './gebiet-form.component.html',
  styleUrls: ['./gebiet-form.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatRadioModule,
    CleanableFormFieldComponent,
  ],
})
export class GebietFormComponent implements TaktischesZeichenForm {
  Gebietstyp = Gebietstyp;
  Gebietsauspraegung = Gebietsauspraegung;

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  iconDataUrlChanged = new EventEmitter<string>();

  @Output()
  taktischesZeichenChanged = new EventEmitter<TaktischesZeichenDTO>();

  private errorService = inject(ErrorService);
  private taktischeZeichenService = inject(TaktischeZeichenService);

  readonly DEFAULT_ICON_DATA_URL =
    this.taktischeZeichenService.taktischeZeichenInfoMapping.get(TaktischesZeichenTyp.Gebiet)?.dataUrl || '';
  private isCustomIcon = false;
  private iconDataUrl?: string;

  dtoToEdit?: GebietDTO;

  formBuilder = inject(NonNullableFormBuilder);

  fcAnzeigename = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcGebietstyp = this.formBuilder.control<Gebietstyp | undefined>(undefined);
  fcGebietsauspraegung = this.formBuilder.control<Gebietsauspraegung | undefined>(undefined);
  fcOrtsangabe = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);

  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    gebietstyp: this.fcGebietstyp,
    gebietsauspraegung: this.fcGebietsauspraegung,
    ortsangabe: this.fcOrtsangabe,
  });

  gebietstypMouseover = false;
  gebietsauspraegungMouseover = false;

  constructor() {
    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v?.trim() || '');
    });

    // Beim Ändern von Gebietstyp und -ausprägung, Neuzeichnen mit passender Polygon-Füllung anstoßen
    this.fcGebietstyp.valueChanges.pipe(takeUntilDestroyed()).subscribe((value?: Gebietstyp) => {
      if (!value) {
        this.fcGebietsauspraegung.setValue(undefined);
        return;
      }

      this.handleIconDataChanged();
      this.taktischesZeichenChanged.emit(this.formGroupToDto());
    });

    this.fcGebietsauspraegung.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      this.taktischesZeichenChanged.emit(this.formGroupToDto());
    });
  }

  setDTO(dto: GebietDTO) {
    if (!dto) {
      this.errorService.showErrorMessage('Kein Model zum Bearbeiten vorhanden.');
      return;
    }

    this.dtoToEdit = dto;
    this.dtoToFormGroup(this.dtoToEdit);
    this.handleIconDataChanged();
  }

  /**
   * Aktualisiertes GebietDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): GebietDTO | null {
    if (this.formGroup.valid) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * FormGroup Werte in PersonDTO schreiben und zurückliefern
   */
  formGroupToDto(): GebietDTO {
    return {
      ...this.dtoToEdit,
      customZeichen: this.isCustomIcon,
      dataUrl: this.iconDataUrl,
      anzeigename: this.fcAnzeigename.value.trim(),
      gebietstyp: this.fcGebietstyp.value,
      gebietsauspraegung: this.fcGebietsauspraegung.value,
      ortsangabe: this.fcOrtsangabe.value?.trim(),
      typ: TaktischesZeichenTyp.Gebiet,
    };
  }

  /**
   * FormGroup mit Werten aus DTO füllen
   */
  dtoToFormGroup(dto: GebietDTO): void {
    this.isCustomIcon = dto.customZeichen || false;
    this.iconDataUrl = dto.dataUrl;
    this.fcAnzeigename.setValue(dto.anzeigename);
    this.fcGebietstyp.setValue(dto.gebietstyp);
    this.fcGebietsauspraegung.setValue(dto.gebietsauspraegung);
    this.fcOrtsangabe.setValue(dto.ortsangabe);
  }

  private handleIconDataChanged() {
    if (!this.isCustomIcon) {
      const gebietstyp = this.fcGebietstyp.value;
      this.iconDataUrl = gebietstyp
        ? this.taktischeZeichenService.gebietDataUrlMapping.get(gebietstyp)
        : this.DEFAULT_ICON_DATA_URL;
      this.iconDataUrlChanged.emit(this.iconDataUrl);
    }
  }

  public setCustomIcon(iconDataUrl: string) {
    this.isCustomIcon = true;
    this.iconDataUrl = iconDataUrl;
  }

  public removeCustomIcon() {
    this.isCustomIcon = false;
    this.handleIconDataChanged();
  }
}
