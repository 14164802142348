import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ErrorService } from '@product/ise-error-lib';
import { geoJSON, latLngBounds } from 'leaflet';
import { BehaviorSubject, catchError, EMPTY, from, map, Observable, switchMap, take } from 'rxjs';
import { ApplicationSettingsDTO, ApplicationSettingsResourceService, ApplicationType } from './api/build/openapi';
import { AuthService } from './auth/auth.service';
import { PopupManagerService } from './lagedarstellung/monitor-manager/popup-manager.service';

// BoundingBox DE
export const BBOX_GER = latLngBounds([47.3024876979, 5.98865807458], [54.983104153, 15.0169958839]);

/**
 * Service für Einstellungen der gesamten Anwendung.
 * - Aktivieren des Darkmodes (Wird im LocalStorage persistiert).
 * - Setzen des ApplicationTypes (LAGE/LAGE-K)
 * - Laden der Mandanten-Settings (Standard-Kartenausschnitt, Organisation, ...)
 */
@Injectable({
  providedIn: 'root',
})
export class AppService {
  // DarkMode
  private darkMode$ = new BehaviorSubject<boolean>('true' === localStorage.getItem('darkmode'));

  // ApplicationType
  private selectedApplicationStorageKey: string = 'selected-application';
  private selectedApplication$ = new BehaviorSubject<ApplicationType | undefined>(undefined);

  // ApplicationSettings
  private authService = inject(AuthService);
  private applicationSettingsResourceService = inject(ApplicationSettingsResourceService);
  private errorService = inject(ErrorService);
  private applicationSettingsSubject$ = new BehaviorSubject<ApplicationSettingsDTO | undefined>(undefined);
  public applicationSettings$ = from(this.applicationSettingsSubject$);

  public vidalAcivated$ = this.applicationSettings$.pipe(map((settings) => !!settings?.vidal));

  private router = inject(Router);
  private popupManager = inject(PopupManagerService);

  constructor() {
    this.applyDarkMode();
    this.authService.isAuthenticated$.pipe(takeUntilDestroyed()).subscribe((authenticated) => {
      if (authenticated) {
        // Verhinert, dass in LAGE auf sekundären Fenstern die Lage-Übersicht geöffnet wird und damit die korrekten "Wände"
        if (this.popupManager.isSecondaryScreen()) {
          return;
        }

        const appType = localStorage.getItem(this.selectedApplicationStorageKey);
        if (appType) {
          switch (appType as ApplicationType) {
            case undefined:
              this.router.navigateByUrl('/program-select');
              break;
            case ApplicationType.Lage:
              this.router.navigate(['lagen']);
              this.selectApplication(ApplicationType.Lage);
              break;
            case ApplicationType.LageK:
              this.router.navigate(['lage-k']);
              this.selectApplication(ApplicationType.LageK);
              break;
          }
        }
      }
    });

    this.darkMode$.subscribe((b) => {
      localStorage.setItem('darkmode', String(b));
      this.applyDarkMode();
    });
    this.fetchApplicationSettings();
  }

  /*
   * DarkMode
   */
  isDarkMode(): Observable<boolean> {
    return this.darkMode$.asObservable();
  }

  toggleDarkMode() {
    this.darkMode$.next(!this.darkMode$.value);
  }

  private applyDarkMode() {
    if (this.darkMode$.value) {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  }

  /*
   * ApplicationType
   */
  selectApplication(applicationType: ApplicationType) {
    localStorage.setItem(this.selectedApplicationStorageKey, applicationType);
    this.authService.mapUserToApplication(applicationType);
    this.selectedApplication$.next(applicationType);
  }

  selectedApplicationObservable(): Observable<ApplicationType | undefined> {
    return this.selectedApplication$.asObservable();
  }

  getCurrentApplicationType(): ApplicationType | undefined {
    return this.selectedApplication$.getValue();
  }

  /*
   * ApplicationSettings
   */
  fetchApplicationSettings() {
    this.authService.isAuthenticated$
      .pipe(
        take(1),
        switchMap((authenticated) => {
          if (authenticated) {
            return this.applicationSettingsResourceService.getApplicationSettings().pipe(
              catchError((errorResp: HttpErrorResponse) => {
                this.errorService.showError(errorResp.error);
                return EMPTY;
              })
            );
          }
          return EMPTY;
        })
      )
      .subscribe((applicationSettings: ApplicationSettingsDTO) => {
        this.applicationSettingsSubject$.next(applicationSettings);
      });
  }

  patchApplicationSettings(applicationSettings: ApplicationSettingsDTO) {
    this.applicationSettingsResourceService
      .patchApplicationSettings(applicationSettings)
      .pipe(
        catchError((errorResp: HttpErrorResponse) => {
          this.errorService.showError(errorResp.error);
          return EMPTY;
        })
      )
      .subscribe((applicationSettings: ApplicationSettingsDTO) => {
        this.applicationSettingsSubject$.next(applicationSettings);
      });
  }

  getSettingsMapBounds() {
    const applicationSettings = this.applicationSettingsSubject$.value;
    if (!applicationSettings) {
      console.warn(
        'Default MapBounds konnten nicht berechnet werden, da ApplicationSettings noch nicht verfügbar sind.'
      );
      this.fetchApplicationSettings();
      return BBOX_GER;
    }

    if (!applicationSettings.defaultGeometry) {
      console.warn(
        'Default MapBounds konnten nicht berechnet werden, da ApplicationSettings keine DefaultGeometry besitzt.'
      );
      return BBOX_GER;
    }

    return geoJSON(applicationSettings.defaultGeometry as GeoJSON.Geometry).getBounds();
  }
}
