/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SchadenkontoColor = 'BLUE' | 'BROWN' | 'GRAY' | 'GREEN' | 'ORANGE' | 'RED' | 'WHITE' | 'YELLOW';

export const SchadenkontoColor = {
    Blue: 'BLUE' as SchadenkontoColor,
    Brown: 'BROWN' as SchadenkontoColor,
    Gray: 'GRAY' as SchadenkontoColor,
    Green: 'GREEN' as SchadenkontoColor,
    Orange: 'ORANGE' as SchadenkontoColor,
    Red: 'RED' as SchadenkontoColor,
    White: 'WHITE' as SchadenkontoColor,
    Yellow: 'YELLOW' as SchadenkontoColor
};

