<mat-card class="container">
  <mat-toolbar class="header">
    <h2>Personenschäden</h2>
  </mat-toolbar>

  <mat-card-content class="charts">
    <mat-card class="personenschaden-chart-container">
      <canvas id="personenchart" #personenchart role="img"></canvas>
    </mat-card>

    <mat-card class="manv-chart-container">
      <canvas id="manvchart" #manvchart role="img"></canvas>
    </mat-card>
  </mat-card-content>
</mat-card>
