<mat-card class="contact-list-card">
  <mat-toolbar class="contact-list-header">
    <h3>{{ headerText }}</h3>
    <span *ngIf="showButtons">
      <button mat-icon-button (click)="addContact()" [disabled]="readonly" matTooltip="Kontaktmöglichkeit hinzufügen">
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="editContact()"
        [disabled]="readonly || selectedIndex < 0"
        matTooltip="Kontaktmöglichkeit bearbeiten"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="askDeleteContact()"
        [disabled]="readonly || selectedIndex < 0"
        matTooltip="Kontaktmöglichkeit löschen"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </span>
  </mat-toolbar>

  <mat-card-content *ngIf="kommunikationOptionen$ | async as kommunikationOpts">
    <mat-action-list>
      <mat-list-item
        *ngFor="let kommunikationOption of kommunikationOpts; let index = index"
        (click)="selectedIndex = index"
        [ngClass]="{ 'selected-list-row': selectedIndex === index }"
      >
        <div
          class="contact-summary"
          *ngIf="kontaktService.summarizeKommunikation(kommunikationOption) as summary"
          [matTooltip]="summary"
        >
          <mat-icon>{{ kontaktService.KontaktTypMapping.get(kommunikationOption.kontaktTyp!)?.icon }}</mat-icon>
          {{ summary }}
        </div>
      </mat-list-item>
    </mat-action-list>

    <h3 class="keine-daten-text" *ngIf="!kommunikationOpts.length">Keine Kontaktmöglichkeiten vorhanden</h3>
  </mat-card-content>
</mat-card>
