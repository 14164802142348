import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { ErrorService } from '@product/ise-error-lib';
import { Observable, take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { BefehlsstelleDTO, PersonDTO, StelleDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { AuftragListComponent } from 'src/app/lagedarstellung/auftrag/auftrag-list/auftrag-list.component';
import { KontaktListComponent } from 'src/app/lagedarstellung/kontakt/kontakt-list/kontakt-list.component';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { IconService } from 'src/app/shared/services/icon.service';
import { TzComboboxComponent } from 'src/app/shared/tz-combobox/tz-combobox.component';
import { compareLabels } from 'src/app/taktische-zeichen/taktische-zeichen-form/taktische-zeichen-form.component';
import {
  Fachaufgabe,
  GrundzeichenId,
  Organisation,
  Symbol,
  TaktischesZeichen,
  erzeugeTaktischesZeichen,
  fachaufgaben,
  organisationen,
  symbole,
} from 'taktische-zeichen-core';
import { befehlsstellenSelector } from '../../befehlsstelle/+state/befehlsstelle.selectors';
import { personenSelector } from '../../personen/+state/person.selectors';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

/**
 * Aufträge müssen noch angebunden werden. Werden noch nicht persistiert.
 */
@Component({
  selector: 'app-stelle-einrichtung-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    KontaktListComponent,
    AuftragListComponent,
    CleanableFormFieldComponent,
    TzComboboxComponent,
    MatInputModule,
  ],
  templateUrl: './stelle-einrichtung-form.component.html',
  styleUrls: ['./stelle-einrichtung-form.component.scss'],
})
export class StelleEinrichtungFormComponent implements TaktischesZeichenForm {
  @ViewChild(KontaktListComponent)
  kontaktList!: KontaktListComponent;

  @ViewChild(AuftragListComponent)
  auftragList!: AuftragListComponent;

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  iconDataUrlChanged = new EventEmitter<string>();

  stelleToEdit?: StelleDTO;

  readonly DEFAULT_ICON_DATA: TaktischesZeichen = { grundzeichen: 'stelle' };
  iconData: TaktischesZeichen = { ...this.DEFAULT_ICON_DATA };
  private isCustomIcon = false;
  private iconDataUrl?: string;

  // Dropdown-Werte
  organisationValues: Organisation[] = organisationen.sort(compareLabels);
  fachaufgabeValues: Fachaufgabe[] = fachaufgaben.sort(compareLabels);
  symbolValues: Symbol[] = symbole.sort(compareLabels);
  leiterValues$: Observable<PersonDTO[]>;
  befehlsstelleValues$: Observable<BefehlsstelleDTO[]>;

  organisationMouseover = false;
  fachaufgabeMouseover = false;
  symbolMouseover = false;
  leiterMouseover = false;
  befehlsstelleMouseover = false;

  private errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);
  private iconService = inject(IconService);

  fcAnzeigename = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcGrundzeichenId = this.formBuilder.control<GrundzeichenId>('stelle');
  fcFachaufgabe = this.formBuilder.control<Fachaufgabe | undefined>(undefined);
  fcOrganisation = this.formBuilder.control<Organisation | undefined>(undefined);
  fcSymbol = this.formBuilder.control<Symbol | undefined>(undefined);
  fcZeichenText = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcOrtsangabe = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcLeiter = this.formBuilder.control<PersonDTO | undefined>(undefined);
  fcBefehlsstelle = this.formBuilder.control<BefehlsstelleDTO | undefined>(undefined);

  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    grundzeichenId: this.fcGrundzeichenId,
    fachaufgabe: this.fcFachaufgabe,
    organisation: this.fcOrganisation,
    symbol: this.fcSymbol,
    zeichenText: this.fcZeichenText,
    ortsangabe: this.fcOrtsangabe,
    leiter: this.fcLeiter,
    befehlsstelle: this.fcBefehlsstelle,
  });

  constructor(store: Store<AppStateInterface>) {
    this.leiterValues$ = store.select(personenSelector).pipe(takeUntilDestroyed());
    this.befehlsstelleValues$ = store.select(befehlsstellenSelector).pipe(takeUntilDestroyed());

    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v?.trim());
    });

    this.fcGrundzeichenId.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.grundzeichen = v;
      this.handleIconDataChanged();
    });

    this.fcFachaufgabe.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.fachaufgabe = v?.id;
      this.handleIconDataChanged();
    });

    this.fcOrganisation.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.organisation = v?.id;
      this.handleIconDataChanged();
    });

    this.fcSymbol.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.symbol = v?.id;
      this.handleIconDataChanged();
    });

    this.fcZeichenText.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.text = v?.trim();
      this.handleIconDataChanged();
    });
  }

  setDTO(stelleDto: StelleDTO) {
    if (!stelleDto) {
      this.errorService.showErrorMessage('Kein Model zum Bearbeiten vorhanden.');
      return;
    }

    // Sonderfall: Anlegen über Karte braucht direkt ein Temp-Icon
    if (!stelleDto.id) {
      this.iconDataUrlChanged.emit(erzeugeTaktischesZeichen(this.DEFAULT_ICON_DATA).dataUrl);
    }

    this.stelleToEdit = stelleDto;
    this.dtoToFormGroup(this.stelleToEdit);
    this.handleIconDataChanged();
  }

  /**
   * Aktualisiertes StelleDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): StelleDTO | null {
    if (this.formGroup.valid) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * FormGroup Werte in PersonDTO schreiben und zurückliefern
   */
  formGroupToDto(): StelleDTO {
    return {
      ...this.stelleToEdit,
      customZeichen: this.isCustomIcon,
      dataUrl: this.iconDataUrl,
      anzeigename: this.fcAnzeigename.value,
      grundzeichen: this.fcGrundzeichenId.value,
      organisation: this.fcOrganisation.value?.id,
      fachaufgabe: this.fcFachaufgabe.value?.id,
      symbol: this.fcSymbol.value?.id,
      zeichenText: this.fcZeichenText.value?.trim(),
      ortsangabe: this.fcOrtsangabe.value?.trim(),
      leiterId: this.fcLeiter.value?.id,
      befehlsstelleId: this.fcBefehlsstelle.value?.id,
      kommunikationOptionen: this.kontaktList.getKommunikationOptionen(),
      auftraege: this.auftragList.getAuftraege(),
      typ: TaktischesZeichenTyp.Stelle,
    };
  }

  /**
   * FormGroup mit Werten aus PersonDTO füllen
   */
  dtoToFormGroup(stelleDTO: StelleDTO): void {
    this.isCustomIcon = stelleDTO.customZeichen || false;
    this.iconDataUrl = stelleDTO.dataUrl;

    this.fcAnzeigename.setValue(stelleDTO.anzeigename);
    this.fcGrundzeichenId.setValue((stelleDTO.grundzeichen as GrundzeichenId) || 'stelle');
    this.fcOrganisation.setValue(organisationen.find((v) => v.id === stelleDTO.organisation));
    this.fcFachaufgabe.setValue(fachaufgaben.find((v) => v.id === stelleDTO.fachaufgabe));
    this.fcSymbol.setValue(symbole.find((v) => v.id === stelleDTO.symbol));
    this.fcZeichenText.setValue(stelleDTO.zeichenText);
    this.fcOrtsangabe.setValue(stelleDTO.ortsangabe);

    if (stelleDTO.befehlsstelleId) {
      this.befehlsstelleValues$
        .pipe(take(1))
        .subscribe((befehlsstelle) =>
          this.fcBefehlsstelle.setValue(
            befehlsstelle.find((befehlsstelle) => befehlsstelle.id === stelleDTO.befehlsstelleId)
          )
        );
    }
    if (stelleDTO.leiterId) {
      this.leiterValues$
        .pipe(take(1))
        .subscribe((leiter) => this.fcLeiter.setValue(leiter.find((l) => l.id === stelleDTO.leiterId)));
    }
    this.auftragList.setAuftraege(stelleDTO.auftraege || []);
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }

  private handleIconDataChanged(): void {
    if (!this.isCustomIcon) {
      this.iconService.generateCompressedDataUrl(this.iconData).subscribe((dataUrl) => {
        this.iconDataUrl = dataUrl;
        this.iconDataUrlChanged.emit(this.iconDataUrl);
      });
    }
  }

  public setCustomIcon(iconDataUrl: string): void {
    this.isCustomIcon = true;
    this.iconDataUrl = iconDataUrl;
  }

  public removeCustomIcon(): void {
    this.isCustomIcon = false;
    this.handleIconDataChanged();
  }
}
