<mat-card *ngIf="dataset">
  <mat-toolbar>
    <app-fuehrungsebene-icon-and-text [fuehrungsebeneDTO]="dataset.uebersichtDTO.fuehrungsebeneDTO" />
    <button
      mat-icon-button
      (click)="navigateToFuehrungsebene()"
      *ngIf="fuehrungsebeneService.getCurrentFuehrungsebene()?.id !== dataset.uebersichtDTO.fuehrungsebeneDTO.id"
      [matTooltip]="'In Führungsebene springen'"
    >
      <mat-icon>south_east</mat-icon>
    </button>
  </mat-toolbar>

  <mat-card-header>
    <mat-card-subtitle> Untergeordnete Führungsebenen </mat-card-subtitle>
    <mat-card-subtitle>
      Direkt: {{ dataset.uebersichtDTO.immediateChildCount }}, Gesamt: {{ dataset.uebersichtDTO.recursiveChildCount }}
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content class="chart-container">
    <canvas [id]="'chart'" #einsatzstellenchart role="img"></canvas>
  </mat-card-content>
</mat-card>
