import { inject, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DialogAction, DialogService } from '@product/ise-dialog-lib';
import { ErrorService } from '@product/ise-error-lib';
import { catchError, EMPTY, switchMap, take } from 'rxjs';
import {
  BibFahrzeugDTO,
  BibliothekResourceService,
  BibPersonDTO,
  BibTaktischeFormationDTO,
  DeleteTzsDto,
  FahrzeugDTO,
  MoveTzsDto,
  MultiplyTzDto,
  PersonDTO,
  TaktischeFormationDTO,
  TaktischesZeichenDTO,
  TaktischesZeichenTyp,
  VerwaltungsebeneDTO,
} from 'src/app/api/build/openapi';
import { bibliothekActions } from './+state/bibliothek.actions';
import { BibTzDTO } from './verwaltungsebene-details/verwaltungsebene-details.component';
import { VerwaltungsebeneDialogComponent } from './verwaltungsebene-dialog/verwaltungsebene-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class BibliothekZeichenService {
  private actions$ = inject(Actions);
  private bibliothekResourceService = inject(BibliothekResourceService);
  private dialogService = inject(DialogService);
  private errorService = inject(ErrorService);
  private store = inject(Store);

  saveBibZeichenDto = (dtoToSave: BibTzDTO, successCallback?: (bibTzDto: BibTzDTO) => void) => {
    if (dtoToSave.id) {
      switch (dtoToSave.typ) {
        case TaktischesZeichenTyp.Fahrzeug:
          if (successCallback) {
            this.actions$
              .pipe(ofType(bibliothekActions.patchFahrzeugSuccess), take(1))
              .subscribe((saveResult) => successCallback(saveResult.patchedFahrzeug));
          }
          this.store.dispatch(
            bibliothekActions.patchFahrzeug({ fahrzeugId: dtoToSave.id, fahrzeugDTO: dtoToSave as BibFahrzeugDTO })
          );
          break;
        case TaktischesZeichenTyp.Person:
          if (successCallback) {
            this.actions$
              .pipe(ofType(bibliothekActions.patchPersonSuccess), take(1))
              .subscribe((saveResult) => successCallback(saveResult.patchedPerson));
          }
          this.store.dispatch(
            bibliothekActions.patchPerson({ personId: dtoToSave.id, personDTO: dtoToSave as BibPersonDTO })
          );
          break;
        case TaktischesZeichenTyp.TaktischeFormation:
          if (successCallback) {
            this.actions$
              .pipe(ofType(bibliothekActions.patchTaktischeFormationSuccess), take(1))
              .subscribe((saveResult) => successCallback(saveResult.patchedTaktischeFormation));
          }
          this.store.dispatch(
            bibliothekActions.patchTaktischeFormation({
              taktischeFormationId: dtoToSave.id,
              taktischeFormationDTO: dtoToSave as BibTaktischeFormationDTO,
            })
          );
          break;
      }
    } else {
      switch (dtoToSave.typ) {
        case TaktischesZeichenTyp.Fahrzeug:
          if (successCallback) {
            this.actions$
              .pipe(ofType(bibliothekActions.createFahrzeugSuccess), take(1))
              .subscribe((saveResult) => successCallback(saveResult.newFahrzeug));
          }
          this.store.dispatch(bibliothekActions.createFahrzeug({ fahrzeug: dtoToSave as BibFahrzeugDTO }));
          break;
        case TaktischesZeichenTyp.Person:
          if (successCallback) {
            this.actions$
              .pipe(ofType(bibliothekActions.createPersonSuccess), take(1))
              .subscribe((saveResult) => successCallback(saveResult.newPerson));
          }
          this.store.dispatch(bibliothekActions.createPerson({ person: dtoToSave as BibPersonDTO }));
          break;
        case TaktischesZeichenTyp.TaktischeFormation:
          if (successCallback) {
            this.actions$
              .pipe(ofType(bibliothekActions.createTaktischeFormationSuccess), take(1))
              .subscribe((saveResult) => successCallback(saveResult.newTaktischeFormation));
          }
          this.store.dispatch(
            bibliothekActions.createTaktischeFormation({ taktischeFormation: dtoToSave as BibTaktischeFormationDTO })
          );
          break;
      }
    }
  };

  isBibZeichen(tzDto: TaktischesZeichenDTO) {
    if (
      tzDto.typ === TaktischesZeichenTyp.Person ||
      tzDto.typ === TaktischesZeichenTyp.Fahrzeug ||
      tzDto.typ === TaktischesZeichenTyp.TaktischeFormation
    ) {
      return !(tzDto as FahrzeugDTO | PersonDTO | TaktischeFormationDTO).lageId;
    }
    return false;
  }

  /**
   * Löscht mehrere Taktische Zeichen aus der Bibliothek
   */
  deleteMultipleTzs(tzDtos: TaktischesZeichenDTO[], recursive: boolean) {
    if (!tzDtos.length) {
      console.warn('Verschieben nicht möglich. Keine Taktischen Zeichen wurden ausgewählt.');
      return;
    }

    const deleteTzsDto: DeleteTzsDto = {
      recursive: recursive,
      fahrzeugDtoIds: tzDtos
        .filter((tzDto) => tzDto.typ === TaktischesZeichenTyp.Fahrzeug)
        .map((tzDto) => tzDto.id || '-1'),
      personDtoIds: tzDtos
        .filter((tzDto) => tzDto.typ === TaktischesZeichenTyp.Person)
        .map((tzDto) => tzDto.id || '-1'),
      taktischeFormationDtoIds: tzDtos
        .filter((tzDto) => tzDto.typ === TaktischesZeichenTyp.TaktischeFormation)
        .map((tzDto) => tzDto.id || '-1'),
    };
    this.bibliothekResourceService
      .deleteBibTzs(deleteTzsDto)
      .pipe(
        catchError((error) => {
          this.errorService.showError(error.error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  /**
   * Verschiebt mehrere Bibliothek-TZs in eine andere Verwaltungsebene
   */
  moveMultipleTzsToVerwaltungsebene(tzDtos: TaktischesZeichenDTO[], verwaltungsebeneId: string) {
    if (!tzDtos.length) {
      console.warn('Verschieben nicht möglich. Keine Taktischen Zeichen wurden ausgewählt.');
      return;
    }

    const moveTzsDto: MoveTzsDto = {
      targetContainerId: verwaltungsebeneId,
      fahrzeugDtoIds: tzDtos
        .filter((tzDto) => tzDto.typ === TaktischesZeichenTyp.Fahrzeug)
        .map((tzDto) => tzDto.id || '-1'),
      personDtoIds: tzDtos
        .filter((tzDto) => tzDto.typ === TaktischesZeichenTyp.Person)
        .map((tzDto) => tzDto.id || '-1'),
      taktischeFormationDtoIds: tzDtos
        .filter((tzDto) => tzDto.typ === TaktischesZeichenTyp.TaktischeFormation)
        .map((tzDto) => tzDto.id || '-1'),
    };

    this.bibliothekResourceService
      .moveBibTzs(moveTzsDto)
      .pipe(
        catchError((error) => {
          this.errorService.showError(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  openDeleteVerwaltungsebeneDialog(
    verwaltungsebeneDto: VerwaltungsebeneDTO,
    dialogRef: MatDialogRef<VerwaltungsebeneDialogComponent>
  ) {
    if (!verwaltungsebeneDto.id) {
      console.warn('Führungsebene kann nicht gelöscht werden, da sie keine Id besitzt.');
      return;
    }

    this.bibliothekResourceService
      .canVerwaltungsebeneBeDeleted(verwaltungsebeneDto.id)
      .pipe(
        switchMap((canBeDeleted: boolean) => {
          if (!canBeDeleted) {
            this.errorService.showErrorMessage(
              'Verwaltungsebene kann nicht gelöscht werden, da sie oder darunterliegende Verwaltungsebenen noch Taktische Zeichen besitzen.'
            );
            return EMPTY;
          }

          return this.dialogService
            .openConfirmDialog(
              'Verwaltungsebene löschen?',
              'Mit dem Löschen der Verwaltungsebene werden auch alle darunterliegenden Verwaltungsebenen gelöscht. Der Vorgang kann nicht rückgängig gemacht werden.'
            )
            .afterClosed();
        })
      )
      .subscribe((result: DialogAction) => {
        if (!verwaltungsebeneDto.id) {
          console.warn('Führungsebene kann nicht gelöscht werden, da sie keine Id besitzt.');
          return;
        }
        if (result === DialogAction.OK) {
          this.actions$.pipe(ofType(bibliothekActions.deleteVerwaltungsebeneSuccess), take(1)).subscribe(() => {
            dialogRef.close();
          });
          this.store.dispatch(bibliothekActions.deleteVerwaltungsebene({ verwaltungsebeneId: verwaltungsebeneDto.id }));
        }
      });
  }

  multiplyBibZeichen(multiplyDto: MultiplyTzDto) {
    return this.bibliothekResourceService.multiplyTzBib(multiplyDto);
  }
}
