<!-- 
  Header mit Zusammenfassung, wie viele Taktische Formationen, Personen und Fahrzeuge 
  die ausgewählte Taktische Formation insgesamt besitzt 
-->
<mat-toolbar *ngIf="selectedTaktischeFormationNested$ | async as selectedTaktischeFormationNested">
  <div class="header">
    <h3>Mannschaftsstärken</h3>
    <mat-icon
      fontSet="material-icons-outlined"
      matTooltip="Besitzt die Formation keine Einheiten, gilt die eingetragene manuelle Einheitenstärke. Andernfalls gilt die aus den Einheiten summierte Einheitenstärke."
      >info</mat-icon
    >
  </div>
  <div class="toolbar-row">
    <div class="header-col">
      <div class="header">
        @if (!selectedTaktischeFormationNested.hatEinheiten) {
        <mat-icon> check </mat-icon>
        }
        <h4 [ngClass]="{ dynamic: !selectedTaktischeFormationNested.hatEinheiten }">Manuell</h4>
      </div>
      <div class="header-row">
        <div class="header-item" matTooltip="Taktische Formationen">
          <span>{{ selectedTaktischeFormationNested.summeTaktischeFormationen }}</span>
          <img width="24px" [src]="taktischeZeichenInfoMapping.get(TaktischesZeichenTyp.TaktischeFormation)?.dataUrl" />
        </div>
        <div class="header-item" matTooltip="Fahrzeuge">
          <span>{{ selectedTaktischeFormationNested.summeFahrzeuge }}</span>
          <img width="24px" [src]="taktischeZeichenInfoMapping.get(TaktischesZeichenTyp.Fahrzeug)?.dataUrl" />
        </div>
      </div>
      <div class="header-row mannschaftsstaerke" matTooltip="Mannschaftsstärke">
        {{ selectedTaktischeFormationNested.mannschaftsstaerke1 }} /
        {{ selectedTaktischeFormationNested.mannschaftsstaerke2 }} /
        {{ selectedTaktischeFormationNested.mannschaftsstaerke3 }} =
        {{
          selectedTaktischeFormationNested.mannschaftsstaerke1 +
            selectedTaktischeFormationNested.mannschaftsstaerke2 +
            selectedTaktischeFormationNested.mannschaftsstaerke3
        }}
      </div>
    </div>

    @if (selectedTaktischeFormationNested.hatEinheiten) {
    <div class="header-col">
      <div class="header">
        <mat-icon> check </mat-icon>
        <h4 [ngClass]="{ dynamic: selectedTaktischeFormationNested.hatEinheiten }">Einheitenstärke</h4>
      </div>
      <div class="header-row">
        <div class="header-item" matTooltip="Taktische Formationen">
          <span>{{ selectedTaktischeFormationNested.summeTaktischeFormationenBerechnet }}</span>
          <img width="24px" [src]="taktischeZeichenInfoMapping.get(TaktischesZeichenTyp.TaktischeFormation)?.dataUrl" />
        </div>
        <div class="header-item" matTooltip="Fahrzeuge">
          <span>{{ selectedTaktischeFormationNested.summeFahrzeugeBerechnet }}</span>
          <img width="24px" [src]="taktischeZeichenInfoMapping.get(TaktischesZeichenTyp.Fahrzeug)?.dataUrl" />
        </div>
      </div>
      <div class="header-row mannschaftsstaerke" matTooltip="Mannschaftsstärke">
        {{ selectedTaktischeFormationNested.mannschaftsstaerke1Berechnet }} /
        {{ selectedTaktischeFormationNested.mannschaftsstaerke2Berechnet }} /
        {{ selectedTaktischeFormationNested.mannschaftsstaerke3Berechnet }} =
        {{
          (selectedTaktischeFormationNested.mannschaftsstaerke1Berechnet || 0) +
            (selectedTaktischeFormationNested.mannschaftsstaerke2Berechnet || 0) +
            (selectedTaktischeFormationNested.mannschaftsstaerke3Berechnet || 0)
        }}
      </div>
    </div>
    }
  </div>
</mat-toolbar>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
  <!-- Blatt-Element (Taktische Formation) im Baum -->
  <mat-tree-node *matTreeNodeDef="let taktischeFormation" matTreeNodeToggle>
    <div
      class="taktische-formation-node"
      (click)="selectTaktischeFormation(taktischeFormation)"
      [ngClass]="{
        'selected-row': taktischeFormation.id === selectedTaktischeFormationNested$.value.id,
        vorlage: taktischeFormation.vorlage,
      }"
    >
      <div class="zeichen">
        <img [src]="taktischeFormation.dataUrl" width="32px" />
        <mat-icon
          color="warn"
          class="unavailable-badge"
          *ngIf="taktischeFormation.unavailable"
          matTooltip="Nicht verfügbar"
          >cancel</mat-icon
        >
      </div>
      <span>{{ taktischeFormation.anzeigename }}</span>
    </div>
  </mat-tree-node>

  <!-- Knoten-Element im Baum -->
  <mat-nested-tree-node *matTreeNodeDef="let taktischeFormation; when: hasChild">
    <div class="mat-tree-node">
      <!-- Button zum Ein-/Ausklappen -->
      <mat-icon class="toggle-icon" matTreeNodeToggle>
        {{ treeControl.isExpanded(taktischeFormation) ? "expand_more" : "chevron_right" }}
      </mat-icon>

      <!-- Knoten-Inhalt (Taktische Formation) -->
      <div
        class="taktische-formation-node"
        (click)="selectTaktischeFormation(taktischeFormation)"
        [ngClass]="{
          'selected-row': taktischeFormation.id === selectedTaktischeFormationNested$.value.id,
          vorlage: taktischeFormation.vorlage,
          unavailable: taktischeFormation.unavailable
        }"
      >
        <img [src]="taktischeFormation.dataUrl" width="32px" />
        {{ taktischeFormation.anzeigename }}
      </div>
    </div>

    <!-- Outlet für Children -->
    <div [class.example-tree-invisible]="!treeControl.isExpanded(taktischeFormation)" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>
