import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { DashboardLageComponent } from './dashboard-lage/dashboard-lage.component';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Personen } from 'src/app/lagedarstellung/triage/personen.interface';
import { DashboardFahrzeugeComponent } from './dashboard-fahrzeuge/dashboard-fahrzeuge.component';
import { DashboardPersonenComponent } from './dashboard-personen/dashboard-personen.component';
import {
  FahrzeugeDataset,
  FuehrungsebeneDataset,
  KennzahlenDashboadService,
  ManvDataset,
  PersonenDataset,
} from './kennzahlen-dashboad.service';

@Component({
  selector: 'app-kennzahlen-dashboard',
  standalone: true,
  imports: [CommonModule, DashboardLageComponent, DashboardFahrzeugeComponent, DashboardPersonenComponent],
  templateUrl: './kennzahlen-dashboard.component.html',
  styleUrls: ['./kennzahlen-dashboard.component.scss'],
})
export class KennzahlenDashboardComponent {
  private kennzahlenService = inject(KennzahlenDashboadService);

  fuehrungsebeneDatasets: FuehrungsebeneDataset[] = [];
  fahrzeugeDataset: FahrzeugeDataset = {
    fahrzeuge: 0,
    staerke1: 0,
    staerke2: 0,
    staerke3: 0,
  };
  personenDatasetBevoelkerung: PersonenDataset = {
    name: 'Bevölkerung',
    personen: this.defaultPersonen(),
  };

  personenDatasetEinsatzkraefte: PersonenDataset = {
    name: 'Einsatzkräfte',
    personen: this.defaultPersonen(),
  };

  personenDatasetManv: ManvDataset = {
    name: 'MANV',
    manv: { category1: 0, category2: 0, category3: 0, category4: 0, dead: 0 },
  };

  constructor() {
    this.kennzahlenService
      .subscribeFuehrungsebenen()
      .pipe(takeUntilDestroyed())
      .subscribe((datasets) => (this.fuehrungsebeneDatasets = datasets));

    this.kennzahlenService
      .subscribeFahrzeuge()
      .pipe(takeUntilDestroyed())
      .subscribe((fahrzeuge) => (this.fahrzeugeDataset = fahrzeuge));

    this.kennzahlenService
      .subscribePersonenEinsatzkraefte()
      .pipe(takeUntilDestroyed())
      .subscribe((dataset) => (this.personenDatasetEinsatzkraefte = dataset));

    this.kennzahlenService
      .subscribePersonenBevoelkerung()
      .pipe(takeUntilDestroyed())
      .subscribe((dataset) => (this.personenDatasetBevoelkerung = dataset));

    this.kennzahlenService
      .subscribePersonenManv()
      .pipe(takeUntilDestroyed())
      .subscribe((manv) => (this.personenDatasetManv = manv));
  }

  reload() {
    this.kennzahlenService.reload();
  }

  private defaultPersonen(): Personen {
    return { betroffen: 0, gerettet: 0, tot: 0, transportiert: 0, verletzt: 0, vermisst: 0 };
  }
}
