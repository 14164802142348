import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  EinsatztagebucheintragDTO,
  EinsatztagebucheintragResultDTO,
  EinsatztagebuchSelectDTO,
  ErrorResponse,
} from 'src/app/api/build/openapi';

export const einsatztagebuchActions = createActionGroup({
  source: 'Einsatztagebuch',
  events: {
    'Reset Store': emptyProps(),

    'Get Einsatztagebuch': props<{ lageId: string; filter: EinsatztagebuchSelectDTO }>(),
    'Get Einsatztagebuch Success': props<{ eintraege: EinsatztagebucheintragResultDTO; lageId: string }>(),
    'Get Einsatztagebuch Failure': props<{ errorResponse: ErrorResponse }>(),

    'Create Einsatztagebucheintrag': props<{ newEintrag: EinsatztagebucheintragDTO }>(),
    'Create Einsatztagebucheintrag Success': props<{ newEintrag: EinsatztagebucheintragDTO }>(),
    'Create Einsatztagebucheintrag Failure': props<{ errorResponse: ErrorResponse }>(),

    'Patch Einsatztagebucheintrag': props<{ patchedEintrag: EinsatztagebucheintragDTO }>(),
    'Patch Einsatztagebucheintrag Success': props<{ patchedEintrag: EinsatztagebucheintragDTO }>(),
    'Patch Einsatztagebucheintrag Failure': props<{ errorResponse: ErrorResponse }>(),
  },
});
