import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, inject, Input, OnChanges, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { Chart } from 'chart.js';
import { fuehrungsebeneActions } from '../../../lagedarstellung/fuehrungsebene/+state/fuehrungsebene.actions';
import { FuehrungsebeneIconAndTextComponent } from '../../../lagedarstellung/fuehrungsebene/fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService } from '../../../lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { FuehrungsebeneDataset } from '../kennzahlen-dashboad.service';

@Component({
  selector: 'app-dashboard-fuehrungsebene',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    FuehrungsebeneIconAndTextComponent,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './dashboard-fuehrungsebene.component.html',
  styleUrls: ['./dashboard-fuehrungsebene.component.scss'],
})
export class DashboardFuehrungsebeneComponent implements AfterViewInit, OnChanges {
  @Input()
  dataset?: FuehrungsebeneDataset;

  @ViewChild('einsatzstellenchart')
  private canvas!: ElementRef<HTMLCanvasElement>;

  private chart?: Chart<'doughnut', number[] | undefined, string>;

  protected fuehrungsebeneService = inject(FuehrungsebeneService);
  private store = inject(Store);

  private readonly CHART_LABELS = ['Anlässe', 'Gefahren', 'Maßnahmen'];
  private readonly CHART_COLORS = ['rgb(255, 77, 174)', 'rgb(255, 64, 129)', 'rgb(179, 18, 106)'];

  ngOnChanges(): void {
    this.initChart();
  }

  ngAfterViewInit(): void {
    this.initChart();
  }

  private initChart() {
    if (!this.canvas) {
      return;
    }

    this.chart?.destroy();
    const ctx = this.canvas.nativeElement;
    this.chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: this.CHART_LABELS,

        datasets: [
          {
            data: this.dataset?.chartData,
            backgroundColor: this.CHART_COLORS,
            hoverOffset: 4,
            circumference: 180,
            rotation: 270,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            align: 'start',

            labels: {
              generateLabels: (chart) => {
                const dataset = chart.data.datasets[0];
                return dataset.data.map((value, index) => ({
                  text: `${this.CHART_LABELS[index]}: ${value}`,
                  fillStyle: this.CHART_COLORS[index],
                  index,
                }));
              },
            },
          },
        },
      },
    });
  }

  navigateToFuehrungsebene() {
    const fuehrungsebeneDTO = this.dataset?.uebersichtDTO.fuehrungsebeneDTO;
    if (fuehrungsebeneDTO) {
      this.store.dispatch(fuehrungsebeneActions.setCurrentFuehrungsebene({ currentFuehrungsebene: fuehrungsebeneDTO }));
    }
  }
}
