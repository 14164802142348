import { createSelector } from '@ngrx/store';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { Fuehrungsebenentyp } from 'src/app/api/build/openapi/model/fuehrungsebenentyp';

export const selectFeature = (appState: AppStateInterface) => appState.fuehrungsebenen;
export const isLoadingFuehrungsebeneSelector = createSelector(selectFeature, (state) => state.isLoading);
export const isSavingSelector = createSelector(selectFeature, (state) => state.isSaving);
export const isDeletingSelector = createSelector(selectFeature, (state) => state.isDeleting);
export const allFuehrungsebenenSelector = createSelector(selectFeature, (state) => state.allFuehrungsebenen);
export const parentFuehrungsebeneSelector = createSelector(selectFeature, (state) => state.parentFuehrungsebene);
export const currentFuehrungsebeneSelector = createSelector(selectFeature, (state) => state.currentFuehrungsebene);
export const childFuehrungsebenenSelector = createSelector(selectFeature, (state) => state.childFuehrungsebenen);

/**
 * Liefert alle Bereitstellungsräume des CurrentFuehrungsebenes
 */
export const currentBereitstellungsraeumeSelector = createSelector(selectFeature, (state) =>
  state.allFuehrungsebenen.filter(
    (fuehrungsebene) =>
      fuehrungsebene.typ === Fuehrungsebenentyp.Bereitstellungsraum &&
      fuehrungsebene.parentFuehrungsebeneId === state.currentFuehrungsebene?.id
  )
);

/**
 * Liefert alle Grundschutz-Fuehrungsebenen des CurrentFuehrungsebenes
 */
export const currentGrundschutzeSelector = createSelector(selectFeature, (state) =>
  state.allFuehrungsebenen.filter(
    (fuehrungsebene) =>
      fuehrungsebene.typ === Fuehrungsebenentyp.Grundschutz &&
      fuehrungsebene.parentFuehrungsebeneId === state.currentFuehrungsebene?.id
  )
);

/**
 * Liefert alle Örtliche-Einheiten-Fuehrungsebenen des CurrentFuehrungsebenes
 */
export const currentOertlicheEinheitenSelector = createSelector(selectFeature, (state) =>
  state.allFuehrungsebenen.filter(
    (fuehrungsebene) =>
      fuehrungsebene.typ === Fuehrungsebenentyp.Oertlich &&
      fuehrungsebene.parentFuehrungsebeneId === state.currentFuehrungsebene?.id
  )
);

/**
 * Liefert alle Fuehrungsebenen vom Typ Leitstelle
 */
export const leitstellenSelector = createSelector(selectFeature, (state) =>
  state.allFuehrungsebenen.filter((fuehrungsebene) => fuehrungsebene.typ === Fuehrungsebenentyp.Leitstelle)
);

/**
 * Liefert alle Fuehrungsebenen vom Typ PolitischGesamtverantwortlicher
 */
export const politischGesamtverantwortlichSelector = createSelector(selectFeature, (state) =>
  state.allFuehrungsebenen.filter(
    (fuehrungsebene) => fuehrungsebene.typ === Fuehrungsebenentyp.PolitischGesamtverantwortlicher
  )
);

/**
 * Liefert alle Fuehrungsebenen vom Typ AdministrativOrganisatorisch
 */
export const administrativOrganisatorischSelector = createSelector(selectFeature, (state) =>
  state.allFuehrungsebenen.filter(
    (fuehrungsebene) => fuehrungsebene.typ === Fuehrungsebenentyp.AdministrativOrganisatorisch
  )
);

/**
 * Liefert alle Fuehrungsebenen vom Typ OperativTaktisch
 */
export const operativTaktischSelector = createSelector(selectFeature, (state) =>
  state.allFuehrungsebenen.filter((fuehrungsebene) => fuehrungsebene.typ === Fuehrungsebenentyp.OperativTaktisch)
);

export const fuehrungsebeneErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const personenuebersichtSelector = createSelector(selectFeature, (state) => state.personenuebersicht);
export const fuehrungsebeneByIdSelector = (fuehrungsebeneId: string) =>
  createSelector(selectFeature, (state) =>
    state.allFuehrungsebenen.find((fuehrungsebene) => fuehrungsebene.id === fuehrungsebeneId)
  );
export const fuehrungsebenenLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);
export const personenuebersichtLageSelector = createSelector(selectFeature, (state) => state.personenuebersichtLage);
export const fuehrungsebeneUebersichtSelector = createSelector(
  selectFeature,
  (state) => state.fuehrungsebeneUebersichtDTO
);
