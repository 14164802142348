/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TaktischesZeichenStatus = 'ANGEFORDERT' | 'ALARMIERT' | 'AUF_MARSCH' | 'IN_FUEHRUNGSEBENE';

export const TaktischesZeichenStatus = {
    Angefordert: 'ANGEFORDERT' as TaktischesZeichenStatus,
    Alarmiert: 'ALARMIERT' as TaktischesZeichenStatus,
    AufMarsch: 'AUF_MARSCH' as TaktischesZeichenStatus,
    InFuehrungsebene: 'IN_FUEHRUNGSEBENE' as TaktischesZeichenStatus
};

