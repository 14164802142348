import { TextFieldModule } from '@angular/cdk/text-field';
import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, FormGroup, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorService } from '@product/ise-error-lib';

// Nach: https://www.sidion.de/lernen/sidion-labor/blog/erfahrungen-mit-und-implementierung-von-custom-field-komponenten-custom-form-fields-in-angular-material.html

/**
 * Einfaches Textfeld (`mat-form-field` mit `input`) für Angular-Formulare, erweritert um einen Leeren/Löschen-Button.
 * Der Button blendet sich ein, wenn ein Wert gesetzt und die Maus über dem Feld ist.
 *
 */
@Component({
  selector: 'app-cleanable-form-field',
  templateUrl: './cleanable-form-field.component.html',
  styleUrls: ['./cleanable-form-field.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    TextFieldModule,
    MatButtonModule,
    NgClass,
    MatIconModule,
  ],
})
export class CleanableFormFieldComponent implements ControlValueAccessor {
  /** Bezeichnung/Beschriftung des Feldes */
  @Input() label!: string;
  /** Name des zugehörigen 'FormControl' */
  @Input() formControlName!: string;
  /** `FormGroup` in des das Feld verwendet wird */
  @Input() formGroup!: FormGroup;

  /** Optionale Maximallänge des Input-Feldes  */
  @Input() maxLength?: number | null = null;

  @Input() useTextarea? = false;

  @Input() textAreaRows = 5;

  @Input() placeholder?: string;

  @Input() tooltip?: string;

  @Input() type?: string;

  mouseover = false;
  value = '';
  placeholderValue: string;

  constructor(public ngControl: NgControl, private errorService: ErrorService) {
    this.ngControl.valueAccessor = this;

    this.placeholderValue = this.placeholder || '';
  }

  writeValue(value: string): void {
    this.value = value;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  // ------------

  onChange = () => {
    // Kann überschrieben werden
  };

  onTouched = () => {
    // Kann überschrieben werden
  };

  getErrorMessage(formControlName: string): string {
    const formControl = this.formGroup.get(formControlName);
    if (!formControl) {
      return '';
    }
    return this.errorService.getErrorMessage(formControl.errors);
  }
}
