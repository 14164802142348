import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, throwError } from 'rxjs';
import {
  EinsatztagebucheintragDTO,
  EinsatztagebuchSelectDTO,
  ErrorResponse,
  LageEinsatztagebuchResourceService,
} from 'src/app/api/build/openapi';
import { lageActions } from 'src/app/lagedarstellung/lagen/+state/lage.actions';
import { einsatztagebuchActions } from './einsatztagebuch.actions';
import { defaultEinsatztagebuchFilter } from '../einsatztagebuch.service';

@Injectable()
export class EinsatztagebuchEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Führungsebenen anstoßen
   *
   * FIXME: muss derzeit beim Welchsel einer Lage getriggert werden, damit das Anmelden für entsprechende Events korrekt abläuft. -> https://ise-online.atlassian.net/browse/LAGE-791
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return einsatztagebuchActions.getEinsatztagebuch({
            lageId: props.currentLage.id,
            filter: defaultEinsatztagebuchFilter,
          });
        } else {
          return einsatztagebuchActions.resetStore();
        }
      })
    )
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(einsatztagebuchActions.getEinsatztagebuch),
      switchMap((props) =>
        this.einsatztagebuchResourceService.getEinsatztagebuchEintraege(props.lageId, props.filter).pipe(
          map((eintraege) =>
            einsatztagebuchActions.getEinsatztagebuchSuccess({ eintraege: eintraege, lageId: props.lageId })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(einsatztagebuchActions.getEinsatztagebuchFailure({ errorResponse }));
          })
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(einsatztagebuchActions.createEinsatztagebucheintrag),
      map((action) => action.newEintrag),
      switchMap((newEintrag: EinsatztagebucheintragDTO) => {
        if (!newEintrag.sourceId) {
          return throwError(() => new Error('Tagebucheintrag braucht LageId'));
        }
        return this.einsatztagebuchResourceService.createEinsatztagebucheintrag(newEintrag.sourceId, newEintrag).pipe(
          map((newEintrag) => einsatztagebuchActions.createEinsatztagebucheintragSuccess({ newEintrag })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(einsatztagebuchActions.createEinsatztagebucheintragFailure({ errorResponse }));
          })
        );
      })
    )
  );

  patch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(einsatztagebuchActions.patchEinsatztagebucheintrag),
      map((action) => action.patchedEintrag),
      switchMap((patchedEintrag: EinsatztagebucheintragDTO) => {
        if (!patchedEintrag.id || !patchedEintrag.sourceId) {
          throw Error('Tagebucheintrag braucht ID und LageId');
        }
        return this.einsatztagebuchResourceService
          .patchEinsatztagebucheintrag(patchedEintrag.id, patchedEintrag.sourceId, patchedEintrag)
          .pipe(
            map((patchedEintrag) => einsatztagebuchActions.patchEinsatztagebucheintragSuccess({ patchedEintrag })),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(einsatztagebuchActions.patchEinsatztagebucheintragFailure({ errorResponse }));
            })
          );
      })
    )
  );

  constructor(private actions$: Actions, private einsatztagebuchResourceService: LageEinsatztagebuchResourceService) {}
}
