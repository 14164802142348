<div mat-dialog-title>AdHoc-Information senden</div>

<mat-dialog-content>
  <div class="flex-col flex-1 mt-1">
    <app-chip-fixed-list-input label="Empfänger" itemName="Empfänger" [control]="fcEmpfaenger" [options]="options" />
    <mat-form-field>
      <mat-label>Betreff</mat-label>
      <input matInput [formControl]="fcBetreff" />
      <mat-error>{{ getErrorMessage(fcBetreff) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Nachricht</mat-label>
      <textarea matInput rows="6" [formControl]="fcNachricht"></textarea>
      <mat-error>{{ getErrorMessage(fcNachricht) }}</mat-error>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button matDialogClose>Abburch</button>
  <button mat-raised-button color="accent" (click)="sendAdHocInfo()">Senden</button>
</mat-dialog-actions>
