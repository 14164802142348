import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ErrorService } from '@product/ise-error-lib';
import { TaktischesZeichenTyp, TierschadenDTO } from 'src/app/api/build/openapi';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { IconService } from 'src/app/shared/services/icon.service';
import { erzeugeTaktischesZeichen, TaktischesZeichen } from 'taktische-zeichen-core';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';
import { TierschadenSubset } from '../tierschaden-subset.interface';
import { TierschadenSubsetComponent } from '../tierschaden-subset/tierschaden-subset.component';

@Component({
  selector: 'app-tierschaden-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    CleanableFormFieldComponent,
    TierschadenSubsetComponent,
  ],
  templateUrl: './tierschaden-form.component.html',
  styleUrls: ['./tierschaden-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TierschadenFormComponent implements TaktischesZeichenForm {
  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  iconDataUrlChanged = new EventEmitter<string>();

  @ViewChild('tierschadenComponent')
  tierschadenComponent!: TierschadenSubsetComponent;

  @ViewChild('tierschadenEkComponent')
  tierschadenEkComponent!: TierschadenSubsetComponent;

  readonly DEFAULT_ICON_DATA: TaktischesZeichen = { symbol: 'veterinaerwesen' };
  iconData: TaktischesZeichen = { ...this.DEFAULT_ICON_DATA };
  private isCustomIcon = false;
  private iconDataUrl?: string;

  dtoToEdit?: TierschadenDTO;

  private errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);
  private iconService = inject(IconService);

  fcAnzeigename = this.formBuilder.control('', [Validators.maxLength(30), Validators.required]);
  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
  });

  tierschaden: TierschadenSubset = {
    verletzt: 0,
    tot: 0,
    betroffen: 0,
  };

  tierschadenEk: TierschadenSubset = {
    verletzt: 0,
    tot: 0,
    betroffen: 0,
  };

  constructor() {
    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v?.trim());
    });
  }

  setDTO(tierschadenDto: TaktischesZeichenDTO) {
    if (!tierschadenDto) {
      this.errorService.showErrorMessage('Kein Model zum Bearbeiten vorhanden.');
      return;
    }

    // Sonderfall: Anlegen über Karte braucht direkt ein Temp-Icon
    if (!tierschadenDto.id) {
      this.iconDataUrlChanged.emit(erzeugeTaktischesZeichen(this.DEFAULT_ICON_DATA).dataUrl);
    }

    this.dtoToEdit = tierschadenDto as TierschadenDTO;
    this.dtoToFormGroup(this.dtoToEdit);
    this.handleIconDataChanged();
  }

  /**
   * Aktualisiertes DTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): TierschadenDTO | null {
    if (!this.formGroup.valid || !this.tierschadenComponent.isValid() || !this.tierschadenEkComponent.isValid()) {
      this.formGroup.markAllAsTouched();
      return null;
    }

    const tierschaden = this.tierschadenComponent.getDto();
    const tierschadenEk = this.tierschadenEkComponent.getDto();

    return {
      ...this.dtoToEdit,
      customZeichen: this.isCustomIcon,
      dataUrl: this.iconDataUrl,
      anzeigename: this.fcAnzeigename?.value.trim(),
      tierVerletzt: tierschaden.verletzt,
      tierTot: tierschaden.tot,
      tierBetroffen: tierschaden.betroffen,
      ekVerletzt: tierschadenEk.verletzt,
      ekTot: tierschadenEk.tot,
      ekBetroffen: tierschadenEk.betroffen,
      typ: TaktischesZeichenTyp.Tierschaden,
    };
  }

  /**
   * FormGroup mit Werten aus PersonDTO füllen
   */
  dtoToFormGroup(dto: TierschadenDTO): void {
    this.isCustomIcon = dto.customZeichen || false;
    this.iconDataUrl = dto.dataUrl;

    this.fcAnzeigename.setValue(dto.anzeigename);
    this.tierschaden = {
      verletzt: dto.tierVerletzt || 0,
      tot: dto.tierTot || 0,
      betroffen: dto.tierBetroffen || 0,
    };

    this.tierschadenEk = {
      verletzt: dto.ekVerletzt || 0,
      tot: dto.ekTot || 0,
      betroffen: dto.ekBetroffen || 0,
    };
  }

  private handleIconDataChanged(): void {
    if (!this.isCustomIcon) {
      this.iconService.generateCompressedDataUrl(this.iconData).subscribe((dataUrl) => {
        this.iconDataUrl = dataUrl;
        this.iconDataUrlChanged.emit(this.iconDataUrl);
      });
    }
  }

  public setCustomIcon(iconDataUrl: string): void {
    this.isCustomIcon = true;
    this.iconDataUrl = iconDataUrl;
  }

  public removeCustomIcon(): void {
    this.isCustomIcon = false;
    this.handleIconDataChanged();
  }
}
