import { AsyncPipe, NgFor, NgIf, SlicePipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { ErrorService } from '@product/ise-error-lib';
import { combineLatest, debounceTime, Observable } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { ApplicationType, FuehrungsebeneDTO, Fuehrungsebenentyp, LageDTO } from 'src/app/api/build/openapi';
import { AppService } from 'src/app/app.service';
import { LoadableComponentComponent } from 'src/app/shared/components/loadable-component/loadable-component.component';
import { TaktischeZeichenService } from '../../taktische-zeichen/taktische-zeichen.service';
import { BaseMapComponent } from '../base-map/base-map.component';
import { FuehrungsebeneHierarchieTreeComponent } from '../fuehrungsebene-hierarchie/fuehrungsebene-hierarchie-tree/fuehrungsebene-hierarchie-tree.component';
import {
  childFuehrungsebenenSelector,
  currentFuehrungsebeneSelector,
  isLoadingFuehrungsebeneSelector,
} from '../fuehrungsebene/+state/fuehrungsebene.selectors';
import { FuehrungsebeneCardComponent } from '../fuehrungsebene/fuehrungsebene-card/fuehrungsebene-card.component';
import { FuehrungsebeneChildCounterComponent } from '../fuehrungsebene/fuehrungsebene-child-counter/fuehrungsebene-child-counter.component';
import {
  FuehrungsebeneDetailsDialogComponent,
  FuehrungsebeneDialogData,
} from '../fuehrungsebene/fuehrungsebene-details-dialog/fuehrungsebene-details-dialog.component';
import { FuehrungsebeneDisplayService } from '../fuehrungsebene/fuehrungsebene-display.service';
import { FuehrungsebeneHeaderComponent } from '../fuehrungsebene/fuehrungsebene-header/fuehrungsebene-header.component';
import { FuehrungsebeneVisibilityDialogComponent } from '../fuehrungsebene/fuehrungsebene-visibility-dialog/fuehrungsebene-visibility-dialog.component';
import { FuehrungsebeneService } from '../fuehrungsebene/fuehrungsebene.service';
import { LogistikSmallComponent } from '../fuehrungsebene/logistik/logistik-small/logistik-small.component';
import { LagedarstellungSidebarToolsComponent } from '../lagedarstellung-sidebar-tools/lagedarstellung-sidebar-tools.component';
import {
  LageDetailsDialogComponent,
  LageDialogInputData,
} from '../lagen/lage-details-dialog/lage-details-dialog.component';
import { LagezeitComponent } from '../lagezeit/lagezeit.component';
import { LayerService } from '../layer.service';
import { TaktischeZeichenContainerComponent } from '../taktische-zeichen/taktische-zeichen-container/taktische-zeichen-container.component';
import { ToolService } from '../tool.service';
import { PersonenuebersichtComponent } from '../triage/personenuebersicht/personenuebersicht.component';
import { LagedarstellungWetterAnzeigeComponent } from '../wetter/lagedarstellung-wetter-anzeige/lagedarstellung-wetter-anzeige.component';

/**
 * Ansicht für den gesamten mittleren Teil der Lagewand.
 * Enthält die Lagekarte, Einsatzabschnitte und Custom-Felder die zukünftig noch folgen.
 */
@Component({
  selector: 'app-lage-map',
  templateUrl: './lage-map.component.html',
  styleUrls: ['./lage-map.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    PersonenuebersichtComponent,
    NgIf,
    FuehrungsebeneHeaderComponent,
    TaktischeZeichenContainerComponent,
    LagedarstellungWetterAnzeigeComponent,
    LogistikSmallComponent,
    MatIconModule,
    MatTooltipModule,
    MatToolbarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    NgFor,
    FuehrungsebeneCardComponent,
    MatButtonToggleModule,
    LagedarstellungSidebarToolsComponent,
    BaseMapComponent,
    FuehrungsebeneHierarchieTreeComponent,
    AsyncPipe,
    SlicePipe,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    LoadableComponentComponent,
    LagezeitComponent,
    FuehrungsebeneChildCounterComponent,
  ],
})
export class LageMapComponent {
  ApplicationType = ApplicationType;
  Fuehrungsebenentyp = Fuehrungsebenentyp;

  private appService = inject(AppService);
  applicationType$ = this.appService.selectedApplicationObservable();
  private errorService = inject(ErrorService);

  currentFuehrungsebene: FuehrungsebeneDTO | null = null;

  // Sammlungen/Zählungen von ChildFuehrungsebenenn und der Auswahl angezeigter Fuehrungsebenen daraus
  childMainFuehrungsebenen: FuehrungsebeneDTO[] = [];

  // Maximal 6 Fuehrungsebenen dürfen links und rechts neben der Karte angezeigt werden
  readonly maxDisplayedFuehrungsebeneCount = 6;
  displayedFuehrungsebenen: FuehrungsebeneDTO[] = [];
  headers$ = this.fuehrungsebeneDisplayService.headers$;

  // True, wenn Custom-Elemente ausgeblendet werden sollen
  fullscreen = false;
  customCollapsed = false;
  leftCollapsed = false;
  rightCollapsed = false;

  isLoadingFuehrungsebenen$: Observable<boolean>;

  constructor(
    protected layerService: LayerService,
    protected toolService: ToolService,
    protected store: Store<AppStateInterface>,
    private dialog: MatDialog,
    protected taktischeZeichenService: TaktischeZeichenService,
    private fuehrungsebeneService: FuehrungsebeneService,
    private fuehrungsebeneDisplayService: FuehrungsebeneDisplayService
  ) {
    this.isLoadingFuehrungsebenen$ = this.store
      .select(isLoadingFuehrungsebeneSelector)
      .pipe(takeUntilDestroyed(), debounceTime(500));

    combineLatest([
      store.select(currentFuehrungsebeneSelector),
      store.select(childFuehrungsebenenSelector),
      fuehrungsebeneDisplayService.displayedFuehrungsebenen$,
    ])
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.currentFuehrungsebene = value[0];
        this.childMainFuehrungsebenen = value[1].filter((child) =>
          FuehrungsebeneService.MAIN_FUEHRUNGSEBENENTYPEN.includes(child.typ)
        );
        this.displayedFuehrungsebenen = value[2];
      });
  }

  toggleCollapsedCustom() {
    this.fullscreen = false;
    this.customCollapsed = !this.customCollapsed;
    setTimeout(() => window.dispatchEvent(new Event('resize')), 250);
  }

  setCollapsedLeft(collapsed: boolean) {
    this.fullscreen = false;
    this.leftCollapsed = collapsed;
    setTimeout(() => window.dispatchEvent(new Event('resize')), 250);
  }

  toggleCollapsedLeft() {
    this.setCollapsedLeft(!this.leftCollapsed);
  }

  setCollapsedRight(collapsed: boolean) {
    this.fullscreen = false;
    this.rightCollapsed = collapsed;
    setTimeout(() => window.dispatchEvent(new Event('resize')), 250);
  }

  toggleCollapsedRight() {
    this.setCollapsedRight(!this.rightCollapsed);
  }

  setFullscreen(fullscreen: boolean) {
    this.fullscreen = fullscreen;
    this.customCollapsed = fullscreen;
    this.rightCollapsed = fullscreen;
    this.leftCollapsed = fullscreen;
    setTimeout(() => window.dispatchEvent(new Event('resize')), 250);
  }

  tryCreateFuehrungsebene() {
    this.fuehrungsebeneService.getAllowedChildTypes().subscribe((allowedTypes) => {
      const currentFuehrungsbene = this.fuehrungsebeneService.getCurrentFuehrungsebene();
      if (!currentFuehrungsbene) {
        return;
      }
      const allowedMainTypes = allowedTypes.filter((allowedType) =>
        FuehrungsebeneService.MAIN_FUEHRUNGSEBENENTYPEN.includes(allowedType)
      );
      if (!allowedMainTypes.length) {
        this.errorService.showErrorMessage(
          `Es dürfen keine weiteren Führungsebenen unter '${currentFuehrungsbene.name}' angelegt werden.`
        );
        return;
      }

      const preparedFuehrungsebene = this.fuehrungsebeneService.prepareNewChildFuehrungsebene(currentFuehrungsbene);
      if (preparedFuehrungsebene) {
        const inputData: FuehrungsebeneDialogData = {
          fuehrungsebeneDTO: preparedFuehrungsebene,
          filteredTypes: allowedMainTypes,
        };
        this.dialog.open(FuehrungsebeneDetailsDialogComponent, {
          data: inputData,
        });
      }
    });
  }

  editFuehrungsebene(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    this.openFuehrungsebeneDetails({ ...fuehrungsebeneDTO });
  }

  private openFuehrungsebeneDetails(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    if (fuehrungsebeneDTO.typ === Fuehrungsebenentyp.Lage) {
      const inputData: LageDialogInputData = {
        lageDto: { ...fuehrungsebeneDTO } as LageDTO,
      };
      this.dialog.open(LageDetailsDialogComponent, {
        data: inputData,
      });
    } else {
      const inputData: FuehrungsebeneDialogData = {
        fuehrungsebeneDTO: { ...fuehrungsebeneDTO },
        filteredTypes: FuehrungsebeneService.MAIN_FUEHRUNGSEBENENTYPEN,
      };
      this.dialog.open(FuehrungsebeneDetailsDialogComponent, {
        data: inputData,
      });
    }
  }

  openFuehrungsebeneVisibilityDialog() {
    this.dialog.open(FuehrungsebeneVisibilityDialogComponent);
  }
}
