<mat-toolbar>
  <h2 class="toolbar-center">
    {{ "Lage" + (lageDto.id ? " bearbeiten" : " hinzufügen") }}
    <mat-icon *ngIf="lageDto.lageStatus === LageStatus.Beendet" matTooltip="Lage ist beendet">lock</mat-icon>
  </h2>
</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="formGroup" class="left-side">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput required="true" [formControl]="formGroup.controls.name" />
      <mat-error>{{ getErrorMessage(formGroup.controls.name) }}</mat-error>
    </mat-form-field>

    <app-chip-list-input
      [control]="formGroup.controls.stichworte"
      label="Stichworte"
      itemName="Stichworte"
      class="mb-1"
    />

    <mat-form-field>
      <mat-label>Startzeit</mat-label>
      <input matInput type="datetime-local" [formControl]="formGroup.controls.startedOn" dateTimeLocalAsDate />
      <mat-error>{{ getErrorMessage(formGroup.controls.startedOn) }}</mat-error>
    </mat-form-field>
  </form>

  <div class="right-side">
    <mat-card
      class="zeichen-container"
      (mouseover)="isCustomZeichenHovered = true"
      (mouseleave)="isCustomZeichenHovered = false"
    >
      <img height="100px" [src]="zeichenDataUrl" />
      <mat-card *ngIf="customZeichen" class="custom-batch">überschrieben</mat-card>
      <div *ngIf="isCustomZeichenHovered" class="edit-button-container">
        <button mat-icon-button (click)="openCustomZeichenDialog()">
          <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf="isCustomZeichenHovered && customZeichen" mat-icon-button (click)="removeCustomZeichen()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </mat-card>

    <app-simple-map
      [feature]="feature"
      [other]="other"
      (featureChanged)="featureChanged($event)"
      (errorHint)="showError($event)"
      [ngClass]="{ 'error-border': !geometryValid }"
    />
    <mat-error *ngIf="!geometryValid">Bitte eine Koordinate oder einen Bereich festlegen</mat-error>
  </div>
</mat-dialog-content>

<div class="footer">
  <app-last-updated *ngIf="lageDto.id" [lastUpdated]="lageDto" />
  <mat-dialog-actions class="end">
    <button
      mat-raised-button
      *ngIf="lageDto.id"
      color="warn"
      (click)="endLage(lageDto.id)"
      matTooltip="Lage beenden"
      [disabled]="lageDto.lageStatus === LageStatus.Beendet || isClosingLage"
    >
      Beenden
    </button>
    <button mat-raised-button mat-dialog-close>Abbrechen</button>
    <button
      mat-raised-button
      color="accent"
      [disabled]="isClosingLage || (isSaving$ | async) === true"
      (click)="save()"
    >
      Speichern
    </button>
  </mat-dialog-actions>
</div>
