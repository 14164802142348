<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <!-- FIXME Control macht Probleme im ChipGrid -->
  <mat-chip-grid #chipGrid required>
    @for(selectedOption of control.value; track selectedOption){
    <mat-chip-row (removed)="remove(selectedOption)" [matTooltip]="selectedOption.id">
      {{ selectedOption.viewValue }}
      <button matChipRemove><mat-icon>cancel</mat-icon></button>
    </mat-chip-row>
    }
    <input
      matInput
      #filterInput
      [placeholder]="this.itemName + ' wählen...'"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      (blur)="validate()"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      @for(option of filteredOptions | async; track option ){
      <mat-option [value]="option">
        <span [matTooltip]="option.id ? '(' + option.id + ')' : ''">{{ option.viewValue }}</span>
      </mat-option>
      }
    </mat-autocomplete>
  </mat-chip-grid>
  <!-- FIXME - wird nie angezeigt ... -->
  <mat-error>{{ getErrorMessage() }} </mat-error>
</mat-form-field>
