import { Injectable, computed, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ErrorService } from '@product/ise-error-lib';
import { BehaviorSubject, EMPTY, Observable, catchError, filter, map, switchMap, take, tap } from 'rxjs';
import {
  AdHocInfoMessageDTO,
  ErrorResponse,
  EventDTO,
  GetSituationReportDTO,
  ParticipantDTO,
  ReportDTO,
  SendAdHocInfoDTO,
  SendSituationReportDTO,
  SituationReportDTO,
  SituationReportHistoryDTO,
  SituationReportSummaryDTO,
  VidalResourceService,
} from '../api/build/openapi';
import { AppService } from '../app.service';
import {
  AdhocInfoDialogComponent,
  DialogData,
  DialogResult,
} from './adhoc-info/adhoc-info-dialog/adhoc-info-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class VidalService {
  private dialog = inject(MatDialog);
  private errorService = inject(ErrorService);
  private vidalService = inject(VidalResourceService);
  private appService = inject(AppService);

  readonly isVidalActive$ = new BehaviorSubject(false);

  readonly currentIdentity = signal<ParticipantDTO | undefined>(undefined);

  public setCurrentIdentity(identity: ParticipantDTO | undefined) {
    this.currentIdentity.set(identity);
  }

  private e = effect(() => {
    const identity = this.currentIdentity();

    if (identity) {
      this.loadParticipants();
    }
  });

  constructor() {
    // hört auf Änderungen anden Application-Settings und aktualisiert ggf. die VIDaL-Config
    this.appService.applicationSettings$
      .pipe(
        takeUntilDestroyed(),
        filter((s) => s !== undefined)
      )
      .subscribe((settings) => {
        this.isVidalActive$.next(settings.vidal || false);
      });
  }

  readonly identities$: Observable<ParticipantDTO[]> = this.vidalService.listIdentities().pipe();

  participants$ = new BehaviorSubject<ParticipantDTO[] | undefined>(undefined);

  loadParticipants() {
    if (!this.currentIdentity()) {
      this.errorService.showError({
        errorMessages: ['Keine VIDaL-Identität gewählt!'],
      });
    }

    this.vidalService
      .listParticipants(this.currentIdentity()!.oid)
      .pipe(
        catchError((error: ErrorResponse) => {
          this.errorService.showError({
            errorMessages: ['Teilnehmer können nicht abgerufen werden.', ...(error.errorMessages || '')],
          });
          return EMPTY;
        })
      )
      .subscribe((v) => {
        this.participants$.next(v);
      });
  }

  // bisher erhaltene AdHoc-Infos
  public adhocInfos = signal<AdHocInfoMessageDTO[]>([]);

  /*
   * Meldungen / SituationReport
   
   */

  private situationReportHistories = signal<SituationReportHistoryDTO[]>([]);
  readonly mySituationReportHistories = computed(() =>
    this.situationReportHistories().filter((history) => history.own)
  );
  readonly otherSituationReportHistories = computed(() =>
    this.situationReportHistories().filter((history) => !history.own)
  );

  readonly selectedSituationReportHistory = signal<SituationReportHistoryDTO | undefined>(undefined);
  private selectedHistoryEffect = effect(() => {
    if (this.selectedSituationReportHistory()) {
      this.getSituationReportSummaries(this.selectedSituationReportHistory()!.rootId);
    }
  });

  readonly situationReportSummaries = signal<SituationReportSummaryDTO[]>([]);
  private selectedSituationReportSummary = signal<SituationReportSummaryDTO | undefined>(undefined);
  private selectedSituationReportSummaryEffect = effect(() => {
    if (this.selectedSituationReportSummary()?.id) {
      this.getSituationReport(this.selectedSituationReportSummary()!.id);
    }
  });

  readonly selectedSituationReport = signal<GetSituationReportDTO | undefined>(undefined);

  readonly newSituationReport = signal<SituationReportDTO | undefined>(undefined);
  readonly writeNewSituationReport = signal<boolean>(false);

  /**
   * Fügt eine neue AdHoc-Info ein
   * @param adhocInfo
   */
  addAdHocInfo(adhocInfo: AdHocInfoMessageDTO) {
    this.adhocInfos.update((values) => [...values, adhocInfo]);
  }

  /**
   * Öffnet einen Dialog zum Schreiben einer AdHoc-Info und schickt diese.
   *
   * @param recipient optilal - vorausgewählter Empfänger, der aber im Dialog geändert werden kann
   * @returns
   */
  sendAdHocInformation(recipient?: ParticipantDTO) {
    return this.participants$.pipe(
      take(1),
      map((options) => (recipient ? { options, preset: [recipient] } : ({ options } as DialogData))),
      switchMap((data) => {
        return this.dialog
          .open(AdhocInfoDialogComponent, { data, autoFocus: false, width: '40%' })
          .afterClosed()
          .pipe(
            switchMap((v) => {
              const result = v as DialogResult;
              if (result) {
                const dto: SendAdHocInfoDTO = {
                  senderOid: this.currentIdentity()?.oid,
                  participantOidList: result.recipientsOids,
                  subject: result.subject,
                  text: result.message,
                };

                return this.vidalService.sendAdHocInfo(dto).pipe(
                  catchError((error: ErrorResponse) => {
                    this.errorService.showError(error);
                    return EMPTY;
                  })
                );
              }
              return EMPTY;
            })
          );
      })
    );
  }

  /** Holt und setzt AdHoc-Infos neu. */
  getAndSetAdHocInfos(oid: string | undefined = undefined) {
    if (!oid) {
      oid = this.currentIdentity()?.oid;
      console.log('using currentIdentityOid', oid);
    }

    if (this.isVidalActive$.getValue()) {
      if (!oid) {
        this.errorService.showError({
          errorMessages: ['AdHoc-Infos können nicht abgerufen werden.', 'VIDaL OID nicht konfiguriert!'],
        });
        return;
      }

      this.vidalService
        .listAdHocInfos(oid)
        .pipe(
          catchError((error: ErrorResponse) => {
            console.error(error);
            this.errorService.showError({
              errorMessages: ['Fehler beim Abrufen von VIDaL AdHoc-Infos', ...(error.errorMessages || '')],
            });
            return EMPTY;
          })
        )
        .subscribe((infos) => {
          this.adhocInfos.update((_) => [...infos] as AdHocInfoMessageDTO[]);
        });
    }
  }

  /* -------------------------
   * Meldungen
   * -------------------------
   */

  getSituationReportHistories() {
    const oid = this.currentIdentity()?.oid;

    if (this.isVidalActive$.getValue()) {
      if (!oid) {
        this.errorService.showError({
          errorMessages: ['Meldungshistorie kann nicht abgerufen werden.', 'VIDaL OID nicht konfiguriert!'],
        });
        return;
      }

      this.vidalService
        .listSituationReportHistories(oid)
        .pipe(
          catchError((error: ErrorResponse) => {
            console.error(error);
            this.errorService.showError({
              errorMessages: ['Fehler beim Abrufen der Meldungshistorie', ...(error.errorMessages || '')],
            });
            return EMPTY;
          }),
          tap(console.log)
        )
        .subscribe((histories) => {
          this.situationReportHistories.update((_) => [...histories] as SituationReportHistoryDTO[]);
        });
    }
  }

  setSelectedSituationReportHistory(selectedHistory: SituationReportHistoryDTO) {
    this.selectedSituationReportHistory.update((_) => selectedHistory);
  }

  getSituationReportSummaries(rootId: string) {
    const oid = this.currentIdentity()?.oid;

    if (this.isVidalActive$.getValue()) {
      if (!oid) {
        this.errorService.showError({
          errorMessages: ['Meldungsverlauf kann nicht abgerufen werden.', 'VIDaL OID nicht konfiguriert!'],
        });
        return;
      }

      this.vidalService
        .listSituationReportSummaries(oid, rootId)
        .pipe(
          catchError((error: ErrorResponse) => {
            console.error(error);
            this.errorService.showError({
              errorMessages: ['Fehler beim Abrufen des Meldungsverlaufs', ...(error.errorMessages || '')],
            });
            return EMPTY;
          })
        )
        .subscribe((summaries) => {
          console.log(summaries);
          this.situationReportSummaries.update((_) => [...summaries] as SituationReportSummaryDTO[]);
        });
    }
  }

  setSelectedSituationReportSummary(selectedSummary: SituationReportSummaryDTO) {
    this.selectedSituationReportSummary.update((_) => selectedSummary);
  }

  private getSituationReport(reportId: string) {
    const oid = this.currentIdentity()?.oid;
    console.log('getSituationReportOid', oid);

    if (this.isVidalActive$.getValue()) {
      if (!oid) {
        this.errorService.showError({
          errorMessages: ['Meldung kann nicht abgerufen werden.', 'VIDaL OID nicht konfiguriert!'],
        });
        return;
      }

      this.vidalService
        .getSituationReport(oid, reportId)
        .pipe(
          catchError((error: ErrorResponse) => {
            console.error(error);
            this.errorService.showError({
              errorMessages: ['Fehler beim Abrufen der Meldung', ...(error.errorMessages || '')],
            });
            return EMPTY;
          })
        )
        .subscribe((situationReport) => {
          console.log(situationReport);
          this.selectedSituationReport.update((_) => situationReport);
        });
    }
  }

  /** Meldungsformular leeren */
  public clearSelectedSituationReport() {
    this.selectedSituationReport.update((_) => ({} as GetSituationReportDTO));
  }

  public clearNewSituationReport() {
    this.newSituationReport.update(() => undefined);
  }

  /**
   * Neuen Meldungsverlauf erzeugen
   */
  public createNewSituationReportHistory() {
    const newSituationReport = {} as SituationReportDTO;
    newSituationReport.report = { sequence: 0 } as ReportDTO;

    this.newSituationReport.update(() => newSituationReport);
  }

  public addNewSituationReportToCurrentHistory() {
    const lastSummary = this.situationReportSummaries()[this.situationReportSummaries().length - 1];

    const newSituationReport = {} as SituationReportDTO;
    newSituationReport.report = {
      sequence: lastSummary.sequence + 1,
      rootId: lastSummary.rootId,
    } as ReportDTO;
    newSituationReport.event = {
      locationAgs: lastSummary.locationAgs,
      type: { code: lastSummary.typeCode },
      timestamp: lastSummary.eventTimestamp,
    } as EventDTO;

    this.newSituationReport.update(() => newSituationReport);
  }

  public sendSituationReport(participants: ParticipantDTO[], situationReport: SituationReportDTO) {
    const oid = this.currentIdentity()?.oid;
    const participantOids = participants.map((p) => p.oid);

    const sendSituationReport = {
      senderOid: oid,
      participantOidList: participantOids,
      report: situationReport,
    } as SendSituationReportDTO;
    console.log('before send', sendSituationReport);

    this.vidalService
      .sendSituationReport(sendSituationReport)
      .pipe(
        catchError((error: ErrorResponse) => {
          this.errorService.showError({ errorMessages: ['Meldung konnte nicht versendet werden.'] });
          console.error('SituationReport Error', error);
          return EMPTY;
        })
      )
      .subscribe(console.log);
  }
}
