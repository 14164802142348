import { Pipe, PipeTransform } from '@angular/core';
import { TaktischesZeichenStatus } from 'src/app/api/build/openapi';

@Pipe({
  name: 'taktischesZeichenStatus',
  standalone: true,
})
export class TaktischesZeichenStatusPipe implements PipeTransform {
  transform(tzStatus?: TaktischesZeichenStatus): string {
    if (!tzStatus) {
      return '';
    }

    switch (tzStatus) {
      case TaktischesZeichenStatus.Alarmiert:
        return 'Alarmiert';
      case TaktischesZeichenStatus.Angefordert:
        return 'Angefordert';
      case TaktischesZeichenStatus.AufMarsch:
        return 'Auf Marsch';
      case TaktischesZeichenStatus.InFuehrungsebene:
        return 'In Führungsebene';
      default:
        return '';
    }
  }
}
