import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorService } from '@product/ise-error-lib';
import { finalize, map, Observable, of, switchMap } from 'rxjs';
import { LageBeendenDTO, LageDTO, LageResourceService } from 'src/app/api/build/openapi';
import { LageHeaderHelper } from 'src/app/shared/lage-header-helper';
import { FuehrungsebeneService } from '../fuehrungsebene/fuehrungsebene.service';
import { DialogResult, LageEndDialogComponent } from './lage-end-dialog/lage-end-dialog.component';

/**
 * Service, der die aktuell ausgewählte Lage beendet.
 *
 * Hinweis: es wird die aktuelle Lage genommen und keine übergebene, da deren ID auch im Header (passiert via Interceptor) gessetzt sein muss.
 * Um eine Lage zu bearbeiten (und zu beenden), muss diese in der Liste ausgewählt sein.
 */
@Injectable({
  providedIn: 'root',
})
export class LageBeendenService {
  private lageResourceService = inject(LageResourceService);
  private headerHelper = new LageHeaderHelper(this.lageResourceService);

  private lageService = inject(FuehrungsebeneService);
  private errorService = inject(ErrorService);

  private dialog = inject(MatDialog);

  public endLage$(lageId?: string): Observable<boolean> {
    let lageIdToClose = lageId;

    if (!lageIdToClose) {
      lageIdToClose = this.lageService.getCurrentLage()?.id;
    }

    if (!lageIdToClose) {
      this.errorService.showErrorMessage('Keine aktuelle Lage zum Beenden ausgewählt!');
      return of(false);
    }
    this.headerHelper.addCurrentLageHeader(lageIdToClose);
    return this.lageResourceService.beende(lageIdToClose, {}).pipe(
      switchMap((v: LageBeendenDTO) => {
        return this.dialog
          .open(LageEndDialogComponent, { data: { confirmationCode: v.confirmationCode } })
          .beforeClosed();
      }),
      switchMap((dialogResult: DialogResult) => {
        if (dialogResult) {
          return this.lageResourceService.beende(lageIdToClose, {
            confirmationCode: dialogResult.confirmationCode,
            reason: dialogResult.reason,
          });
        }
        return of(false);
      }),
      map((lageDTO: LageDTO) => {
        return lageDTO?.id ? true : false;
      }),
      finalize(() => this.headerHelper.removeCurrentLageHeader())
    );
  }
}
