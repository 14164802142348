import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorService } from '@product/ise-error-lib';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import {
  BefehlsstelleDTO,
  Einsatzweise,
  PersonDTO,
  TaktischeFormationDTO,
  TaktischeFormationNestedDTO,
  TaktischeFormationSelectableInfo,
  TaktischeFormationSelectableInfoType,
  TaktischesZeichenTyp,
} from 'src/app/api/build/openapi';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { AuftragListComponent } from 'src/app/lagedarstellung/auftrag/auftrag-list/auftrag-list.component';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { KontaktListComponent } from 'src/app/lagedarstellung/kontakt/kontakt-list/kontakt-list.component';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { IconService } from 'src/app/shared/services/icon.service';
import { TzComboboxComponent } from 'src/app/shared/tz-combobox/tz-combobox.component';
import { compareLabels } from 'src/app/taktische-zeichen/taktische-zeichen-form/taktische-zeichen-form.component';
import {
  Einheit,
  EinheitId,
  Fachaufgabe,
  GrundzeichenId,
  Organisation,
  Symbol,
  TaktischesZeichen,
  Verwaltungsstufe,
  einheiten,
  erzeugeTaktischesZeichen,
  fachaufgaben,
  organisationen,
  symbole,
  verwaltungsstufen,
} from 'taktische-zeichen-core';
import { Mannschaft, MannschaftFormComponent } from '../../mannschaft-form/mannschaft-form.component';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';
import {
  TaktischeFormationDialogComponent,
  TaktischeFormationDialogInputData,
} from '../taktische-formation-dialog/taktische-formation-dialog.component';
import { TaktischeFormationService } from '../taktische-formation.service';

@Component({
  selector: 'app-taktische-formation-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    KontaktListComponent,
    AuftragListComponent,
    CleanableFormFieldComponent,
    TzComboboxComponent,
    MatTooltipModule,
    MatDialogModule,
    MatInputModule,
    MatRadioModule,
    MannschaftFormComponent,
  ],
  templateUrl: './taktische-formation-form.component.html',
  styleUrls: ['./taktische-formation-form.component.scss'],
})
export class TaktischeFormationFormComponent implements TaktischesZeichenForm {
  @ViewChild(MannschaftFormComponent)
  mannschaftForm!: MannschaftFormComponent;

  @ViewChild(KontaktListComponent)
  kontaktList!: KontaktListComponent;

  @ViewChild(AuftragListComponent)
  auftragList!: AuftragListComponent;

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  iconDataUrlChanged = new EventEmitter<string>();

  dtoToEdit?: TaktischeFormationNestedDTO;

  Einsatzweise = Einsatzweise;

  readonly DEFAULT_ICON_DATA: TaktischesZeichen = { grundzeichen: 'taktische-formation' };
  iconData: TaktischesZeichen = { ...this.DEFAULT_ICON_DATA };
  private isCustomIcon = false;
  private iconDataUrl?: string;

  // Dropdown-Werte
  organisationValues: Organisation[] = organisationen.sort(compareLabels);
  fachaufgabeValues: Fachaufgabe[] = fachaufgaben.sort(compareLabels);
  groessenordnungValues: Einheit[] = einheiten.sort(compareLabels);
  verwaltungsstufeValues: Verwaltungsstufe[] = verwaltungsstufen.sort(compareLabels);
  symbolValues: Symbol[] = symbole.sort(compareLabels);

  einheitsfuehrerValues$: Observable<PersonDTO[]> = of([]);
  fuehrungseinheitValues$: Observable<TaktischeFormationDTO[]> = of([]);
  befehlsstelleValues$: Observable<BefehlsstelleDTO[]> = of([]);

  organisationMouseover = false;
  fachaufgabeMouseover = false;
  groessenordnungMouseover = false;
  verwaltungsstufeMouseover = false;
  symbolMouseover = false;
  einheitsfuehrerMouseover = false;
  fuehrungseinheitMouseover = false;
  befehlsstelleMouseover = false;
  mannschaftsstaerkeMousoever = false;
  summeFahrzeugeMouseover = false;
  summeTaktischeFormationenMouseover = false;

  private errorService = inject(ErrorService);
  private fuehrungsebeneService = inject(FuehrungsebeneService);
  private iconService = inject(IconService);
  private taktischeFormationService = inject(TaktischeFormationService);

  private formBuilder = inject(NonNullableFormBuilder);

  fcAnzeigename = this.formBuilder.control('', [Validators.maxLength(30), Validators.required]);
  fcGrundzeichenId = this.formBuilder.control<GrundzeichenId>('taktische-formation');
  fcFachaufgabe = this.formBuilder.control<Fachaufgabe | undefined>(undefined);
  fcOrganisation = this.formBuilder.control<Organisation | undefined>(undefined);
  fcGroessenordnung = this.formBuilder.control<Einheit | undefined>(undefined);
  fcVerwaltungsstufe = this.formBuilder.control<Verwaltungsstufe | undefined>(undefined);
  fcSymbol = this.formBuilder.control<Symbol | undefined>(undefined);
  fcZeichenText = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcZeichenTextName = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcTextOrganisation = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcOrtsangabe = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcEinheitsfuehrer = this.formBuilder.control<PersonDTO | undefined>(undefined);
  fcFuehrungseinheit = this.formBuilder.control<TaktischeFormationDTO | undefined>(undefined);
  fcBefehlsstelle = this.formBuilder.control<BefehlsstelleDTO | undefined>(undefined);
  fcEinsatzweise = this.formBuilder.control<Einsatzweise>(Einsatzweise.Geschlossen);

  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    grundzeichen: this.fcGrundzeichenId,
    fachaufgabe: this.fcFachaufgabe,
    organisation: this.fcOrganisation,
    groessenordnung: this.fcGroessenordnung,
    verwaltungsstufe: this.fcVerwaltungsstufe,
    symbol: this.fcSymbol,
    zeichenText: this.fcZeichenText,
    zeichenTextName: this.fcZeichenTextName,
    zeichenTextOrganisation: this.fcTextOrganisation,
    ortsangabe: this.fcOrtsangabe,
    einheitsfuehrer: this.fcEinheitsfuehrer,
    fuehrungseinheit: this.fcFuehrungseinheit,
    befehlsstelle: this.fcBefehlsstelle,
    einsatzweise: this.fcEinsatzweise,
  });

  readonly mannschaftsstarkeMap = new Map<EinheitId, number[]>([
    ['trupp', [0, 0, 3]],
    ['zugtrupp', [0, 1, 2]],
    ['staffel', [0, 1, 5]],
    ['gruppe', [0, 1, 8]],
    ['zug', [1, 3, 18]],
    ['bereitschaft', [4, 10, 59]],
    ['abteilung', [20, 30, 177]],
    ['grossverband', [68, 90, 531]],
  ]);

  private dialog = inject(MatDialog);

  constructor() {
    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v?.trim());
    });

    this.fcFachaufgabe.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.fachaufgabe = v?.id;
      this.handleIconDataChanged();
    });

    this.fcOrganisation.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.organisation = v?.id;
      this.handleIconDataChanged();
    });

    this.fcGroessenordnung.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.setMannschaftsstaerke(v?.id ? this.mannschaftsstarkeMap.get(v.id) : [0, 0, 0]);
      this.iconData.einheit = v?.id;
      this.handleIconDataChanged();
    });

    this.fcVerwaltungsstufe.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.verwaltungsstufe = v?.id;
      this.handleIconDataChanged();
    });

    this.fcSymbol.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.symbol = v?.id;
      this.handleIconDataChanged();
    });

    this.fcZeichenText.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.text = v?.trim();
      this.handleIconDataChanged();
    });

    this.fcZeichenTextName.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.name = v?.trim();
      this.handleIconDataChanged();
    });

    this.fcTextOrganisation.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.organisationName = v?.trim();
      this.handleIconDataChanged();
    });
  }

  setDTO(formationDto: TaktischesZeichenDTO) {
    if (!formationDto) {
      this.errorService.showErrorMessage('Kein Model zum Bearbeiten vorhanden.');
      return;
    }

    // Sonderfall: Anlegen über Karte braucht direkt ein Temp-Icon
    if (!formationDto.id) {
      this.iconDataUrlChanged.emit(erzeugeTaktischesZeichen(this.DEFAULT_ICON_DATA).dataUrl);
    }

    this.dtoToEdit = formationDto as TaktischeFormationNestedDTO;
    const selectableInfo: TaktischeFormationSelectableInfo = {
      type: this.dtoToEdit.id
        ? TaktischeFormationSelectableInfoType.Existing
        : this.fuehrungsebeneService.getCurrentLage()
        ? TaktischeFormationSelectableInfoType.NewForLage
        : TaktischeFormationSelectableInfoType.NewForBib,
      lageId: this.fuehrungsebeneService.getCurrentLage()?.id,
      taktischeFormationId: this.dtoToEdit.id,
    };

    this.einheitsfuehrerValues$ = this.taktischeFormationService.getSelectablePersonen(selectableInfo);
    this.fuehrungseinheitValues$ = this.taktischeFormationService.getSelectableTaktischeFormationen(selectableInfo);
    this.befehlsstelleValues$ = this.taktischeFormationService.getSelectableBefehlsstellen(selectableInfo);

    this.dtoToFormGroup(this.dtoToEdit);
    this.handleIconDataChanged();
  }

  /**
   * Aktualisiertes StelleDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): TaktischeFormationDTO | null {
    if (this.formGroup.valid && this.mannschaftForm.validate()) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * FormGroup Werte in DTO schreiben und zurückliefern
   */
  formGroupToDto(): TaktischeFormationDTO {
    const mannschaft = this.mannschaftForm.getMannschaft();

    return {
      ...this.dtoToEdit,
      customZeichen: this.isCustomIcon,
      dataUrl: this.iconDataUrl,
      anzeigename: this.fcAnzeigename.value.trim(),
      grundzeichen: this.fcGrundzeichenId.value,
      organisation: this.fcOrganisation.value?.id,
      fachaufgabe: this.fcFachaufgabe.value?.id,
      groessenordnung: this.fcGroessenordnung.value?.id,
      mannschaftsstaerke1: mannschaft.staerke1,
      mannschaftsstaerke2: mannschaft.staerke2,
      mannschaftsstaerke3: mannschaft.staerke3,
      summeFahrzeuge: mannschaft.fahrzeugCount || 0,
      summeTaktischeFormationen: mannschaft.formationCount || 0,
      verwaltungsstufe: this.fcVerwaltungsstufe.value?.id,
      symbol: this.fcSymbol.value?.id,
      zeichenText: this.fcZeichenText.value?.trim(),
      zeichenTextName: this.fcZeichenTextName.value?.trim(),
      zeichenTextOrganisation: this.fcTextOrganisation.value?.trim(),
      ortsangabe: this.fcOrtsangabe.value?.trim(),
      einheitsfuehrerId: this.fcEinheitsfuehrer.value?.id,
      fuehrungseinheitId: this.fcFuehrungseinheit.value?.id,
      befehlsstelleId: this.fcBefehlsstelle.value?.id,
      kommunikationOptionen: this.kontaktList.getKommunikationOptionen(),
      auftraege: this.auftragList.getAuftraege(),
      typ: TaktischesZeichenTyp.TaktischeFormation,
      einsatzweise: this.fcEinsatzweise.value,
    };
  }

  /**
   * FormGroup mit Werten aus DTO füllen
   */
  dtoToFormGroup(dto: TaktischeFormationNestedDTO): void {
    this.isCustomIcon = dto.customZeichen || false;
    this.iconDataUrl = dto.dataUrl;

    this.fcAnzeigename.setValue(dto.anzeigename);
    this.fcGrundzeichenId.setValue((dto.grundzeichen as GrundzeichenId) || 'taktische-formation');
    this.fcOrganisation.setValue(organisationen.find((v) => v.id === dto.organisation));
    this.fcFachaufgabe.setValue(fachaufgaben.find((v) => v.id === dto.fachaufgabe));
    this.fcGroessenordnung.setValue(einheiten.find((v) => v.id === dto.groessenordnung));
    this.fcVerwaltungsstufe.setValue(verwaltungsstufen.find((v) => v.id === dto.verwaltungsstufe));
    this.fcSymbol.setValue(symbole.find((v) => v.id === dto.symbol));
    this.fcZeichenText.setValue(dto.zeichenText);
    this.fcZeichenTextName.setValue(dto.zeichenTextName);
    this.fcTextOrganisation.setValue(dto.zeichenTextOrganisation);
    this.fcOrtsangabe.setValue(dto.ortsangabe);
    this.fcEinheitsfuehrer.setValue(dto.einheitsfuehrer);
    this.fcFuehrungseinheit.setValue(dto.fuehrungseinheit);
    this.fcBefehlsstelle.setValue(dto.befehlsstelle);
    this.auftragList.setAuftraege(dto.auftraege || []);
    this.fcEinsatzweise.setValue(dto.einsatzweise || Einsatzweise.Geschlossen);

    const mannschaft: Mannschaft = {
      staerke1: dto.mannschaftsstaerke1 || 0,
      staerke2: dto.mannschaftsstaerke2 || 0,
      staerke3: dto.mannschaftsstaerke3 || 0,
      fahrzeugCount: dto.summeFahrzeuge || 0,
      formationCount: dto.summeTaktischeFormationen || 0,
    };
    this.mannschaftForm.setMannschaft(mannschaft);
  }

  openTaktischeFormationHierarchie() {
    if (!this.dtoToEdit?.id) {
      return;
    }
    const dialogData: TaktischeFormationDialogInputData = {
      taktischeFormationNestedDTO: this.dtoToEdit,
      calledFromBib: false,
    };
    this.dialog
      .open(TaktischeFormationDialogComponent, { data: dialogData })
      .afterClosed()
      .pipe(
        switchMap(() => {
          if (!this.dtoToEdit?.id) {
            return EMPTY;
          }
          return this.taktischeFormationService.getTaktischeFormationNested(this.dtoToEdit.id);
        })
      )
      .subscribe((taktischeFormationNested) => {
        if (taktischeFormationNested) {
          this.setDTO(taktischeFormationNested);
        }
      });
  }

  private setMannschaftsstaerke(mannschaftsstaerke: number[] | undefined) {
    if (!mannschaftsstaerke) return;

    const currentMannschaft = this.mannschaftForm.getMannschaft();
    this.mannschaftForm.setMannschaft({
      staerke1: mannschaftsstaerke[0],
      staerke2: mannschaftsstaerke[1],
      staerke3: mannschaftsstaerke[2],
      fahrzeugCount: currentMannschaft.fahrzeugCount,
      formationCount: currentMannschaft.formationCount,
    });
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }

  private handleIconDataChanged(): void {
    if (!this.isCustomIcon) {
      this.iconService.generateCompressedDataUrl(this.iconData).subscribe((dataUrl) => {
        this.iconDataUrl = dataUrl;
        this.iconDataUrlChanged.emit(this.iconDataUrl);
      });
    }
  }

  public setCustomIcon(iconDataUrl: string): void {
    this.isCustomIcon = true;
    this.iconDataUrl = iconDataUrl;
  }

  public removeCustomIcon(): void {
    this.isCustomIcon = false;
    this.handleIconDataChanged();
  }
}
