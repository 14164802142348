import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ErrorService } from '@product/ise-error-lib';
import { AnlassDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { TagebuchComponent } from 'src/app/lagedarstellung/tagebuch/tagebuch/tagebuch.component';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { IconService } from 'src/app/shared/services/icon.service';
import { Symbol, TaktischesZeichen, erzeugeTaktischesZeichen, symbole } from 'taktische-zeichen-core';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

@Component({
  selector: 'app-anlass-ereignis-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    TagebuchComponent,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    CleanableFormFieldComponent,
  ],
  templateUrl: './anlass-ereignis-form.component.html',
  styleUrls: ['./anlass-ereignis-form.component.scss'],
})
export class AnlassEreignisFormComponent implements TaktischesZeichenForm {
  @ViewChild(TagebuchComponent)
  tagebuch!: TagebuchComponent;

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  iconDataUrlChanged = new EventEmitter<string>();

  private errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);
  private iconService = inject(IconService);

  fcAnzeigename = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcSymbol = this.formBuilder.control<Symbol | undefined>(undefined);
  fcZeichenText = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);

  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    symbol: this.fcSymbol,
    zeichenText: this.fcZeichenText,
  });

  anlassToEdit?: AnlassDTO;

  readonly DEFAULT_ICON_DATA: TaktischesZeichen = { grundzeichen: 'anlass' };
  iconData: TaktischesZeichen = { ...this.DEFAULT_ICON_DATA };
  private isCustomIcon = false;
  private iconDataUrl?: string;

  symbolValues: Symbol[] = symbole.sort((a: Symbol, b: Symbol) => a.label.localeCompare(b.label));
  symbolMouseover = false;

  constructor() {
    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v?.trim());
    });

    this.fcSymbol.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.symbol = v?.id;
      this.handleIconDataChanged();
    });

    this.fcZeichenText.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.text = v?.trim();
      this.handleIconDataChanged();
    });
  }

  setDTO(anlassDTO: AnlassDTO) {
    if (!anlassDTO) {
      this.errorService.showErrorMessage('Kein Model zum Bearbeiten vorhanden.');
      return;
    }

    // Sonderfall: Anlegen über Karte braucht direkt ein Temp-Icon
    if (!anlassDTO.id) {
      this.iconDataUrlChanged.emit(erzeugeTaktischesZeichen(this.DEFAULT_ICON_DATA).dataUrl);
    }

    this.anlassToEdit = anlassDTO;
    this.dtoToFormGroup(this.anlassToEdit);
    this.handleIconDataChanged();
  }

  /**
   * Aktualisiertes AnlassDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): AnlassDTO | null {
    if (this.formGroup.valid) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * FormGroup Werte in PersonDTO schreiben und zurückliefern
   */
  formGroupToDto(): AnlassDTO {
    return {
      ...this.anlassToEdit,
      customZeichen: this.isCustomIcon,
      dataUrl: this.iconDataUrl,
      anzeigename: this.fcAnzeigename.value.trim(),
      symbol: this.fcSymbol.value?.id,
      zeichenText: this.fcZeichenText.value?.trim(),
      tagebuch: this.tagebuch.getTagebucheintraege(),
      typ: TaktischesZeichenTyp.Anlass,
    };
  }

  /**
   * FormGroup mit Werten aus PersonDTO füllen
   */
  dtoToFormGroup(anlassDTO: AnlassDTO): void {
    this.isCustomIcon = anlassDTO.customZeichen || false;
    this.iconDataUrl = anlassDTO.dataUrl;

    this.fcAnzeigename.setValue(anlassDTO.anzeigename);
    this.fcSymbol.setValue(symbole.find((v) => v.id === anlassDTO.symbol));
    this.fcZeichenText.setValue(anlassDTO.zeichenText);
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }

  private handleIconDataChanged(): void {
    if (!this.isCustomIcon) {
      this.iconService.generateCompressedDataUrl(this.iconData).subscribe((dataUrl) => {
        this.iconDataUrl = dataUrl;
        this.iconDataUrlChanged.emit(this.iconDataUrl);
      });
    }
  }

  public setCustomIcon(iconDataUrl: string): void {
    this.isCustomIcon = true;
    this.iconDataUrl = iconDataUrl;
  }

  public removeCustomIcon(): void {
    this.isCustomIcon = false;
    this.handleIconDataChanged();
  }
}
