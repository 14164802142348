import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Witterung } from '../../../api/build/openapi/model/witterung';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import {
  WettermessungDialogComponent,
  WettermessungDialogData,
} from '../wettermessung-dialog/wettermessung-dialog.component';
import { IseKompassComponent } from '@product/ise-kompass-lib';
import { WetterDTO, WettermessungDTO } from 'src/app/api/build/openapi';
import { WetterService } from '../wetter.service';

export interface WetterDialogData {
  wetter?: WetterDTO;
}

@Component({
  selector: 'app-wetter-dialog',
  templateUrl: './wetter-dialog.component.html',
  styleUrls: ['./wetter-dialog.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    NgFor,
    NgIf,
    MatDialogModule,
    AsyncPipe,
    DateTimePipe,
    MatToolbarModule,
    MatTooltipModule,
    IseKompassComponent,
  ],
})
export class WetterDialogComponent {
  wetter?: WetterDTO;
  wettermessungen: WettermessungDTO[] = [];
  latestWettermessung?: WettermessungDTO;

  wetterForm: FormGroup = inject(NonNullableFormBuilder).group({
    messpunkt: [''],
    witterung: [Witterung.Wolkenlos],
    verantwortlich: [''],
    windrichtung: [1],
    umlaufend: [false],
    prognose6h: [''],
    prognose12h: [''],
    prognose24h: [''],
  });

  data: WetterDialogData = inject(MAT_DIALOG_DATA);
  wetterService = inject(WetterService);

  constructor(private dialog: MatDialogRef<WetterDialogComponent>, private matDialog: MatDialog) {
    if (this.data && this.data.wetter) {
      this.wetter = this.data.wetter;
      this.wetterForm.patchValue(this.wetter);
      this.wettermessungen = this.wetter.wettermessungen || [];
      this.latestWettermessung = this.wetterService.getLatestWettermessung(this.wetter);
    }
  }

  onClickHinzufuegen() {
    this.matDialog
      .open(WettermessungDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.wettermessungen = [...this.wettermessungen, result];
          this.latestWettermessung = result;
        }
      });
  }

  editWettermessung(wettermessungIndex: number) {
    const wettermessungData: WettermessungDialogData = {
      wettermessung: { ...this.wettermessungen[wettermessungIndex] },
    };
    this.matDialog
      .open(WettermessungDialogComponent, { data: wettermessungData })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.wettermessungen = this.wettermessungen.map((messung, index) =>
            index === wettermessungIndex ? result : messung
          );
        }
      });
  }

  onClickSave() {
    const wetterResult: WetterDTO = this.wetterForm.getRawValue();
    wetterResult.id = this.wetter?.id;
    wetterResult.version = this.wetter?.version;
    wetterResult.lageId = this.wetter?.lageId;
    wetterResult.wettermessungen = this.wettermessungen;
    this.dialog.close(wetterResult);
  }

  onWindrichtungChange(grad: number) {
    this.wetterForm.patchValue({ windrichtung: grad });
  }

  toggleWitterung() {
    const current = this.wetterForm.get('witterung')?.value;
    const witterungArr = Object.values(Witterung);
    let index: number = witterungArr.indexOf(current);
    index = index < witterungArr.length - 1 ? index + 1 : 0;
    this.wetterForm.patchValue({ witterung: witterungArr[index] });
  }
}
