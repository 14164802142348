import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Chart, registerables } from 'chart.js/auto';
import { DashboardFuehrungsebeneComponent } from '../dashboard-fuehrungsebene/dashboard-fuehrungsebene.component';
import { FuehrungsebeneDataset } from '../kennzahlen-dashboad.service';
Chart.register(...registerables);

@Component({
  selector: 'app-dashboard-lage',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, MatCardModule, MatButtonModule, MatIconModule, DashboardFuehrungsebeneComponent],
  templateUrl: './dashboard-lage.component.html',
  styleUrls: ['./dashboard-lage.component.scss'],
})
export class DashboardLageComponent {
  @Input()
  fuehrungsebeneDatasets: FuehrungsebeneDataset[] = [];

  @Output()
  reload: EventEmitter<void> = new EventEmitter<void>();

  onClickReload() {
    this.reload.emit();
  }
}
