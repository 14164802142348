<!-- Wenn weniger 1-2 Header, diese untereinander anzeigen -->
<ng-container *ngIf="headers.length <= 2">
  @for (header of headers; track $index) {
  <h3>{{ header }}</h3>
  }
</ng-container>

<!-- Sonst einen zusammenfassenden Header mit Tooltip anzeigen -->
<div class="shortened-headers" *ngIf="headers.length > 2 && shortenedHeader">
  <h3>{{ shortenedHeader }}</h3>
  <mat-icon fontSet="material-icons-outlined" [matTooltip]="headers.join('\n')">info</mat-icon>
</div>
