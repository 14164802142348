import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, switchMap } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { FuehrungsebeneDTO, FuehrungsebeneResourceService } from 'src/app/api/build/openapi';
import { fuehrungsebeneActions } from '../+state/fuehrungsebene.actions';
import { FuehrungsebeneIconAndTextComponent } from '../fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import {
  FuehrungsebeneTreeNestedDialogInputData,
  FuehrungsebeneTreeNestedDialogOutputData,
  FuehrungsebeneTreeSelectNestedDialogComponent,
} from '../fuehrungsebene-tree-select-nested-dialog/fuehrungsebene-tree-select-nested-dialog.component';
import { FuehrungsebeneService } from '../fuehrungsebene.service';

/**
 * Breadcrumbs für Navigation zwischen Führungsebenen.
 * Bildet direkten Pfad zwischen aktueller Lage und aktueller Führungsebenen über dessen parentFuehrungsebene ab.
 */
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, AsyncPipe, MatIconModule, MatButtonModule, FuehrungsebeneIconAndTextComponent],
})
export class BreadcrumbComponent {
  fuehrungsebeneBreadcrumbs$: Observable<FuehrungsebeneDTO[]>;
  protected fuehrungsebeneService = inject(FuehrungsebeneService);
  private fuehrungsebeneResourceService = inject(FuehrungsebeneResourceService);

  constructor(private store: Store<AppStateInterface>, private dialog: MatDialog) {
    this.fuehrungsebeneBreadcrumbs$ = this.fuehrungsebeneService.fuehrungsebeneBreadcrumbs$;
  }

  navigateToFuehrungsebene(fuehrungsebene: FuehrungsebeneDTO) {
    this.store.dispatch(fuehrungsebeneActions.setCurrentFuehrungsebene({ currentFuehrungsebene: fuehrungsebene }));
  }

  openNavigationsbaum() {
    // Prüfen, dass aktuell eine Lage ausgewählt ist
    const currentLage = this.fuehrungsebeneService.getCurrentLage();
    if (!currentLage?.id) {
      console.warn('Keine Lage für Navigationsbaum verfügbar');
      return;
    }

    // Navigationsbaum im Backend holen und Dialog mit Auswahl öffnen
    this.fuehrungsebeneResourceService
      .getNavigationTree(currentLage.id)
      .pipe(
        switchMap((treeNode) => {
          if (!treeNode) {
            console.warn('Kein Navigationsbaum verfügbar.');
            return EMPTY;
          }

          const dialogInputData: FuehrungsebeneTreeNestedDialogInputData = {
            treeData: treeNode,
            selectedFuehrungsebeneId: this.fuehrungsebeneService.getCurrentFuehrungsebene()?.id,
            headerText: 'Navigation',
          };
          return this.dialog
            .open(FuehrungsebeneTreeSelectNestedDialogComponent, { data: dialogInputData })
            .afterClosed();
        })
      )
      .subscribe((outputData: FuehrungsebeneTreeNestedDialogOutputData) => {
        // Nach erfolgreicher Auswahl zu entsprechender Führungsebene navigieren
        if (outputData?.selectedFuehrungsebeneId) {
          this.store.dispatch(
            fuehrungsebeneActions.setCurrentFuehrungsebeneId({
              currentFuehrungsebeneId: outputData.selectedFuehrungsebeneId,
            })
          );
        }
      });
  }
}
