<mat-toolbar>
  <h2>{{ dialogHeader }}</h2>
</mat-toolbar>

<mat-dialog-content>
  <div class="form-container">
    <span class="tz-type-row">
      <!-- Auswahl ZeichenType: Aktiviert je nach ZeichenType das Form für Fahrzeuge oder Personen -->
      <mat-form-field class="no-margin tz-type">
        <mat-label>Zeichentyp</mat-label>
        <mat-select #zeichenType (valueChange)="changeZeichenType($event)" [disabled]="!newObject">
          <mat-select-trigger>
            <img
              class="zeichen-icon"
              [src]="
                (darkmode$ | async)
                  ? taktischeZeichenInfoMapping.get(taktischesZeichenTyp)?.iconDarkmode
                  : taktischeZeichenInfoMapping.get(taktischesZeichenTyp)?.icon
              "
            />
            {{ taktischeZeichenInfoMapping.get(taktischesZeichenTyp)?.name }}
          </mat-select-trigger>
          <mat-option [value]="tzTyp" *ngFor="let tzTyp of allowedTzTypes">
            <img
              class="zeichen-icon"
              [src]="
                (darkmode$ | async)
                  ? taktischeZeichenInfoMapping.get(tzTyp)?.iconDarkmode
                  : taktischeZeichenInfoMapping.get(tzTyp)?.icon
              "
            />
            {{ taktischeZeichenInfoMapping.get(tzTyp)?.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox
        *ngIf="taktischeZeichenInfoMapping.get(taktischesZeichenTyp)?.allowVorlage"
        [formControl]="fcVorlage"
      >
        Vorlage
      </mat-checkbox>
    </span>

    <div class="dynamic-form">
      <app-fahrzeug-form
        *ngIf="taktischesZeichenTyp === TaktischesZeichenTyp.Fahrzeug"
        (anzeigenameChanged)="updateAnzeigename($event)"
        (iconDataUrlChanged)="updateIconDataUrl($event)"
      />

      <app-person-form
        *ngIf="taktischesZeichenTyp === TaktischesZeichenTyp.Person"
        (anzeigenameChanged)="updateAnzeigename($event)"
        (iconDataUrlChanged)="updateIconDataUrl($event)"
      />

      <app-gefahr-form
        *ngIf="taktischesZeichenTyp === TaktischesZeichenTyp.Gefahr"
        (anzeigenameChanged)="updateAnzeigename($event)"
        (iconDataUrlChanged)="updateIconDataUrl($event)"
      />

      <app-massnahme-form
        *ngIf="taktischesZeichenTyp === TaktischesZeichenTyp.Massnahme"
        (anzeigenameChanged)="updateAnzeigename($event)"
        (iconDataUrlChanged)="updateIconDataUrl($event)"
      />

      <app-anlass-ereignis-form
        *ngIf="taktischesZeichenTyp === TaktischesZeichenTyp.Anlass"
        (anzeigenameChanged)="updateAnzeigename($event)"
        (iconDataUrlChanged)="updateIconDataUrl($event)"
      />

      <app-stelle-einrichtung-form
        *ngIf="taktischesZeichenTyp === TaktischesZeichenTyp.Stelle"
        (anzeigenameChanged)="updateAnzeigename($event)"
        (iconDataUrlChanged)="updateIconDataUrl($event)"
      />

      <app-befehlsstelle-form
        *ngIf="taktischesZeichenTyp === TaktischesZeichenTyp.Befehlsstelle"
        (anzeigenameChanged)="updateAnzeigename($event)"
        (iconDataUrlChanged)="updateIconDataUrl($event)"
      />

      <app-gebaeude-form
        *ngIf="taktischesZeichenTyp === TaktischesZeichenTyp.Gebaeude"
        (anzeigenameChanged)="updateAnzeigename($event)"
        (iconDataUrlChanged)="updateIconDataUrl($event)"
      />

      <app-taktische-formation-form
        *ngIf="taktischesZeichenTyp === TaktischesZeichenTyp.TaktischeFormation"
        (anzeigenameChanged)="updateAnzeigename($event)"
        (iconDataUrlChanged)="updateIconDataUrl($event)"
      />

      <app-personenschaden-form
        *ngIf="taktischesZeichenTyp === TaktischesZeichenTyp.Personenschaden"
        (anzeigenameChanged)="updateAnzeigename($event)"
        (iconDataUrlChanged)="updateIconDataUrl($event)"
      />

      <app-gebiet-form
        *ngIf="taktischesZeichenTyp === TaktischesZeichenTyp.Gebiet"
        (anzeigenameChanged)="updateAnzeigename($event)"
        (iconDataUrlChanged)="updateIconDataUrl($event)"
        (taktischesZeichenChanged)="updateTzDto($event)"
      />

      <app-tierschaden-form
        *ngIf="taktischesZeichenTyp === TaktischesZeichenTyp.Tierschaden"
        (anzeigenameChanged)="updateAnzeigename($event)"
        (iconDataUrlChanged)="updateIconDataUrl($event)"
      />

      <app-foto-form
        *ngIf="taktischesZeichenTyp === TaktischesZeichenTyp.Foto"
        (anzeigenameChanged)="updateAnzeigename($event)"
        (iconDataUrlChanged)="updateIconDataUrl($event)"
      />
    </div>
  </div>

  <!-- Karte zum Anzeigen der Koordinate -->
  <div class="zeichen-map-container">
    <mat-card class="zeichen-container" (mouseover)="hoverTz = true" (mouseleave)="hoverTz = false">
      <h2>{{ anzeigename }}</h2>
      <img height="100px" [src]="iconDataUrl" />
      <mat-card *ngIf="isCustomIcon" class="custom-batch">überschrieben</mat-card>
      <div *ngIf="hoverTz" class="edit-button-container">
        <button mat-icon-button (click)="openTzDialog()">
          <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf="isCustomIcon" mat-icon-button (click)="removeCustomIcon()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </mat-card>

    <app-tz-geometry-map (geometryChanged)="geometryChanged($event)" />

    <mat-form-field class="less-margin">
      <mat-label>Geometrie</mat-label>
      <input matInput [value]="taktischesZeichenGeometry | geometryAsString" readonly />
      <button
        *ngIf="taktischesZeichenGeometry"
        matSuffix
        mat-icon-button
        (click)="copyGeometryToClipboard($event)"
        matTooltip="In Zwischenablage kopieren"
      >
        <mat-icon>content_copy</mat-icon>
      </button>
    </mat-form-field>
  </div>
</mat-dialog-content>

<!-- Buttons zum Speichern/Abbrechen -->
<div class="footer">
  <app-last-updated *ngIf="dtoToEdit" [lastUpdated]="dtoToEdit" />
  <mat-dialog-actions class="end">
    <button mat-raised-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="accent" (click)="save()" [disabled]="isSaving">Speichern</button>
  </mat-dialog-actions>
</div>
