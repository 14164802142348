import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, throwError, withLatestFrom } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { ErrorResponse, FuehrungsebeneResourceService, Fuehrungsebenentyp } from 'src/app/api/build/openapi';
import { lageActions } from '../../lagen/+state/lage.actions';
import { personenschadenActions } from '../../taktische-zeichen/personenschaden/+state/personenschaden.actions';
import { fuehrungsebeneActions } from './fuehrungsebene.actions';
import { currentFuehrungsebeneSelector } from './fuehrungsebene.selectors';

@Injectable()
export class FuehrungsebeneEffects {
  /**
   * Wenn sich die aktuelle Lage ändert, Neuladen der Fuehrungsebenen anstoßen
   */
  currentLageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lageActions.setCurrentLage),
      map((props) => {
        if (props.currentLage?.id) {
          return fuehrungsebeneActions.getFuehrungsebenen({ lageId: props.currentLage.id });
        } else {
          return fuehrungsebeneActions.resetStore();
        }
      })
    )
  );

  /**
   * Alle Fuehrungsebenen einer Lage in den Store laden.
   */
  getFuehrungsebenen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fuehrungsebeneActions.getFuehrungsebenen),
      switchMap((props) =>
        this.fuehrungsebeneResourceService.getFuehrungsebenenByLageId(props.lageId).pipe(
          map((fuehrungsebenen) =>
            fuehrungsebeneActions.getFuehrungsebenenSuccess({ fuehrungsebenen, loadedLageId: props.lageId })
          ),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(fuehrungsebeneActions.getFuehrungsebenenFailure({ errorResponse }));
          })
        )
      )
    )
  );

  /**
   * Legt einen neuen Fuehrungsebene an.
   */
  createFuehrungsebene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fuehrungsebeneActions.createFuehrungsebene),
      map((action) => action.fuehrungsebene),
      switchMap((fuehrungsebene) => {
        if (!fuehrungsebene.lageId) {
          return throwError(() => new Error('Führungsebene hat keine LageId'));
        }

        return this.fuehrungsebeneResourceService.createFuehrungsebene(fuehrungsebene.lageId, fuehrungsebene).pipe(
          map((createdFuehrungsebene) => fuehrungsebeneActions.createFuehrungsebeneSuccess({ createdFuehrungsebene })),
          catchError((error: HttpErrorResponse) => {
            const errorResponse: ErrorResponse = error.error;
            return of(fuehrungsebeneActions.createFuehrungsebeneFailure({ errorResponse }));
          })
        );
      })
    )
  );

  /**
   * Patcht einen existieren Fuehrungsebene.
   */
  patchFuehrungsebene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fuehrungsebeneActions.patchFuehrungsebene),
      switchMap((props) => {
        if (props.fuehrungsebene.id && props.fuehrungsebene.lageId) {
          return this.fuehrungsebeneResourceService
            .patchFuehrungsebene(props.fuehrungsebene.id, props.fuehrungsebene.lageId, props.fuehrungsebene)
            .pipe(
              map((patchedFuehrungsebene) =>
                fuehrungsebeneActions.patchFuehrungsebeneSuccess({ patchedFuehrungsebene })
              ),
              catchError((error: HttpErrorResponse) => {
                const errorResponse: ErrorResponse = error.error;
                return of(fuehrungsebeneActions.patchFuehrungsebeneFailure({ errorResponse }));
              })
            );
        }
        return throwError(() => new Error('Führungsebene hat keine Id oder LageId'));
      })
    )
  );

  /**
   * Löscht einen existierenden Fuehrungsebene
   */
  deleteFuehrungsebene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fuehrungsebeneActions.deleteFuehrungsebene),
      map((action) => action.fuehrungsebene),
      switchMap((fuehrungsebene) => {
        if (fuehrungsebene.id && fuehrungsebene.lageId) {
          return this.fuehrungsebeneResourceService.deleteFuehrungsebene(fuehrungsebene.id, fuehrungsebene.lageId).pipe(
            map(() => fuehrungsebeneActions.deleteFuehrungsebeneSuccess({ deletedFuehrungsebeneDTO: fuehrungsebene })),
            catchError((error: HttpErrorResponse) => {
              const errorResponse: ErrorResponse = error.error;
              return of(fuehrungsebeneActions.deleteFuehrungsebeneFailure({ errorResponse }));
            })
          );
        }
        return throwError(() => new Error('Führungsebene hat keine Id oder LageId'));
      })
    )
  );

  /**
   * Wenn alle Fuehrungsebenen erfolgreich geladen wurden, wird die Personenübersicht neugeladen.
   */
  getFuehrungsebenenSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fuehrungsebeneActions.getFuehrungsebenenSuccess),
      withLatestFrom(this.store.select(currentFuehrungsebeneSelector)),
      switchMap(([_, currentFuehrungsebene]) => {
        if (currentFuehrungsebene) {
          return of(fuehrungsebeneActions.getPersonenuebersicht({ fuehrungsebene: currentFuehrungsebene }));
        }
        return throwError(
          () => new Error('Kann Personenübersicht nicht laden, da keine Current-Führungsebene vorhanden')
        );
      })
    )
  );

  /**
   * Wenn sich der aktuelle Fuehrungsebene ändert, wird die Personenübersicht neugeladen.
   */
  currentFuehrungsebeneChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fuehrungsebeneActions.setCurrentFuehrungsebene),
      map((action) => action.currentFuehrungsebene),
      switchMap((currentFuehrungsebene) => {
        if (currentFuehrungsebene) {
          return of(fuehrungsebeneActions.getPersonenuebersicht({ fuehrungsebene: currentFuehrungsebene }));
        }
        return throwError(
          () => new Error('Kann Personenübersicht nicht laden, da keine Current-Führungsebene vorhanden')
        );
      })
    )
  );

  /**
   * Wenn ein Personenschaden angelegt oder bearbeitet wurde, wird die Personenübersicht neugeladen.
   */
  personenschaedenChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        personenschadenActions.createPersonenschadenSuccess,
        personenschadenActions.patchPersonenschadenSuccess,
        personenschadenActions.deletePersonenschadenSuccess
      ),
      withLatestFrom(this.store.select(currentFuehrungsebeneSelector)),
      switchMap(([_, currentFuehrungsebene]) => {
        if (currentFuehrungsebene) {
          return of(fuehrungsebeneActions.getPersonenuebersicht({ fuehrungsebene: currentFuehrungsebene }));
        }
        return throwError(
          () => new Error('Kann Personenübersicht nicht laden, da keine Current-Führungsebene vorhanden')
        );
      })
    )
  );

  /**
   * Manuelles Laden der Personenübersicht
   */
  getPersonenuebersicht$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fuehrungsebeneActions.getPersonenuebersicht),
      map((action) => action.fuehrungsebene),
      switchMap((fuehrungsebene) => {
        const isLage = fuehrungsebene.typ === Fuehrungsebenentyp.Lage;
        const lageId = isLage ? fuehrungsebene.id : fuehrungsebene.lageId;
        if (fuehrungsebene.id && lageId) {
          return this.fuehrungsebeneResourceService
            .getPersonenuebersichtByFuehrungsebene(fuehrungsebene.id, lageId)
            .pipe(
              map((personenuebersicht) =>
                fuehrungsebeneActions.getPersonenuebersichtSuccess({ personenuebersicht: personenuebersicht })
              ),
              catchError((error: HttpErrorResponse) => {
                const errorResponse: ErrorResponse = error.error;
                return of(fuehrungsebeneActions.getPersonenuebersichtFailure({ errorResponse }));
              })
            );
        }
        return throwError(() => new Error('Führungsebene hat keine Id oder LageId'));
      })
    )
  );

  getPersonenuebersichtLage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fuehrungsebeneActions.getPersonenuebersichtLage),
      switchMap((props) =>
        this.fuehrungsebeneResourceService.getPersonenuebersichtByLage(props.lageId).pipe(
          map((personenuebersicht) => fuehrungsebeneActions.getPersonenuebersichtLageSuccess({ personenuebersicht })),
          catchError((error) =>
            of(fuehrungsebeneActions.getPersonenuebersichtLageFailure({ errorResponse: error.message }))
          )
        )
      )
    )
  );

  getFuehrungsebeneUebersicht$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fuehrungsebeneActions.getFuehrungsebeneuebersicht),
      switchMap((props) =>
        this.fuehrungsebeneResourceService.getFuehrungsebeneUebersicht(props.fuehrungsebeneId, props.lageId).pipe(
          map((fuehrungsebeneUebersichtDTO) =>
            fuehrungsebeneActions.getFuehrungsebeneuebersichtSuccess({ fuehrungsebeneUebersichtDTO })
          ),
          catchError((error) =>
            of(fuehrungsebeneActions.getFuehrungsebeneuebersichtFailure({ errorResponse: error.message }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppStateInterface>,
    private fuehrungsebeneResourceService: FuehrungsebeneResourceService
  ) {}
}
