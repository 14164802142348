import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Personen } from '../personen.interface';
import { IconService, PERSONEN_LIGHTMODE } from 'src/app/shared/services/icon.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Große Komponente zur Übersicht von Personen verschiedener Zustände.
 * Personenanzahl kann vom Benutzer editiert werden.
 */
@Component({
  selector: 'app-personenuebersicht-subset',
  templateUrl: './personenuebersicht-subset.component.html',
  styleUrls: ['./personenuebersicht-subset.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    AsyncPipe,
  ],
})
export class PersonenuebersichtSubsetComponent implements OnChanges {
  @Input({ required: true })
  header!: string;
  @Input()
  disabled = false;

  @Input()
  personen?: Personen;

  icons = PERSONEN_LIGHTMODE;
  private formBuilder = inject(NonNullableFormBuilder);

  protected verletzt = this.formBuilder.control(0, [Validators.required, Validators.min(0)]);
  protected tot = this.formBuilder.control(0, [Validators.required, Validators.min(0)]);
  protected vermisst = this.formBuilder.control(0, [Validators.required, Validators.min(0)]);
  protected betroffen = this.formBuilder.control(0, [Validators.required, Validators.min(0)]);
  protected gerettet = this.formBuilder.control(0, [Validators.required, Validators.min(0)]);
  protected transportiert = this.formBuilder.control(0, [Validators.required, Validators.min(0)]);

  protected formGroup = this.formBuilder.group({
    verletzt: this.verletzt,
    tot: this.tot,
    vermisst: this.vermisst,
    betroffen: this.betroffen,
    gerettet: this.gerettet,
    transportiert: this.transportiert,
  });

  constructor() {
    inject(IconService)
      .getPersonenIcons()
      .pipe(takeUntilDestroyed())
      .subscribe((icons) => (this.icons = icons));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['personen']) {
      this.formGroup.setValue(changes['personen'].currentValue);
    }
  }

  isValid(): boolean {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return false;
    }

    return true;
  }

  getDto(): Personen {
    return {
      verletzt: this.verletzt.value || 0,
      tot: this.tot.value || 0,
      vermisst: this.vermisst.value || 0,
      betroffen: this.betroffen.value || 0,
      gerettet: this.gerettet.value || 0,
      transportiert: this.transportiert.value || 0,
    };
  }
}
