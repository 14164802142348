import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  TaktischeZeichenItemComponent,
  TzItemEvent,
} from '../../../taktische-zeichen-container/taktische-zeichen-item/taktische-zeichen-item.component';
import {
  BefehlsstelleDTO,
  FahrzeugDTO,
  FuehrungsebeneNestedDTO,
  PersonDTO,
  TaktischeFormationDTO,
  TaktischeFormationSelectableInfo,
  TaktischesZeichenDTO,
  TaktischesZeichenTyp,
} from '../../../../../api/build/openapi';
import { combineLatest, map, Observable } from 'rxjs';
import { Overlay } from '@angular/cdk/overlay';
import { TaktischeZeichenSelectionService } from '../../../../../planung/bibliothek/verwaltungsebene-details/taktische-zeichen-selection.service';
import { FuehrungsebeneService } from '../../../../fuehrungsebene/fuehrungsebene.service';
import { TzMultiSelectService } from '../../../tz-multi-select-service';
import { TaktischeFormationService } from '../../taktische-formation.service';
import { BibliothekFilter } from 'src/app/planung/bibliothek/bibliothek-base/bibliothek-filter';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-fuehrungsebene-detail-akkordeon',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    TaktischeZeichenItemComponent,
    MatExpansionModule,
  ],
  templateUrl: './fuehrungsebene-detail-akkordeon.component.html',
  styleUrl: './fuehrungsebene-detail-akkordeon.component.scss',
})
export class FuehrungsebeneDetailAkkordeonComponent implements OnInit, OnChanges {
  TaktischesZeichenTyp = TaktischesZeichenTyp;
  private destroyRef = inject(DestroyRef);

  @Input({ required: true }) fuehrungsebene!: FuehrungsebeneNestedDTO;
  @Input() expand = false;
  @Input() expandChildren = false;
  @Input() selectionMode = false;
  @Input({ required: true }) bibliothekFilter$!: Observable<BibliothekFilter>;
  @Input() taktischeFormationId: string = '';
  @Input() exludeTaktischesZeichenTypen: TaktischesZeichenTyp[] = [];
  @Input() singleSelect: boolean = false;
  @Input() selectedFahrzeuge: FahrzeugDTO[] = [];
  @Input() selectedPersonen: PersonDTO[] = [];
  @Input() selectedTaktischeFormationen: TaktischeFormationDTO[] = [];
  @ViewChild(MatAccordion) accordion: MatAccordion | undefined;

  private selectionService = inject(TaktischeZeichenSelectionService);
  public viewContainerRef = inject(ViewContainerRef);
  public overlay = inject(Overlay);
  public fuehrungsebeneService = inject(FuehrungsebeneService);
  public multiSelectService = inject(TzMultiSelectService);
  public taktischeFormationService = inject(TaktischeFormationService);

  fahrzeuge: FahrzeugDTO[] = [];
  personen: PersonDTO[] = [];
  taktischeFormationen: TaktischeFormationDTO[] = [];
  befehlsstellen: BefehlsstelleDTO[] = [];

  ngOnInit(): void {
    const selectableInfo: TaktischeFormationSelectableInfo = {
      type: 'EXISTING',
      taktischeFormationId: this.taktischeFormationId,
      lageId: this.fuehrungsebeneService.getCurrentLage()?.id,
    };

    if (!this.fuehrungsebene.id) {
      return;
    }
    if (!this.exludeTaktischesZeichenTypen.includes(TaktischesZeichenTyp.Fahrzeug)) {
      combineLatest([this.taktischeFormationService.getSelectableFahrzeuge(selectableInfo), this.bibliothekFilter$])
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          map(([fahrzeugDTOs, filter]) => this.filterTZs(fahrzeugDTOs, filter))
        )
        .subscribe((fahrzeuge) => (this.fahrzeuge = this.mergeWithSelectedFahrzeuge(fahrzeuge as FahrzeugDTO[])));
    }

    if (!this.exludeTaktischesZeichenTypen.includes(TaktischesZeichenTyp.Person)) {
      combineLatest([this.taktischeFormationService.getSelectablePersonen(selectableInfo), this.bibliothekFilter$])
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          map(([personDTOs, filter]) => this.filterTZs(personDTOs, filter))
        )
        .subscribe((personen) => (this.personen = this.mergeWithSelectedPersonen(personen as PersonDTO[])));
    }

    if (!this.exludeTaktischesZeichenTypen.includes(TaktischesZeichenTyp.Befehlsstelle)) {
      combineLatest([
        this.taktischeFormationService.getSelectableBefehlsstellen(selectableInfo),
        this.bibliothekFilter$,
      ])
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          map(([befehlsstelleDTOs, filter]) => this.filterTZs(befehlsstelleDTOs, filter))
        )
        .subscribe((befehlsstellen) => (this.befehlsstellen = befehlsstellen));
    }

    if (!this.exludeTaktischesZeichenTypen.includes(TaktischesZeichenTyp.TaktischeFormation)) {
      combineLatest([
        this.taktischeFormationService.getSelectableTaktischeFormationen(selectableInfo),
        this.bibliothekFilter$,
      ])
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          map(([taktischeFormationDTOs, filter]) =>
            this.filterTZs(
              taktischeFormationDTOs.filter((tf) => tf.id != this.taktischeFormationId),

              filter
            )
          )
        )
        .subscribe(
          (taktischeFormationen) =>
            (this.taktischeFormationen = this.mergeWithSelectedTaktischeFormationen(
              taktischeFormationen as TaktischeFormationDTO[]
            ))
        );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.accordion) {
      if (changes['expandChildren'] && !changes['expandChildren'].currentValue) {
        this.accordion.closeAll();
      } else {
        this.accordion.openAll();
      }
    }
  }

  selectTz(tzClickEvent: TzItemEvent) {
    if (this.selectionMode) {
      return;
    }

    tzClickEvent.mouseEvent.stopPropagation();
    if (!tzClickEvent.mouseEvent.ctrlKey) {
      this.multiSelectService.clearSelection();
      this.multiSelectService.add(tzClickEvent.dto);
    } else {
      this.multiSelectService.toggleSelection(tzClickEvent.dto);
    }
  }
  toggleSelection(zeichen: TaktischesZeichenDTO, event: MouseEvent) {
    event.stopPropagation();
    if (!this.selectionMode || (this.singleSelect && this.selectionService.hasSelection() && !this.isSelected(zeichen)))
      return;

    this.selectionService.toggleSelection(zeichen);
  }
  isSelected(zeichen: TaktischesZeichenDTO): boolean {
    if (this.selectionMode) {
      return this.selectionService.isSelected(zeichen);
    }

    return this.multiSelectService.isSelected(zeichen);
  }

  private filterTZs(
    tzDTOs: FahrzeugDTO[] | PersonDTO[] | TaktischeFormationDTO[] | BefehlsstelleDTO[],
    filter: BibliothekFilter
  ) {
    if (!tzDTOs || !tzDTOs.length) {
      return [];
    }

    if (filter.grundzeichen.length) {
      if (
        (tzDTOs[0].typ === TaktischesZeichenTyp.Fahrzeug && !filter.grundzeichen.includes('fahrzeug')) ||
        (tzDTOs[0].typ === TaktischesZeichenTyp.Person && !filter.grundzeichen.includes('person')) ||
        (tzDTOs[0].typ === TaktischesZeichenTyp.TaktischeFormation &&
          !filter.grundzeichen.includes('taktische-formation'))
      ) {
        return [];
      }
    }

    return tzDTOs.filter((tzDTO: FahrzeugDTO | PersonDTO | TaktischeFormationDTO | BefehlsstelleDTO) => {
      if (tzDTO.fuehrungsebeneId !== this.fuehrungsebene.id) {
        return;
      }
      if (filter.filterText.length && !tzDTO.anzeigename.toLowerCase().includes(filter.filterText.toLowerCase())) {
        return false;
      }

      if (
        filter.organisationen.length &&
        (!tzDTO.organisation || !filter.organisationen.includes(tzDTO.organisation))
      ) {
        return false;
      }

      if (filter.nurVorlagen && !tzDTO.vorlage) {
        return false;
      }

      return true;
    });
  }

  private mergeWithSelectedFahrzeuge(fahrzeuge: FahrzeugDTO[]) {
    if (!this.selectedFahrzeuge) {
      return fahrzeuge;
    }
    const result = [...fahrzeuge];
    this.selectedFahrzeuge
      .filter((fz) => fz.fuehrungsebeneId === this.fuehrungsebene.id)
      .forEach((fz) => result.push(fz));

    return result;
  }
  private mergeWithSelectedPersonen(personen: PersonDTO[]) {
    if (!this.selectedPersonen) {
      return personen;
    }
    const result = [...personen];
    this.selectedPersonen.filter((p) => p.fuehrungsebeneId === this.fuehrungsebene.id).forEach((p) => result.push(p));

    return result;
  }
  private mergeWithSelectedTaktischeFormationen(taktischeFormationen: TaktischeFormationDTO[]) {
    if (!this.selectedTaktischeFormationen) {
      return taktischeFormationen;
    }
    const result = [...taktischeFormationen];
    this.selectedTaktischeFormationen
      .filter((tf) => tf.fuehrungsebeneId === this.fuehrungsebene.id)
      .forEach((tf) => result.push(tf));

    return result;
  }
}
