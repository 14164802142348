<mat-toolbar>
  <h2>
    {{
      fuehrungsebeneService.fuehrungsebenentypMapping.get(fuehrungsebeneDTO.typ)?.longname +
        (fuehrungsebeneDTO.id ? (readonly ? " anzeigen" : " bearbeiten") : " hinzufügen")
    }}
  </h2>
</mat-toolbar>

<div class="content">
  <app-fuehrungsebene-details-panel
    [fuehrungsebeneDTO]="fuehrungsebeneDTO"
    [filteredTypes]="filteredTypes"
    [zeichenDataUrl]="fuehrungsebeneDTO.symbol"
    [readonly]="readonly"
  />
</div>

<div class="footer">
  <app-last-updated *ngIf="fuehrungsebeneDTO.id" [lastUpdated]="fuehrungsebeneDTO" />
  <mat-dialog-actions class="end">
    @if (canBeDeleted) {
    <button mat-raised-button color="warn" (click)="askDelete()">Entfernen</button>
    }
    <button mat-raised-button mat-dialog-close>Abbrechen</button>
    <button mat-raised-button color="accent" (click)="save()" [disabled]="readonly || (isSaving$ | async)">
      Speichern
    </button>
  </mat-dialog-actions>
</div>
