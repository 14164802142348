<form [formGroup]="formGroup" class="left-side">
  <mat-form-field class="less-margin">
    <mat-label>Typ</mat-label>
    <mat-select matInput [formControl]="fcTyp" (selectionChange)="updateGeometryStyle()">
      @for (type of selectableTypes; track $index) {
      <mat-option [value]="type">{{ fuehrungsebeneService.fuehrungsebenentypMapping.get(type)?.longname }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Name</mat-label>
    <input matInput required="true" [formControl]="fcName" [readonly]="readonly" />
    <mat-error>{{ getErrorMessage(fcName) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Schadenkonto Farbe</mat-label>
    <mat-select [formControl]="fcSchadenkontoColor" [panelClass]="readonly ? 'readonly' : ''">
      <mat-select-trigger>
        <div
          class="color-option"
          [style.backgroundColor]="schadenkontoColorMapping.get(fcSchadenkontoColor.value)"
        ></div>
      </mat-select-trigger>
      <mat-option *ngFor="let entry of schadenkontoColorMapping | keyvalue" [value]="entry.key">
        <div class="color-option" [style.backgroundColor]="entry.value"></div>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Startzeit</mat-label>
    <input matInput type="datetime-local" [formControl]="fcStartedOn" dateTimeLocalAsDate [readonly]="readonly" />
    <mat-error>{{ getErrorMessage(fcStartedOn) }}</mat-error>
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Adresse</mat-label>
    <input matInput [formControl]="fcAdresse" [readonly]="readonly" />
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Leiter Name</mat-label>
    <input matInput [formControl]="fcLeiterName" [readonly]="readonly" />
  </mat-form-field>

  <mat-form-field class="less-margin">
    <mat-label>Leiter Dienstgrad</mat-label>
    <input matInput [formControl]="fcLeiterDienstgrad" [readonly]="readonly" />
  </mat-form-field>

  <ng-container *ngIf="showEinsatzleitung">
    <mat-checkbox class="checkbox" [formControl]="fcEinsatzleitung">Einsatzleitung</mat-checkbox>

    <label for="einsatzleitungstyp">Art der Einsatzleitung</label>
    <mat-radio-group [formControl]="fcEinsatzleitungstyp" name="einsatzleitungstyp">
      <mat-radio-button [value]="Einsatzleitungstyp.Paragraf33">Einsatzleitung §33 BHKG</mat-radio-button>
      <mat-radio-button [value]="Einsatzleitungstyp.Paragraf37">Einsatzleitung §37 BHKG</mat-radio-button>
    </mat-radio-group>
  </ng-container>

  <ng-container *ngIf="showMedizinischeRettung">
    <mat-checkbox class="checkbox" [formControl]="fcMedizinischeRettung">Medizinische Rettung</mat-checkbox>
  </ng-container>

  <app-kontakt-list
    class="mb-1"
    [kommunikationOptionen]="fuehrungsebeneDTO.kommunikationOptionen || []"
    [readonly]="readonly"
  />

  <app-auftrag-list class="mb-1" [readonly]="readonly" />

  <app-einsatzfilter-selection-list
    [einsatzfilterDTOs]="fuehrungsebeneDTO.einsatzfilter || []"
    [fuehrungsebeneDTO]="fuehrungsebeneDTO"
    [readonly]="readonly"
    *ngIf="showEinsatzfilter"
  />
</form>

<div class="right-side">
  <mat-card
    class="zeichen-container"
    (mouseover)="isCustomZeichenHovered = true"
    (mouseleave)="isCustomZeichenHovered = false"
  >
    <img height="100px" [src]="zeichenDataUrl" />
    <mat-card *ngIf="customZeichen" class="custom-batch">überschrieben</mat-card>
    <div *ngIf="isCustomZeichenHovered" class="edit-button-container">
      <button mat-icon-button (click)="openCustomZeichenDialog()" [disabled]="readonly">
        <mat-icon>edit</mat-icon>
      </button>
      <button
        *ngIf="isCustomZeichenHovered && customZeichen"
        mat-icon-button
        (click)="removeCustomZeichen()"
        [disabled]="readonly"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </mat-card>

  <app-simple-map
    [feature]="feature"
    [other]="other"
    [readonly]="readonly"
    (featureChanged)="featureChanged($event)"
    (errorHint)="showError($event)"
    [ngClass]="{ 'error-border': !geometryValid }"
  />
  <mat-error *ngIf="!geometryValid">Bitte eine Koordinate oder einen Bereich festlegen</mat-error>
</div>
