import { createSelector } from '@ngrx/store';
import { AppStateInterface } from 'src/app/+state/appState.interface';

export const selectFeature = (appState: AppStateInterface) => appState.einsatztagebuch;

export const isLoadingEinsatztagebuchSelector = createSelector(selectFeature, (state) => state.isLoading);
export const isSavingSelector = createSelector(selectFeature, (state) => state.isSaving);
export const einsatztagebuchSelector = createSelector(selectFeature, (state) => state.einsatztagebuch);
export const einsatztagebuchErrorSelector = createSelector(selectFeature, (state) => state.errorResponse);
export const einsatztagebuchLoadedLageSelector = createSelector(selectFeature, (state) => state.loadedLageId);
export const einsatztagebuchPaginationInfoSelector = createSelector(selectFeature, (state) => state.pageinationInfo);
