/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Fuehrungsebenentyp = 'LAGE' | 'EINSATZ' | 'SCHADENGEBIET' | 'EINSATZRAUM' | 'EINSATZSTELLE' | 'EINSATZABSCHNITT' | 'UNTERABSCHNITT' | 'KRISENSTAB' | 'SAE' | 'BEREITSTELLUNGSRAUM' | 'GRUNDSCHUTZ' | 'OERTLICH' | 'POLITISCH_GESAMTVERANTWORTLICHER' | 'ADMINISTRATIV_ORGANISATORISCH' | 'OPERATIV_TAKTISCH' | 'LEITSTELLE';

export const Fuehrungsebenentyp = {
    Lage: 'LAGE' as Fuehrungsebenentyp,
    Einsatz: 'EINSATZ' as Fuehrungsebenentyp,
    Schadengebiet: 'SCHADENGEBIET' as Fuehrungsebenentyp,
    Einsatzraum: 'EINSATZRAUM' as Fuehrungsebenentyp,
    Einsatzstelle: 'EINSATZSTELLE' as Fuehrungsebenentyp,
    Einsatzabschnitt: 'EINSATZABSCHNITT' as Fuehrungsebenentyp,
    Unterabschnitt: 'UNTERABSCHNITT' as Fuehrungsebenentyp,
    Krisenstab: 'KRISENSTAB' as Fuehrungsebenentyp,
    Sae: 'SAE' as Fuehrungsebenentyp,
    Bereitstellungsraum: 'BEREITSTELLUNGSRAUM' as Fuehrungsebenentyp,
    Grundschutz: 'GRUNDSCHUTZ' as Fuehrungsebenentyp,
    Oertlich: 'OERTLICH' as Fuehrungsebenentyp,
    PolitischGesamtverantwortlicher: 'POLITISCH_GESAMTVERANTWORTLICHER' as Fuehrungsebenentyp,
    AdministrativOrganisatorisch: 'ADMINISTRATIV_ORGANISATORISCH' as Fuehrungsebenentyp,
    OperativTaktisch: 'OPERATIV_TAKTISCH' as Fuehrungsebenentyp,
    Leitstelle: 'LEITSTELLE' as Fuehrungsebenentyp
};

