import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { ErrorService } from '@product/ise-error-lib';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import { Observable } from 'rxjs';
import { FuehrungsebeneDTO, Fuehrungsebenentyp, LageDTO, LageStatus } from 'src/app/api/build/openapi';
import { DatetimeLocalAccessorDirective } from 'src/app/shared/accessors/datetime-local-accessor.directive';
import { ChipListInputComponent } from 'src/app/shared/components/chip-list-input/chip-list-input.component';
import { IconService } from 'src/app/shared/services/icon.service';
import { SimpleMapComponent } from 'src/app/shared/simple-map/simple-map.component';
import {
  TaktischeZeichenEditorDialogComponent,
  TzEditorDialogInputData,
} from 'src/app/taktische-zeichen/taktische-zeichen-editor-dialog/taktische-zeichen-editor-dialog.component';
import { TaktischesZeichen } from 'taktische-zeichen-core';
import { lageActions } from '../+state/lage.actions';
import { isSavingSelector } from '../+state/lage.selectors';
import { LastUpdatedComponent } from '../../../shared/last-updated/last-updated.component';
import { FuehrungsebeneService } from '../../fuehrungsebene/fuehrungsebene.service';
import { LayerService } from '../../layer.service';
import { LageBeendenService } from '../lage-beenden.service';

export interface LageDialogInputData {
  lageDto: LageDTO;
}

@Component({
  selector: 'app-lage-details-dialog',
  templateUrl: './lage-details-dialog.component.html',
  styleUrls: ['./lage-details-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    ChipListInputComponent,
    LastUpdatedComponent,
    SimpleMapComponent,
    DatetimeLocalAccessorDirective,
  ],
})
export class LageDetailsDialogComponent implements AfterViewInit {
  LageStatus = LageStatus;
  Fuehrungsebenentyp = Fuehrungsebenentyp;

  lageDto: LageDTO;

  zeichenDataUrl?: string;
  customZeichen = false;
  isCustomZeichenHovered = false;

  protected isSaving$: Observable<boolean>;
  protected isClosingLage = false;

  private endLageService = inject(LageBeendenService);
  private errorService = inject(ErrorService);
  private layerService = inject(LayerService);
  protected fuehrungsebeneService = inject(FuehrungsebeneService);
  private iconService = inject(IconService);
  private store = inject(Store);

  private dialog = inject(MatDialog);
  private destroyRef = inject(DestroyRef);
  protected dialogRef = inject(MatDialogRef);
  private dialogData: LageDialogInputData = inject(MAT_DIALOG_DATA);

  private formBuilder = inject(NonNullableFormBuilder);
  protected formGroup = this.formBuilder.group({
    name: this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]),
    startedOn: this.formBuilder.control<Date | undefined>(undefined),
    stichworte: this.formBuilder.control<string[]>([], [Validators.required]),
  });

  feature?: Feature;
  other = this.prepareEmptyFeatureCollection();
  geometryValid = true;

  constructor() {
    this.lageDto = this.dialogData.lageDto;
    this.isSaving$ = this.store.select(isSavingSelector);
    this.dialogRef.updateSize('60%');
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.modelToFormGroup();
      this.setFeature();

      const preparedOthers = this.prepareEmptyFeatureCollection();

      // Lage zeichnen (falls gerade nicht die Lage selbst bearbeitet wird)
      const lage = this.fuehrungsebeneService.getCurrentLage();
      if (lage && lage.id !== this.lageDto.id) {
        this.mergeFeatureCollections(preparedOthers, this.prepareLageFeatureCollection(lage));
      }

      const fuehrungsebenen = this.fuehrungsebeneService.getAllFuehrungsebenen();
      if (fuehrungsebenen) {
        this.mergeFeatureCollections(
          preparedOthers,
          this.prepareFuehrungsebenenFeatureCollection(fuehrungsebenen.filter((f) => f.id !== this.lageDto.id))
        );
      }

      if (preparedOthers.features.length > 0) {
        this.other = preparedOthers;
      }

      window.dispatchEvent(new Event('resize'));
    });
  }

  save(): void {
    if (!this.validate()) {
      return;
    }

    this.formGroupToModel();

    /**
     * Beim Speichern wird isSaving im Store auf true gesetzt.
     * Nachdem Speichern abgeschlossen, Fenster schließen.
     */
    this.isSaving$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isSaving) => {
      if (!isSaving) {
        this.dialogRef.close();
      }
    });

    if (this.lageDto.id) {
      this.store.dispatch(lageActions.patchLage({ patchedLage: this.lageDto }));
    } else {
      this.store.dispatch(lageActions.createLage({ newLage: this.lageDto }));
    }
  }

  /**
   * Öffnet einen Dialog zum Beenden der aktuellen Lage und löst dies ggf. aus.
   */
  endLage(lageId?: string): void {
    if (this.isClosingLage) {
      return;
    }

    this.isClosingLage = true;
    this.endLageService.endLage$(lageId).subscribe((lageBeendet) => {
      this.isClosingLage = false;
      if (lageBeendet) {
        this.dialogRef.close();
      }
    });
  }

  private setFeature() {
    const style = this.layerService.getFuehrungsebeneGeometryStyle(this.lageDto, false);

    const f: Feature = {
      type: 'Feature',
      geometry: this.lageDto.geometry as Geometry,
      properties: { style, tooltip: this.lageDto.name },
    };
    this.feature = f;
  }

  private mergeFeatureCollections(main: FeatureCollection, other: FeatureCollection) {
    main.features.push(...other.features);
  }

  private prepareEmptyFeatureCollection(): FeatureCollection {
    return { type: 'FeatureCollection', features: [] };
  }

  private prepareFuehrungsebenenFeatureCollection(fuehrungsebenenDTOs: FuehrungsebeneDTO[]) {
    const otherFeatureCollection: FeatureCollection = this.prepareEmptyFeatureCollection();

    fuehrungsebenenDTOs.forEach((f) => otherFeatureCollection.features.push(this.prepareFeature(f)));
    return otherFeatureCollection;
  }

  private prepareLageFeatureCollection(lageDTO: LageDTO) {
    const collection = this.prepareEmptyFeatureCollection();

    const style = this.layerService.getFuehrungsebeneGeometryStyle(lageDTO, false);
    const properties = { style, tooltip: lageDTO.name };

    collection.features.push({
      type: 'Feature',
      geometry: lageDTO.geometry as Geometry,
      properties,
    } as Feature);

    return collection;
  }

  private prepareFeature(fuehrungsebeneDTO: FuehrungsebeneDTO): Feature {
    const style = this.layerService.getFuehrungsebeneGeometryStyle(fuehrungsebeneDTO, false);
    const properties = { style, tooltip: fuehrungsebeneDTO.name };

    return {
      type: 'Feature',
      geometry: fuehrungsebeneDTO.geometry as Geometry,
      properties,
    } as Feature;
  }

  /**
   * Wenn sich der Fuehrungsebenentyp ändert, muss Geometrie auf Karte neugezeichnet werden,
   * damit Icon, Tooltip und Polygonfarbe zum Fuehrungsebenentypen passen
   */
  updateGeometryStyle() {
    if (this.lageDto) {
      this.formGroupToModel();
      this.setFeature();
    }
  }

  /**
   * Validiert alle Form-Felder und markiert ungültige Felder.
   */
  validate(): boolean {
    this.geometryValid = !!this.lageDto.geometry;

    if (!this.formGroup.valid || !this.geometryValid) {
      this.formGroup.markAllAsTouched();
      return false;
    }

    return true;
  }

  /**
   * Schreibt Werte aus der FormGroup in das aktuelle FuehrungsebeneDTO
   */
  formGroupToModel(): void {
    if (!this.lageDto) {
      return;
    }

    this.lageDto.customSymbol = this.customZeichen;
    this.lageDto.name = this.formGroup.controls.name.value.trim();
    this.lageDto.startedOn = this.formGroup.controls.startedOn.value?.toISOString();
    this.lageDto.stichworte = this.formGroup.controls.stichworte.value;
    this.lageDto.symbol = this.zeichenDataUrl;
  }

  /**
   * Schreibt Werte aus dem aktuellen FuehrungsebeneDTO in die FormGroup
   */
  modelToFormGroup(): void {
    if (!this.lageDto) {
      return;
    }
    this.customZeichen = this.lageDto.customSymbol;
    this.formGroup.reset();
    this.formGroup.controls.name.setValue(this.lageDto.name);
    this.formGroup.controls.startedOn.setValue(this.lageDto.startedOn ? new Date(this.lageDto.startedOn) : undefined);
    this.formGroup.controls.stichworte.setValue(this.lageDto.stichworte || []);
    if (this.lageDto.symbol) {
      this.zeichenDataUrl = this.lageDto.symbol;
    } else {
      this.generateFuehrungsebenentypeDataUrl();
    }
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }

  featureChanged = (feature: Feature) => {
    this.lageDto.geometry = feature.geometry;
    this.geometryValid = !!this.lageDto.geometry;
  };

  showError($event: string) {
    this.errorService.showError({ errorMessages: [$event] });
  }

  openCustomZeichenDialog() {
    const inputData: TzEditorDialogInputData = {
      taktischesZeichen: this.generateStelleTz(),
    };
    this.dialog
      .open(TaktischeZeichenEditorDialogComponent, { data: inputData })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.customZeichen = true;
          this.zeichenDataUrl = result.dataUrl;
          this.lageDto.symbol = this.zeichenDataUrl;
          this.setFeature();
        }
      });
  }

  removeCustomZeichen() {
    this.customZeichen = false;
    this.generateFuehrungsebenentypeDataUrl();
  }

  private generateFuehrungsebenentypeDataUrl() {
    this.iconService.generateCompressedDataUrl(this.generateStelleTz()).subscribe((dataUrl) => {
      this.zeichenDataUrl = dataUrl;
      this.lageDto.symbol = this.zeichenDataUrl;
      this.setFeature();
    });
  }

  private generateStelleTz(): TaktischesZeichen {
    return {
      grundzeichen: 'stelle',
      organisation: 'fuehrung',
      text: 'L',
    };
  }
}
