import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AnlassResourceService } from './api/anlassResource.service';
import { AnwesenheitResourceService } from './api/anwesenheitResource.service';
import { ApplicationSettingsResourceService } from './api/applicationSettingsResource.service';
import { AuthenticationResourceService } from './api/authenticationResource.service';
import { BefehlsstelleResourceService } from './api/befehlsstelleResource.service';
import { BibliothekResourceService } from './api/bibliothekResource.service';
import { EinsatzResourceService } from './api/einsatzResource.service';
import { EinsatzfilterResourceService } from './api/einsatzfilterResource.service';
import { FahrzeugResourceService } from './api/fahrzeugResource.service';
import { FotoResourceService } from './api/fotoResource.service';
import { FuehrungsebeneMassnahmeResourceService } from './api/fuehrungsebeneMassnahmeResource.service';
import { FuehrungsebeneResourceService } from './api/fuehrungsebeneResource.service';
import { GebaeudeResourceService } from './api/gebaeudeResource.service';
import { GebietResourceService } from './api/gebietResource.service';
import { GefahrResourceService } from './api/gefahrResource.service';
import { LageEinsatztagebuchResourceService } from './api/lageEinsatztagebuchResource.service';
import { LageMassnahmeResourceService } from './api/lageMassnahmeResource.service';
import { LageResourceService } from './api/lageResource.service';
import { LageberichtResourceService } from './api/lageberichtResource.service';
import { LagebesprechungResourceService } from './api/lagebesprechungResource.service';
import { MapConfigResourceService } from './api/mapConfigResource.service';
import { MeldungResourceService } from './api/meldungResource.service';
import { PersonResourceService } from './api/personResource.service';
import { PersonenschadenResourceService } from './api/personenschadenResource.service';
import { SimulationResourceService } from './api/simulationResource.service';
import { StabMassnahmeResourceService } from './api/stabMassnahmeResource.service';
import { StelleResourceService } from './api/stelleResource.service';
import { TaktischeFormationResourceService } from './api/taktischeFormationResource.service';
import { TaktischeZeichenResourceService } from './api/taktischeZeichenResource.service';
import { TierschadenResourceService } from './api/tierschadenResource.service';
import { VidalResourceService } from './api/vidalResource.service';
import { WetterResourceService } from './api/wetterResource.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
