import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { Component, Input, inject, input } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatChipEditedEvent, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ErrorService } from '@product/ise-error-lib';

@Component({
  selector: 'app-chip-list-input',
  standalone: true,
  imports: [CommonModule, MatChipsModule, MatInputModule, MatFormFieldModule, MatIconModule, ReactiveFormsModule],
  templateUrl: './chip-list-input.component.html',
  styleUrls: ['./chip-list-input.component.scss'],
})
export class ChipListInputComponent {
  private errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);

  @Input()
  control = this.formBuilder.control<string[]>([]);

  @Input()
  label? = 'Filter Label';

  @Input()
  itemName? = 'Werte';

  readonly = input<boolean>(false);

  // Tasten mit denen die aktuelle Eingabe abgeschlossen wird und eine neue Eingabe beginnt
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  /**
   * Neuen Wert in FormControl schreiben
   */
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Wert hinzufügen
    if (value) {
      this.control.setValue([...this.control.value, value]);
    }

    // Eingabe leeren
    event.chipInput!.clear();
  }

  /**
   * Existierenden Wert in FormControl ändern und falls leer, entfernen.
   */
  edit(oldValue: string, event: MatChipEditedEvent) {
    const newValue = event.value.trim();

    // Wert löschen, wenn Eingabe leer
    if (!newValue) {
      this.remove(oldValue);
      return;
    }

    this.control.setValue(this.control.value.map((v) => (v === oldValue ? newValue : v)));
  }

  /**
   * Existierenden Wert aus FormControl löschen
   */
  remove(value: string): void {
    this.control.setValue(this.control.value.filter((v) => v !== value));
  }

  getErrorMessage(): string {
    return this.errorService.getErrorMessage(this.control.errors);
  }
}
