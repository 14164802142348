<!-- Header und Content nur anzeigen, wenn logistikDTO für currentFuehrungsebene existiert -->
<app-fuehrungsebene-header *ngIf="logistikDTO" [fuehrungsebeneDTO]="logistikDTO" (edit)="editLogistik()" />

<app-loadable [isLoading$]="isLoadingFuehrungsebenen$">
  <div *ngIf="logistikDTO" class="logistik-content">
    <img [src]="logistikDTO.symbol" width="30" [matTooltip]="logistikDTO.name" />

    <!-- 
    Child-Führungsebenen
   -->
    <div class="child-fuehrungsebenen" *ngIf="logistikChildren.length">
      <!-- Vertikale Linie zu Hauptlogistik-Führungsebene -->
      <svg width="1" height="10" *ngIf="logistikChildren.length">
        <line x1="0" y1="0" x2="0" y2="100%" stroke="black" stroke-width="2" />
      </svg>

      <!-- Zeile mit allen Child-Führungsebene -->
      <div class="child-fuehrungsebene-row">
        <div class="child-fuehrungsebene-column" *ngFor="let childFuehrungsebene of logistikChildren; let i = index">
          <!-- Horizontale Linie oberhalb der Child-Führungsebenen, um diese zu verbinden -->
          <svg width="100%" height="1" *ngIf="logistikChildren.length > 1">
            <line
              [attr.x1]="i > 0 ? '0' : '50%'"
              y1="0"
              [attr.x2]="i < logistikChildren.length - 1 ? '100%' : '50%'"
              y2="0"
              stroke="black"
              stroke-width="2"
            />
          </svg>

          <!-- Vertikale Linie zwischen Child-Führungsebene und der horizontalen Verbindungslinie aller Child-Führungsebenen -->
          <svg width="1" height="10">
            <line x1="0" y1="0" x2="0" y2="100%" stroke="black" stroke-width="2" />
          </svg>
          <img
            [src]="fuehrungsebeneService.getFuehrungsebeneIcon(childFuehrungsebene)"
            width="30"
            [matTooltip]="childFuehrungsebene.name"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- Button zum Hinzufügen von Logistik nur anzeigen, wenn noch keine Logistik existiert und der Typ der Current-Führungsebene passt -->

  <div class="logistik-button" *ngIf="(isLoadingFuehrungsebenen$ | async) === false && logistikButtonVisible">
    <button mat-raised-button color="accent" (click)="createLogistik()">
      <mat-icon>add</mat-icon>
      Logistik
    </button>
  </div>
</app-loadable>
