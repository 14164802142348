import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorService } from '@product/ise-error-lib';
import { PersonDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { AuftragListComponent } from 'src/app/lagedarstellung/auftrag/auftrag-list/auftrag-list.component';
import { KontaktListComponent } from 'src/app/lagedarstellung/kontakt/kontakt-list/kontakt-list.component';
import { IconService } from 'src/app/shared/services/icon.service';
import { compareLabels } from 'src/app/taktische-zeichen/taktische-zeichen-form/taktische-zeichen-form.component';
import {
  Einheit,
  EinheitId,
  Fachaufgabe,
  Funktion,
  Organisation,
  TaktischesZeichen,
  Verwaltungsstufe,
  einheiten,
  erzeugeTaktischesZeichen,
  fachaufgaben,
  funktionen,
  organisationen,
  verwaltungsstufen,
} from 'taktische-zeichen-core';
import { CleanableFormFieldComponent } from '../../../../shared/cleanable-form-field/cleanable-form-field.component';
import { MannschaftFormComponent } from '../../mannschaft-form/mannschaft-form.component';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    CleanableFormFieldComponent,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    KontaktListComponent,
    AuftragListComponent,
    MannschaftFormComponent,
  ],
})
export class PersonFormComponent implements TaktischesZeichenForm {
  @ViewChild(MannschaftFormComponent) mannschaftForm!: MannschaftFormComponent;
  @ViewChild(KontaktListComponent) kontaktListe!: KontaktListComponent;
  @ViewChild(AuftragListComponent) auftragListe!: AuftragListComponent;

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  iconDataUrlChanged = new EventEmitter<string>();

  readonly DEFAULT_ICON_DATA: TaktischesZeichen = { grundzeichen: 'person' };
  iconData: TaktischesZeichen = { ...this.DEFAULT_ICON_DATA };
  private isCustomIcon = false;
  private iconDataUrl?: string;

  personToEdit?: PersonDTO;

  // Hover-Merker
  organisationMouseover = false;
  fachaufgabeMouseover = false;
  groessenordnungMouseover = false;
  verwaltungsstufeMouseover = false;
  funktionMouseover = false;

  // Dropdown-Werte
  organisationenValues: Organisation[] = organisationen.sort(compareLabels);
  fauchaufgabeValues: Fachaufgabe[] = fachaufgaben.sort(compareLabels);
  groessenordnungValues: Einheit[] = einheiten.sort(compareLabels);
  verwaltungsstufeValues: Verwaltungsstufe[] = verwaltungsstufen.sort(compareLabels);
  funktionValues: Funktion[] = funktionen.sort(compareLabels);

  private errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);
  private iconService = inject(IconService);

  // Form Controls
  fcAnzeigename = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcOrganisation = this.formBuilder.control<Organisation | undefined>(undefined);
  fcFachaufgabe = this.formBuilder.control<Fachaufgabe | undefined>(undefined);
  fcGroessenordnung = this.formBuilder.control<Einheit | undefined>(undefined);
  fcVerwaltungsstufe = this.formBuilder.control<Verwaltungsstufe | undefined>(undefined);
  fcOrtsangabe = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcFunktion = this.formBuilder.control<Funktion | undefined>(undefined, [Validators.maxLength(255)]);
  fcFunktionKurztext = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(6)]);
  fcVorname = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcNachname = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcDienstgrad = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcHerkunft = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcGliederung = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);

  // Form Group
  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    organisation: this.fcOrganisation,
    fachaufgabe: this.fcFachaufgabe,
    groessenordnung: this.fcGroessenordnung,
    verwaltungsstufe: this.fcVerwaltungsstufe,
    ortsangabe: this.fcOrtsangabe,
    funktion: this.fcFunktion,
    funktionKurztext: this.fcFunktionKurztext,
    vorname: this.fcVorname,
    nachname: this.fcNachname,
    dienstgrad: this.fcDienstgrad,
    herkunft: this.fcHerkunft,
    gliederung: this.fcGliederung,
  });

  constructor() {
    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v.trim());
    });

    this.fcFachaufgabe.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.fachaufgabe = v?.id;
      this.handleIconDataChanged();
    });

    this.fcOrganisation.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.organisation = v?.id;
      this.handleIconDataChanged();
    });

    this.fcGroessenordnung.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.updateMannschaftsstaerke(v?.id);
      this.iconData.einheit = v?.id;
      this.handleIconDataChanged();
    });

    this.fcVerwaltungsstufe.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.verwaltungsstufe = v?.id;
      this.handleIconDataChanged();
    });

    this.fcFunktion.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.funktion = v?.id;
      this.handleIconDataChanged();
    });

    this.fcFunktionKurztext.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.text = v?.trim();
      this.handleIconDataChanged();
    });
  }

  private updateMannschaftsstaerke(groessenordnung?: EinheitId): void {
    const staerke1 =
      groessenordnung &&
      ['abteilung', 'zug', 'zugtrupp', 'bereitschaft', 'abteilung', 'grossverband'].includes(groessenordnung)
        ? 1
        : 0;
    const staerke2 = groessenordnung === 'gruppe' || groessenordnung === 'staffel' ? 1 : 0;
    const staerke3 = !groessenordnung || groessenordnung === 'trupp' ? 1 : 0;

    this.mannschaftForm.setMannschaft({
      staerke1,
      staerke2,
      staerke3,
    });
  }

  /**
   * PersonDTO Model von außen setzen
   */
  setDTO(personDto: TaktischesZeichenDTO) {
    if (!personDto) {
      this.errorService.showErrorMessage('Kein Model zum Bearbeiten vorhanden.');
      return;
    }

    // Sonderfall: Anlegen über Karte braucht direkt ein Temp-Icon
    if (!personDto.id) {
      this.iconDataUrlChanged.emit(erzeugeTaktischesZeichen(this.DEFAULT_ICON_DATA).dataUrl);
    }

    this.personToEdit = personDto as PersonDTO;
    this.dtoToFormGroup(this.personToEdit);
    this.handleIconDataChanged();
  }

  /**
   * Aktualisiertes PersonDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): PersonDTO | null {
    if (this.formGroup.valid && this.mannschaftForm.validate()) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * FormGroup Werte in PersonDTO schreiben und zurückliefern
   */
  formGroupToDto(): PersonDTO {
    const mannschaft = this.mannschaftForm.getMannschaft();
    return {
      ...this.personToEdit,
      customZeichen: this.isCustomIcon,
      dataUrl: this.iconDataUrl,
      anzeigename: this.fcAnzeigename.value.trim(),
      organisation: this.fcOrganisation.value?.id,
      fachaufgabe: this.fcFachaufgabe.value?.id,
      groessenordnung: this.fcGroessenordnung.value?.id,
      mannschaftsstaerke1: mannschaft.staerke1,
      mannschaftsstaerke2: mannschaft.staerke2,
      mannschaftsstaerke3: mannschaft.staerke3,
      verwaltungsstufe: this.fcVerwaltungsstufe.value?.id,
      ortsangabe: this.fcOrtsangabe.value?.trim(),
      funktion: this.fcFunktion.value?.id,
      funktionKurztext: this.fcFunktionKurztext.value?.trim(),
      vorname: this.fcVorname.value?.trim(),
      nachname: this.fcNachname.value?.trim(),
      dienstgrad: this.fcDienstgrad.value?.trim(),
      herkunft: this.fcHerkunft.value?.trim(),
      gliederung: this.fcGliederung.value?.trim(),
      kommunikationOptionen: this.kontaktListe.getKommunikationOptionen(),
      auftraege: this.auftragListe.getAuftraege(),
      typ: TaktischesZeichenTyp.Person,
    };
  }

  /**
   * FormGroup mit Werten aus PersonDTO füllen
   */
  dtoToFormGroup(personDTO: PersonDTO) {
    this.isCustomIcon = personDTO.customZeichen || false;
    this.iconDataUrl = personDTO.dataUrl;

    this.fcAnzeigename.setValue(personDTO.anzeigename);
    this.fcOrtsangabe.setValue(personDTO.ortsangabe);
    this.fcFunktionKurztext.setValue(personDTO.funktionKurztext);
    this.fcVorname.setValue(personDTO.vorname);
    this.fcNachname.setValue(personDTO.nachname);
    this.fcDienstgrad.setValue(personDTO.dienstgrad);
    this.fcHerkunft.setValue(personDTO.herkunft);
    this.fcGliederung.setValue(personDTO.gliederung);
    this.fcOrganisation.setValue(organisationen.find((v) => v.id === personDTO.organisation));
    this.fcFachaufgabe.setValue(fachaufgaben.find((v) => v.id === personDTO.fachaufgabe));
    this.fcGroessenordnung.setValue(einheiten.find((v) => v.id === personDTO.groessenordnung));
    this.fcVerwaltungsstufe.setValue(verwaltungsstufen.find((v) => v.id === personDTO.verwaltungsstufe));
    this.fcFunktion.setValue(funktionen.find((v) => v.id === personDTO.funktion));
    this.auftragListe.setAuftraege(personDTO.auftraege || []);
    this.mannschaftForm.setMannschaft({
      staerke1: personDTO.mannschaftsstaerke1 || 0,
      staerke2: personDTO.mannschaftsstaerke2 || 0,
      staerke3: personDTO.mannschaftsstaerke3 || 1,
    });
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }

  private handleIconDataChanged(): void {
    if (!this.isCustomIcon) {
      this.iconService.generateCompressedDataUrl(this.iconData).subscribe((dataUrl) => {
        this.iconDataUrl = dataUrl;
        this.iconDataUrlChanged.emit(this.iconDataUrl);
      });
    }
  }

  public setCustomIcon(iconDataUrl: string): void {
    this.isCustomIcon = true;
    this.iconDataUrl = iconDataUrl;
  }

  public removeCustomIcon(): void {
    this.isCustomIcon = false;
    this.handleIconDataChanged();
  }
}
