import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuehrungsebeneDisplayService } from '../fuehrungsebene-display.service';

/**
 * Zeigt an, wie viele und welche direkten Children eine Führungsebene hat und
 * wie viele davon links und rechts neben der Karte dargestellt werden.
 */
@Component({
  selector: 'app-fuehrungsebene-child-counter',
  standalone: true,
  imports: [NgIf, MatIconModule, MatTooltipModule],
  templateUrl: './fuehrungsebene-child-counter.component.html',
  styleUrl: './fuehrungsebene-child-counter.component.scss',
})
export class FuehrungsebeneChildCounterComponent {
  fuehrungsebeneDisplayService = inject(FuehrungsebeneDisplayService);

  headers: string[] = [];
  shortenedHeader?: string;

  constructor() {
    this.fuehrungsebeneDisplayService.headers$
      .pipe(takeUntilDestroyed())
      .subscribe((headers) => (this.headers = headers));
    this.fuehrungsebeneDisplayService.shortenedHeader$
      .pipe(takeUntilDestroyed())
      .subscribe((shortenedHeader) => (this.shortenedHeader = shortenedHeader));
  }
}
