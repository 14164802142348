import { Overlay } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { FlatTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewContainerRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { FuehrungsebeneNestedDTO, VerwaltungsebeneDTO } from '../../../../../api/build/openapi';

export interface FlatNode {
  id: string;
  name: string;
  expandable: boolean;
  level: number;
}

@Component({
  selector: 'app-fuehrungsebenen-tree',
  standalone: true,
  imports: [
    CommonModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatCardModule,
    PortalModule,
    MatTooltipModule,
  ],
  templateUrl: './fuehrungsebenen-tree.component.html',
  styleUrls: ['./fuehrungsebenen-tree.component.scss'],
})
export class FuehrungsebenenTreeComponent implements OnChanges {
  // Workaround; siehe Methoden unten.
  private expandedNodes: FlatNode[] = [];

  protected matTreeNodePaddingIndent = 10; // default 40 px

  @Input() fuehrungsebenen?: FuehrungsebeneNestedDTO[];

  /**
   * Baum mit Verwaltungsebenen öffnen
   */
  @Input() expandTree = false;

  /**
   * Editierfunktionen werden aktiviert
   */
  @Input() enableEdit = false;

  /**
   * Wenn aktiviert, steht ist ein Button verfügbar,
   * welches die Selektion einer Verwaltungsebene via @Output 'verwaltungsebeneSelected' mitteilt
   */
  @Input() enableSelection = false;
  /**
   * Liefert die ID einer selektierten Verwaltungsebene.
   *
   * Nur wenn `enableSelection` gesetzt.
   */
  @Output() verwaltungsebeneSelected = new EventEmitter<string>();

  /**
   * "Unter" einem Node soll eine weitere VE erstellt werden können.
   * Um zirkuläre Abhängigkeiten zu vermeiden, meldet der Tree nur, unter welcher VE das gemacht werden soll.
   * (Zirkulär?: `VerwaltungsebeneDialogComponent` nutzt den Tree zum Verschieben)
   */
  @Output() createVerwaltungsebeneTriggered = new EventEmitter<VerwaltungsebeneDTO>();

  /**
   * Meldet die ID eines Nodes = Verwaltungsebene, auf die im Tree geklickt wird.
   */
  @Output() treeNodeClicked = new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges) {
    // Workaround / Bug, dass für 'treeControl.expandAll()' die Daten gesetzt werden müssen: https://github.com/angular/components/issues/12469
    if (changes['expandTree']) {
      if (this.fuehrungsebenen) {
        this.dataSource.data = this.fuehrungsebenen || [];
        this.expandTreeFn(changes['expandTree'].currentValue);
      }
    }

    if (changes['fuehrungsebenen'] && this.fuehrungsebenen) {
      this.saveExpandedNodes();
      this.dataSource.data = this.fuehrungsebenen;
      this.restoreExpandedNodes();

      if (this.expandTree) {
        this.dataSource.data = this.fuehrungsebenen || [];
        this.expandTreeFn(true);
      }
    }
  }

  constructor(private dialog: MatDialog, public viewContainerRef: ViewContainerRef, public overlay: Overlay) {}

  /*
  ##### Tree #####
  */

  private flattener = new MatTreeFlattener(
    (fuehrungsebeneNode: FuehrungsebeneNestedDTO, level: number) => {
      return {
        id: fuehrungsebeneNode.id || '',
        name: fuehrungsebeneNode.name || '?',
        expandable: !!fuehrungsebeneNode.fuehrungsebeneDTOs && fuehrungsebeneNode.fuehrungsebeneDTOs.length > 0,
        level: level,
      };
    },
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.fuehrungsebeneDTOs
  );

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.flattener);

  hasChild = (_: number, node: FlatNode) => node.expandable;
  private expandTreeFn = (expand: boolean) => (expand ? this.treeControl.expandAll() : this.treeControl.collapseAll());

  /*
   Der Tree speichert nicht, welche Elemene ausgeklappt sind.
   Beim Aktualisieren klappen daher normalerweise alle Ebenen zu.
   Folgende zwei Methoden dienen als Workaround und speichern den Status

  https://github.com/angular/components/issues/11984#issuecomment-519972431
   */

  private saveExpandedNodes() {
    if (!this.treeControl.dataNodes) {
      return;
    }
    this.expandedNodes = [];
    this.treeControl.dataNodes.forEach((n) => {
      if (n.expandable && this.treeControl.isExpanded(n)) {
        this.expandedNodes.push(n);
      }
    });
  }

  private restoreExpandedNodes() {
    this.expandedNodes.forEach((expandedNode) => {
      const toExpand = this.treeControl.dataNodes.find((n) => n.id === expandedNode.id);
      if (toExpand) {
        this.treeControl.expand(toExpand);
      }
    });

    this.expandedNodes = [];
  }

  openVerwaltungsebeneDialog(event: MouseEvent, parentVerwaltungsebene?: VerwaltungsebeneDTO) {
    event.stopPropagation();

    this.createVerwaltungsebeneTriggered.emit(parentVerwaltungsebene);
  }

  emitTreeNodeClicked(node: FlatNode) {
    this.treeNodeClicked.emit(node.id);
  }

  emitSelectedVerwaltungsebene(event: MouseEvent, verwaltungsebeneNode: FlatNode) {
    if (!this.enableSelection) {
      return;
    }

    event.stopPropagation();
    this.verwaltungsebeneSelected.emit(verwaltungsebeneNode.id);
  }
}
