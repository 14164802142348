<mat-form-field class="no-margin">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid #chipGrid [formControl]="control">
    <!-- Chip-Liste für alle Werte des FormControls -->
    <mat-chip-row
      *ngFor="let value of control.value"
      (removed)="remove(value)"
      [editable]="true"
      (edited)="edit(value, $event)"
    >
      {{ value }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>

    <!-- Eingabefeld für neue Werte -->
    <input
      [placeholder]="control.value.length ? 'Weitere ' + itemName + ' eintragen...' : itemName + ' eintragen...'"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="add($event)"
      [readonly]="readonly()"
    />
  </mat-chip-grid>
  <mat-error>{{ getErrorMessage() }}</mat-error>
</mat-form-field>
