<mat-toolbar class="application-toolbar" color="primary">
  <img *ngIf="logo$ | async as logo" class="logo" [src]="logo" [routerLink]="['/lagen']" />

  <!-- Verschiedene Navigationsmenüs, je nach Anwendungsbereich -->
  <div class="ml-2" *ngIf="isAuthenticated && (popupManagerService.isInitializedPopup$ | async) === false">
    <app-lage-navigation
      *ngIf="
        (selectedApplication$ | async) === ApplicationType.Lage &&
        (router.url.startsWith('/einsaetze') ||
          router.url.startsWith('/meldungen') ||
          router.url.startsWith('/lagedarstellung') ||
          router.url.startsWith('/einsatztagebuch'))
      "
    />
    <app-planung-navigation *ngIf="router.url.startsWith('/planung')" />
    <app-informationen-navigation *ngIf="router.url.startsWith('/informationen')" />
    <app-lage-k-navigation
      *ngIf="
        (selectedApplication$ | async) === ApplicationType.LageK &&
        (router.url.startsWith('/lage-k') ||
          router.url.startsWith('/meldungen') ||
          router.url.startsWith('/anwesenheiten') ||
          router.url.startsWith('/einsatztagebuch'))
      "
    />
  </div>
  <!-- VIDaL Navigation -->
  <app-vidal-navigation *ngIf="router.url.startsWith('/vidal')" />

  <div class="align-right">
    <app-nats-status *ngIf="isAuthenticated" />
    <ng-container *ngIf="isAuthenticated">
      <a
        mat-icon-button
        class="toolbar-button"
        href="/doc"
        target="_blank"
        matTooltip="Dokumentation in neuem Tab öffnen"
        ><mat-icon>question_mark</mat-icon>
      </a>
    </ng-container>

    <button mat-icon-button class="toolbar-button" [matMenuTriggerFor]="menu">
      <img class="user-icon" *ngIf="userIconPath; else default" [src]="userIconPath" />
      <ng-template #default>
        <mat-icon>account_circle</mat-icon>
      </ng-template>
    </button>
  </div>

  <mat-menu #menu="matMenu">
    <!-- Verschiedene Anwendungsbereiche -->
    <button
      mat-menu-item
      [routerLink]="['/lagen']"
      *ngIf="isAuthenticated && (this.selectedApplication$ | async)"
      [ngClass]="{
        'current-selection':
          !router.url.startsWith('/planung') &&
          !router.url.startsWith('/vidal') &&
          !router.url.startsWith('/informationen') &&
          !router.url.startsWith('/administration')
      }"
    >
      <mat-icon>dashboard</mat-icon>
      <span>Lagen</span>
    </button>

    @if (isAuthenticated && vidalActive) {
    <button
      mat-menu-item
      [routerLink]="['/vidal']"
      [ngClass]="{ 'current-selection': router.url.startsWith('/vidal') }"
    >
      <mat-icon>sync_alt</mat-icon>
      <span>VIDaL</span>
    </button>
    }

    <button
      mat-menu-item
      [routerLink]="['/planung']"
      *ngIf="isAuthenticated"
      [ngClass]="{ 'current-selection': router.url.startsWith('/planung') }"
    >
      <mat-icon>settings</mat-icon>
      <span>Planung</span>
    </button>

    <button
      mat-menu-item
      [routerLink]="['/informationen']"
      *ngIf="isAuthenticated"
      [ngClass]="{ 'current-selection': router.url.startsWith('/informationen') }"
    >
      <mat-icon>info</mat-icon>
      <span>Informationen</span>
    </button>

    <button
      mat-menu-item
      [routerLink]="['/anwendung']"
      *ngIf="isAuthenticated"
      [ngClass]="{ 'current-selection': router.url.startsWith('/anwendung') }"
    >
      <mat-icon>widgets</mat-icon>
      <span>Anwendung</span>
    </button>

    <hr *ngIf="isAuthenticated" />

    <!-- Dark Mode -->
    <button mat-menu-item (click)="toggleDarkMode($event)">
      <mat-icon>{{ (darkmode$ | async) ? "light_mode" : "dark_mode" }}</mat-icon>
      <span>{{ (darkmode$ | async) ? "Light" : "Dark" }}</span>
    </button>

    <!-- Multi-Window -->
    <button
      *ngIf="router.url === '/lagedarstellung'"
      mat-menu-item
      (click)="monitorService.toggleUseMultiWindowSetup()"
      [disabled]="!monitorService.hasUserPermission$.value"
      [matTooltip]="getMultiMonitorTooltip()"
    >
      <mat-icon>desktop_windows</mat-icon>
      <span>{{ (monitorService.useMultiWindowSetup$ | async) === false ? "3" : "1" }} Fenster verwenden</span>
    </button>

    <!-- Datumsformat -->
    <button *ngIf="isAuthenticated" mat-menu-item [matMenuTriggerFor]="dateTimeFormat">
      <mat-icon>calendar_month</mat-icon>
      <span>Datumsformat</span>
    </button>

    <mat-menu #dateTimeFormat="matMenu">
      <button
        mat-menu-item
        (click)="setDateTimeFormat($event, format.value)"
        *ngFor="let format of LageDateTimeFormat | keyvalue"
        [ngClass]="{ 'current-selection': format.value === currentTimeFormat }"
      >
        {{ format.value }}
      </button>
    </mat-menu>

    <!-- Simulation -->
    <!-- nach Absprache, Simulation nicht öffnen können -->
    <!-- <button *ngIf="isAuthenticated" mat-menu-item (click)="openSimulationDialog()">
      <mat-icon>add_chart</mat-icon>
      <span>Simulation</span>
    </button> -->

    <!--Komponenten-Sichtbarkeit-->
    <button *ngIf="isAuthenticated" mat-menu-item [matMenuTriggerFor]="toggleComponents">
      <mat-icon>flip_to_front</mat-icon>
      <span>Komponenten</span>
    </button>

    <mat-menu #toggleComponents="matMenu">
      <button mat-menu-item (click)="toggleShowDevelopmentComponents($event)">
        <mat-icon>{{ (isShowDevelopment$ | async) ? "visibility" : "visibility_off" }}</mat-icon>
        <span>Development</span>
      </button>
    </mat-menu>

    <!-- Dialoge für Informationen, wenn nicht eingeloggt -->
    <button *ngIf="!isAuthenticated" mat-menu-item (click)="openImpressumDialog()">
      <mat-icon>info</mat-icon>
      <span>Impressum</span>
    </button>

    <button *ngIf="!isAuthenticated" mat-menu-item (click)="openDsgvoDialog()">
      <mat-icon>vpn_lock</mat-icon>
      <span>Datenschutzerklärung</span>
    </button>

    <button *ngIf="!isAuthenticated" mat-menu-item (click)="openCookieDialog()">
      <mat-icon>settings_input_svideo</mat-icon>
      <span>Cookies</span>
    </button>

    <hr />

    <!-- Link zu Keycloak zum Bearbeiten der Userdaten -->
    <a mat-menu-item *ngIf="userName" [href]="accountManagementUrl" target="_blank">
      <mat-icon> launch </mat-icon>
      <span>{{ userName }}</span>
      <span *ngIf="tenant"> ({{ tenant }})</span>
    </a>

    <!-- Ein-/Ausloggen -->
    <button mat-menu-item (click)="toggleAuth()">
      <mat-icon>{{ isAuthenticated ? "logout" : "login" }}</mat-icon>
      <span>{{ isAuthenticated ? "Logout" : "Login" }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
