import { NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorService } from '@product/ise-error-lib';
import { catchError, EMPTY } from 'rxjs';
import {
  MultiplyTzDto,
  TaktischeFormationDTO,
  TaktischesZeichenDTO,
  TaktischesZeichenTyp,
} from 'src/app/api/build/openapi';
import { BibliothekZeichenService } from 'src/app/planung/bibliothek/bibliothek-zeichen.service';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';

export enum MultiplyOrigin {
  LAGE,
  BIBLIOTHEK,
}

export interface MultiplyDialogInputData {
  tzDto: TaktischesZeichenDTO;
  multiplyOrigin: MultiplyOrigin;
}

@Component({
  selector: 'app-multiply-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatButtonModule,
    NgIf,
    MatIconModule,
  ],
  templateUrl: './multiply-dialog.component.html',
  styleUrl: './multiply-dialog.component.scss',
})
export class MultiplyDialogComponent implements OnInit {
  TaktischesZeichenTyp = TaktischesZeichenTyp;

  protected errorService = inject(ErrorService);
  protected inputData: MultiplyDialogInputData = inject(MAT_DIALOG_DATA);
  private dialogRef = inject(MatDialogRef);
  private formBuilder = inject(NonNullableFormBuilder);
  private taktischeZeichenService = inject(TaktischeZeichenService);
  private bibService = inject(BibliothekZeichenService);
  private multiplyOrigin: MultiplyOrigin;

  isBibFormation = false;
  tzTypDescription = 'Taktisches Zeichen';

  fcCount = this.formBuilder.control<number>(1, [Validators.min(1), Validators.max(10)]);
  fcRecursive = this.formBuilder.control<boolean>(true);
  fcReference = this.formBuilder.control<boolean>(false);

  isSaving = false;

  constructor() {
    this.fcRecursive.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      if (v) {
        this.fcReference.enable();
      } else {
        this.fcReference.disable();
        this.fcReference.setValue(false);
      }
    });
    this.multiplyOrigin = this.inputData.multiplyOrigin;
  }

  ngOnInit(): void {
    this.isBibFormation =
      this.inputData.tzDto.typ === TaktischesZeichenTyp.TaktischeFormation &&
      !!(this.inputData.tzDto as TaktischeFormationDTO).lageId;

    this.tzTypDescription = this.taktischeZeichenService.getTzTypDescription([this.inputData.tzDto]);
  }

  multiply() {
    if (this.isSaving) {
      return;
    }

    if (!this.inputData.tzDto.id) {
      console.warn('TZ kann nicht dupliziert werden, da keine Id vorhanden ist.');
      return;
    }

    if (!this.fcCount.valid) {
      this.fcCount.markAsTouched();
      return;
    }

    this.isSaving = true;
    const multiplyDto: MultiplyTzDto = {
      tzId: this.inputData.tzDto.id,
      tzTyp: this.inputData.tzDto.typ,
      count: this.fcCount.value,
      recursive: this.fcRecursive.value,
      referenceChildren: this.fcReference.value,
    };

    if (this.multiplyOrigin == MultiplyOrigin.LAGE) {
      this.taktischeZeichenService
        .multiplyTz(multiplyDto)
        .pipe(
          catchError((errorResponse) => {
            this.isSaving = false;
            this.errorService.showError({ errorMessages: [errorResponse.statusText] });
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.bibService
        .multiplyBibZeichen(multiplyDto)
        .pipe(
          catchError((errorResponse) => {
            this.isSaving = false;
            this.errorService.showError({ errorMessages: [errorResponse.statusText] });
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.dialogRef.close();
        });
    }
  }
}
