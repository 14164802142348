import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Observable } from 'rxjs';
import { ApplicationType, TaktischesZeichenDTO } from 'src/app/api/build/openapi';
import { IconAndTextComponent } from 'src/app/shared/components/icon-and-text/icon-and-text.component';
import { TaktischeZeichenService } from 'src/app/taktische-zeichen/taktische-zeichen.service';
import { AppService } from '../../../app.service';
import { MapHandler } from '../../base-map/map-handler';
import { TaktischeZeichenTypInfo } from '../taktische-zeichen.interface';

@Component({
  selector: 'app-tz-popup-toolbar',
  standalone: true,
  imports: [IconAndTextComponent, MatToolbarModule, MatIconModule, MatButtonModule, MatTooltipModule, NgIf, AsyncPipe],
  templateUrl: './tz-popup-toolbar.component.html',
  styleUrl: './tz-popup-toolbar.component.scss',
})
export class TzPopupToolbarComponent implements OnInit {
  @Input({ required: true }) tzDto!: TaktischesZeichenDTO;
  @Output() editClicked = new EventEmitter();
  @Output() moveClicked = new EventEmitter();

  protected tzInfo?: TaktischeZeichenTypInfo;

  private mapHandler = inject(MapHandler);
  private taktischeZeichenService = inject(TaktischeZeichenService);
  darkmode$ = inject(AppService).isDarkMode();

  ApplicationType = ApplicationType;
  private appService = inject(AppService);
  protected selectedApp$: Observable<ApplicationType | undefined> = this.appService.selectedApplicationObservable();

  ngOnInit(): void {
    if (this.tzDto.typ) {
      this.tzInfo = this.taktischeZeichenService.taktischeZeichenInfoMapping.get(this.tzDto.typ);
    }
  }

  focusDto() {
    if (this.tzDto.geometry) {
      this.mapHandler.focusGeometry(this.tzDto.geometry as GeoJSON.Geometry);
    }
  }

  moveDto() {
    this.moveClicked.emit();
  }

  editDto() {
    this.editClicked.emit();
  }
}
