import { Component, DestroyRef, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import {
  BefehlsstelleDTO,
  FahrzeugDTO,
  FuehrungsebeneNestedDTO,
  FuehrungsebeneResourceService,
  PersonDTO,
  TaktischeFormationDTO,
  TaktischesZeichenDTO,
  TaktischesZeichenTyp,
  VerwaltungsebeneDTO,
} from '../../../../api/build/openapi';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../../../+state/appState.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  verwaltungsebenenIdSelector,
  verwaltungsebenenSelector,
} from '../../../../planung/bibliothek/+state/bibliothek.selectors';
import { VerwaltungsebenenTreeComponent } from '../../../../planung/bibliothek/verwaltungsebenen-tree/verwaltungsebenen-tree.component';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AsyncPipe, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { BehaviorSubject, switchMap, combineLatest, startWith, debounceTime, map, take } from 'rxjs';
import { FuehrungsebeneService } from 'src/app/lagedarstellung/fuehrungsebene/fuehrungsebene.service';
import { BibliothekFilter } from 'src/app/planung/bibliothek/bibliothek-base/bibliothek-filter';
import { TaktischeZeichenSelectionService } from 'src/app/planung/bibliothek/verwaltungsebene-details/taktische-zeichen-selection.service';
import { VerwaltungsebeneDetailsComponent } from 'src/app/planung/bibliothek/verwaltungsebene-details/verwaltungsebene-details.component';
import { GrundzeichenId, OrganisationId } from 'taktische-zeichen-core';
import { TaktischeZeichenItemComponent } from '../../taktische-zeichen-container/taktische-zeichen-item/taktische-zeichen-item.component';
import { FuehrungsebeneDetailAkkordeonComponent } from './fuehrungsebene-detail-akkordeon/fuehrungsebene-detail-akkordeon.component';
import { FuehrungsebenenTreeComponent } from './fuehrungsebenen-tree/fuehrungsebenen-tree.component';

/**
 * Objekt, das der Konfiguration beim Öffnen des Dialogs dient.
 */
export interface SelectDialogInputData {
  taktischeFormationId: string;
  shownTzs: TaktischesZeichenTyp[];
  multiSelect: boolean;
  headerText?: string;
  // Personen, Fahrzeuge, Taktische Formationen, die schon beim Öffnen selektiert sein sollen
  selectedFahrzeuge?: FahrzeugDTO[];
  selectedPersonen?: PersonDTO[];
  selectedTaktischeFormationen?: TaktischeFormationDTO[];
  selectedBefehlsstellen?: BefehlsstelleDTO[];
  excludeTaktischeZeichenTypen?: TaktischesZeichenTyp[];
  singleSelect?: boolean;
  calledFromBib: boolean;
  lageId?: string;
}

/**
 * Objekt, das mit Positiver Bestätigung des Dialogs zurückgeliefert wird.
 * Ausgewählte TZs werden in ihre jeweiligen Listen einsortiert.
 */
export interface SelectDialogOutputData {
  fahrzeugIds: string[];
  personIds: string[];
  taktischeFormationIds: string[];
  befehlsstelleIds: string[];
}

@Component({
  selector: 'app-taktische-formation-select-dialog',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatOptionModule,
    MatInputModule,
    MatToolbarModule,
    MatCardModule,
    MatTooltipModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDialogModule,
    VerwaltungsebenenTreeComponent,
    VerwaltungsebeneDetailsComponent,
    TaktischeZeichenItemComponent,
    FuehrungsebenenTreeComponent,
    FuehrungsebeneDetailAkkordeonComponent,
    KeyValuePipe,
    AsyncPipe,
    NgFor,
    NgIf,
  ],
  templateUrl: './taktische-formation-select-dialog.component.html',
  styleUrl: './taktische-formation-select-dialog.component.scss',
})
export class TaktischeFormationSelectDialogComponent {
  private destroyRef = inject(DestroyRef);
  private store = inject(Store<AppStateInterface>);
  private formBuilder = inject(NonNullableFormBuilder);
  selectService = inject(TaktischeZeichenSelectionService);
  private dialogRef = inject(MatDialogRef);
  private fuehrungsebeneResourceService = inject(FuehrungsebeneResourceService);
  private fuehrungsebeneService = inject(FuehrungsebeneService);

  grundzeichenOptions = new Map<string, GrundzeichenId>([
    ['Personen', 'person'],
    ['Fahrzeuge', 'fahrzeug'],
    ['Taktische Formationen', 'taktische-formation'],
  ]);
  organisationOptions = new Map<string, OrganisationId>([
    ['Bundeswehr', 'bundeswehr'],
    ['Feuerwehr', 'feuerwehr'],
    ['Führung', 'fuehrung'],
    ['Gefahrenabwehr', 'gefahrenabwehr'],
    ['Hilfsorganisationen', 'hilfsorganisation'],
    ['Polizei', 'polizei'],
    ['THW', 'thw'],
  ]);

  protected fcFilterText = this.formBuilder.control<string>('');
  protected fcFilterOrganisationen = this.formBuilder.control<string[]>([]);
  protected fcFilterGrundzeichen = this.formBuilder.control<string[]>([]);
  protected fcFilterVorlagen = this.formBuilder.control<boolean>(false);

  verwaltungsebenenTree: VerwaltungsebeneDTO[] = [];
  fuehrungsebenenTree?: FuehrungsebeneNestedDTO;
  accordionExpanded = false;
  taktischeFormationId: string = '';
  isSaving: boolean = false;

  fokusId$ = new BehaviorSubject<string | undefined>(undefined);
  verwaltungsebenen$ = this.fokusId$.pipe(switchMap((v) => this.store.select(verwaltungsebenenIdSelector(v))));
  fuehrungebenen$: BehaviorSubject<FuehrungsebeneNestedDTO[]> = new BehaviorSubject<FuehrungsebeneNestedDTO[]>([]);

  selectedTz$ = this.selectService.selectedTaktischeZeichen$;
  bibliothekFilter$ = combineLatest([
    this.fcFilterText.valueChanges.pipe(startWith(this.fcFilterText.value)),
    this.fcFilterOrganisationen.valueChanges.pipe(startWith(this.fcFilterOrganisationen.value)),
    this.fcFilterGrundzeichen.valueChanges.pipe(startWith(this.fcFilterGrundzeichen.value)),
    this.fcFilterVorlagen.valueChanges.pipe(startWith(this.fcFilterVorlagen.value)),
  ]).pipe(
    takeUntilDestroyed(),
    debounceTime(200),
    map(([filterText, organisationen, grundzeichen, nurVorlagen]) => {
      const filter: BibliothekFilter = {
        filterText: filterText,
        organisationen: organisationen,
        grundzeichen: grundzeichen,
        nurVorlagen: nurVorlagen,
      };
      return filter;
    })
  );
  constructor(@Inject(MAT_DIALOG_DATA) protected data: SelectDialogInputData) {
    this.store
      .select(verwaltungsebenenSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((verwaltungsebeneDTOs) => (this.verwaltungsebenenTree = verwaltungsebeneDTOs));
    this.selectService.clear();
    this.taktischeFormationId = data.taktischeFormationId;
    if (!this.data.excludeTaktischeZeichenTypen?.includes(TaktischesZeichenTyp.Fahrzeug)) {
      this.data.selectedFahrzeuge?.forEach((f) => this.selectService.add(f));
    }
    if (!this.data.excludeTaktischeZeichenTypen?.includes(TaktischesZeichenTyp.Person)) {
      this.data.selectedPersonen?.forEach((f) => this.selectService.add(f));
    }
    if (!this.data.excludeTaktischeZeichenTypen?.includes(TaktischesZeichenTyp.TaktischeFormation)) {
      this.data.selectedTaktischeFormationen?.forEach((f) => this.selectService.add(f));
    }
    if (this.fuehrungsebeneService.getCurrentLage()) {
      const lageId: string = this.fuehrungsebeneService.getCurrentLage()!.id!;
      this.fuehrungsebeneResourceService
        .getNavigationTree(lageId)
        .pipe(take(1))
        .subscribe((tree) => (this.fuehrungsebenenTree = tree));
      this.getFuehrungsebenen();
    }

    this.destroyRef.onDestroy(() => this.selectService.clear());
  }

  onClickVerwaltungsebeneTreeNode(verwaltungsebeneId: string | undefined) {
    this.fokusId$.next(verwaltungsebeneId);
  }

  onClickFuehrungsebeneTreeNode(fuehrungsebeneId: string | undefined) {
    this.getFuehrungsebenen(fuehrungsebeneId);
  }

  onClickClearFilter() {
    this.fcFilterText.setValue('');
    this.fcFilterGrundzeichen.setValue([]);
    this.fcFilterOrganisationen.setValue([]);
    this.fcFilterVorlagen.setValue(false);
  }

  onClickToggleAccordion() {
    this.accordionExpanded = !this.accordionExpanded;
  }

  onClickSelecectedTz(tz: TaktischesZeichenDTO, index: number) {
    this.selectService.removeAt(index);
  }

  onClickSave() {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;

    const dialogResult: SelectDialogOutputData = {
      fahrzeugIds: [],
      personIds: [],
      taktischeFormationIds: [],
      befehlsstelleIds: [],
    };

    this.selectService.selectedTaktischeZeichen$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((tzArr) => {
      tzArr.forEach((tz) => {
        switch (tz.typ) {
          case 'FAHRZEUG':
            dialogResult.fahrzeugIds.push(tz.id as string);
            break;
          case 'PERSON':
            dialogResult.personIds.push(tz.id as string);
            break;
          case 'TAKTISCHE_FORMATION':
            dialogResult.taktischeFormationIds.push(tz.id as string);
            break;
          case 'BEFEHLSSTELLE':
            dialogResult.befehlsstelleIds.push(tz.id as string);
            break;
        }
      });
      this.isSaving = false;
      this.dialogRef.close(dialogResult);
    });
  }

  private getFuehrungsebenen(fuehrungsebeneId?: string) {
    const lageId = this.fuehrungsebeneService.getCurrentLage()?.id;
    if (!fuehrungsebeneId) {
      this.fuehrungsebeneResourceService
        .getNavigationTree(lageId!)
        .pipe(take(1))
        .subscribe((tree: FuehrungsebeneNestedDTO) => this.fuehrungebenen$.next(tree.fuehrungsebeneDTOs));
    } else {
      this.fuehrungsebeneResourceService
        .getNavigationTree(lageId!)
        .pipe(take(1))
        .subscribe((tree: FuehrungsebeneNestedDTO) =>
          this.setFuehrungsebeneById(tree.fuehrungsebeneDTOs, fuehrungsebeneId)
        );
    }
  }

  private setFuehrungsebeneById(arr: FuehrungsebeneNestedDTO[], id: string) {
    for (const ebene of arr) {
      if (ebene.id === id) {
        this.fuehrungebenen$.next([ebene]);
      } else {
        this.setFuehrungsebeneById(ebene.fuehrungsebeneDTOs, id);
      }
    }
  }
}
