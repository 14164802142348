<ng-container *ngIf="personenuebersicht$ | async as personen">
  <mat-toolbar class="header">
    <h4>
      {{ header }}:
      {{
        (personen.gesamtVerletzt || 0) +
          (personen.gesamtTot || 0) +
          (personen.gesamtVermisst || 0) +
          (personen.gesamtBetroffen || 0) +
          (personen.gesamtGerettet || 0) +
          (personen.gesamtTransportiert || 0)
      }}
    </h4>
  </mat-toolbar>

  <div class="columns">
    <mat-card class="column">
      <div class="column-header category1">
        <img [src]="icons.verletzt" />
        <h5>verletzt</h5>
      </div>
      <div class="column-data">
        <h5>{{ personen.gesamtVerletzt }}</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-header">
        <img [src]="icons.tot" />
        <h5>tot</h5>
      </div>
      <div class="column-data">
        <h5>{{ personen.gesamtTot }}</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-header">
        <img [src]="icons.tot" />
        <h5>vermisst</h5>
      </div>
      <div class="column-data">
        <h5>{{ personen.gesamtVermisst }}</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-header">
        <img [src]="icons.betroffen" />
        <h5>betroffen</h5>
      </div>
      <div class="column-data">
        <h5>{{ personen.gesamtBetroffen }}</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-header">
        <img [src]="icons.gerettet" />
        <h5>gerettet</h5>
      </div>
      <div class="column-data">
        <h5>{{ personen.gesamtGerettet }}</h5>
      </div>
    </mat-card>

    <mat-card class="column">
      <div class="column-header">
        <img [src]="icons.transportiert" />
        <h5>transp.</h5>
      </div>
      <div class="column-data">
        <h5>{{ personen.gesamtTransportiert }}</h5>
      </div>
    </mat-card>
  </div>
</ng-container>
