<a mat-button [routerLink]="['/vidal/adhoc-info']" [ngClass]="{ 'nav-selected': router.url === '/vidal/adhoc-info' }">
  AdHoc-Information
</a>

<a mat-button [routerLink]="['/vidal/meldungen']" [ngClass]="{ 'nav-selected': router.url === '/vidal/meldungen' }">
  Meldungen
</a>

<a
  mat-button
  [routerLink]="['/vidal/ressourcen']"
  [ngClass]="{ 'nav-selected': router.url === '/vidal/ressourcen' }"
  disabled
>
  Ressourcen
</a>
<a
  mat-button
  [routerLink]="['/vidal/einheiten']"
  [ngClass]="{ 'nav-selected': router.url === '/vidal/einheiten' }"
  disabled
>
  Einheiten
</a>
<a
  mat-button
  [routerLink]="['/vidal/einsatzstatistik']"
  [ngClass]="{ 'nav-selected': router.url === '/vidal/einsatzstatistik' }"
  disabled
>
  Kontinuierliche Einsatzstatistik
</a>
