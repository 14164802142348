<div class="flex-grow flex-col" cdkDropListGroup>
  <!-- Zeile mit Custom-Elementen -->
  <div class="custom-bar" [style.flexBasis.%]="customCollapsed ? 0 : 20">
    <app-lagedarstellung-custom-componenten
      (componentCreatedEvent)="fuehrungsebeneCreated($event)"
      (editFuehrungsebeneEvent)="editFuehrungsebene($event)"
      [fuehrungsebeneCreatedEvent$]="fuehrungsebeneCreatedEvent.asObservable()"
      [fuehrungsebenen$]="grundschutz$"
    />
  </div>

  <!-- Button zum ein-/ausklappen der Custom-Elemente -->
  <mat-icon
    class="custom-collapse"
    (click)="toggleCollapsed()"
    [matTooltip]="'Custom-Elemente ' + (customCollapsed ? 'ausklappen' : 'einklappen')"
    >{{ customCollapsed ? "expand_more" : "expand_less" }}</mat-icon
  >

  <div class="fahrzeug-containers">
    <!-- Header für Fahrzeugstatus, Bereitstellungsräume, Örtliche Einheiten -->
    <div class="status-headers">
      <mat-toolbar *ngFor="let state of einsatzmittelStates" class="header-item">
        <h3>{{ state | taktischesZeichenStatus }}</h3>
      </mat-toolbar>

      <mat-toolbar class="header-item">
        <h3>Bereitstellungsräume</h3>
        <button
          mat-icon-button
          class="add-br-button"
          (click)="tryCreateBereitstellungsraum()"
          matTooltip="Bereitstellungsraum hinzufügen"
          [disabled]="!allowAddingFuehrungsebenen"
        >
          <mat-icon>add</mat-icon>
        </button>
      </mat-toolbar>

      <mat-toolbar class="header-item">
        <h3>Örtliche Einheiten</h3>
        <button
          mat-icon-button
          class="add-br-button"
          (click)="tryCreateOertlicheEinheiten()"
          matTooltip="Örtliche Einheit hinzufügen"
          [disabled]="!allowAddingFuehrungsebenen"
        >
          <mat-icon>add</mat-icon>
        </button>
      </mat-toolbar>
    </div>

    <!-- Container für Fahrzeugstatus, Bereitstellungsräume, Örtliche Einheiten -->
    <div class="status-columns">
      <!-- Spalten für jeden EinsatzmittelStatus 'Angefordert','Alarmiert', 'Auf Marsch' erzeugen -->
      <div class="status-column" *ngFor="let state of einsatzmittelStates">
        <!-- Jede Spalte in Führungsebenen für alle Bereitstellungsräume unterteilen -->
        <div class="status-column-item" *ngFor="let bereitstellungsraum of bereitstellungsraumDTOs">
          <app-taktische-zeichen-container
            class="scrollable"
            [fuehrungsebeneDTO]="bereitstellungsraum!"
            [taktischesZeichenStatus]="state"
            [allowedTzTypes]="allowedTzTypes"
          />
        </div>
        <h3 class="keine-daten-text" *ngIf="!bereitstellungsraumDTOs.length">
          Noch kein Bereitstellungsraum vorhanden
        </h3>
      </div>

      <!-- Spalte für Bereitstellungsräume-->
      <div class="status-column">
        <div class="status-column-item" *ngFor="let bereitstellungsraum of bereitstellungsraumDTOs">
          <app-fuehrungsebene-header
            [fuehrungsebeneDTO]="bereitstellungsraum"
            (edit)="editFuehrungsebene(bereitstellungsraum)"
          />
          <app-taktische-zeichen-container
            class="scrollable"
            [fuehrungsebeneDTO]="bereitstellungsraum"
            [allowedTzTypes]="allowedTzTypes"
          />
        </div>
        <h3 class="keine-daten-text" *ngIf="!bereitstellungsraumDTOs.length">
          Noch kein Bereitstellungsraum vorhanden
        </h3>
      </div>

      <!-- Spalte für Örtliche Einheiten-->
      <div class="status-column">
        <div class="status-column-item" *ngFor="let oertlicheEinheit of oertlicheEinheiten">
          <app-fuehrungsebene-header
            [fuehrungsebeneDTO]="oertlicheEinheit"
            (edit)="editFuehrungsebene(oertlicheEinheit)"
          />
          <app-taktische-zeichen-container
            class="scrollable"
            [fuehrungsebeneDTO]="oertlicheEinheit"
            [allowedTzTypes]="allowedTzTypes"
          />
        </div>
        <h3 class="keine-daten-text" *ngIf="!oertlicheEinheiten.length">Keine Örtlichen Einheiten vorhanden</h3>
      </div>
    </div>
  </div>
</div>
