import { CdkDropListGroup } from '@angular/cdk/drag-drop';
import { NgFor, NgIf } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import {
  FuehrungsebeneDTO,
  Fuehrungsebenentyp,
  TaktischesZeichenStatus,
  TaktischesZeichenTyp,
} from 'src/app/api/build/openapi';
import {
  currentBereitstellungsraeumeSelector,
  currentFuehrungsebeneSelector,
  currentGrundschutzeSelector,
  currentOertlicheEinheitenSelector,
} from '../fuehrungsebene/+state/fuehrungsebene.selectors';
import { FuehrungsebeneService } from '../fuehrungsebene/fuehrungsebene.service';
import { TaktischeZeichenContainerComponent } from '../taktische-zeichen/taktische-zeichen-container/taktische-zeichen-container.component';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { ErrorService } from '@product/ise-error-lib';
import { LogService } from 'src/app/shared/services/log.service';
import {
  FuehrungsebeneDetailsDialogComponent,
  FuehrungsebeneDialogData,
} from '../fuehrungsebene/fuehrungsebene-details-dialog/fuehrungsebene-details-dialog.component';
import { FuehrungsebeneHeaderComponent } from '../fuehrungsebene/fuehrungsebene-header/fuehrungsebene-header.component';
import {
  ComponentAddedEvent,
  LagedarstellungCustomComponentenComponent,
} from '../lagedarstellung-custom-componenten/lagedarstellung-custom-componenten.component';
import { PopupManagerService } from '../monitor-manager/popup-manager.service';
import { TaktischesZeichenStatusPipe } from '../taktische-zeichen/taktisches-zeichen-status.pipe';

/**
 * "Monitor 1" Ansicht mit Bereitstellungsräumen, Grundschutz und Örtlichen Einheiten.
 * Bildet Personen/Fahrzeugen in den Zuständen Angefordert, Alarmiert, Auf Marsch ab und erlaubt Anlegen/Bearbeiten.
 */
@Component({
  selector: 'app-einsatzmittel-base',
  templateUrl: './einsatzmittel-base.component.html',
  styleUrls: ['./einsatzmittel-base.component.scss'],
  standalone: true,
  imports: [
    CdkDropListGroup,
    MatCardModule,
    NgIf,
    FuehrungsebeneHeaderComponent,
    TaktischeZeichenContainerComponent,
    MatIconModule,
    MatTooltipModule,
    NgFor,
    MatToolbarModule,
    MatButtonModule,
    LagedarstellungCustomComponentenComponent,
    TaktischesZeichenStatusPipe,
  ],
})
export class EinsatzmittelBaseComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private dialog = inject(MatDialog);
  private errorService = inject(ErrorService);
  protected fuehrungsebeneService = inject(FuehrungsebeneService);
  private logger = inject(LogService);
  private popupService = inject(PopupManagerService);
  private route = inject(ActivatedRoute);
  private store = inject(Store);

  fuehrungsebeneCreatedEvent: Subject<FuehrungsebeneDTO> = new Subject<FuehrungsebeneDTO>();

  // Im ersten Tab dürfen nur Fahrzeuge, Personen, Taktische Formationen angelegt werden
  readonly allowedTzTypes: TaktischesZeichenTyp[] = [
    TaktischesZeichenTyp.Fahrzeug,
    TaktischesZeichenTyp.Person,
    TaktischesZeichenTyp.TaktischeFormation,
  ];

  einsatzmittelStates = [
    TaktischesZeichenStatus.Angefordert,
    TaktischesZeichenStatus.Alarmiert,
    TaktischesZeichenStatus.AufMarsch,
  ];

  TaktischesZeichenStatus = TaktischesZeichenStatus;

  grundschutz$ = this.store.select(currentGrundschutzeSelector);
  bereitstellungsraumDTOs: FuehrungsebeneDTO[] = [];
  oertlicheEinheiten: FuehrungsebeneDTO[] = [];
  currentFuehrungsebeneDTO: FuehrungsebeneDTO | null = null;

  allowAddingFuehrungsebenen = false;

  constructor() {
    this.popupService.initIfPopUp(this.route);
  }

  ngOnInit(): void {
    combineLatest([
      this.store.select(currentFuehrungsebeneSelector),
      this.store.select(currentBereitstellungsraeumeSelector),
      this.store.select(currentOertlicheEinheitenSelector),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([currentFuehrungsebeneDTO, bereitstellungsraumDTOs, oertlicheEinheitDTOs]) => {
        this.currentFuehrungsebeneDTO = currentFuehrungsebeneDTO;
        this.bereitstellungsraumDTOs = bereitstellungsraumDTOs;
        this.oertlicheEinheiten = oertlicheEinheitDTOs;

        this.allowAddingFuehrungsebenen = currentFuehrungsebeneDTO?.typ !== Fuehrungsebenentyp.Lage;
      });
  }

  // True, wenn Custom-Elemente ausgeblendet werden sollen
  customCollapsed = false;

  /**
   * Bereitet neuen Bereitstellugnsraum vor und öffnet einen Dialog zum Bearbeiten.
   * Wird erst gespeichert, wenn aktiv auf "Speichern" gedrückt wird.
   */
  tryCreateBereitstellungsraum() {
    this.fuehrungsebeneService.getAllowedChildTypes().subscribe((allowedTypes) => {
      if (!allowedTypes.includes(Fuehrungsebenentyp.Bereitstellungsraum)) {
        this.errorService.showErrorMessage(
          'Es darf kein weiterer Bereitstellungsraum angelegt werden. Das Limit ist erreicht.'
        );
        return;
      }

      const currentFuehrungsbene = this.fuehrungsebeneService.getCurrentFuehrungsebene();
      if (!currentFuehrungsbene) {
        console.warn('Keine CurrentFuehrungsebene vorhanden');
        return;
      }
      const newBereitstellungsraumDto = this.fuehrungsebeneService.prepareNewChildFuehrungsebene(
        currentFuehrungsbene,
        Fuehrungsebenentyp.Bereitstellungsraum
      );
      if (newBereitstellungsraumDto) {
        this.openFuehrungsebeneDetails(newBereitstellungsraumDto);
      }
    });
  }

  tryCreateGrundschutz(slot: string) {
    this.fuehrungsebeneService.getAllowedChildTypes().subscribe((allowedTypes) => {
      if (!allowedTypes.includes(Fuehrungsebenentyp.Grundschutz)) {
        this.errorService.showErrorMessage(
          'Es darf kein weiterer Grundschutz angelegt werden. Das Limit ist erreicht.'
        );
        return;
      }

      const currentFuehrungsbene = this.fuehrungsebeneService.getCurrentFuehrungsebene();
      if (!currentFuehrungsbene) {
        console.warn('Keine CurrentFuehrungsebene vorhanden');
        return;
      }
      const newGrundschutzDto = this.fuehrungsebeneService.prepareNewChildFuehrungsebene(
        currentFuehrungsbene,
        Fuehrungsebenentyp.Grundschutz
      );
      if (!newGrundschutzDto) {
        this.logger.error('Grundschutz konnte nicht angelegt werden');
        return;
      }

      const inputData: FuehrungsebeneDialogData = {
        fuehrungsebeneDTO: { ...newGrundschutzDto },
        filteredTypes: [Fuehrungsebenentyp.Grundschutz],
      };

      this.dialog
        .open(FuehrungsebeneDetailsDialogComponent, {
          data: inputData,
        })
        .afterClosed()
        .subscribe((grundschutzDto) => {
          this.savePositionForFuehrungsebene(grundschutzDto, slot);
          this.fuehrungsebeneCreatedEvent.next(grundschutzDto);
        });
    });
  }

  tryCreateOertlicheEinheiten() {
    this.fuehrungsebeneService.getAllowedChildTypes().subscribe((allowedTypes) => {
      if (!allowedTypes.includes(Fuehrungsebenentyp.Oertlich)) {
        this.errorService.showErrorMessage(
          'Es dürfen keine weiteren Örtlichen Einheiten angelegt werden. Das Limit ist erreicht.'
        );
        return;
      }

      const currentFuehrungsbene = this.fuehrungsebeneService.getCurrentFuehrungsebene();
      if (!currentFuehrungsbene) {
        console.warn('Keine CurrentFuehrungsebene vorhanden');
        return;
      }
      const newOrtlicheEinheitenDto = this.fuehrungsebeneService.prepareNewChildFuehrungsebene(
        currentFuehrungsbene,
        Fuehrungsebenentyp.Oertlich
      );
      if (newOrtlicheEinheitenDto) {
        this.openFuehrungsebeneDetails(newOrtlicheEinheitenDto);
      }
    });
  }

  /**
   * Öffnet Dialog zum Bearbeiten der übergebenen Führungsebene.
   */
  editFuehrungsebene(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    this.openFuehrungsebeneDetails({ ...fuehrungsebeneDTO });
  }

  /**
   * Öffnet Dialog zum Bearbeiten der übergebenen Führungsebene.
   * (Fasst neue und bestehende Führungsebenen zusammen)
   */
  private openFuehrungsebeneDetails(fuehrungsebeneDTO: FuehrungsebeneDTO) {
    const inputData: FuehrungsebeneDialogData = {
      fuehrungsebeneDTO: fuehrungsebeneDTO,
      filteredTypes: [fuehrungsebeneDTO.typ],
    };
    this.dialog.open(FuehrungsebeneDetailsDialogComponent, {
      data: inputData,
    });
  }

  /**
   * Klappt die obere Zeile mit Custom-Elementen, wie z.B. Grundschutz ein und aus.
   */
  toggleCollapsed() {
    this.customCollapsed = !this.customCollapsed;
    window.dispatchEvent(new Event('resize'));
  }

  fuehrungsebeneCreated(event: ComponentAddedEvent) {
    switch (event.name) {
      case 'Grundschutz':
        this.tryCreateGrundschutz(event.slot);
        break;
    }
  }

  savePositionForFuehrungsebene(fuehrungsebeneDTO: FuehrungsebeneDTO, slot: string) {
    localStorage.setItem(`${fuehrungsebeneDTO.lageId}/${fuehrungsebeneDTO.id}`, slot);
  }
}
