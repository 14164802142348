import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ApplicationType } from 'src/app/api/build/openapi';
import { AppService } from 'src/app/app.service';
import { erzeugeTaktischesZeichen, TaktischesZeichen } from 'taktische-zeichen-core';

export interface PersonenIcons {
  verletzt: string;
  tot: string;
  vermisst: string;
  betroffen: string;
  gerettet: string;
  transportiert: string;
}

export const PERSONEN_LIGHTMODE: PersonenIcons = {
  verletzt: 'assets/svg/tz/personen/person-verletzt.svg',
  tot: 'assets/svg/tz/personen/person-tot.svg',
  vermisst: 'assets/svg/tz/personen/person-vermisst.svg',
  betroffen: 'assets/svg/tz/personen/person-betroffen.svg',
  gerettet: 'assets/svg/tz/personen/person-gerettet.svg',
  transportiert: 'assets/svg/tz/personen/person-transportiert.svg',
};

export const PERSONEN_DARKMODE: PersonenIcons = {
  verletzt: 'assets/svg/tz/personen/person-verletzt_darkmode.svg',
  tot: 'assets/svg/tz/personen/person-tot_darkmode.svg',
  vermisst: 'assets/svg/tz/personen/person-vermisst_darkmode.svg',
  betroffen: 'assets/svg/tz/personen/person-betroffen_darkmode.svg',
  gerettet: 'assets/svg/tz/personen/person-gerettet_darkmode.svg',
  transportiert: 'assets/svg/tz/personen/person-transportiert_darkmode.svg',
};

export interface ApplicationLogos {
  lage: string;
  lageK: string;
  ise: string;
}

export const APPLICATION_LIGHTMODE: ApplicationLogos = {
  ise: 'assets/svg/logo_ise.svg',
  lage: 'assets/svg/logo_lage.svg',
  lageK: 'assets/svg/logo_lage_k.svg',
};

export const APPLICATION_DARKMODE: ApplicationLogos = {
  ise: 'assets/svg/logo_ise_darkmode.svg',
  lage: 'assets/svg/logo_lage_darkmode.svg',
  lageK: 'assets/svg/logo_lage_k_darkmode.svg',
};

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private appService = inject(AppService);
  private darkmode$ = this.appService.isDarkMode();
  private currentApp$ = this.appService.selectedApplicationObservable();

  private applicationLogos$: BehaviorSubject<ApplicationLogos> = new BehaviorSubject(APPLICATION_LIGHTMODE);
  private personenIcons$: BehaviorSubject<PersonenIcons> = new BehaviorSubject(PERSONEN_LIGHTMODE);

  private currentAppLogo$: BehaviorSubject<string> = new BehaviorSubject('');

  private canvas?: HTMLCanvasElement;

  constructor() {
    combineLatest([this.darkmode$, this.currentApp$])
      .pipe(takeUntilDestroyed())
      .subscribe(([darkmode, type]) => {
        if (darkmode) {
          this.setDarkMode(type);
        } else {
          this.setLightMode(type);
        }
      });
  }

  getApplicationLogos(): Observable<ApplicationLogos> {
    return this.applicationLogos$.asObservable();
  }

  getPersonenIcons(): Observable<PersonenIcons> {
    return this.personenIcons$.asObservable();
  }

  getCurrentApplicationLogo(): Observable<string> {
    return this.currentAppLogo$.asObservable();
  }

  /**
   * Erzeugt eine webp-dataurl basierend auf einem TaktischenZeichen (TZ-Library)
   * Die Library arbeitet mit SVGs, wodurch die generierten Icons zu groß werden können (~30kB)
   * WebP liegt da bei 2-3kB.
   */
  public generateCompressedDataUrl(taktischesZeichen: TaktischesZeichen): Observable<string> {
    const img = new Image();
    return new Observable<string>((observer) => {
      img.onerror = () => observer.error(new Error('Fehler beim Laden des SVG-Bildes.'));
      img.onload = () => {
        const MAX_SIZE = 128;

        // Icon bei gleicher Ratio so skalieren, dass mindestens eine Seite 128px hat
        let width = MAX_SIZE;
        let height = MAX_SIZE;
        if (img.width > img.height && img.width) {
          height = (MAX_SIZE * img.height) / img.width;
        }
        if (img.width < img.height && img.height) {
          width = (MAX_SIZE * img.width) / img.height;
        }

        // Canvas auf 128x128 setzen
        if (!this.canvas) {
          this.canvas = document.createElement('canvas');
        }
        this.canvas.width = MAX_SIZE;
        this.canvas.height = MAX_SIZE;

        // Padding berechnen, damit Icon zentriert im Canvas liegt
        const paddingWidth = (MAX_SIZE - width) / 2;
        const paddingHeight = (MAX_SIZE - height) / 2;

        const ctx = this.canvas.getContext('2d');
        ctx?.drawImage(img, paddingWidth, paddingHeight, width, height);

        const pngDataUrl = this.canvas.toDataURL('image/webp', 1);
        observer.next(pngDataUrl);
        observer.complete();
      };

      const svgString = erzeugeTaktischesZeichen(taktischesZeichen).toString();
      const svgDataUrl = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgString);
      img.src = svgDataUrl;
    });
  }

  private setDarkMode(appType: ApplicationType | undefined) {
    this.applicationLogos$.next(APPLICATION_DARKMODE);
    this.personenIcons$.next(PERSONEN_DARKMODE);

    if (appType) {
      this.currentAppLogo$.next(
        appType === ApplicationType.Lage ? APPLICATION_DARKMODE.lage : APPLICATION_DARKMODE.lageK
      );
    }
  }

  private setLightMode(appType: ApplicationType | undefined) {
    this.applicationLogos$.next(APPLICATION_LIGHTMODE);
    this.personenIcons$.next(PERSONEN_LIGHTMODE);

    if (appType) {
      this.currentAppLogo$.next(
        appType === ApplicationType.Lage ? APPLICATION_LIGHTMODE.lage : APPLICATION_LIGHTMODE.lageK
      );
    }
  }
}
