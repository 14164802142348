import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { ErrorService } from '@product/ise-error-lib';
import { Observable, map, take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { BefehlsstelleDTO, Beweglichkeit, GebaeudeDTO, TaktischesZeichenTyp } from 'src/app/api/build/openapi';
import { KontaktListComponent } from 'src/app/lagedarstellung/kontakt/kontakt-list/kontakt-list.component';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { IconService } from 'src/app/shared/services/icon.service';
import { compareLabels } from 'src/app/taktische-zeichen/taktische-zeichen-form/taktische-zeichen-form.component';
import {
  Fachaufgabe,
  Organisation,
  Symbol,
  TaktischesZeichen,
  fachaufgaben,
  organisationen,
  symbole,
} from 'taktische-zeichen-core';
import { befehlsstellenSelector } from '../../befehlsstelle/+state/befehlsstelle.selectors';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

@Component({
  selector: 'app-gebaeude-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatRadioModule,
    KontaktListComponent,
    CleanableFormFieldComponent,
    MatTooltipModule,
    MatInputModule,
  ],
  templateUrl: './gebaeude-form.component.html',
  styleUrls: ['./gebaeude-form.component.scss'],
})
export class GebaeudeFormComponent implements TaktischesZeichenForm {
  @ViewChild(KontaktListComponent)
  kontaktList!: KontaktListComponent;

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  iconDataUrlChanged = new EventEmitter<string>();

  formGroup: FormGroup;

  readonly DEFAULT_ICON_DATA: TaktischesZeichen = { grundzeichen: 'gebaeude' };
  iconData: TaktischesZeichen = { ...this.DEFAULT_ICON_DATA };
  private isCustomIcon = false;
  private iconDataUrl?: string;

  // Dropdown-Werte
  organisationValues: Organisation[] = organisationen.sort(compareLabels);
  fachaufgabeValues: Fachaufgabe[] = fachaufgaben.sort(compareLabels);
  symbolValues: Symbol[] = symbole.sort(compareLabels);
  befehlsstelleValues$: Observable<BefehlsstelleDTO[]>;

  fachaufgabeMouseover = false;
  symbolMouseover = false;
  organisationMouseover = false;
  befehlsstelleMouseover = false;

  gebaeudeToEdit?: GebaeudeDTO;

  private errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);
  private iconService = inject(IconService);

  fcAnzeigename = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcOrganisation = this.formBuilder.control<Organisation | undefined>(undefined);
  fcFachaufgabe = this.formBuilder.control<Fachaufgabe | undefined>(undefined);
  fcSymbol = this.formBuilder.control<Symbol | undefined>(undefined);
  fcZeichenText = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcBefehlsstelle = this.formBuilder.control<BefehlsstelleDTO | undefined>(undefined);

  constructor(store: Store<AppStateInterface>) {
    this.befehlsstelleValues$ = store.select(befehlsstellenSelector).pipe(
      takeUntilDestroyed(),
      map((arr) => arr.filter((b) => b.beweglichkeit === Beweglichkeit.Ortsfest))
    );
    this.formGroup = this.formBuilder.group({
      anzeigename: this.fcAnzeigename,
      organisation: this.fcOrganisation,
      fachaufgabe: this.fcFachaufgabe,
      symbol: this.fcSymbol,
      zeichenText: this.fcZeichenText,
      befehlsstelle: this.fcBefehlsstelle,
    });

    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v?.trim());
    });

    this.fcOrganisation.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.organisation = v?.id;
      this.handleIconDataChanged();
    });

    this.fcFachaufgabe.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.fachaufgabe = v?.id;
      this.handleIconDataChanged();
    });

    this.fcSymbol.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.symbol = v?.id;
      this.handleIconDataChanged();
    });

    this.fcZeichenText.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.text = v?.trim() || '';
      this.handleIconDataChanged();
    });
  }

  setDTO(gebaeudeDTO: GebaeudeDTO) {
    if (!gebaeudeDTO) {
      this.errorService.showErrorMessage('Kein Model zur Bearbeitung vorhanden.');
      return;
    }

    this.gebaeudeToEdit = gebaeudeDTO;
    this.dtoToFormGroup(this.gebaeudeToEdit);
    this.handleIconDataChanged();
  }

  /**
   * Aktualisiertes GebaeudeDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): GebaeudeDTO | null {
    if (this.formGroup.valid) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * FormGroup Werte in PersonDTO schreiben und zurückliefern
   */
  formGroupToDto(): GebaeudeDTO {
    return {
      ...this.gebaeudeToEdit,
      customZeichen: this.isCustomIcon,
      dataUrl: this.iconDataUrl,
      anzeigename: this.fcAnzeigename.value?.trim(),
      organisation: this.fcOrganisation.value?.id,
      fachaufgabe: this.fcFachaufgabe.value?.id,
      symbol: this.fcSymbol.value?.id,
      zeichenText: this.fcZeichenText.value?.trim(),
      befehlsstelleId: this.fcBefehlsstelle.value?.id,
      kommunikationOptionen: this.kontaktList.getKommunikationOptionen(),
      typ: TaktischesZeichenTyp.Gebaeude,
    };
  }

  /**
   * FormGroup mit Werten aus PersonDTO füllen
   */
  dtoToFormGroup(gebaeudeDTO: GebaeudeDTO): void {
    this.isCustomIcon = gebaeudeDTO.customZeichen || false;
    this.iconDataUrl = gebaeudeDTO.dataUrl;

    this.fcAnzeigename.setValue(gebaeudeDTO.anzeigename);
    this.fcOrganisation.setValue(organisationen.find((v) => v.id === gebaeudeDTO.organisation));
    this.fcFachaufgabe.setValue(fachaufgaben.find((v) => v.id === gebaeudeDTO.fachaufgabe));
    this.fcSymbol.setValue(symbole.find((v) => v.id === gebaeudeDTO.symbol));
    this.fcZeichenText.setValue(gebaeudeDTO.zeichenText);
    if (gebaeudeDTO.befehlsstelleId) {
      this.befehlsstelleValues$
        .pipe(take(1))
        .subscribe((befehlsstelle) =>
          this.fcBefehlsstelle.setValue(befehlsstelle.find((b) => b.id === gebaeudeDTO.befehlsstelleId))
        );
    }
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }

  private handleIconDataChanged(): void {
    if (!this.isCustomIcon) {
      this.iconService.generateCompressedDataUrl(this.iconData).subscribe((dataUrl) => {
        this.iconDataUrl = dataUrl;
        this.iconDataUrlChanged.emit(this.iconDataUrl);
      });
    }
  }

  public setCustomIcon(iconDataUrl: string): void {
    this.isCustomIcon = true;
    this.iconDataUrl = iconDataUrl;
  }

  public removeCustomIcon(): void {
    this.isCustomIcon = false;
    this.handleIconDataChanged();
  }
}
