import { Component, inject } from '@angular/core';
import { FormControl, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorService } from '@product/ise-error-lib';
import { ParticipantDTO } from 'src/app/api/build/openapi';
import {
  ChipFixedListInputComponent,
  ValueWithId,
} from '../../../shared/components/chip-fixed-list-input/chip-fixed-list-input.component';

export class DialogData {
  /** Optionen die ausgewält werden können */
  options: ParticipantDTO[] = [];
  /** Optionen die vorbesetzt sind */
  preset?: ParticipantDTO[] = [];
}

export class DialogResult {
  recipientsOids: string[] = [];
  subject = '';
  message = '';
}

@Component({
  selector: 'app-adhoc-info-dialog',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule,
    ChipFixedListInputComponent,
  ],
  templateUrl: './adhoc-info-dialog.component.html',
  styleUrl: './adhoc-info-dialog.component.scss',
})
export class AdhocInfoDialogComponent {
  private formBuilder = inject(NonNullableFormBuilder);
  private errorMessageService = inject(ErrorService);

  private dialogData: DialogData = inject(MAT_DIALOG_DATA);

  protected fcEmpfaenger = this.formBuilder.control<ValueWithId[]>([], [Validators.required]);
  protected fcBetreff = this.formBuilder.control<string>('', [Validators.required, Validators.maxLength(2000)]);
  protected fcNachricht = this.formBuilder.control<string>('', [Validators.maxLength(10_000)]);

  // FIXME fcEmpfaenger
  protected formGroup = this.formBuilder.group([this.fcBetreff, this.fcNachricht]);

  protected options = this.dialogData.options.map(
    (p) => ({ viewValue: p.shortName + ' (' + p.operatorName + ')', id: p.oid } as ValueWithId)
  );
  protected preselectedOptions: ValueWithId[] = [];

  constructor(private dialogRef: MatDialogRef<AdhocInfoDialogComponent>) {
    if (this.dialogData.preset && this.dialogData.preset.length) {
      const presetValue = this.dialogData.preset.map((p) => ({ viewValue: p.shortName, id: p.oid } as ValueWithId));
      this.fcEmpfaenger.setValue(presetValue);
    }
  }

  sendAdHocInfo() {
    // FIXME fcEmpfaenger validieren
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const recipientsOids = this.fcEmpfaenger.value.map((v) => v.id);

    const result: DialogResult = {
      recipientsOids,
      subject: this.fcBetreff.value,
      message: this.fcNachricht.value,
    };

    this.dialogRef.close(result);
  }

  getErrorMessage(control: FormControl): string {
    return this.errorMessageService.getErrorMessage(control);
  }
}
