import { CommonModule, NgFor, NgIf } from '@angular/common';
import {
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, map, of, switchMap, take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import {
  BibFahrzeugDTO,
  BibPersonDTO,
  BibTaktischeFormationDTO,
  Kommunikation,
  TaktischesZeichenTyp,
  VerwaltungsebeneDTO,
} from 'src/app/api/build/openapi';
import { KontaktService } from 'src/app/lagedarstellung/kontakt/kontakt.service';

import { Overlay } from '@angular/cdk/overlay';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TaktischesZeichenDTO } from 'src/app/api/build/openapi/model/taktischesZeichenDTO';
import { LageService } from 'src/app/lagedarstellung/lagen/lage.service';
import { TzBubbleMenuService } from 'src/app/lagedarstellung/taktische-zeichen/tz-bubble-menu.service';
import { TzDialogService } from 'src/app/lagedarstellung/taktische-zeichen/tz-dialog.service';
import { TzMultiSelectService } from 'src/app/lagedarstellung/taktische-zeichen/tz-multi-select-service';
import {
  fahrzeugeWithAnyVerwaltungsebeneIdSelector,
  fahrzeugeWithVerwaltungsebeneIdSelector,
  personenWithAnyVerwaltungsebeneIdSelector,
  personenWithVerwaltungsebeneIdSelector,
  taktischeFormationenWithAnyVerwaltungsebeneIdSelector,
  taktischeFormationenWithVerwaltungsebeneIdSelector,
} from '../+state/bibliothek.selectors';
import {
  TaktischeZeichenItemComponent,
  TzItemEvent,
} from '../../../lagedarstellung/taktische-zeichen/taktische-zeichen-container/taktische-zeichen-item/taktische-zeichen-item.component';
import { VerwaltungsebeneDialogComponent } from '../verwaltungsebene-dialog/verwaltungsebene-dialog.component';
import { verwaltungsebenenMap } from '../verwaltungsebenenMap';
import { TaktischeZeichenSelectionService } from './taktische-zeichen-selection.service';
import { BibliothekFilter } from '../bibliothek-base/bibliothek-filter';

export type BibTzDTO = BibFahrzeugDTO | BibPersonDTO | BibTaktischeFormationDTO;

@Component({
  selector: 'app-verwaltungsebene-details',
  standalone: true,
  templateUrl: './verwaltungsebene-details.component.html',
  styleUrls: ['./verwaltungsebene-details.component.scss'],
  imports: [
    CommonModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    TaktischeZeichenItemComponent,
    MatExpansionModule,
    NgIf,
    NgFor,
  ],
})
export class VerwaltungsebeneDetailsComponent implements OnInit, OnChanges {
  private destroyRef = inject(DestroyRef);

  TaktischesZeichenTyp = TaktischesZeichenTyp;
  verwaltungsebenenMap = verwaltungsebenenMap;
  @Input({ required: true }) verwaltungsebene!: VerwaltungsebeneDTO;
  @Input() expand = false;
  @Input() expandChildren = false;

  /**
   * Für die Verwendung in einer Lage.
   *
   * Es wird das Editieren der Taktischen Zeichen und Verwaltungsebenen unterbunden.
   *
   * Beim Selektieren eines TZ wird dies dem "TaktischeZeichenSelectionService" gemeldet.
   */
  @Input() selectionMode = false;

  // accordionExpanded = false;
  @ViewChild(MatAccordion) accordion!: MatAccordion | undefined;

  @Input({ required: true })
  bibliothekFilter$!: Observable<BibliothekFilter>;

  @Input()
  taktischeFormationId: string = '';

  @Input()
  exludeTaktischesZeichenTypen: TaktischesZeichenTyp[] = [];

  @Input()
  singleSelect: boolean = false;

  fahrzeuge: BibFahrzeugDTO[] = [];
  personen: BibPersonDTO[] = [];
  taktischeFormationen: BibTaktischeFormationDTO[] = [];

  private tzBubbleMenuService = inject(TzBubbleMenuService);
  private lageService = inject(LageService);
  private multiSelectService = inject(TzMultiSelectService);
  protected tzDialogService = inject(TzDialogService);

  private anyTzInLage$: Observable<boolean> = of(false);

  constructor(
    private dialog: MatDialog,
    private kontaktService: KontaktService,
    private store: Store<AppStateInterface>,
    private selectionService: TaktischeZeichenSelectionService,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay
  ) {}

  ngOnInit(): void {
    if (!this.verwaltungsebene.id) {
      return;
    }
    // BibliothekFilter auf Fahrzeuge anwenden
    if (!this.exludeTaktischesZeichenTypen.includes(TaktischesZeichenTyp.Fahrzeug)) {
      combineLatest([
        this.store.select(fahrzeugeWithVerwaltungsebeneIdSelector(this.verwaltungsebene.id)),
        this.bibliothekFilter$,
      ])
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          map(([fahrzeugDTOs, filter]) => this.filterTZs(fahrzeugDTOs, filter) as BibFahrzeugDTO[])
        )
        .subscribe((fahrzeuge) => (this.fahrzeuge = fahrzeuge));
    }

    // BibliothekFilter auf Personen anwenden
    if (!this.exludeTaktischesZeichenTypen.includes(TaktischesZeichenTyp.Person)) {
      combineLatest([
        this.store.select(personenWithVerwaltungsebeneIdSelector(this.verwaltungsebene.id)),
        this.bibliothekFilter$,
      ])
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          map(([personDTOs, filter]) => this.filterTZs(personDTOs, filter) as BibPersonDTO[])
        )
        .subscribe((personen) => (this.personen = personen));
    }
    // BibliothekFilter auf Taktische Formationen anwenden
    if (!this.exludeTaktischesZeichenTypen.includes(TaktischesZeichenTyp.TaktischeFormation)) {
      combineLatest([
        this.store.select(taktischeFormationenWithVerwaltungsebeneIdSelector(this.verwaltungsebene.id)),
        this.bibliothekFilter$,
      ])
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          map(
            ([taktischeFormationDTOs, filter]) =>
              this.filterTZs(
                taktischeFormationDTOs.filter((tf) => tf.id != this.taktischeFormationId),
                filter
              ) as BibTaktischeFormationDTO[]
          )
        )
        .subscribe((taktischeFormationen) => (this.taktischeFormationen = taktischeFormationen));
    }
    // FIXME ggf. wg. Performance nicht direkt alle TZ sammeln und erst dann prüfen? Besser erst TF, Personen und dann Fahrzeuge?
    /**
     * Die ID einer Verwaltungsebene und die aller Kinder (rekursiv) werden zusammengesucht.
     * Es werden alle Fahrzeuge, Personen und Taktischen Formationen die zu einer Verwaltungsebene passen darauf überprüft, ob sie in einer Lage sind.
     * Observable liefert `false` sobald nur eins der gefundnen in einer Lage ist
     */
    this.anyTzInLage$ = of(this.getAllVerwaltungsebenenIds(this.verwaltungsebene)).pipe(
      switchMap((verwaltungsebenenIds) =>
        combineLatest([
          this.store.select(fahrzeugeWithAnyVerwaltungsebeneIdSelector(verwaltungsebenenIds)),
          this.store.select(personenWithAnyVerwaltungsebeneIdSelector(verwaltungsebenenIds)),
          this.store.select(taktischeFormationenWithAnyVerwaltungsebeneIdSelector(verwaltungsebenenIds)),
        ]).pipe(
          takeUntilDestroyed(this.destroyRef),
          map(
            ([personen, fahrzeuge, taktischeFormationen]) =>
              personen.some((p) => !!p.lageId) ||
              fahrzeuge.some((p) => !!p.lageId) ||
              taktischeFormationen.some((p) => !!p.lageId)
          )
        )
      )
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.accordion) {
      if (changes['expandChildren'] && !changes['expandChildren'].currentValue) {
        this.accordion.closeAll();
      } else {
        this.accordion.openAll();
      }
    }
  }

  edit(event: MouseEvent) {
    this.anyTzInLage$.pipe(take(1)).subscribe((v) => {
      event.stopPropagation();
      this.dialog.open(VerwaltungsebeneDialogComponent, {
        data: { verwaltungsebene: this.verwaltungsebene, canDelete: v },
      });
    });
  }

  hasKommunikationsOptionen = () =>
    this.verwaltungsebene.kommunikationOptionen && this.verwaltungsebene.kommunikationOptionen.length > 0;

  summarizeKommunikation(kommunikation: Kommunikation): string {
    return this.kontaktService.summarizeKommunikation(kommunikation);
  }

  getKommunikationIcon(kommunikation: Kommunikation): string {
    const value = kommunikation.kontaktTyp
      ? this.kontaktService.KontaktTypMapping.get(kommunikation.kontaktTyp)
      : undefined;
    return value?.icon || '';
  }

  // #########################
  // Nur im "selectionMode"
  // #########################

  toggleSelection(zeichen: TaktischesZeichenDTO, event: MouseEvent) {
    event.stopPropagation();
    if (!this.selectionMode || (this.singleSelect && this.selectionService.hasSelection() && !this.isSelected(zeichen)))
      return;
    this.selectionService.toggleSelection(zeichen);
  }

  isSelected(zeichen: TaktischesZeichenDTO): boolean {
    if (this.selectionMode) {
      return this.selectionService.isSelected(zeichen);
    }

    return this.multiSelectService.isSelected(zeichen);
  }

  getTooltip(zeichen: BibTzDTO): string {
    let tooltip: string = `${zeichen.anzeigename}`;
    if (this.hasLage(zeichen)) {
      const lage = this.lageService.getLageById(zeichen.reservedByLageId ? zeichen.reservedByLageId : '');
      tooltip = tooltip + (lage !== undefined ? ` | Lage: ${lage.name}` : '');
    }

    return tooltip;
  }

  /**
   * Prüft, ob das übergebene TZ eine Lage-ID besitzt, also einer Lage zugeordnet ist.
   *
   * Sorgt damit dafür (im Template), dass das Zeichen nicht editiert werden kann.
   *
   * @param zeichen
   * @returns
   */
  hasLage(zeichen: BibTzDTO): boolean {
    return !!zeichen.lageTzId;
  }

  private getAllVerwaltungsebenenIds = (verwaltungsebene: VerwaltungsebeneDTO): string[] => {
    let resp: string[] = [];

    if (!verwaltungsebene.id) {
      return [];
    }

    if (verwaltungsebene.verwaltungsebenen) {
      verwaltungsebene.verwaltungsebenen.forEach((v) => (resp = resp.concat(this.getAllVerwaltungsebenenIds(v))));
    }

    return [verwaltungsebene.id, ...resp];
  };

  /**
   * BibliothekFilter für verschiedene TZs anwenden
   */
  private filterTZs(tzDTOs: BibTzDTO[], filter: BibliothekFilter) {
    if (!tzDTOs || !tzDTOs.length) {
      return [];
    }

    if (filter.grundzeichen.length) {
      if (
        (tzDTOs[0].typ === TaktischesZeichenTyp.Fahrzeug && !filter.grundzeichen.includes('fahrzeug')) ||
        (tzDTOs[0].typ === TaktischesZeichenTyp.Person && !filter.grundzeichen.includes('person')) ||
        (tzDTOs[0].typ === TaktischesZeichenTyp.TaktischeFormation &&
          !filter.grundzeichen.includes('taktische-formation'))
      ) {
        return [];
      }
    }

    return tzDTOs.filter((tzDTO: BibTzDTO) => {
      if (filter.filterText.length && !tzDTO.anzeigename.toLowerCase().includes(filter.filterText.toLowerCase())) {
        return false;
      }

      if (
        filter.organisationen.length &&
        (!tzDTO.organisation || !filter.organisationen.includes(tzDTO.organisation))
      ) {
        return false;
      }

      if (filter.nurVorlagen && !tzDTO.vorlage) {
        return false;
      }

      return true;
    });
  }

  selectTz(tzClickEvent: TzItemEvent) {
    // Multiselect nicht im SelectionMode nutzen
    if (this.selectionMode || (tzClickEvent.dto as BibTzDTO).unavailable) {
      return;
    }

    tzClickEvent.mouseEvent.stopPropagation();
    if (!tzClickEvent.mouseEvent.ctrlKey) {
      this.multiSelectService.clearSelection();
      this.multiSelectService.add(tzClickEvent.dto);
    } else {
      this.multiSelectService.toggleSelection(tzClickEvent.dto);
    }
  }

  openTzBubbleMenu(tzClickEvent: TzItemEvent) {
    // Contextmenu nicht im SelectionMode öffnen
    if (this.selectionMode || (tzClickEvent.dto as BibTzDTO).unavailable) {
      return;
    }

    if (!tzClickEvent.mouseEvent.ctrlKey && !this.multiSelectService.isSelected(tzClickEvent.dto)) {
      this.multiSelectService.clearSelection();
      this.multiSelectService.add(tzClickEvent.dto);
    }
    this.tzBubbleMenuService.openTzBubbleMenu(tzClickEvent, true);
  }

  clearSelection(event: MouseEvent) {
    if (!event.ctrlKey) {
      this.multiSelectService.clearSelection();
    }
  }

  createTz(event: MouseEvent) {
    event.stopPropagation();
    this.tzDialogService.openCreateTzDialogBib(this.verwaltungsebene);
  }
}
