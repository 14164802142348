<mat-card>
  <mat-toolbar>
    <h3>
      {{ header }}:
      {{ verletzt.value + tot.value + vermisst.value + betroffen.value + gerettet.value + transportiert.value }}
    </h3>
  </mat-toolbar>

  <form *ngIf="personen" [formGroup]="formGroup">
    <div class="personen-row">
      <div class="personen-column">
        <div class="personen-column-header">
          <img [src]="icons.verletzt" />
          <h4>verletzt</h4>
        </div>
        <h3 *ngIf="disabled">{{ verletzt.value }}</h3>
        <mat-form-field *ngIf="!disabled">
          <input matInput type="number" [formControl]="verletzt" min="0" />
        </mat-form-field>
      </div>

      <div class="personen-column">
        <div class="personen-column-header">
          <img [src]="icons.tot" />
          <h4>tot</h4>
        </div>
        <h3 *ngIf="disabled">{{ tot.value }}</h3>
        <mat-form-field *ngIf="!disabled">
          <input matInput type="number" [formControl]="tot" min="0" />
        </mat-form-field>
      </div>

      <div class="personen-column">
        <div class="personen-column-header">
          <img [src]="icons.vermisst" />
          <h4>vermisst</h4>
        </div>
        <h3 *ngIf="disabled">{{ vermisst.value }}</h3>
        <mat-form-field *ngIf="!disabled">
          <input matInput type="number" [formControl]="vermisst" min="0" />
        </mat-form-field>
      </div>

      <div class="personen-column">
        <div class="personen-column-header">
          <img [src]="icons.betroffen" />
          <h4>betroffen</h4>
        </div>
        <h3 *ngIf="disabled">{{ betroffen.value }}</h3>
        <mat-form-field *ngIf="!disabled">
          <input matInput type="number" [formControl]="betroffen" min="0" />
        </mat-form-field>
      </div>

      <div class="personen-column">
        <div class="personen-column-header">
          <img [src]="icons.gerettet" />
          <h4>gerettet</h4>
        </div>
        <h3 *ngIf="disabled">{{ gerettet.value }}</h3>
        <mat-form-field *ngIf="!disabled">
          <input matInput type="number" [formControl]="gerettet" min="0" />
        </mat-form-field>
      </div>

      <div class="personen-column">
        <div class="personen-column-header">
          <img [src]="icons.transportiert" />
          <h4>transportiert</h4>
        </div>
        <h3 *ngIf="disabled">{{ transportiert.value }}</h3>
        <mat-form-field *ngIf="!disabled">
          <input matInput type="number" formControlName="transportiert" min="0" [readonly]="disabled" />
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-card>
