import { TextFieldModule } from '@angular/cdk/text-field';
import { NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Inject, inject, ViewChild } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ErrorService } from '@product/ise-error-lib';
import { Geometry } from 'geojson';
import { catchError, EMPTY } from 'rxjs';
import { FuehrungsebeneDTO, MoveTzDto, TaktischesZeichenDTO, TaktischesZeichenStatus } from 'src/app/api/build/openapi';
import { DatetimeLocalAccessorDirective } from 'src/app/shared/accessors/datetime-local-accessor.directive';
import { LageValidators } from 'src/app/shared/lage-validators';
import { GeometryPipe } from 'src/app/shared/pipes/geometry.pipe';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { TaktischeZeichenService, TzEinheitDto } from 'src/app/taktische-zeichen/taktische-zeichen.service';
import { TaktischesZeichenStatusPipe } from '../taktische-zeichen/taktisches-zeichen-status.pipe';
import { TzGeometryMapComponent } from '../taktische-zeichen/tz-geometry-map/tz-geometry-map.component';
import { TzListComponent } from '../taktische-zeichen/tz-list/tz-list.component';
import { ToolService } from '../tool.service';

export interface StatuswechselDialogInputData {
  tzDtos: TaktischesZeichenDTO[];
  targetFuehrungsebeneDto: FuehrungsebeneDTO;
  targetTzStatus?: TaktischesZeichenStatus;
}

export interface StatuswechselDialogOutputData {
  moveTzDtos: MoveTzDto[];
}

@Component({
  selector: 'app-statuswechsel-dialog',
  templateUrl: './statuswechsel-dialog.component.html',
  styleUrls: ['./statuswechsel-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    TextFieldModule,
    MatButtonModule,
    MatIconModule,
    GeometryPipe,
    DatetimeLocalAccessorDirective,
    MatToolbarModule,
    TzGeometryMapComponent,
    TzListComponent,
    MatCardModule,
  ],
})
export class StatuswechselDialogComponent implements AfterViewInit {
  @ViewChild(TzGeometryMapComponent) map!: TzGeometryMapComponent;

  heading = 'Taktische Zeichen verschieben';
  fuehrungsebeneMessage?: string;
  statusMessage?: string;

  geometry?: Geometry;

  private clipboardService = inject(ClipboardService);
  private geometryPipe = inject(GeometryPipe);
  private taktischesZeichenStatusPipe = inject(TaktischesZeichenStatusPipe);
  private taktischeZeichenService = inject(TaktischeZeichenService);
  private toolService = inject(ToolService);

  private formBuilder = inject(NonNullableFormBuilder);

  fcDatum = this.formBuilder.control<Date>(new Date(), [Validators.required, LageValidators.dateTimeValidator]);
  fcOrtsangabe = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);

  formGroup = this.formBuilder.group({
    ortsangabe: this.fcOrtsangabe,
    datum: this.fcDatum,
  });

  tzDtos: TaktischesZeichenDTO[] = [];
  targetFuehrungsebeneDto?: FuehrungsebeneDTO;
  targetTzStatus?: TaktischesZeichenStatus;
  moveDtoMapping = new Map<string, MoveTzDto>([]);

  selectedTzDto?: TaktischesZeichenDTO;
  showOrtsangabe = false;
  protected isSaving = false;

  private dialogRef = inject(MatDialogRef<StatuswechselDialogComponent>);
  private errorService = inject(ErrorService);

  private importantTzStatus = [
    TaktischesZeichenStatus.Alarmiert,
    TaktischesZeichenStatus.Angefordert,
    TaktischesZeichenStatus.AufMarsch,
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: StatuswechselDialogInputData) {
    if (dialogData) {
      this.tzDtos = dialogData.tzDtos;
      this.targetFuehrungsebeneDto = dialogData.targetFuehrungsebeneDto;
      this.targetTzStatus = dialogData.targetTzStatus;
      this.heading = `${this.taktischeZeichenService.getTzTypDescription(this.tzDtos)} verschieben`;
      this.createMoveTzDtoMapping();

      // Sofort ersten Eintrag auswählen
      if (this.tzDtos.length) {
        this.selectTz(this.tzDtos[0]);
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.drawSelectedTzDtoToMap();
    }, 100);
  }

  private createMoveTzDtoMapping() {
    this.moveDtoMapping = new Map<string, MoveTzDto>();
    this.tzDtos.forEach((tzDto) => {
      if (!tzDto.id) {
        console.warn('Taktisches Zeichen hat keine id');
        return;
      }

      if (!this.targetFuehrungsebeneDto?.id) {
        console.warn('Keine Führungsebene ausgewählt');
        return;
      }

      const moveTzDto: MoveTzDto = {
        tzId: tzDto.id,
        tzTyp: tzDto.typ,
        tzStatus: this.targetTzStatus,
        fuehrungsebeneId: this.targetFuehrungsebeneDto.id,
        address: this.taktischeZeichenService.isTzEinheitDto(tzDto) ? (tzDto as TzEinheitDto).ortsangabe : undefined,
        geometry: tzDto.geometry,
        moveTime: new Date(Date.now()).toISOString(),
      };
      const tzIdentifier = this.getTzIdentifier(tzDto);
      this.moveDtoMapping.set(tzIdentifier, moveTzDto);
    });
  }

  selectTz(tzDto: TaktischesZeichenDTO) {
    if (this.selectedTzDto && this.getTzIdentifier(this.selectedTzDto) === this.getTzIdentifier(tzDto)) {
      return;
    }

    this.writeToCurrentMoveTzDto();
    this.selectedTzDto = tzDto;
    this.showOrtsangabe = this.taktischeZeichenService.isTzEinheitDto(this.selectedTzDto);
    this.readFromCurrentMoveTzDto();
  }

  /**
   * Schreibt alle Eingaben in das aktuelle MoveTzDto zurück
   */
  private writeToCurrentMoveTzDto() {
    if (this.selectedTzDto) {
      this.writeToMoveTzDto(this.selectedTzDto);
    }
  }

  /**
   * Schreibt alle Eingaben in das MoveTzDto des übergebenen TZs zurück
   */
  private writeToMoveTzDto(tzDto: TaktischesZeichenDTO) {
    const tzIdentifier = this.getTzIdentifier(tzDto);
    const moveTzDto = this.moveDtoMapping.get(tzIdentifier);
    if (!moveTzDto) {
      console.warn('Kein TzMoveDto für TZ vorhanden');
      return;
    }

    if (this.showOrtsangabe && this.taktischeZeichenService.isTzEinheitDto(tzDto)) {
      moveTzDto.address = this.fcOrtsangabe.value;
    }

    if (this.map.selectedTool && this.toolService.getAllowedToolTypes(tzDto.typ).includes(this.map.selectedTool.type)) {
      moveTzDto.geometry = this.geometry;
    }

    moveTzDto.moveTime = this.fcDatum.value.toISOString();
  }

  /**
   * Aktuelle Eingaben auf alle Taktischen Zeichen anwenden
   */
  protected applyChangesToAllTzs() {
    // Aktuelle Änderungen in jedes TZ schreiben
    this.tzDtos.forEach((tzDto) => {
      this.writeToMoveTzDto(tzDto);
    });

    this.readFromCurrentMoveTzDto();
  }

  /**
   * Befüllt die FormGroup und Karte mit den Werten des aktuellen MoveTzDtos.
   */
  private readFromCurrentMoveTzDto() {
    if (!this.selectedTzDto) {
      console.warn('Kann nicht speichern, da kein TZ ausgewählt');
      return;
    }
    const tzIdentifier = this.getTzIdentifier(this.selectedTzDto);
    const moveTzDto = this.moveDtoMapping.get(tzIdentifier);
    if (!moveTzDto) {
      console.warn('Kein TzMoveDto für TZ vorhanden');
      return;
    }

    if (this.showOrtsangabe) {
      this.fcOrtsangabe.setValue(moveTzDto.address);
    }

    if (moveTzDto.moveTime) {
      this.fcDatum.setValue(new Date(Date.parse(moveTzDto.moveTime)));
    }

    // Koordinate setzen und auf Karte bringen
    this.setKoordinate(moveTzDto.geometry as GeoJSON.Geometry);
    this.drawSelectedTzDtoToMap();

    // Text für Führungsebenenwechsel
    if (this.selectedTzDto.fuehrungsebeneId !== moveTzDto.fuehrungsebeneId) {
      this.fuehrungsebeneMessage = 'Neue Führungsebene: ' + this.targetFuehrungsebeneDto?.name;
    }

    // Text für Statuswechsel
    if (
      moveTzDto.tzStatus &&
      this.importantTzStatus.includes(moveTzDto.tzStatus) &&
      moveTzDto.tzStatus !== this.selectedTzDto.status?.status
    ) {
      this.statusMessage = 'Neuer Status: ' + this.taktischesZeichenStatusPipe.transform(moveTzDto.tzStatus);
    }
  }

  drawSelectedTzDtoToMap() {
    if (!this.map || !this.selectedTzDto) {
      return;
    }

    const tzIdentifier = this.getTzIdentifier(this.selectedTzDto);
    const moveTzDto = this.moveDtoMapping.get(tzIdentifier);
    if (!moveTzDto) {
      return;
    }

    this.map.setMarkerIcon(this.selectedTzDto.dataUrl || '');
    const additionalTzs = this.tzDtos
      .filter((tzDto) => tzDto !== this.selectedTzDto)
      .map((tzDto) => {
        const tzIdentifier = this.getTzIdentifier(tzDto);
        const moveTzDto = this.moveDtoMapping.get(tzIdentifier);
        return { ...tzDto, geometry: moveTzDto?.geometry };
      }) as TaktischesZeichenDTO[];
    this.map.setTaktischesZeichen(
      {
        ...this.selectedTzDto,
        geometry: moveTzDto.geometry,
        fuehrungsebeneId: moveTzDto.fuehrungsebeneId,
      },
      false,
      additionalTzs
    );
  }

  setKoordinate(geometrie: GeoJSON.Geometry) {
    this.geometry = geometrie;
  }

  saveAndClose(): void {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;
    this.writeToCurrentMoveTzDto();
    const moveTzDtos = [...this.moveDtoMapping.values()];
    this.taktischeZeichenService
      .moveMultipleTzs(moveTzDtos)
      .pipe(
        catchError((errorResp: HttpErrorResponse) => {
          this.errorService.showError(errorResp.error);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  copyGeometryToClipboard(event: MouseEvent): void {
    this.clipboardService.copy(this.geometryPipe.transform(this.geometry));
    event.stopPropagation();
  }

  private getTzIdentifier(tzDto: TaktischesZeichenDTO) {
    return tzDto.id + tzDto.typ;
  }
}
