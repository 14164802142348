<ng-container *ngIf="{ currentLageSelected: (currentLageSelected$ | async) } as obj">
  <a
    mat-button
    [disabled]="!obj.currentLageSelected"
    [routerLink]="['/meldungen']"
    [ngClass]="{ 'nav-selected': currentUrl === '/meldungen', 'nav-disabled': !obj.currentLageSelected }"
    >Meldungen</a
  >
  <a
    mat-button
    [disabled]="!obj.currentLageSelected"
    [routerLink]="['/lage-k']"
    [ngClass]="{ 'nav-selected': currentUrl === '/lage-k', 'nav-disabled': !obj.currentLageSelected }"
    >Dashboard</a
  >
  <a
    mat-button
    [disabled]="!obj.currentLageSelected"
    [routerLink]="['/lage-k/anwesenheit']"
    [ngClass]="{
      'nav-selected': currentUrl === '/lage-k/anwesenheit',
      'nav-disabled': !obj.currentLageSelected
    }"
    >Anwesenheitsliste</a
  >

  <a
    mat-button
    [disabled]="!obj.currentLageSelected"
    [routerLink]="['/einsatztagebuch']"
    [ngClass]="{
      'nav-selected': currentUrl === '/einsatztagebuch',
      'nav-disabled': !obj.currentLageSelected
    }"
    >Einsatztagebuch</a
  >
  <app-lage-infobar />
</ng-container>
