<div class="oben">
  <app-dashboard-lage [fuehrungsebeneDatasets]="fuehrungsebeneDatasets" (reload)="reload()" />
  <app-dashboard-fahrzeuge [fahrzeugeDataset]="fahrzeugeDataset" />
</div>

<app-dashboard-personen
  [bevoelkerungDataset]="personenDatasetBevoelkerung"
  [einsatzkraefteDataset]="personenDatasetEinsatzkraefte"
  [manvDataset]="personenDatasetManv"
/>
