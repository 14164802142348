import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ApplicationType } from '../api/build/openapi';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramSelectGuard {
  private selectedApp?: ApplicationType;

  canActivate() {
    if (!this.selectedApp) {
      this.router.navigateByUrl('/anwendung');
      return false;
    }
    return true;
  }

  constructor(private appService: AppService, private router: Router) {
    this.appService
      .selectedApplicationObservable()
      .pipe(takeUntilDestroyed())
      .subscribe((a) => {
        this.selectedApp = a;
      });
  }
}
