import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store, select } from '@ngrx/store';
import { map, switchMap } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';

import { DateTimePipe } from 'src/app/shared/pipes/date-time.pipe';
import { LagebesprechungDialogComponent } from '../lagebesprechung/lagebesprechung-dialog/lagebesprechung-dialog.component';
import { LagebesprechungService } from '../lagebesprechung/lagebesprechung.service';
import { currentLageSelector } from '../lagen/+state/lage.selectors';
import { MassnahmenComponent } from '../massnahmen/massnahmen.component';
import { OrganigrammComponent } from '../organigramm/organigramm/organigramm.component';

import { ActivatedRoute } from '@angular/router';
import { Fuehrungsebenentyp, LageDTO, LagebesprechungDTO, SchadenkontoColor } from 'src/app/api/build/openapi';
import { PopupManagerService } from '../monitor-manager/popup-manager.service';

const EMPTY_LAGE: LageDTO = {
  customSymbol: false,
  name: '',
  startedOn: '-',
  stichworte: ['-'],
  adresse: '-',
  typ: Fuehrungsebenentyp.Lage,
  schadenkontoColor: SchadenkontoColor.Gray,
};

@Component({
  selector: 'app-screen-three',
  templateUrl: './screen-three.component.html',
  styleUrls: ['./screen-three.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    NgFor,
    MatTooltipModule,
    OrganigrammComponent,
    MassnahmenComponent,
    AsyncPipe,
    DateTimePipe,
  ],
})
export class ScreenThreeComponent {
  // True, wenn Custom-Elemente ausgeblendet werden sollen
  customCollapsed = false;

  lage: LageDTO = EMPTY_LAGE;

  currentLage$ = this.store.pipe(
    select(currentLageSelector),
    map((currentLage) => (currentLage ? currentLage : EMPTY_LAGE))
  );

  // Lagebesprechung
  lagebesprechung$ = this.lagebesprechungsService.nextLagebesprechung$.pipe(
    map((v) => (v && v.status !== LagebesprechungDTO.StatusEnum.Abgebrochen ? v : ({} as LagebesprechungDTO)))
  );

  nextLagebesprechung$ = this.lagebesprechung$.pipe(switchMap((v) => this.dateTime.transform(v.zeitpunkt)));

  notificationTime$ = this.lagebesprechung$.pipe(
    map((v) => (v && v.erinnerungInMinuten && v.erinnerungInMinuten > 0 ? v.erinnerungInMinuten : undefined))
  );

  constructor(
    private store: Store<AppStateInterface>,
    private lagebesprechungsService: LagebesprechungService,
    public dialog: MatDialog,
    private dateTime: DateTimePipe,
    private route: ActivatedRoute,
    private popupService: PopupManagerService
  ) {
    this.popupService.initIfPopUp(this.route);
  }

  toggleCollapsed() {
    this.customCollapsed = !this.customCollapsed;
    window.dispatchEvent(new Event('resize'));
  }

  openLagebesprechungDialog() {
    this.dialog.open(LagebesprechungDialogComponent, {
      data: { lagebesprechung: this.lagebesprechungsService.nextLagebesprechung$.value },
    });
  }
}
