import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { childFuehrungsebenenSelector, currentFuehrungsebeneSelector } from '../+state/fuehrungsebene.selectors';
import { AppStateInterface } from '../../../+state/appState.interface';
import { FuehrungsebeneDTO, Fuehrungsebenentyp } from '../../../api/build/openapi';
import { FuehrungsebeneDisplayService } from '../fuehrungsebene-display.service';
import { FuehrungsebeneService } from '../fuehrungsebene.service';

/**
 * Dialog zur Auswahl angezeigter Führungsebenen. Ausgehend vom currentFuehrungsebene, kann der Benutzer aus allen Child-Führungsebenen wählen.
 */
@Component({
  selector: 'app-fuehrungsebene-visibility-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatToolbarModule,
    MatTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './fuehrungsebene-visibility-dialog.component.html',
  styleUrls: ['./fuehrungsebene-visibility-dialog.component.scss'],
})
export class FuehrungsebeneVisibilityDialogComponent {
  currentFuehrungsebene: FuehrungsebeneDTO | null = null;
  childFuehrungsebenen: FuehrungsebeneDTO[] = [];
  selectedFuehrungsebeneIds: string[] = [];

  displayedColumns: string[] = ['select', 'name', 'typ', 'leiterName', 'adresse'];

  protected showError = false;
  protected isSaving = false;
  protected hasLocalStorageEntry = false;

  constructor(
    store: Store<AppStateInterface>,
    private dialogRef: DialogRef,
    private fuehrungsebeneDisplayService: FuehrungsebeneDisplayService,
    private fuehrungsebeneService: FuehrungsebeneService
  ) {
    combineLatest([store.select(currentFuehrungsebeneSelector), store.select(childFuehrungsebenenSelector)])
      .pipe(takeUntilDestroyed())
      .subscribe(([currentFuehrungsebene, childFuehrungsebenen]) => {
        this.currentFuehrungsebene = currentFuehrungsebene;
        this.childFuehrungsebenen = childFuehrungsebenen;
        this.loadSelection();
      });
  }

  private loadSelection(): void {
    if (!this.currentFuehrungsebene) {
      this.selectedFuehrungsebeneIds = [];
      return;
    }

    // Wenn Auswahl geladen werden konnte, diese anzeigen. Ansonsten die ersten (max 6) verfügbaren
    const selectedFuehrungsebeneIds = this.currentFuehrungsebene.id
      ? this.fuehrungsebeneDisplayService.getSelectedFuehrungsebeneIds(this.currentFuehrungsebene.id)
      : [];
    if (selectedFuehrungsebeneIds) {
      this.selectedFuehrungsebeneIds = selectedFuehrungsebeneIds.filter((selectedId) =>
        this.childFuehrungsebenen.some((child) => child.id === selectedId)
      );
    } else {
      this.selectedFuehrungsebeneIds = this.childFuehrungsebenen
        .slice(0, Math.min(this.childFuehrungsebenen.length, 6))
        .map((fuehrungsebene) => fuehrungsebene.id) as string[];
    }
    this.hasLocalStorageEntry = this.fuehrungsebeneDisplayService.hasSelectedFuehrungsebenen();
  }

  getFuehrungsebenentypText(fuehrungsebenentyp: Fuehrungsebenentyp): string {
    return this.fuehrungsebeneService.fuehrungsebenentypMapping.get(fuehrungsebenentyp)?.longname || '';
  }

  toggleSelection(fuehrungsebeneId: string) {
    if (this.selectedFuehrungsebeneIds.includes(fuehrungsebeneId)) {
      this.selectedFuehrungsebeneIds = this.selectedFuehrungsebeneIds.filter((id) => id != fuehrungsebeneId);
    } else {
      this.selectedFuehrungsebeneIds = [...this.selectedFuehrungsebeneIds, fuehrungsebeneId];
    }
  }

  clearCache() {
    this.fuehrungsebeneDisplayService.clearSelectedFuehrungsebenen();
    this.loadSelection();
  }

  save(): void {
    if (this.isSaving) {
      return;
    }

    if (this.selectedFuehrungsebeneIds.length > 6 || !this.currentFuehrungsebene?.id) {
      this.showError = true;
      return;
    }

    this.isSaving = true;
    this.showError = false;
    this.fuehrungsebeneDisplayService.saveSelectedFuehrungsebenen(
      this.currentFuehrungsebene.id,
      this.selectedFuehrungsebeneIds
    );
    this.dialogRef.close();
  }
}
