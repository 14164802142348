import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { FuehrungsebeneDTO, Fuehrungsebenentyp } from 'src/app/api/build/openapi';
import { fuehrungsebeneActions } from '../+state/fuehrungsebene.actions';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { AuftragHeaderElementComponent } from '../../auftrag/auftrag-header-element/auftrag-header-element.component';
import { MapHandler } from '../../base-map/map-handler';
import { KontaktIconsComponent } from '../../kontakt/kontakt-icons/kontakt-icons.component';
import { FuehrungsebeneIconAndTextComponent } from '../fuehrungsebene-icon-and-text/fuehrungsebene-icon-and-text.component';
import { FuehrungsebeneService, FuehrungsebenentypSettings } from '../fuehrungsebene.service';

/**
 * Header Komponente für Führungsebenen. Je nach Fuehrungsebenentyp werden unterschiedliche Abkürzungen/Farben/Beschriftungen angezeigt.
 * Berechnet die Mannschaftsstärke der Führungsebene anhand der eigenen Fahrzeuge.
 */
@Component({
  selector: 'app-fuehrungsebene-header',
  templateUrl: './fuehrungsebene-header.component.html',
  styleUrls: ['./fuehrungsebene-header.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    AuftragHeaderElementComponent,
    AsyncPipe,
    DateTimePipe,
    KontaktIconsComponent,
    FuehrungsebeneIconAndTextComponent,
  ],
})
export class FuehrungsebeneHeaderComponent implements OnChanges {
  @Input()
  fuehrungsebeneDTO: FuehrungsebeneDTO | null = null;

  @Output()
  edit = new EventEmitter();

  Fuehrungsebenentyp = Fuehrungsebenentyp;

  allowNavigation = false;
  allowFocus = false;
  allowMove = false;
  allowEdit = false;
  fuehrungsebenentypSettings?: FuehrungsebenentypSettings;

  private store = inject(Store);
  private mapHandler = inject(MapHandler);
  protected fuehrungsebeneService = inject(FuehrungsebeneService);
  protected schadenkontoColorMapping = FuehrungsebeneService.SCHADENKONTO_COLOR_MAPPING;

  ngOnChanges() {
    if (this.fuehrungsebeneDTO && this.fuehrungsebeneDTO.typ) {
      this.fuehrungsebenentypSettings = this.fuehrungsebeneService.fuehrungsebenentypMapping.get(
        this.fuehrungsebeneDTO.typ
      );
      this.allowEdit = this.fuehrungsebeneService.editingFuehrungsebenentypAllowed(this.fuehrungsebeneDTO.typ);
      this.allowFocus = FuehrungsebeneService.MAIN_FUEHRUNGSEBENENTYPEN.includes(this.fuehrungsebeneDTO.typ);
      this.allowMove =
        this.allowEdit && FuehrungsebeneService.MOVABLE_FUEHRUNGSEBENETYPEN.includes(this.fuehrungsebeneDTO.typ);
      this.allowNavigation =
        this.fuehrungsebeneDTO.id !== this.fuehrungsebeneService.getCurrentFuehrungsebene()?.id &&
        FuehrungsebeneService.MAIN_FUEHRUNGSEBENENTYPEN.includes(this.fuehrungsebeneDTO.typ);
    } else {
      this.fuehrungsebenentypSettings = undefined;
    }
  }

  navigateToFuehrungsebene() {
    if (this.allowNavigation) {
      this.store.dispatch(
        fuehrungsebeneActions.setCurrentFuehrungsebene({ currentFuehrungsebene: this.fuehrungsebeneDTO })
      );
    }
  }

  focusFuehrungsebene() {
    if (this.fuehrungsebeneDTO && this.fuehrungsebeneDTO.geometry) {
      this.mapHandler.focusGeometry(this.fuehrungsebeneDTO.geometry as GeoJSON.Geometry);
    }
  }

  moveFuehrungsebene() {
    if (this.fuehrungsebeneDTO) {
      this.fuehrungsebeneService.openMoveFuehrungsebeneDialog(this.fuehrungsebeneDTO);
    }
  }
}
