import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Output, QueryList, ViewChildren, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { ErrorService } from '@product/ise-error-lib';
import { Observable, take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import {
  BefehlsstelleDTO,
  Beweglichkeit,
  FahrzeugDTO,
  GebaeudeDTO,
  Kommunikation,
  PersonDTO,
  TaktischesZeichenTyp,
} from 'src/app/api/build/openapi';
import { KontaktListComponent } from 'src/app/lagedarstellung/kontakt/kontakt-list/kontakt-list.component';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { IconService } from 'src/app/shared/services/icon.service';
import { TzComboboxComponent } from 'src/app/shared/tz-combobox/tz-combobox.component';
import { compareLabels } from 'src/app/taktische-zeichen/taktische-zeichen-form/taktische-zeichen-form.component';
import {
  Einheit,
  Fachaufgabe,
  Organisation,
  Symbol,
  TaktischesZeichen,
  Verwaltungsstufe,
  einheiten,
  erzeugeTaktischesZeichen,
  fachaufgaben,
  organisationen,
  symbole,
  verwaltungsstufen,
} from 'taktische-zeichen-core';
import { fahrzeugeSelector } from '../../fahrzeuge/+state/fahrzeug.selectors';
import { gebaeudeSelector } from '../../gebaeude/+state/gebaeude.selectors';
import { personenSelector } from '../../personen/+state/person.selectors';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

@Component({
  selector: 'app-befehlsstelle-form',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    KontaktListComponent,
    CleanableFormFieldComponent,
    TzComboboxComponent,
    MatTooltipModule,
  ],
  templateUrl: './befehlsstelle-form.component.html',
  styleUrls: ['./befehlsstelle-form.component.scss'],
})
export class BefehlsstelleFormComponent implements AfterViewInit, TaktischesZeichenForm {
  Beweglichkeit = Beweglichkeit;
  isBeweglich = false;

  @ViewChildren(KontaktListComponent)
  kontaktListQuery!: QueryList<KontaktListComponent>;

  kontaktList!: KontaktListComponent;
  kontaktListExtern?: KontaktListComponent;

  kommunikationOptionenExtern: Kommunikation[] = [];

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  iconDataUrlChanged = new EventEmitter<string>();

  befehlsstelleToEdit?: BefehlsstelleDTO;

  readonly DEFAULT_ICON_DATA: TaktischesZeichen = { grundzeichen: 'befehlsstelle' };
  iconData: TaktischesZeichen = { ...this.DEFAULT_ICON_DATA };
  private isCustomIcon = false;
  private iconDataUrl?: string;

  // Dropdown-Werte
  organisationValues: Organisation[] = organisationen.sort(compareLabels);
  fachaufgabeValues: Fachaufgabe[] = fachaufgaben.sort(compareLabels);
  einheitValues: Einheit[] = einheiten.sort(compareLabels);
  verwaltungsstufeValues: Verwaltungsstufe[] = verwaltungsstufen.sort(compareLabels);
  symbolValues: Symbol[] = symbole.sort(compareLabels);

  leiterValues$: Observable<PersonDTO[]>;
  fahrzeugValues$: Observable<FahrzeugDTO[]>;
  gebaeudeValues$: Observable<GebaeudeDTO[]>;

  organisationMouseover = false;
  fachaufgabeMouseover = false;
  einheitMouseover = false;
  verwaltungsstufeMouseover = false;
  symbolMouseover = false;
  leiterMouseover = false;
  fahrzeugMouseover = false;
  gebaeudeMouseover = false;

  private errorService = inject(ErrorService);
  private iconService = inject(IconService);
  private formBuilder = inject(NonNullableFormBuilder);

  fcAnzeigename = this.formBuilder.control('', [Validators.maxLength(30), Validators.required]);
  fcBeweglichkeit = this.formBuilder.control(Beweglichkeit.Ortsfest);
  fcOrganisation = this.formBuilder.control<Organisation | undefined>(undefined);
  fcFachaufgabe = this.formBuilder.control<Fachaufgabe | undefined>(undefined);
  fcEinheit = this.formBuilder.control<Einheit | undefined>(undefined);
  fcVerwaltungsstufe = this.formBuilder.control<Verwaltungsstufe | undefined>(undefined);
  fcSymbol = this.formBuilder.control<Symbol | undefined>(undefined);
  fcZeichenText = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcOrtsangabe = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcLeiter = this.formBuilder.control<PersonDTO | undefined>(undefined);
  fcFahrzeug = this.formBuilder.control<FahrzeugDTO | undefined>(undefined);
  fcGebaeude = this.formBuilder.control<GebaeudeDTO | undefined>(undefined);

  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    beweglichkeit: this.fcBeweglichkeit,
    organisation: this.fcOrganisation,
    fachaufgabe: this.fcFachaufgabe,
    einheit: this.fcEinheit,
    verwaltungsstufe: this.fcVerwaltungsstufe,
    symbol: this.fcSymbol,
    zeichenText: this.fcZeichenText,
    ortsangabe: this.fcOrtsangabe,
    leiter: this.fcLeiter,
    fahrzeug: this.fcFahrzeug,
    gebaeude: this.fcGebaeude,
  });

  constructor(store: Store<AppStateInterface>) {
    this.leiterValues$ = store.select(personenSelector);
    this.fahrzeugValues$ = store.select(fahrzeugeSelector);
    this.gebaeudeValues$ = store.select(gebaeudeSelector);

    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((value: string) => {
      this.anzeigenameChanged.emit(value?.trim());
    });

    this.fcOrganisation.valueChanges.pipe(takeUntilDestroyed()).subscribe((value: Organisation | undefined) => {
      this.iconData.organisation = value?.id;
      this.handleIconDataChanged();
    });

    this.fcFachaufgabe.valueChanges.pipe(takeUntilDestroyed()).subscribe((value: Fachaufgabe | undefined) => {
      this.iconData.fachaufgabe = value?.id;
      this.handleIconDataChanged();
    });

    this.fcEinheit.valueChanges.pipe(takeUntilDestroyed()).subscribe((value?: Einheit | undefined) => {
      this.iconData.einheit = value?.id;
      this.handleIconDataChanged();
    });

    this.fcVerwaltungsstufe.valueChanges.pipe(takeUntilDestroyed()).subscribe((value: Verwaltungsstufe | undefined) => {
      this.iconData.verwaltungsstufe = value?.id;
      this.handleIconDataChanged();
    });

    this.fcSymbol.valueChanges.pipe(takeUntilDestroyed()).subscribe((value: Symbol | undefined) => {
      this.iconData.symbol = value?.id;
      this.handleIconDataChanged();
    });

    this.fcZeichenText.valueChanges.pipe(takeUntilDestroyed()).subscribe((value: string | undefined) => {
      this.iconData.text = value?.trim();
      this.handleIconDataChanged();
    });

    this.fcBeweglichkeit.valueChanges.pipe(takeUntilDestroyed()).subscribe((value: Beweglichkeit) => {
      this.isBeweglich = value === Beweglichkeit.Beweglich;
      if (this.isBeweglich) {
        this.fcGebaeude.setValue(undefined);
        this.fcOrtsangabe.setValue(undefined);
      } else {
        this.fcFahrzeug.setValue(undefined);
      }
      this.updateKontaktmoeglichkeiten();
    });

    this.fcFahrzeug.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => this.updateKontaktmoeglichkeiten());
    this.fcGebaeude.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => this.updateKontaktmoeglichkeiten());
  }

  ngAfterViewInit(): void {
    this.kontaktListQuery.forEach((comp) => {
      if (comp.headerText === 'Kontaktmöglichkeiten') {
        this.kontaktList = comp;
      } else {
        this.kontaktListExtern = comp;
      }
    });
  }

  /**
   * Ist die Befehlsstelle ortsfest, können eigene Kontaktmöglichkeiten verwaltet werden.
   * Ist die Befehlsstelle beweglich, werden die Kontaktmöglichkeiten des ausgewählten Fahrzeugs angezeigt.
   */
  updateKontaktmoeglichkeiten(): void {
    const fahrzeugOrGebaeude = this.formGroup.get(this.isBeweglich ? 'fahrzeug' : 'gebaeude')?.value;
    this.kommunikationOptionenExtern = fahrzeugOrGebaeude?.kommunikationOptionen || [];
  }

  setDTO(befehlsstelleDTO: BefehlsstelleDTO) {
    if (!befehlsstelleDTO) {
      this.errorService.showErrorMessage('Kein Model zum Bearbeiten vorhanden.');
      return;
    }

    // Sonderfall: Anlegen über Karte braucht direkt ein Temp-Icon
    if (!befehlsstelleDTO.id) {
      this.iconDataUrlChanged.emit(erzeugeTaktischesZeichen(this.DEFAULT_ICON_DATA).dataUrl);
    }

    this.befehlsstelleToEdit = befehlsstelleDTO;
    this.dtoToFormGroup(this.befehlsstelleToEdit);
    this.handleIconDataChanged();
  }

  /**
   * Aktualisiertes BefehlsstelleDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): BefehlsstelleDTO | null {
    if (this.formGroup.valid) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * FormGroup Werte in PersonDTO schreiben und zurückliefern
   */
  formGroupToDto(): BefehlsstelleDTO {
    return {
      ...this.befehlsstelleToEdit,
      customZeichen: this.isCustomIcon,
      dataUrl: this.iconDataUrl,
      anzeigename: this.fcAnzeigename.value.trim(),
      beweglichkeit: this.fcBeweglichkeit.value,
      organisation: this.fcOrganisation.value?.id,
      fachaufgabe: this.fcFachaufgabe.value?.id,
      einheit: this.fcEinheit.value?.id,
      verwaltungsstufe: this.fcVerwaltungsstufe.value?.id,
      symbol: this.fcSymbol.value?.id,
      zeichenText: this.fcZeichenText.value?.trim(),
      ortsangabe: this.fcOrtsangabe.value?.trim(),
      leiterId: this.fcLeiter.value?.id,
      fahrzeugId: this.fcFahrzeug.value?.id,
      gebaeudeId: this.fcGebaeude.value?.id,
      kommunikationOptionen: this.kontaktList.getKommunikationOptionen(),
      typ: TaktischesZeichenTyp.Befehlsstelle,
    };
  }

  /**
   * FormGroup mit Werten aus DTO füllen
   */
  dtoToFormGroup(befehlsstelleDTO: BefehlsstelleDTO): void {
    this.isCustomIcon = befehlsstelleDTO.customZeichen || false;
    this.iconDataUrl = befehlsstelleDTO.dataUrl;

    this.fcAnzeigename.setValue(befehlsstelleDTO.anzeigename);
    this.fcBeweglichkeit.setValue(befehlsstelleDTO.beweglichkeit || Beweglichkeit.Ortsfest);
    this.fcOrganisation.setValue(organisationen.find((v) => v.id === befehlsstelleDTO.organisation));
    this.fcFachaufgabe.setValue(fachaufgaben.find((v) => v.id === befehlsstelleDTO.fachaufgabe));
    this.fcEinheit.setValue(einheiten.find((v) => v.id === befehlsstelleDTO.einheit));
    this.fcVerwaltungsstufe.setValue(verwaltungsstufen.find((v) => v.id === befehlsstelleDTO.verwaltungsstufe));
    this.fcSymbol.setValue(symbole.find((v) => v.id === befehlsstelleDTO.symbol));
    this.fcZeichenText.setValue(befehlsstelleDTO.zeichenText);
    this.fcOrtsangabe.setValue(befehlsstelleDTO.ortsangabe);

    if (befehlsstelleDTO.fahrzeugId) {
      this.fahrzeugValues$
        .pipe(take(1))
        .subscribe((fahrzeuge) =>
          this.fcFahrzeug.setValue(fahrzeuge.find((fahrzeug) => fahrzeug.id === befehlsstelleDTO.fahrzeugId))
        );
    }

    if (befehlsstelleDTO.leiterId) {
      this.leiterValues$
        .pipe(take(1))
        .subscribe((leiter) => this.fcLeiter.setValue(leiter.find((l) => l.id === befehlsstelleDTO.leiterId)));
    }

    if (befehlsstelleDTO.gebaeudeId) {
      this.gebaeudeValues$
        .pipe(take(1))
        .subscribe((gebaeude) => this.fcGebaeude.setValue(gebaeude.find((g) => g.id === befehlsstelleDTO.gebaeudeId)));
    }
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }

  private handleIconDataChanged(): void {
    if (!this.isCustomIcon) {
      this.iconService.generateCompressedDataUrl(this.iconData).subscribe((dataUrl) => {
        this.iconDataUrl = dataUrl;
        this.iconDataUrlChanged.emit(this.iconDataUrl);
      });
    }
  }

  public setCustomIcon(iconDataUrl: string): void {
    this.isCustomIcon = true;
    this.iconDataUrl = iconDataUrl;
  }

  public removeCustomIcon(): void {
    this.isCustomIcon = false;
    this.handleIconDataChanged();
  }
}
