<div
  class="item mat-elevation-z3"
  [ngClass]="{ 'mat-elevation-z6': hovering }"
  (mouseover)="hovering = true"
  (mouseleave)="hovering = false"
>
  <div class="zeichen-image-wrapper" [matBadge]="tzDTOs.length">
    <img [src]="(darkmode$ | async) ? tzInfoMapping.get(tzTyp)?.iconDarkmode : tzInfoMapping.get(tzTyp)?.icon" />
  </div>
  <div class="zeichen-text" [matTooltip]="tzInfoMapping.get(tzTyp)?.plural || ''" [matTooltipShowDelay]="500">
    {{ tzInfoMapping.get(tzTyp)?.plural }}
  </div>
</div>
