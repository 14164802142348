<mat-toolbar>
  <app-icon-and-text
    [icon]="(darkmode$ | async) ? tzInfo?.iconDarkmode || '' : tzInfo?.icon || ''"
    [text]="tzDto.anzeigename"
    [tooltip]="tzInfo?.name"
  />
  <span class="buttons">
    <button *ngIf="tzDto.fuehrungsebeneId && tzDto.geometry" mat-icon-button (click)="focusDto()">
      <mat-icon matTooltip="{{ tzInfo?.name }} fokussieren">center_focus_strong</mat-icon>
    </button>
    @if ((selectedApp$ | async) === ApplicationType.Lage) {
    <button
      mat-icon-button
      (click)="moveDto()"
      *ngIf="tzDto.fuehrungsebeneId"
      matTooltip="{{ tzInfo?.name }} verschieben"
    >
      <mat-icon>move_down</mat-icon>
    </button>
    <button mat-icon-button (click)="editDto()">
      <mat-icon matTooltip="{{ tzInfo?.name }} bearbeiten">edit</mat-icon>
    </button>
    }
  </span>
</mat-toolbar>
