<div class="full-height flex-col flex-center flex-gap-3" *ngIf="logos$ | async as logos">
  <img [src]="logos.lage" height="100" />
  <button mat-raised-button (click)="toggleLogin()" color="accent">
    <mat-icon class="icon">{{ isLoggedIn ? "logout" : "login" }}</mat-icon>
    {{ isLoggedIn ? "Logout" : "Login" }}
  </button>

  <div class="flex-col flex-center">
    <p>Powered by</p>
    <img class="flex-self-end" [src]="logos.ise" height="30" />
  </div>
</div>
