import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { ErrorService } from '@product/ise-error-lib';
import { Observable, take } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import {
  FuehrungsebeneMassnahmeDTO,
  PersonDTO,
  Prioritaet,
  Rolle,
  Sachgebiet,
  TaktischesZeichenTyp,
} from 'src/app/api/build/openapi';
import { MassnahmenService } from 'src/app/lagedarstellung/massnahmen.service';
import { LagePrio } from 'src/app/lagedarstellung/massnahmen/massnahme-add-dialog/massnahme-add-dialog.component';
import { TagebuchComponent } from 'src/app/lagedarstellung/tagebuch/tagebuch/tagebuch.component';
import { DatetimeLocalAccessorDirective } from 'src/app/shared/accessors/datetime-local-accessor.directive';
import { CleanableFormFieldComponent } from 'src/app/shared/cleanable-form-field/cleanable-form-field.component';
import { IconService } from 'src/app/shared/services/icon.service';
import { TzComboboxComponent } from 'src/app/shared/tz-combobox/tz-combobox.component';
import { compareLabels } from 'src/app/taktische-zeichen/taktische-zeichen-form/taktische-zeichen-form.component';
import { Symbol, TaktischesZeichen, erzeugeTaktischesZeichen, symbole } from 'taktische-zeichen-core';
import { personenSelector } from '../../personen/+state/person.selectors';
import { TaktischesZeichenForm } from '../../taktische-zeichen.interface';

@Component({
  selector: 'app-massnahme-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    TagebuchComponent,
    CleanableFormFieldComponent,
    DatetimeLocalAccessorDirective,
    TzComboboxComponent,
  ],
  templateUrl: './massnahme-form.component.html',
  styleUrls: ['./massnahme-form.component.scss'],
})
export class MassnahmeFormComponent implements TaktischesZeichenForm {
  @ViewChild(TagebuchComponent)
  tagebuch!: TagebuchComponent;

  @Output()
  anzeigenameChanged = new EventEmitter<string>();

  @Output()
  iconDataUrlChanged = new EventEmitter<string>();

  Prioritaet = Prioritaet;
  Sachgebiet = Sachgebiet;
  Rolle = Rolle;

  selectedPrio?: LagePrio;

  readonly DEFAULT_ICON_DATA: TaktischesZeichen = { grundzeichen: 'massnahme' };
  iconData: TaktischesZeichen = { ...this.DEFAULT_ICON_DATA };
  private isCustomIcon = false;
  private iconDataUrl?: string;

  readonly prios: Map<Prioritaet, LagePrio> = MassnahmenService.prioMapping;
  prioSort = MassnahmenService.prioSort;

  symbolValues: Symbol[] = symbole.sort(compareLabels);
  symbolMouseover = false;
  personMouseover = false;
  massnahmeToEdit?: FuehrungsebeneMassnahmeDTO;

  personenValues$: Observable<PersonDTO[]> = inject(Store<AppStateInterface>)
    .select(personenSelector)
    .pipe(takeUntilDestroyed());

  roleTextMapping: Map<Rolle, string> = MassnahmenService.rolleMapping;

  private errorService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);
  private iconService = inject(IconService);

  fcAnzeigename = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcSymbol = this.formBuilder.control<Symbol | undefined>(undefined);
  fcZeichenText = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcBeschreibung = this.formBuilder.control<string | undefined>(undefined, [Validators.maxLength(255)]);
  fcPrioritaet = this.formBuilder.control<Prioritaet>(Prioritaet.Normal);
  fcErledigt = this.formBuilder.control<boolean>(false);
  fcZuErledigenSachgebiet = this.formBuilder.control<Sachgebiet | undefined>(undefined);
  fcZuErledigenDatum = this.formBuilder.control<Date | undefined>(undefined);
  fcBisWannRolle = this.formBuilder.control<Rolle | undefined>(undefined);
  fcBisWannPerson = this.formBuilder.control<PersonDTO | undefined>(undefined);
  fcBisWannDatum = this.formBuilder.control<Date | undefined>(undefined);
  fcErledigtDurchRolle = this.formBuilder.control<Rolle | undefined>(undefined);
  fcErledigtDurchPerson = this.formBuilder.control<PersonDTO | undefined>(undefined);
  fcErledigtDurchDatum = this.formBuilder.control<Date | undefined>(undefined);

  formGroup = this.formBuilder.group({
    anzeigename: this.fcAnzeigename,
    symbol: this.fcSymbol,
    zeichenText: this.fcZeichenText,
    beschreibung: this.fcBeschreibung,
    prioritaet: this.fcPrioritaet,
    erledigt: this.fcErledigt,
    zuErledigenSachgebiet: this.fcZuErledigenSachgebiet,
    zuErledigenDatum: this.fcZuErledigenDatum,
    bisWannRolle: this.fcBisWannRolle,
    bisWannPerson: this.fcBisWannPerson,
    bisWannDatum: this.fcBisWannDatum,
    erledigtDurchRolle: this.fcErledigtDurchRolle,
    erledigtDurchPerson: this.fcErledigtDurchPerson,
    erledigtDurchDatum: this.fcErledigtDurchDatum,
  });

  constructor() {
    this.fcAnzeigename.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.anzeigenameChanged.emit(v.trim());
    });

    this.fcSymbol.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.symbol = v?.id;
      this.handleIconDataChanged();
    });

    this.fcZeichenText.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.iconData.text = v?.trim() || '';
      this.handleIconDataChanged();
    });
  }

  setDTO(dto: FuehrungsebeneMassnahmeDTO): void {
    if (!dto) {
      this.errorService.showErrorMessage('Kein Model zum Bearbeiten vorhanden.');
      return;
    }

    // Sonderfall: Anlegen über Karte braucht direkt ein Temp-Icon
    if (!dto.id) {
      this.iconDataUrlChanged.emit(erzeugeTaktischesZeichen(this.DEFAULT_ICON_DATA).dataUrl);
    }

    this.massnahmeToEdit = dto;
    this.dtoToFormGroup(this.massnahmeToEdit);
    this.handleIconDataChanged();
  }

  /**
   * Aktualisiertes MassnahmeDTO zurückholen.
   * Liefert null, wenn validierung fehlgeschlagen
   */
  getDTO(): FuehrungsebeneMassnahmeDTO | null {
    if (this.formGroup.valid) {
      return this.formGroupToDto();
    }
    this.formGroup.markAllAsTouched();
    return null;
  }

  /**
   * FormGroup Werte in DTO schreiben und zurückliefern
   */
  formGroupToDto(): FuehrungsebeneMassnahmeDTO {
    return {
      ...this.massnahmeToEdit,
      customZeichen: this.isCustomIcon,
      dataUrl: this.iconDataUrl,
      anzeigename: this.fcAnzeigename.value.trim(),
      symbol: this.fcSymbol.value?.id,
      zeichenText: this.fcZeichenText.value?.trim(),
      beschreibung: this.fcBeschreibung.value?.trim(),
      prioritaet: this.fcPrioritaet.value,
      erledigt: this.fcErledigt.value,
      zuErledigenSachgebiet: this.fcZuErledigenSachgebiet.value,
      zuErledigenDatum: this.fcZuErledigenDatum.value?.toISOString(),
      bisWannRolle: this.fcBisWannRolle.value,
      bisWannPersonId: this.fcBisWannPerson.value?.id,
      bisWannDatum: this.fcBisWannDatum.value?.toISOString(),
      erledigtDurchRolle: this.fcErledigtDurchRolle.value,
      erledigtDurchPersonId: this.fcErledigtDurchPerson.value?.id,
      erledigtDurchDatum: this.fcErledigtDurchDatum.value?.toISOString(),
      tagebuch: this.tagebuch.getTagebucheintraege(),
      typ: TaktischesZeichenTyp.Massnahme,
    };
  }

  /**
   * FormGroup mit Werten aus DTO füllen
   */
  dtoToFormGroup(massnahmeDTO: FuehrungsebeneMassnahmeDTO): void {
    this.isCustomIcon = massnahmeDTO.customZeichen || false;
    this.iconDataUrl = massnahmeDTO.dataUrl;

    this.fcAnzeigename.setValue(massnahmeDTO.anzeigename);
    this.fcSymbol.setValue(symbole.find((v) => v.id === massnahmeDTO.symbol));
    this.fcZeichenText.setValue(massnahmeDTO.zeichenText);
    this.fcBeschreibung.setValue(massnahmeDTO.beschreibung);
    this.fcPrioritaet.setValue(massnahmeDTO.prioritaet || Prioritaet.Normal);
    this.fcErledigt.setValue(massnahmeDTO.erledigt || false);
    this.fcZuErledigenSachgebiet.setValue(massnahmeDTO.zuErledigenSachgebiet);
    this.fcZuErledigenDatum.setValue(
      massnahmeDTO.zuErledigenDatum ? new Date(massnahmeDTO.zuErledigenDatum) : undefined
    );
    this.fcBisWannRolle.setValue(massnahmeDTO.bisWannRolle);
    if (massnahmeDTO.bisWannPersonId) {
      this.personenValues$
        .pipe(take(1))
        .subscribe((person) =>
          this.fcBisWannPerson.setValue(person.find((p) => p.id === massnahmeDTO.bisWannPersonId))
        );
    }
    this.fcBisWannDatum.setValue(massnahmeDTO.bisWannDatum ? new Date(massnahmeDTO.bisWannDatum) : undefined);
    this.fcErledigtDurchRolle.setValue(massnahmeDTO.erledigtDurchRolle);
    if (massnahmeDTO.erledigtDurchPersonId) {
      this.personenValues$
        .pipe(take(1))
        .subscribe((person) =>
          this.fcErledigtDurchPerson.setValue(person.find((p) => p.id === massnahmeDTO.erledigtDurchPersonId))
        );
    }
    this.fcErledigtDurchDatum.setValue(
      massnahmeDTO.erledigtDurchDatum ? new Date(massnahmeDTO.erledigtDurchDatum) : undefined
    );
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }

  private handleIconDataChanged(): void {
    if (!this.isCustomIcon) {
      this.iconService.generateCompressedDataUrl(this.iconData).subscribe((dataUrl) => {
        this.iconDataUrl = dataUrl;
        this.iconDataUrlChanged.emit(this.iconDataUrl);
      });
    }
  }

  public setCustomIcon(iconDataUrl: string): void {
    this.isCustomIcon = true;
    this.iconDataUrl = iconDataUrl;
  }

  public removeCustomIcon(): void {
    this.isCustomIcon = false;
    this.handleIconDataChanged();
  }
}
