import { LageResourceService } from '../api/build/openapi';

/**
 * Klasse, die beim Setzen und Entfernen eines Request-Headers unterstützt.
 *
 * Ist eine Lage selektiert, wird automatisch über den {@link AuthInterceptor} der Lage-Header gesetzt.
 * Für Sonderfälle mit dem {@link LageResourceService} kann diese Klasse verwendet werden.
 *
 * WICHTIG: ein hinzugefügter Header muss explizit entfernt werden, dies geschieht nicht automatisch!
 */
export class LageHeaderHelper {
  constructor(private lageResourceSevice: LageResourceService) {}

  private HEADER_KEY_CURRENT_LAGE = 'ise-current-lage-id';

  /**
   * Ergänzt in den Headern des {@link LageResourceService} jenen für die aktuelle Lage mit dem übergebenen Parameter, wenn der Header nicht existiert.
   *
   * Hinweis: dies sollte nur temporär geschehen und baldmöglichst mittels {@link removeCurrentLageHeader} wieder entfernt werden!
   *
   * @param lageId
   */
  addCurrentLageHeader(lageId: string) {
    if (!this.lageResourceSevice.defaultHeaders.has(this.HEADER_KEY_CURRENT_LAGE)) {
      this.lageResourceSevice.defaultHeaders = this.lageResourceSevice.defaultHeaders.append(
        this.HEADER_KEY_CURRENT_LAGE,
        lageId
      );
    }
  }

  /**
   * Entfernt den Header für die aktuelle Lage, wenn dieser existiert.
   */
  removeCurrentLageHeader() {
    if (this.lageResourceSevice.defaultHeaders.has(this.HEADER_KEY_CURRENT_LAGE))
      this.lageResourceSevice.defaultHeaders = this.lageResourceSevice.defaultHeaders.delete(
        this.HEADER_KEY_CURRENT_LAGE
      );
  }
}
