import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AsyncPipe } from '@angular/common';
import { Component, ElementRef, Input, ViewChild, inject, input } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorService } from '@product/ise-error-lib';
import { Observable, map, startWith } from 'rxjs';

export interface ValueWithId {
  viewValue: string;
  id: string;
}

@Component({
  selector: 'app-chip-fixed-list-input',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
    MatInputModule,
    MatTooltipModule,
  ],
  templateUrl: './chip-fixed-list-input.component.html',
  styleUrl: './chip-fixed-list-input.component.scss',
})
export class ChipFixedListInputComponent {
  // Tasten mit denen die aktuelle Eingabe abgeschlossen wird und eine neue Eingabe beginnt
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  private errorMessageService = inject(ErrorService);
  private formBuilder = inject(NonNullableFormBuilder);

  @Input()
  label? = 'Filter Label';

  @Input()
  itemName? = 'Werte';

  readonly options = input.required<ValueWithId[]>();

  /**
   * ACHTUNG: dient derziet nur zum Austausch der Optionen.
   *
   * Der Controler wird an keine Unter-Komponente gehangen, hier ergeben sich Fehler in Verhalten des Chip-Grids.
   *
   * @todo Control korrekt verwenden!
   *
   */
  @Input()
  control = this.formBuilder.control<ValueWithId[]>([], [Validators.required]);

  inputControl = this.formBuilder.control<string | null>('');
  filteredOptions: Observable<ValueWithId[]>;

  @ViewChild('filterInput') filterInput!: ElementRef<HTMLInputElement>;
  @ViewChild('testField') field!: ElementRef<MatFormField>;

  constructor() {
    this.filteredOptions = this.inputControl.valueChanges.pipe(
      startWith(null),
      map((input) => (input ? this.getFilterOptions(input) : this.getFilterOptionsWithoutSelected()))
    );

    this.control.valueChanges.subscribe(console.log);
  }

  private getFilterOptionsWithoutSelected = () =>
    this.options()
      .filter((op) => !this.control.value.map((v) => v.id).includes(op.id))
      .slice();

  private getFilterOptions(input: string): ValueWithId[] {
    // Wird beim Selektieren eines Chips von irgendwo intern mit falschen typ getriggert
    if (typeof input !== 'string') {
      return [];
    }

    const filterValue = input.trim().toLowerCase();

    return this.getFilterOptionsWithoutSelected()
      .filter((op) => op.viewValue.toLowerCase().includes(filterValue))
      .slice();
  }

  selected(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value as ValueWithId;

    this.control.setValue([...this.control.value, value]);

    this.inputControl.setValue(null);
    this.filterInput.nativeElement.value = '';
  }

  remove(value: ValueWithId) {
    const index = this.control.value.indexOf(value);

    if (index >= 0) {
      this.control.value.splice(index, 1);
      this.control.updateValueAndValidity();
    }

    this.inputControl.setValue(this.inputControl.value);
  }

  validate() {
    this.control.updateValueAndValidity();
    this.inputControl.markAsTouched();
  }

  getErrorMessage(): string {
    return this.errorMessageService.getErrorMessage(this.control);
  }
}
