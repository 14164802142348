import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, inject, Input, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ErrorService } from '@product/ise-error-lib';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import { first } from 'rxjs';
import {
  Einsatzleitungstyp,
  FuehrungsebeneDTO,
  Fuehrungsebenentyp,
  LageDTO,
  SchadenkontoColor,
} from 'src/app/api/build/openapi';
import { ComponentQualificationService } from 'src/app/misc/component-qualification.service';
import { DatetimeLocalAccessorDirective } from 'src/app/shared/accessors/datetime-local-accessor.directive';
import { IconService } from 'src/app/shared/services/icon.service';
import {
  TaktischeZeichenEditorDialogComponent,
  TzEditorDialogInputData,
} from 'src/app/taktische-zeichen/taktische-zeichen-editor-dialog/taktische-zeichen-editor-dialog.component';
import { TaktischesZeichen } from 'taktische-zeichen-core';
import { EinsatzfilterSelectionListComponent } from '../../../einsatz/einsatzfilter-selection-list/einsatzfilter-selection-list.component';
import { SimpleMapComponent } from '../../../shared/simple-map/simple-map.component';
import { AuftragListComponent } from '../../auftrag/auftrag-list/auftrag-list.component';
import { KontaktListComponent } from '../../kontakt/kontakt-list/kontakt-list.component';
import { LayerService } from '../../layer.service';
import { FuehrungsebeneService } from '../fuehrungsebene.service';

@Component({
  selector: 'app-fuehrungsebene-details-panel',
  templateUrl: './fuehrungsebene-details-panel.component.html',
  styleUrls: ['./fuehrungsebene-details-panel.component.scss'],
  standalone: true,
  imports: [
    AuftragListComponent,
    CommonModule,
    DatetimeLocalAccessorDirective,
    EinsatzfilterSelectionListComponent,
    KontaktListComponent,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    ReactiveFormsModule,
    SimpleMapComponent,
  ],
})
export class FuehrungsebeneDetailsPanelComponent implements AfterViewInit {
  Fuehrungsebenentyp = Fuehrungsebenentyp;
  Einsatzleitungstyp = Einsatzleitungstyp;
  SchadenkontoColor = SchadenkontoColor;

  @ViewChild(KontaktListComponent)
  kontaktList!: KontaktListComponent;

  @ViewChild(AuftragListComponent)
  auftragList!: AuftragListComponent;

  @ViewChild(EinsatzfilterSelectionListComponent)
  einsatzfilterList!: EinsatzfilterSelectionListComponent;

  @Input()
  fuehrungsebeneDTO!: FuehrungsebeneDTO;

  @Input()
  readonly = false;

  private dialog = inject(MatDialog);
  private layerService = inject(LayerService);
  private componentService = inject(ComponentQualificationService);
  private errorService = inject(ErrorService);
  protected fuehrungsebeneService = inject(FuehrungsebeneService);
  private iconService = inject(IconService);

  @Input()
  filteredTypes?: Fuehrungsebenentyp[];
  selectableTypes: Fuehrungsebenentyp[] = [];

  showEinsatzleitung = false;
  showMedizinischeRettung = false;
  showEinsatzfilter = false;

  geometryValid = true;
  feature: Feature | undefined;
  other = this.prepareEmptyFeatureCollection();

  @Input()
  zeichenDataUrl?: string;
  customZeichen = false;
  isCustomZeichenHovered = false;

  schadenkontoColorMapping = FuehrungsebeneService.SCHADENKONTO_COLOR_MAPPING;

  private formBuilder = inject(NonNullableFormBuilder);
  fcTyp = this.formBuilder.control<Fuehrungsebenentyp | undefined>(undefined, [Validators.required]);
  fcName = this.formBuilder.control<string>('', [Validators.maxLength(30), Validators.required]);
  fcAdresse = this.formBuilder.control<string | undefined>(undefined);
  fcStartedOn = this.formBuilder.control<Date | undefined>(undefined);
  fcLeiterName = this.formBuilder.control<string | undefined>(undefined);
  fcLeiterDienstgrad = this.formBuilder.control<string | undefined>(undefined);
  fcLeiterFunkrufname = this.formBuilder.control<string | undefined>(undefined);
  fcEinsatzleitung = this.formBuilder.control<boolean>(false);
  fcEinsatzleitungstyp = this.formBuilder.control<Einsatzleitungstyp | undefined>(undefined);
  fcMedizinischeRettung = this.formBuilder.control<boolean>(false);
  fcSchadenkontoColor = this.formBuilder.control<SchadenkontoColor>(SchadenkontoColor.Gray, [Validators.required]);

  formGroup = this.formBuilder.group({
    typ: this.fcTyp,
    name: this.fcName,
    adresse: this.fcAdresse,
    startedOn: this.fcStartedOn,
    leiterName: this.fcLeiterName,
    leiterDienstgrad: this.fcLeiterDienstgrad,
    einsatzleitung: this.fcEinsatzleitung,
    einsatzleitungstyp: this.fcEinsatzleitungstyp,
    medizinischeRettung: this.fcMedizinischeRettung,
    schadenkontoColor: this.fcSchadenkontoColor,
  });

  constructor() {
    this.fcTyp.valueChanges.pipe(takeUntilDestroyed()).subscribe((typ) => {
      this.showMedizinischeRettung = !!typ && FuehrungsebeneService.MED_RETTUNG_FUEHRUNGSEBENETYPEN.includes(typ);
      this.showEinsatzleitung = !!typ && FuehrungsebeneService.EINSATZLEITUNG_FUEHRUNGSEBENENTYPEN.includes(typ);

      // Namensvorschlag passend zum neuen Typen einsetzen (falls kein eigener Name gesetzt)
      if (!this.fuehrungsebeneDTO.id && !this.fcName.dirty && !!typ) {
        this.fcName.setValue(this.fuehrungsebeneService.fuehrungsebenentypMapping.get(typ)?.longname || '');
      }

      // Zeichen passend zum neuen Typen generieren
      if (!this.customZeichen) {
        this.generateFuehrungsebenentypeDataUrl();
      }
    });

    this.fcEinsatzleitung.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      if (value) {
        this.fcEinsatzleitungstyp.enable();
      } else {
        this.fcEinsatzleitungstyp.disable();
        this.fcEinsatzleitungstyp.setValue(undefined);
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const parentFuehrungsebene = this.fuehrungsebeneService
        .getAllFuehrungsebenen()
        .find((fuehrungsebene) => fuehrungsebene.id === this.fuehrungsebeneDTO.parentFuehrungsebeneId);
      if (parentFuehrungsebene) {
        this.fuehrungsebeneService.getAllowedChildTypes().subscribe((allowedTypes) => {
          this.selectableTypes = this.filteredTypes
            ? allowedTypes.filter((type) => this.filteredTypes?.includes(type))
            : allowedTypes;

          if (!this.selectableTypes.includes(this.fuehrungsebeneDTO.typ)) {
            this.selectableTypes = [this.fuehrungsebeneDTO.typ, ...this.selectableTypes];
          }
        });
      }

      this.componentService.isShowDevelopment$.pipe(first()).subscribe((showDevelopment) => {
        this.showEinsatzfilter =
          showDevelopment && FuehrungsebeneService.MAIN_FUEHRUNGSEBENENTYPEN.includes(this.fuehrungsebeneDTO.typ);
      });

      this.modelToFormGroup();
      this.setFeature();

      const preparedOthers = this.prepareEmptyFeatureCollection();

      // Lage zeichnen (falls gerade nicht die Lage selbst bearbeitet wird)
      const lage = this.fuehrungsebeneService.getCurrentLage();
      if (lage && lage.id !== this.fuehrungsebeneDTO.id) {
        this.mergeFeatureCollections(preparedOthers, this.prepareLageFeatureCollection(lage));
      }

      const fuehrungsebenen = this.fuehrungsebeneService.getAllFuehrungsebenen();
      if (fuehrungsebenen) {
        this.mergeFeatureCollections(
          preparedOthers,
          this.prepareFuehrungsebenenFeatureCollection(
            fuehrungsebenen.filter((f) => f.id !== this.fuehrungsebeneDTO.id)
          )
        );
      }

      if (preparedOthers.features.length > 0) {
        this.other = preparedOthers;
      }

      if (this.readonly) {
        this.formGroup.disable();
      }

      window.dispatchEvent(new Event('resize'));
    });
  }

  private setFeature() {
    const style = this.layerService.getFuehrungsebeneGeometryStyle(this.fuehrungsebeneDTO, false);

    const f: Feature = {
      type: 'Feature',
      geometry: this.fuehrungsebeneDTO.geometry as Geometry,
      properties: { style, tooltip: this.fuehrungsebeneDTO.name },
    };
    this.feature = f;
  }

  private mergeFeatureCollections(main: FeatureCollection, other: FeatureCollection) {
    main.features.push(...other.features);
  }

  private prepareEmptyFeatureCollection(): FeatureCollection {
    return { type: 'FeatureCollection', features: [] };
  }

  private prepareFuehrungsebenenFeatureCollection(fuehrungsebenenDTOs: FuehrungsebeneDTO[]) {
    const otherFeatureCollection: FeatureCollection = this.prepareEmptyFeatureCollection();

    fuehrungsebenenDTOs.forEach((f) => otherFeatureCollection.features.push(this.prepareFeature(f)));
    return otherFeatureCollection;
  }

  private prepareLageFeatureCollection(lageDTO: LageDTO) {
    const collection = this.prepareEmptyFeatureCollection();

    const style = this.layerService.getFuehrungsebeneGeometryStyle(lageDTO, false);
    const properties = { style, tooltip: lageDTO.name };

    collection.features.push({
      type: 'Feature',
      geometry: lageDTO.geometry as Geometry,
      properties,
    } as Feature);

    return collection;
  }

  private prepareFeature(fuehrungsebeneDTO: FuehrungsebeneDTO): Feature {
    const style = this.layerService.getFuehrungsebeneGeometryStyle(fuehrungsebeneDTO, false);
    const properties = { style, tooltip: fuehrungsebeneDTO.name };

    return {
      type: 'Feature',
      geometry: fuehrungsebeneDTO.geometry as Geometry,
      properties,
    } as Feature;
  }

  /**
   * Wenn sich der Fuehrungsebenentyp ändert, muss Geometrie auf Karte neugezeichnet werden,
   * damit Icon, Tooltip und Polygonfarbe zum Fuehrungsebenentypen passen
   */
  updateGeometryStyle() {
    if (this.fuehrungsebeneDTO) {
      this.formGroupToModel();
      this.setFeature();
    }
  }

  /**
   * Validiert alle Form-Felder und markiert ungültige Felder.
   */
  validate(): boolean {
    this.geometryValid = !!this.fuehrungsebeneDTO.geometry;

    if (!this.formGroup.valid || !this.geometryValid) {
      this.formGroup.markAllAsTouched();
      return false;
    }

    return true;
  }

  /**
   * Schreibt Werte aus der FormGroup in das aktuelle FuehrungsebeneDTO
   */
  formGroupToModel(): void {
    if (!this.fuehrungsebeneDTO) {
      return;
    }

    if (!this.fcTyp.value) {
      console.warn('Führungsebenentyp ist verpflichtend');
      return;
    }
    this.fuehrungsebeneDTO.typ = this.fcTyp.value;

    this.fuehrungsebeneDTO.adresse = this.fcAdresse.value?.trim();
    this.fuehrungsebeneDTO.auftraege = this.auftragList.getAuftraege();
    this.fuehrungsebeneDTO.customSymbol = this.customZeichen;
    if (this.showEinsatzfilter) {
      this.fuehrungsebeneDTO.einsatzfilter = this.einsatzfilterList.getEinsatzfilterDTOs();
    }
    if (FuehrungsebeneService.EINSATZLEITUNG_FUEHRUNGSEBENENTYPEN.includes(this.fuehrungsebeneDTO.typ)) {
      this.fuehrungsebeneDTO.einsatzleitung = this.fcEinsatzleitung.value;
      this.fuehrungsebeneDTO.einsatzleitungstyp = this.fcEinsatzleitungstyp.value;
    }
    this.fuehrungsebeneDTO.kommunikationOptionen = this.kontaktList.getKommunikationOptionen();
    this.fuehrungsebeneDTO.leiterDienstgrad = this.fcLeiterDienstgrad.value?.trim();
    this.fuehrungsebeneDTO.leiterFunkrufname = this.fcLeiterFunkrufname.value?.trim();
    this.fuehrungsebeneDTO.leiterName = this.fcLeiterName.value?.trim();
    if (FuehrungsebeneService.MED_RETTUNG_FUEHRUNGSEBENETYPEN.includes(this.fuehrungsebeneDTO.typ)) {
      this.fuehrungsebeneDTO.medizinischeRettung = this.fcMedizinischeRettung.value;
    }
    this.fuehrungsebeneDTO.name = this.fcName.value.trim();
    this.fuehrungsebeneDTO.schadenkontoColor = this.fcSchadenkontoColor.value;
    this.fuehrungsebeneDTO.startedOn = this.fcStartedOn.value?.toISOString();
    this.fuehrungsebeneDTO.symbol = this.zeichenDataUrl;
  }

  /**
   * Schreibt Werte aus dem aktuellen FuehrungsebeneDTO in die FormGroup
   */
  modelToFormGroup(): void {
    if (!this.fuehrungsebeneDTO) {
      return;
    }
    this.customZeichen = this.fuehrungsebeneDTO.customSymbol;
    this.formGroup.reset();
    this.fcTyp.setValue(this.fuehrungsebeneDTO.typ);

    this.fcAdresse.setValue(this.fuehrungsebeneDTO.adresse);
    this.auftragList.setAuftraege(this.fuehrungsebeneDTO.auftraege || []);
    if (FuehrungsebeneService.EINSATZLEITUNG_FUEHRUNGSEBENENTYPEN.includes(this.fuehrungsebeneDTO.typ)) {
      this.fcEinsatzleitung.setValue(this.fuehrungsebeneDTO.einsatzleitung || false);
      this.fcEinsatzleitungstyp.setValue(this.fuehrungsebeneDTO.einsatzleitungstyp);
    }
    this.fcLeiterDienstgrad.setValue(this.fuehrungsebeneDTO.leiterDienstgrad);
    this.fcLeiterName.setValue(this.fuehrungsebeneDTO.leiterName);
    if (FuehrungsebeneService.MED_RETTUNG_FUEHRUNGSEBENETYPEN.includes(this.fuehrungsebeneDTO.typ)) {
      this.fcMedizinischeRettung.setValue(this.fuehrungsebeneDTO.medizinischeRettung || false);
    }
    this.fcName.setValue(this.fuehrungsebeneDTO.name);
    this.fcSchadenkontoColor.setValue(this.fuehrungsebeneDTO.schadenkontoColor);
    this.fcStartedOn.setValue(
      this.fuehrungsebeneDTO.startedOn ? new Date(this.fuehrungsebeneDTO.startedOn) : undefined
    );

    if (this.fuehrungsebeneDTO.symbol) {
      this.zeichenDataUrl = this.fuehrungsebeneDTO.symbol;
    } else {
      this.generateFuehrungsebenentypeDataUrl();
    }
  }

  getErrorMessage(formControl: FormControl): string {
    return this.errorService.getErrorMessage(formControl.errors);
  }

  featureChanged = (feature: Feature) => {
    this.fuehrungsebeneDTO.geometry = feature.geometry;
    this.geometryValid = !!this.fuehrungsebeneDTO.geometry;
  };

  showError($event: string) {
    this.errorService.showError({ errorMessages: [$event] });
  }

  openCustomZeichenDialog() {
    const inputData: TzEditorDialogInputData = {
      taktischesZeichen: this.generateStelleTz(this.fcTyp.value),
    };
    this.dialog
      .open(TaktischeZeichenEditorDialogComponent, { data: inputData })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.customZeichen = true;
          this.zeichenDataUrl = result.dataUrl;
          this.fuehrungsebeneDTO.symbol = this.zeichenDataUrl;
          this.setFeature();
        }
      });
  }

  removeCustomZeichen() {
    this.customZeichen = false;
    this.generateFuehrungsebenentypeDataUrl();
  }

  private generateFuehrungsebenentypeDataUrl() {
    this.iconService.generateCompressedDataUrl(this.generateStelleTz(this.fcTyp.value)).subscribe((dataUrl) => {
      this.zeichenDataUrl = dataUrl;
      this.fuehrungsebeneDTO.symbol = this.zeichenDataUrl;
      this.setFeature();
    });
  }

  private generateStelleTz(fuehrungsebenentyp?: Fuehrungsebenentyp): TaktischesZeichen {
    const typShortname = fuehrungsebenentyp
      ? this.fuehrungsebeneService.fuehrungsebenentypMapping.get(fuehrungsebenentyp)?.shortname
      : '';
    return {
      grundzeichen: 'stelle',
      organisation: 'fuehrung',
      text: typShortname,
    };
  }
}
