/**
 * lage-backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MessageDirectionEnum = 'UNSPECIFIED' | 'INCOMING' | 'OUTGOING';

export const MessageDirectionEnum = {
    Unspecified: 'UNSPECIFIED' as MessageDirectionEnum,
    Incoming: 'INCOMING' as MessageDirectionEnum,
    Outgoing: 'OUTGOING' as MessageDirectionEnum
};

