import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AppStateInterface } from 'src/app/+state/appState.interface';
import { FuehrungsebeneUebersichtDTO, PersonenuebersichtDTO } from 'src/app/api/build/openapi';
import { fuehrungsebeneActions } from 'src/app/lagedarstellung/fuehrungsebene/+state/fuehrungsebene.actions';
import {
  currentFuehrungsebeneSelector,
  fuehrungsebeneUebersichtSelector,
  personenuebersichtLageSelector,
} from 'src/app/lagedarstellung/fuehrungsebene/+state/fuehrungsebene.selectors';
import { currentLageSelector } from 'src/app/lagedarstellung/lagen/+state/lage.selectors';
import { fahrzeugActions } from 'src/app/lagedarstellung/taktische-zeichen/fahrzeuge/+state/fahrzeug.actions';
import { actualFahrzeugeSelector } from 'src/app/lagedarstellung/taktische-zeichen/fahrzeuge/+state/fahrzeug.selectors';
import { Manv } from 'src/app/lagedarstellung/triage/manv.interface';
import { Personen } from 'src/app/lagedarstellung/triage/personen.interface';

export interface FuehrungsebeneDataset {
  uebersichtDTO: FuehrungsebeneUebersichtDTO;
  chartData: number[];
}

export interface FahrzeugeDataset {
  fahrzeuge: number;
  staerke1: number;
  staerke2: number;
  staerke3: number;
}

export interface PersonenDataset {
  name: string;
  personen: Personen;
}

export interface ManvDataset {
  name: string;
  manv: Manv;
}

@Injectable({
  providedIn: 'root',
})
export class KennzahlenDashboadService {
  readonly indexAnlass = 0;
  readonly indexGefahren = 1;
  readonly indexMassnahmen = 2;

  private fuehrungsebeneDatasets$: BehaviorSubject<FuehrungsebeneDataset[]> = new BehaviorSubject<
    FuehrungsebeneDataset[]
  >([]);

  private fahrzeugeDataset$: BehaviorSubject<FahrzeugeDataset> = new BehaviorSubject<FahrzeugeDataset>({
    fahrzeuge: 0,
    staerke1: 0,
    staerke2: 0,
    staerke3: 0,
  });
  private personenBevoelkerungDataset$: BehaviorSubject<PersonenDataset> = new BehaviorSubject<PersonenDataset>({
    name: 'Bevölkerung',
    personen: this.defaultPersonen(),
  });
  private personenEinsatzkraefteDataset$: BehaviorSubject<PersonenDataset> = new BehaviorSubject<PersonenDataset>({
    name: 'Einsatzkräfte',
    personen: this.defaultPersonen(),
  });

  private personenManvDataset$: BehaviorSubject<ManvDataset> = new BehaviorSubject<ManvDataset>({
    name: 'MANV',
    manv: { category1: 0, category2: 0, category3: 0, category4: 0, dead: 0 },
  });

  private store: Store<AppStateInterface> = inject(Store);
  private currentLageId?: string;
  private currentFuehrungsebeneId?: string;

  constructor() {
    combineLatest([this.store.select(currentLageSelector), this.store.select(currentFuehrungsebeneSelector)])
      .pipe(takeUntilDestroyed())
      .subscribe(([currentLage, currentFuehrungsebene]) => {
        this.currentLageId = currentLage?.id;
        this.currentFuehrungsebeneId = currentFuehrungsebene?.id;
        this.reload();
      });

    this.store
      .select(fuehrungsebeneUebersichtSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((uebersicht) => this.generateFuehrungsebeneUebersichtDataset(uebersicht));

    this.store
      .select(actualFahrzeugeSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((fahrzeuge) => {
        const count = [0, 0, 0];
        fahrzeuge.forEach((f) => {
          count[0] += f.mannschaftsstaerke1 || 0;
          count[1] += f.mannschaftsstaerke2 || 0;
          count[2] += f.mannschaftsstaerke3 || 0;
        });
        this.fahrzeugeDataset$.next({
          fahrzeuge: fahrzeuge.length,
          staerke1: count[0],
          staerke2: count[1],
          staerke3: count[2],
        });
      });

    this.store
      .select(personenuebersichtLageSelector)
      .pipe(takeUntilDestroyed())
      .subscribe((personenuebersicht) => {
        if (!personenuebersicht) {
          return;
        }
        this.generatePersonenschaefenDatasets(personenuebersicht);
      });
  }

  private generateFuehrungsebeneUebersichtDataset(uebersichtDTOs?: FuehrungsebeneUebersichtDTO[]) {
    if (!uebersichtDTOs) {
      return;
    }

    this.fuehrungsebeneDatasets$.next([
      ...uebersichtDTOs
        .map((dto) => ({
          uebersichtDTO: dto,
          chartData: [dto.anlaesseGesamt, dto.gefahrenGesamt, dto.massnahmenGesamt],
        }))
        .values(),
    ]);
  }

  private defaultPersonen(): Personen {
    return { betroffen: 0, gerettet: 0, tot: 0, transportiert: 0, verletzt: 0, vermisst: 0 };
  }

  generatePersonenschaefenDatasets(uebersicht: PersonenuebersichtDTO) {
    this.personenBevoelkerungDataset$.next({
      name: 'Bevölkerung',
      personen: {
        verletzt: uebersicht.bevVerletzt || 0,
        tot: uebersicht.bevTot || 0,
        vermisst: uebersicht.bevVermisst || 0,
        betroffen: uebersicht.bevBetroffen || 0,
        gerettet: uebersicht.bevGerettet || 0,
        transportiert: uebersicht.bevTransportiert || 0,
      },
    });
    this.personenEinsatzkraefteDataset$.next({
      name: 'Einsatzkräfte',
      personen: {
        verletzt: uebersicht.ekVerletzt || 0,
        tot: uebersicht.ekTot || 0,
        vermisst: uebersicht.ekVermisst || 0,
        betroffen: uebersicht.ekBetroffen || 0,
        gerettet: uebersicht.ekGerettet || 0,
        transportiert: uebersicht.ekTransportiert || 0,
      },
    });
    this.personenManvDataset$.next({
      name: 'MANV',
      manv: {
        category1: uebersicht.manv1 || 0,
        category2: uebersicht.manv2 || 0,
        category3: uebersicht.manv3 || 0,
        category4: uebersicht.manv4 || 0,
        dead: uebersicht.manvTote || 0,
      },
    });
  }

  subscribeFuehrungsebenen(): Observable<FuehrungsebeneDataset[]> {
    return this.fuehrungsebeneDatasets$.asObservable();
  }

  subscribeFahrzeuge(): Observable<FahrzeugeDataset> {
    return this.fahrzeugeDataset$.asObservable();
  }

  subscribePersonenBevoelkerung(): Observable<PersonenDataset> {
    return this.personenBevoelkerungDataset$.asObservable();
  }

  subscribePersonenEinsatzkraefte(): Observable<PersonenDataset> {
    return this.personenEinsatzkraefteDataset$.asObservable();
  }

  subscribePersonenManv(): Observable<ManvDataset> {
    return this.personenManvDataset$.asObservable();
  }

  reload() {
    if (!this.currentLageId || !this.currentFuehrungsebeneId) {
      return;
    }

    this.store.dispatch(
      fuehrungsebeneActions.getFuehrungsebeneuebersicht({
        lageId: this.currentLageId,
        fuehrungsebeneId: this.currentFuehrungsebeneId,
      })
    );
    this.store.dispatch(fahrzeugActions.getFahrzeuge({ lageId: this.currentLageId }));
    this.store.dispatch(fuehrungsebeneActions.getPersonenuebersichtLage({ lageId: this.currentLageId }));
  }
}
