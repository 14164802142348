<mat-toolbar class="sub-toolbar">
  <h2>Lagen</h2>
  <button mat-icon-button (click)="refreshLagen()" matTooltip="Aktualisieren">
    <mat-icon>refresh</mat-icon>
  </button>
  <div class="spacer"></div>
  <span>
    <button mat-icon-button (click)="fireAddEvent()" matTooltip="Lage hinzufügen">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-icon-button (click)="fireEditEvent()" matTooltip="Lage bearbeiten">
      <mat-icon>edit</mat-icon>
    </button>
  </span>
</mat-toolbar>
