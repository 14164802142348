import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import '@geoman-io/leaflet-geoman-free';
import pointOnFeature from '@turf/point-on-feature';
import { Feature, FeatureCollection, GeoJsonObject, Geometry } from 'geojson';
import * as L from 'leaflet';
import { control } from 'leaflet';
import { filter, take } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { LayerConfigService } from 'src/app/planung/karte/layer-config.service';
import { DragAndDropDirective } from '../directives/drag-and-drop.directive';

/**
 * WIP
 * Erster Wurf einer "einfachen" Karte für z.B. Anzeige oder Eingabe in Formularen
 *
 * Mittelfistiges Ziel: Karte, mit der Geometrien dargestellt/ausgewählt werden können. Zugriff auf zentral hinterlegte Layer.
 *
 *
 * Aktuell nur Selektion und Event für Koordinate = Klick auf Karte
 */
@Component({
  selector: 'app-simple-map',
  templateUrl: './simple-map.component.html',
  styleUrls: ['./simple-map.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    DragAndDropDirective,
  ],
})
export class SimpleMapComponent implements OnInit, OnChanges {
  /** Das Feature, das bearbeitet werden kann */
  @Input() feature?: Feature;

  /**  Informiert über Änderungen am übergebenen Feature `feature` indem es ein Neues liefert */
  @Output() featureChanged = new EventEmitter<Feature>();

  /** Sollte sich ein Fehler in der Karte ergben, wird hierüber eine Fehlermeldung veröffnetlicht */
  @Output() errorHint = new EventEmitter<string>();

  /**
   * Andere Geometrien die angezeigt werden sollen.
   * Nur zur Anzeige, sie sind nicht veränderbar.
   */
  @Input() other?: FeatureCollection;

  @Input()
  readonly = false;

  private map?: L.Map;
  /** Hintergrundkarte */
  private baseLayer?: L.TileLayer;

  /** FeatureGruppe, die nur angezeigt wird */
  private nonEditableGroup = L.featureGroup(undefined, { pmIgnore: true, snapIgnore: false });

  /** FeatureGruppe, auf der gearbeitet wreden soll  */
  private drawGroup = L.featureGroup();
  private drawGroupLayersEditCopy?: L.Layer[];

  /**
   * Helfer für Struktur.
   * Hier fügt Geoman neue Layer ein. Werden "abgefangen" und weiterverarbeitet.
   * Sollte nach Verarbeitungen leer sein.
   */
  private geomanWorkingGroup = L.featureGroup();

  /** Standard-/Fallback-Icon */
  private defaultIcon = L.icon({
    iconUrl: 'assets/svg/ise_pin.svg',
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  private defaultPolylineStyle = { color: '#ff017b', weight: 3 };

  private layerConfigService = inject(LayerConfigService);
  private appService = inject(AppService);

  ngOnInit(): void {
    this.initMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Das Feature, was bearbeitet werden soll
    if (changes['feature']) {
      const feature = changes['feature'].currentValue as Feature;

      if (feature && feature.geometry) {
        this.drawFeature(feature);
      }

      // Marker-Stil zum Zeichnen übernhemen
      if (feature && feature.properties) {
        const markerOptions = this.extractMarkerOptions(feature, true);
        this.map?.pm.setGlobalOptions({ ...this.map?.pm.getGlobalOptions(), markerStyle: markerOptions });
      }
    }

    // Andere Features, die dargestellt werden sollen
    if (changes['other']) {
      const other = changes['other'].currentValue as FeatureCollection;

      if (other) {
        const otherlayers = this.prepareGeoJsonLayers(other);
        this.nonEditableGroup.clearLayers();
        otherlayers.addTo(this.nonEditableGroup);

        if (this.drawGroup.getLayers().length === 0) {
          this.map?.fitBounds(this.nonEditableGroup.getBounds());
        }
      }
    }
  }

  private initMap(): void {
    this.map = L.map('simple-map', { zoomControl: false });
    // initial auf DE zoomen
    this.map.fitBounds(this.appService.getSettingsMapBounds());

    // Geoman allgemein
    this.map.pm.setGlobalOptions({
      layerGroup: this.geomanWorkingGroup,
      limitMarkersToCount: 20,
      markerStyle: { icon: this.defaultIcon },
    });

    this.map.pm.setLang('de');

    // Hintergrundkarte
    this.setBaseLayer();

    // Gruppen zum Arbeiten und nur Darstellen hinzufügen
    this.drawGroup.addTo(this.map);
    this.nonEditableGroup.addTo(this.map);

    // Controls konfigurieren
    control.zoom({ position: 'topright' }).addTo(this.map);
    control
      .scale({
        imperial: false,
      })
      .addTo(this.map);

    if (!this.readonly) {
      this.map.pm.addControls({
        position: 'topleft',
        drawCircle: false,
        drawText: false,
        drawCircleMarker: false,
        drawRectangle: false,
        drawPolyline: false,
        cutPolygon: false,
        rotateMode: false,
        removalMode: false,
      });

      // Context-Menü bricht Bearbeitung ab
      this.map.on('contextmenu', () => {
        this.map?.pm.disableDraw();
        /* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
        this.map?.pm.globalEditModeEnabled() ? this.map?.pm.disableGlobalEditMode() : {};
      });

      // Aktuelle Layer zwischenspeichern, falls Abbruch gedrückt wird.
      this.map.on('pm:drawstart', (_) => this.prepareDrawGoup());

      // Wenn Abbruch (vermutet), dann letzte Layer wieder darstellen
      this.map.on('pm:drawend', (_) => this.restoreIfCanceled());

      // Wenn neue Geometire gezeichnet, entsprechnd unserer Darstellung anpassen
      this.map.on('pm:create', (e) => {
        const feature = this.layerToSingleFeature(e.layer);

        e.layer.remove();
        const newFeatureLayer = this.prepareGeoJsonLayers(feature, true, this.editCallback);
        newFeatureLayer.addTo(this.drawGroup);

        this.updateFeatureAndEmit(feature);

        // Nur einen Marker zeichnen lassen; Zeichnen beenden
        if (e.shape === 'Marker') {
          this.map?.pm.disableDraw();
        }
      });
    }
  }

  /** Holt die zentral gesetzte Hintergrundkarte und setzt sie für diese Karte  */
  private setBaseLayer() {
    this.layerConfigService.selectedBaseLayerConfig$
      .pipe(
        // config service liefert zunächst undefined, während vom Backend Layer geladen werden
        filter((v) => v !== undefined),
        take(1)
      )
      .subscribe((baseLayerConfig) => {
        if (this.baseLayer) {
          this.map?.removeLayer(this.baseLayer);
        }

        // ! undefined wird gefiltert
        this.baseLayer = this.layerConfigService.createTileLayerFromConfig(baseLayerConfig!);

        if (this.baseLayer) {
          this.map?.addLayer(this.baseLayer);
        }
      });
  }

  private drawFeature(feature: Feature) {
    this.drawGroup.clearLayers();
    const featureLayer = this.prepareGeoJsonLayers(feature, true, this.editCallback);

    featureLayer.addTo(this.drawGroup);
    this.map?.fitBounds(featureLayer.getBounds());
  }

  /** Aktualisiert die Geometrie im übergebenen `feature` und feuert ein Event mit diesem  */
  private updateFeatureAndEmit(feature: Feature) {
    this.feature = { ...this.feature, geometry: feature.geometry } as Feature;
    this.featureChanged.emit(this.feature);
  }

  /** Callback, der aus dem übergebenen `layer` ein Feature extrahiert und dieses als neues `feature` meldet. */
  private editCallback = (e: { shape: string; layer: L.Layer }) => {
    this.updateFeatureAndEmit(this.layerToSingleFeature(e.layer));
  };

  /** Bereitet einen GeoJSON Layer vor, der unserer Darstellung entspricht */
  private prepareGeoJsonLayers(
    json: GeoJsonObject,
    editable = false,
    editListener = (e: { shape: string; layer: L.Layer }) => {
      /* default NO-OP */
    }
  ) {
    const editingFeature = this.feature && this.isEditingFeature() && this.feature.properties;

    const extractMarkerOptions = editingFeature
      ? () => this.extractMarkerOptions(this.feature, editable)
      : (feature: Feature) => this.extractMarkerOptions(feature, editable);

    const extractPolygonOptions = editingFeature
      ? () => this.extractPolygonOptions(this.feature, editable)
      : (feature: Feature) => this.extractPolygonOptions(feature, editable);

    const extractTooltip = this.extractTooltip;
    const layerToSingleFeature = this.layerToSingleFeature;

    // --------------------------------------------------

    const result = L.geoJSON();
    const options: L.GeoJSONOptions = {
      pointToLayer(_, latlng) {
        const marker = L.marker(latlng, extractMarkerOptions({} as Feature));
        return marker;
      },
      onEachFeature(feature, layer) {
        const tooltip = extractTooltip(feature);

        switch (feature.geometry.type) {
          case 'Point': {
            const coord = feature.geometry.coordinates;
            const marker = L.marker([coord[1], coord[0]], extractMarkerOptions(feature));

            if (tooltip) {
              marker.bindTooltip(tooltip);
            }

            marker.addTo(result);

            if (editable) {
              marker.on('pm:edit', (e) => {
                editListener(e);
              });
            }
            break;
          }
          case 'LineString': {
            L.geoJSON(feature, extractPolygonOptions(feature)).addTo(result);
            break;
          }
          case 'Polygon':
          case 'MultiPolygon': {
            // FIXME WORKAROUND - Lagekarte stellt kein Multipolygon dar
            // kein Fallthrough, da linter meckert
            if (feature.geometry.type == 'MultiPolygon') {
              // Feature von MultiPolygon in erstes Poylgon ändern ...
              const modFeature = feature;
              modFeature.geometry.type = 'Polygon';
              if (modFeature.geometry.type === 'Polygon') {
                const pos = feature.geometry.coordinates[0];
                modFeature.geometry.coordinates = pos;
              }

              feature = modFeature;
            }

            // FIXME Koordinate kann auf der Kante liegen --> unschön --> mölicher Ansatz siehe 'geometry-helpers.ts'
            const centerCoords = pointOnFeature(feature as Feature).geometry.coordinates;
            const marker = L.marker([centerCoords[1], centerCoords[0]], extractMarkerOptions(feature));

            if (tooltip) {
              marker.bindTooltip(tooltip);
            }

            const l = layer as L.GeoJSON;
            const polyOpts = extractPolygonOptions(feature);
            l.setStyle(polyOpts);

            const polyWithCenter = L.featureGroup([l, marker]);
            polyWithCenter.addTo(result);

            if (editable) {
              layer.on('pm:edit', (e) => {
                // Marker für Zentrum aktualisieren, wenn editiert
                const centerCoords = pointOnFeature(layerToSingleFeature(e.layer) as Feature).geometry.coordinates;
                marker.setLatLng([centerCoords[1], centerCoords[0]]);
                editListener(e);
              });
            }
            break;
          }
        }
      },
    };

    // --------------------------------------------------

    const tmpLayer = L.geoJSON(undefined, { ...options });
    tmpLayer.addData(json);

    return result;
  }

  private isEditingFeature() {
    return this.drawGroup.getLayers().length === 0 && this.drawGroupLayersEditCopy;
  }

  private prepareDrawGoup() {
    this.drawGroupLayersEditCopy = this.drawGroup.getLayers();
    this.drawGroup.clearLayers();
  }

  /**
   * Versucht zu erkennen, ob das Zeichnen abgebrochen wurde und stellt dann den alten Inhalt wieder her.
   */
  private restoreIfCanceled() {
    // Gruppe zum Zeichnen leer und Zwischenspeicher aber befüllt
    if (
      this.drawGroup.getLayers().length === 0 &&
      this.drawGroupLayersEditCopy &&
      this.drawGroupLayersEditCopy.length > 0
    ) {
      this.drawGroupLayersEditCopy.forEach((l) => l.addTo(this.drawGroup));
    }

    this.drawGroupLayersEditCopy = undefined;
  }

  /**
   * Extrahiert das erste Feature aus einem leaflet-Layer
   *
   * @param layer
   * @returns
   */
  private layerToSingleFeature = (layer: L.Layer | L.Path | L.Marker): Feature => {
    const tmp = L.geoJSON().addLayer(layer);
    const fc = tmp.toGeoJSON();

    if (fc.type === 'FeatureCollection') {
      // FIXME ? - was ist hier los
      // if (fc.features[0].geometry.type === 'MultiPolygon') {
      //   fc.features[0].geometry;
      // }

      return fc.features[0];
    } else {
      throw new Error('Unerwartete Geometrie');
    }
  };

  /**
   * Erzeug einen Tooltip, wenn das übergebe Feature die entsprechende Property enthält
   *
   * @param feature
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private extractTooltip = (feature: Feature<Geometry, any> | undefined): L.Tooltip | undefined => {
    if (feature && feature.properties && feature.properties.tooltip) {
      return L.tooltip({ content: feature.properties.tooltip, direction: 'top' });
    }
    return undefined;
  };

  /**
   * Standard-Optionen für leaflet-Layer in Verbindung mit Geoman.
   *
   * @param editable
   * @returns
   */
  private getDefaultOptions = (editable: boolean): L.GeoJSONOptions => {
    return editable ? {} : { pmIgnore: true, snapIgnore: false };
  };

  /**
   * Extrahiert mögliche MarkerOptions aus den Feature-Properties
   *
   * @param feature
   * @param editable
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private extractMarkerOptions = (feature: Feature<Geometry, any> | undefined, editable: boolean) => {
    const defaultOpts = this.getDefaultOptions(editable);

    if (!feature || !feature.properties || !feature.properties.style) {
      return { ...defaultOpts, icon: this.defaultIcon };
    }

    return { ...defaultOpts, ...feature.properties.style.markerOptions };
  };

  /**
   * Extrahiert mögliche PolygonOptions aus den Feature-Properties
   *
   * @param feature
   * @param editable
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private extractPolygonOptions = (feature: Feature<Geometry, any> | undefined, editable: boolean) => {
    const defaultOpts = this.getDefaultOptions(editable);

    if (!feature || !feature.properties || !feature.properties.style) {
      return { ...defaultOpts, ...this.defaultPolylineStyle };
    }

    return { ...defaultOpts, ...feature.properties.style.polygonOptions };
  };

  /** Blendet die Features aus `other` aus bzw. ein */
  toggleOther = () => {
    /* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
    this.map?.hasLayer(this.nonEditableGroup)
      ? this.map?.removeLayer(this.nonEditableGroup)
      : this.map?.addLayer(this.nonEditableGroup);
  };

  /**
   * Wird eine Datei auf der Karte fallengelassen, wird versucht, diese als GeoJSON einzulesen.
   *
   * @param files
   */
  filesDropped(files: FileList) {
    // nur eine Datei unterstützt
    const file = files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        this.addOrUpdateGeoJSON(event.target?.result as string);
      };
      fileReader.readAsText(file);
    }
  }

  /**
   * Prüft, ob der übergebene String valides GeoJSON ist (für uns), und aktualisiert das zu erstellende/editierdene Feature.
   * @todo Verallgemeinerung dieser Klasse
   *
   * @param potentialGeoJSON möglicher GeoJSON-String
   *
   * @throws Exception, wenn GeoJSON nicht geparsed werden kann.
   */
  private addOrUpdateGeoJSON = (potentialGeoJSON: string) => {
    try {
      const importedJSON = JSON.parse(potentialGeoJSON);

      // Auf veraltete Angabe von CRS prüfen.
      // RFC sieht den Parameter nicht vor, existierte aber in alter Version.
      // Manches GeoJSON enthält dies und versucht darüber andere CRS statt WGS84 zu setzten:
      // https://datatracker.ietf.org/doc/html/rfc7946#section-4
      if (importedJSON.crs) {
        const crs = '' + importedJSON.crs.properties?.name;
        // Fehler, wenn nicht WGS84
        if (!crs.toLocaleLowerCase().endsWith('crs84')) {
          const errorMsg =
            'GeoJSON mit fehlerhafter CRS-Angabe (' +
            crs +
            ') erkannt. Siehe: https://datatracker.ietf.org/doc/html/rfc7946#section-4';

          this.errorHint.emit(errorMsg);
          return;
        }
      }

      const optionalGeoJSON = importedJSON as
        | GeoJSON.FeatureCollection
        | GeoJSON.Feature
        | GeoJSON.Polygon
        | GeoJSON.MultiPolygon;

      const geojson = L.geoJSON(optionalGeoJSON);
      this.updateFeatureAndEmit(this.layerToSingleFeature(geojson));
      if (this.feature) {
        this.drawFeature(this.feature);
      }
    } catch (e) {
      console.error(e);
    }
  };
}
